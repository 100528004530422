import {
  ChartTooltipProps,
  getFilteredChartTooltipPayload,
} from "@mantine/charts"
import { Badge, Box, Divider, Flex, Group, Paper, Text } from "@mantine/core"
import _ from "lodash"
import { useMemo } from "react"

import { EventsResponses } from "@costory/types/endpoints/events"
import { AggBy } from "@costory/types/prisma-client"

import { tickFormatter } from "@costory/front/utils/charts"
import { formatNumber } from "@costory/front/utils/format"

export const BUSINESS_METRIC_NAME = "Metric"
// Must be aligned with dataKey in StackedBarChart
export const BUSINESS_METRIC_TECHNICAL_NAME = "business_metric"

interface ExtendedChartTooltipProps extends ChartTooltipProps {
  currency: "USD" | "EUR"
  tooltipData?: {
    groupByValue: string
    periodCost: number
    previousPeriodCost: number
    change: number
    relativeChange: number
  }[]
  coordinate?: { x: number }
  aggBy?: AggBy
}

export const ChartTooltip = ({
  label,
  payload,
  tooltipData,
  currency,
  coordinate,
  aggBy,
}: ExtendedChartTooltipProps) => {
  const displayLabel = useMemo(
    () => label ?? payload?.[0]?.name ?? "",
    [label, payload],
  )
  const totalValue = useMemo(
    () =>
      _.sumBy(
        payload?.filter(
          (el) =>
            el.name !== BUSINESS_METRIC_TECHNICAL_NAME &&
            el.name !== BUSINESS_METRIC_NAME,
        ),
        (p) => p.value,
      ),
    [payload],
  )

  const tooltip = useMemo(
    () =>
      _.find(tooltipData, {
        groupByValue: displayLabel,
      }),
    [displayLabel, tooltipData],
  )

  if (!payload || payload.length === 0) return null
  const events = payload[0].payload[
    "events"
  ] as EventsResponses.Aggregated["events"]
  const businessMetric = payload.find(
    (el) =>
      el.name === BUSINESS_METRIC_TECHNICAL_NAME ||
      el.name === BUSINESS_METRIC_NAME,
  )
  return (
    <Paper
      withBorder
      pos={coordinate ? "absolute" : "relative"}
      w={350}
      style={{
        left: coordinate?.x ?? 0,
        transform: tooltip ? "translateX(-5vw)" : "none",
        zIndex: 1000,
      }}
    >
      <Flex mb="sm" justify="space-between" align="center">
        <Text
          fw={700}
          fz="md"
          maw={350}
          mr="md"
          style={{
            ...(tooltip
              ? {
                  whiteSpace: "normal",
                  overflowWrap: "break-word",
                  wordBreak: "break-word",
                }
              : {}),
          }}
        >
          {aggBy ? tickFormatter(displayLabel, aggBy) : displayLabel}
        </Text>
        {tooltip ? (
          <Box>
            <Badge
              size="lg"
              color={
                tooltip?.relativeChange && tooltip.relativeChange > 0
                  ? "red.5"
                  : "green.5"
              }
            >
              {formatNumber(tooltip.relativeChange, "percent", 0, currency)}
            </Badge>
          </Box>
        ) : (
          <Text fz="md">
            {formatNumber(totalValue, "currency", 2, currency)}
          </Text>
        )}
      </Flex>
      <Divider />
      {getFilteredChartTooltipPayload(payload)
        .filter(
          (el) =>
            el.name !== BUSINESS_METRIC_TECHNICAL_NAME &&
            el.name !== BUSINESS_METRIC_NAME,
        )
        .map(({ name, value, color, payload }) => (
          <Group
            key={name}
            gap={1}
            mt={8}
            justify="space-between"
            wrap="nowrap"
          >
            <Group gap={8} wrap="nowrap" mr={30}>
              <Box
                w={8}
                h={8}
                style={{ borderRadius: 4 }}
                bg={color || payload.fill}
              />
              <Text
                fw={700}
                mr="sm"
                style={{
                  whiteSpace: "normal",
                  overflowWrap: "break-word",
                  wordBreak: "break-word",
                }}
              >
                {name}
              </Text>
            </Group>
            <Text fz="md">{formatNumber(value, "currency", 2, currency)}</Text>
          </Group>
        ))}
      {events && events.length > 0 && <Divider />}
      {events &&
        events.map((el) => (
          <Group
            key={el.name}
            gap={1}
            mt={8}
            justify="space-between"
            wrap="nowrap"
          >
            <Group gap={8} wrap="nowrap" mr={30}>
              <Box w={8} h={8} style={{ borderRadius: 4 }} bg="red.6" />
              <Text
                fw={700}
                mr="sm"
                style={{
                  whiteSpace: "normal",
                  overflowWrap: "break-word",
                  wordBreak: "break-word",
                }}
              >
                {el.name}
              </Text>
            </Group>
          </Group>
        ))}
      {businessMetric && (
        <Group
          key="Metric"
          gap={1}
          mt={8}
          justify="space-between"
          wrap="nowrap"
        >
          <Text
            fw={700}
            mr="sm"
            style={{
              whiteSpace: "normal",
              overflowWrap: "break-word",
              wordBreak: "break-word",
            }}
          >
            Metric
          </Text>
          <Text fz="md">
            {formatNumber(businessMetric.value, "decimal", 2)}
          </Text>
        </Group>
      )}
    </Paper>
  )
}
