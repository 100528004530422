import { GridChartBaseProps } from "@mantine/charts"
import {
  ActionIcon,
  Box,
  Flex,
  Group,
  SegmentedControl,
  Slider,
  Stack,
  ThemeIcon,
  Tooltip,
} from "@mantine/core"
import { useDisclosure } from "@mantine/hooks"
import { IconChartBar, IconHelpCircle, IconTimeline } from "@tabler/icons-react"
import dayjs from "dayjs"
import { useState } from "react"
import { ReferenceLinePosition } from "recharts/types/cartesian/ReferenceLine"
import { CategoricalChartState } from "recharts/types/chart/types"
import { LabelPosition } from "recharts/types/component/Label"

import { EventsResponses } from "@costory/types/endpoints/events"
import { MetricsResponses } from "@costory/types/endpoints/metrics"
import { Filters } from "@costory/types/filters"
import { ExplorerChartType } from "@costory/types/prisma-client"

import { AggregateBy, EventsTags } from "@costory/front/components/FilterBar"
import { AreaChart } from "@costory/front/components/charts/AreaChart"
import { LineChart } from "@costory/front/components/charts/LineChart"
import { StackedBarChart } from "@costory/front/components/charts/StackedBarChart"
import { WaterfallChart } from "@costory/front/components/charts/WaterfallChart"
import { QueryWrapper } from "@costory/front/components/layout/QueryWrapper"
import { useChartContext } from "@costory/front/contexts/ChartContext"
import { ModalZoomOnEvents } from "@costory/front/pages/Events/ModalZoomOnEvents"
import { NewEventForm } from "@costory/front/pages/Events/NewEventForm"
import { useEventsQuery } from "@costory/front/queries/explore"
import { getBestDrillDown } from "@costory/front/utils/columns"

export type HandlerEvents = {
  eventClickOnChart: (
    events: EventsResponses.Aggregated[],
    val: CategoricalChartState,
  ) => void
  createEventFromXAxisClick: (event: { value: string }) => void
  eventDefaultDate: Date
  selectedEvents: EventsResponses.Aggregated | undefined
  openedSelectedEvents: boolean
  openedNewEvent: boolean
}

type Props = {
  filters: Filters
  height?: number
  withLegend?: boolean
  explorerChartType?: ExplorerChartType
  drillDownInto?: (
    groupBy: string,
    value: string,
    newGroupBy: string,
    mode: "sameWindow" | "newTab",
  ) => void
  setExplorerChartType?: (chartType: ExplorerChartType) => void
  referenceLines?: GridChartBaseProps["referenceLines"]
  setThreshold?: (value: number | null) => void
  threshold?: number | null
  selectedMetric?: MetricsResponses.Metric
}

export const ExplorerMainChart = (props: Props) => {
  const { filterForm } = useChartContext()
  // const showInput = <Show form={filterForm} />
  const aggregateByInput = <AggregateBy form={filterForm} />
  const eventsTagsInput = <EventsTags form={filterForm} />

  const [thresholdForViz, setThresholdForViz] = useState(props.threshold || 0)
  const [eventDefaultDate, setEventDate] = useState(new Date())
  const [selectedEvents, setSelectedEvents] =
    useState<EventsResponses.Aggregated>()
  const [openedSelectedEvents, handlersOpenSelectedEvents] =
    useDisclosure(false)
  const [openedNewEvent, handlersNewEventModal] = useDisclosure(false)
  function createEventFromXAxisClick(event: { value: string }) {
    const date = event.value
    setEventDate(dayjs(date).toDate())
    handlersNewEventModal.open()
  }
  function eventClickOnChart(
    events: EventsResponses.Aggregated[],
    val: CategoricalChartState,
  ) {
    const filteredEvents = events.filter((el) => el.date === val.activeLabel!)
    // if click on Chart where there are events open modal
    if (filteredEvents.length > 0) {
      setSelectedEvents(filteredEvents[0])
      handlersOpenSelectedEvents.open()
    }
  }
  const handlerEvents = {
    eventClickOnChart,
    createEventFromXAxisClick,
    eventDefaultDate,
    selectedEvents,
    openedSelectedEvents,
    openedNewEvent,
  }
  const eventsQuery = useEventsQuery(props.filters)

  const { currency } = filterForm.getValues()
  const sliderLabelFactory = (value: number) => `${currency} ${value}`

  return (
    <>
      {openedNewEvent && (
        <NewEventForm
          isOpen={openedNewEvent}
          onClose={handlersNewEventModal.close}
          defaultDate={eventDefaultDate}
        />
      )}
      {selectedEvents && (
        <ModalZoomOnEvents
          events={selectedEvents}
          isOpened={openedSelectedEvents}
          onClose={handlersOpenSelectedEvents.close}
        />
      )}
      <Stack h="100%">
        <Flex
          gap={15}
          direction="row"
          align="flex-end"
          style={{ justifyContent: "space-between" }}
        >
          <Group align="flex-end">
            {aggregateByInput}
            {/* Uncomment the line below to restore show input */}
            {/* {showInput} */}
            {props.explorerChartType !== ExplorerChartType.WATERFALL &&
              eventsTagsInput}
          </Group>
          <Group align="center" style={{ "--group-justify": "flex-end" }}>
            {props.explorerChartType === ExplorerChartType.WATERFALL &&
              props.setThreshold && (
                <Flex align="center" gap={15}>
                  <Tooltip
                    w={400}
                    multiline={true}
                    label="Combine all items with an absolute value below this threshold."
                  >
                    <ThemeIcon
                      style={{ cursor: "pointer" }}
                      c="primary.6"
                      size="sm"
                    >
                      <IconHelpCircle />
                    </ThemeIcon>
                  </Tooltip>
                  <Box w={200}>
                    <Slider
                      max={1000}
                      step={250}
                      restrictToMarks
                      labelAlwaysOn
                      label={(value) => sliderLabelFactory(value)}
                      value={thresholdForViz}
                      onChange={(value) => {
                        setThresholdForViz(value)
                      }}
                      onChangeEnd={(value) => {
                        props.setThreshold!(value)
                      }}
                    />
                  </Box>
                </Flex>
              )}
            {props.explorerChartType && (
              <SegmentedControl
                data={[
                  {
                    value: ExplorerChartType.BAR,
                    label: (
                      <Tooltip label="Bar">
                        <ActionIcon
                          size="sm"
                          style={{ pointerEvents: "none" }}
                          aria-label="Bar"
                        >
                          <IconChartBar size="inherit" />
                        </ActionIcon>
                      </Tooltip>
                    ),
                  },
                  {
                    value: ExplorerChartType.LINE,
                    label: (
                      <Tooltip label="Line">
                        <ActionIcon
                          size="sm"
                          style={{ pointerEvents: "none" }}
                          aria-label="Line"
                        >
                          <IconTimeline size="inherit" />
                        </ActionIcon>
                      </Tooltip>
                    ),
                  },
                  {
                    value: ExplorerChartType.WATERFALL,
                    label: (
                      <Tooltip label="Waterfall">
                        <ActionIcon
                          size="sm"
                          style={{ pointerEvents: "none" }}
                          aria-label="Waterfall"
                        >
                          <img src="/img/waterfall.svg" alt="" />
                        </ActionIcon>
                      </Tooltip>
                    ),
                  },
                ]}
                value={props.explorerChartType}
                onChange={(value) => {
                  return (
                    value &&
                    props.setExplorerChartType?.(value as ExplorerChartType)
                  )
                }}
              />
            )}
          </Group>
        </Flex>
        <QueryWrapper query={eventsQuery} allowEmptyArray>
          {({ data: events }) => {
            const referencedLinesEvents = parseEventsToReferenceLines(events)
            return (
              <Graph
                {...props}
                referenceLines={
                  props.referenceLines
                    ? props.referenceLines.concat(referencedLinesEvents || [])
                    : referencedLinesEvents
                }
                handlerEvents={handlerEvents}
                events={events}
              />
            )
          }}
        </QueryWrapper>
      </Stack>
    </>
  )
}
function Graph({
  filters,
  height,
  explorerChartType,
  withLegend = true,
  drillDownInto = () => {},
  referenceLines,
  threshold,
  handlerEvents,
  events,
  selectedMetric,
}: Props & {
  handlerEvents: HandlerEvents
  events: EventsResponses.Aggregated[]
}) {
  const withMetricsLine = !!selectedMetric && selectedMetric.technical === false
  switch (explorerChartType) {
    case ExplorerChartType.LINE: {
      return (
        <>
          <LineChart
            height={height}
            filters={filters}
            withLegend={withLegend}
            withMetricsLine={withMetricsLine}
          />
        </>
      )
    }
    case ExplorerChartType.WATERFALL: {
      return (
        <WaterfallChart
          height={height}
          filters={{ ...filters, threshold }}
          withLegend={withLegend}
          dashboardChartConfig={75}
          drillDownInto={(value, mode) =>
            drillDownInto(
              filters.groupBy,
              value,
              getBestDrillDown(filters.groupBy),
              mode,
            )
          }
        />
      )
    }
    case ExplorerChartType.AREA: {
      return (
        <AreaChart height={height} filters={filters} withLegend={withLegend} />
      )
    }
    default: {
      return (
        <StackedBarChart
          handlerEvents={handlerEvents}
          height={height}
          filters={filters}
          withLegend={withLegend}
          events={events}
          drillDownInto={(value, mode) =>
            drillDownInto(
              filters.groupBy,
              value,
              getBestDrillDown(filters.groupBy),
              mode,
            )
          }
          referenceLines={referenceLines}
          withMetricsLines={withMetricsLine}
        />
      )
    }
  }
}

function parseEventsToReferenceLines(
  events: EventsResponses.Aggregated[],
): GridChartBaseProps["referenceLines"] {
  return events.map((el) => ({
    x: el.date,
    label: el.events.map((el) => el.name).join(", "),
    labelPosition: "insideTopLeft" as LabelPosition,
    strokeWidth: 2,
    position: "start" as ReferenceLinePosition,
    stroke: "var(--mantine-color-cyan-6)",
  }))
}
