import { Badge, Stack } from "@mantine/core"
import {
  createMRTColumnHelper,
  MantineReactTable,
  useMantineReactTable,
} from "mantine-react-table"
import { useMemo } from "react"

import { AnomalyResponses } from "@costory/types/endpoints/anomalies"

import { QueryWrapper } from "@costory/front/components/layout/QueryWrapper"
import { DateCell } from "@costory/front/components/table/DateCell"
import { NumberCell } from "@costory/front/components/table/NumberCell"
import { useListAnomaliesQuery } from "@costory/front/queries/anomalies"
import { getDefaultTableOptions } from "@costory/front/utils/table"

const AnomaliesPage = ({ data }: { data: AnomalyResponses.Anomaly[] }) => {
  const columns = useMemo(() => {
    const columnHelper = createMRTColumnHelper<AnomalyResponses.Anomaly>()
    return [
      columnHelper.accessor("date", {
        header: "Date",
        filterVariant: "select",
        Cell: ({ cell }) => <DateCell date={cell.getValue()} />,
      }),
      columnHelper.accessor("axes", {
        header: "Axes",
        size: 400,
        Cell: ({ cell }) => (
          <>
            {cell.getValue().map((axis, index) => (
              <Badge key={index}>
                {axis.name}:{axis.value}
              </Badge>
            ))}
          </>
        ),
      }),
      columnHelper.accessor("actualCost", {
        header: "Actual Cost",
        Cell: ({ cell }) => (
          <NumberCell value={cell.getValue()} currency="USD" />
        ),
      }),
      columnHelper.accessor("forecastCost", {
        header: "Forecast Cost",
        Cell: ({ cell }) => (
          <NumberCell value={cell.getValue()} currency="USD" />
        ),
      }),
      columnHelper.accessor("upperForecast", {
        header: "Upper Forecast",
        Cell: ({ cell }) => (
          <NumberCell value={cell.getValue()} currency="USD" />
        ),
      }),
      columnHelper.accessor("delta", {
        header: "Delta",
        Cell: ({ cell }) => (
          <NumberCell value={cell.getValue()} currency="USD" />
        ),
      }),
    ]
  }, [])

  const table = useMantineReactTable<AnomalyResponses.Anomaly>({
    columns,
    data,
    enableRowActions: true,
    positionActionsColumn: "last",
    ...getDefaultTableOptions(),
  })
  return (
    <>
      <>
        <MantineReactTable table={table} />
      </>
    </>
  )
}

export function AnomalyPage() {
  const query = useListAnomaliesQuery()

  return (
    <Stack>
      <QueryWrapper query={query}>
        {({ data }) => <AnomaliesPage data={data} />}
      </QueryWrapper>
    </Stack>
  )
}

export default AnomaliesPage
