import { ActionIcon, Box, Button, Flex, Group } from "@mantine/core"
import { useDisclosure } from "@mantine/hooks"
import { modals } from "@mantine/modals"
import { IconEdit, IconTrash } from "@tabler/icons-react"
import {
  createMRTColumnHelper,
  MantineReactTable,
  useMantineReactTable,
} from "mantine-react-table"
import { useMemo, useState } from "react"
import { useParams } from "react-router-dom"

import { MetricsResponses } from "@costory/types/endpoints/metrics"

import { DEFAULT_DATE_FORMAT } from "@costory/shared/const"
import dayjs from "@costory/shared/dayjs"

import { QueryWrapper } from "@costory/front/components/layout/QueryWrapper"
import { MetricForm } from "@costory/front/pages/MetricsV2/MetricForm"
import {
  useDeleteMetricMutation,
  useMetricsQuery,
} from "@costory/front/queries/metrics"
import { getDefaultTableOptions } from "@costory/front/utils/table"

const ListMetrics = ({
  metrics,
  datasourceId,
}: {
  metrics: MetricsResponses.CustomMetric[]
  datasourceId: string | undefined
}) => {
  const deleteMetricMutation = useDeleteMetricMutation()
  const [selectedMetric, setSelectedMetric] =
    useState<MetricsResponses.CustomMetric | null>(null)
  const [opened, handlers] = useDisclosure(!!datasourceId)

  const columns = useMemo(() => {
    const columnHelper = createMRTColumnHelper<MetricsResponses.CustomMetric>()
    return [
      columnHelper.accessor("name", {
        header: "Name",
      }),
      columnHelper.accessor("datasource.name", {
        header: "Datasource",
        Cell: ({ row }) => (
          <>
            {row.original.datasource!.name}/{row.original.rawMetric}
          </>
        ),
      }),
      columnHelper.display({
        id: "owner",
        header: "Owner",
        enableColumnActions: false,
        Cell: ({ row }) => (
          <>
            {row.original.owner.firstName} {row.original.owner.lastName}
          </>
        ),
      }),
      columnHelper.accessor("updatedAt", {
        header: "Last Updated",
        Cell: ({ row }) => (
          <>
            {dayjs(row.original.updatedAt).local().format(DEFAULT_DATE_FORMAT)}
          </>
        ),
      }),
      columnHelper.display({
        header: "Actions",
        enableColumnActions: false,
        Cell: ({ row }) => (
          <Group gap={8} wrap="nowrap">
            <ActionIcon
              onClick={() => {
                modals.openConfirmModal({
                  title: "Delete Metric",
                  children: "Are you sure you want to delete this metric?",
                  labels: { confirm: "Delete", cancel: "Cancel" },
                  confirmProps: { color: "red" },
                  onConfirm: () => deleteMetricMutation.mutate(row.original.id),
                })
              }}
            >
              <IconTrash />
            </ActionIcon>
            <ActionIcon
              onClick={() => {
                setSelectedMetric(row.original)
                handlers.open()
              }}
            >
              <IconEdit />
            </ActionIcon>
          </Group>
        ),
      }),
    ]
  }, [deleteMetricMutation, handlers])

  const table = useMantineReactTable<MetricsResponses.CustomMetric>({
    columns,
    data: metrics,
    ...getDefaultTableOptions(),
  })

  return (
    <>
      <MetricForm
        opened={opened}
        setOpened={handlers.toggle}
        initialValues={
          selectedMetric
            ? {
                name: selectedMetric.name,
                rawMetric: selectedMetric.rawMetric,
                datasourceId: selectedMetric.datasourceId!,
                formula: selectedMetric.formula || "",
                costType: selectedMetric.costType || "cost",
              }
            : {
                datasourceId: datasourceId ?? "",
                name: "",
                rawMetric: "",
                formula: "",
                costType: "cost",
              }
        }
        id={selectedMetric?.id}
      />
      <Box>
        <Flex mb={20} justify="flex-end">
          <Button variant="filled" onClick={handlers.open}>
            Create New Metric
          </Button>
        </Flex>
        <MantineReactTable table={table} />
      </Box>
    </>
  )
}

export const MetricsV2Page = () => {
  const { datasourceId } = useParams()
  const metricQuery = useMetricsQuery()

  return (
    <QueryWrapper query={metricQuery} allowEmptyArray>
      {({ data: metrics }) => (
        <ListMetrics
          metrics={metrics.filter((el) => !!el.datasourceId)}
          datasourceId={datasourceId}
        />
      )}
    </QueryWrapper>
  )
}
