import { Button, Drawer, Group, Select, Stack, Title } from "@mantine/core"
import { useForm, UseFormReturnType } from "@mantine/form"
import { useDisclosure } from "@mantine/hooks"
import { IconScreenshot } from "@tabler/icons-react"
import { useQuery } from "@tanstack/react-query"
import _ from "lodash"
import { useMemo } from "react"

import { AxesResponses } from "@costory/types/endpoints/axes"
import { DigestRequests, Position } from "@costory/types/endpoints/digest"
import { MetricsResponses } from "@costory/types/endpoints/metrics"
import { Filters } from "@costory/types/filters"
import { AggBy, Currency } from "@costory/types/prisma-client"

import { computePresetDates } from "@costory/shared/utils/filters"

import { FilterBar, CurrencyPicker } from "@costory/front/components/FilterBar"
import { Chart } from "@costory/front/components/charts/Chart"
import { ModalCurrency } from "@costory/front/components/charts/ModalCurrency"
import { QueryWrapper } from "@costory/front/components/layout/QueryWrapper"
import { apiClient } from "@costory/front/lib/apiClient"
import { getReactQueryBuilderQuery } from "@costory/front/pages/DigestV2/helper"
import { useBusinessMetricsQuery } from "@costory/front/queries/businessMetrics"
import { formatDatasources } from "@costory/front/utils/filters"

type Props = {
  opened: boolean
  close: () => void
  position: Position
  period: DigestRequests.Period
  negationValues: Position[]
  setImgPromise?: (filters: Filters) => void
}

type PropsPrefillExplorer = {
  axes: AxesResponses.Axis[]
  form: UseFormReturnType<Filters>
  metrics: MetricsResponses.Metric[]
}

const PrefillExplorerUI = ({ axes, form, metrics }: PropsPrefillExplorer) => {
  const metricsOptions = useMemo(
    () =>
      _.sortBy(metrics, (d) => Number(!("technical" in d))).map((metric) => ({
        label: metric.name,
        value: metric.id,
      })),
    [metrics],
  )
  const groupByOptions = useMemo(() => formatDatasources(axes), [axes])

  const [openedCurrencyModal, currencyModalHandlers] = useDisclosure()
  return (
    <>
      <Stack>
        <CurrencyPicker
          form={form}
          openModal={() => currencyModalHandlers.open()}
        />
        <ModalCurrency
          opened={openedCurrencyModal}
          close={currencyModalHandlers.close}
        />
        <FilterBar
          drawerMode
          form={form}
          metricsOptions={metricsOptions}
          groupByOptions={groupByOptions}
          queryError={false}
        />

        <Chart
          chartType={
            form.getValues().explorerChartType === "WATERFALL"
              ? "WATERFALL"
              : "TREND"
          }
          filters={form.getValues()}
          height={500}
        />
      </Stack>
    </>
  )
}

export const ExplorerDrawer = ({
  opened,
  close,
  position,
  period,
  negationValues,
  setImgPromise,
}: Props) => {
  const axesQuery = useQuery({
    queryKey: ["axes"],
    queryFn: async () => {
      const response = await apiClient.get<AxesResponses.Axis[]>("/axes")
      return response.data
    },
  })
  const metricsQuery = useBusinessMetricsQuery()
  const whereClause = position.map((elem) => getReactQueryBuilderQuery(elem))
  const negativeWhereClause = negationValues.map((el) => ({
    combinator: "and",
    rules: el.map((ee) => getReactQueryBuilderQuery(ee)),
  }))
  const initialValues = useMemo(
    () => ({
      metricId: "cost",
      currency: "USD" as Currency,
      limit: 10,
      aggBy: "Day" as AggBy,
      groupBy: "cos_provider",
      ...computePresetDates(
        period === "MonthToMonth"
          ? "LAST_MONTH"
          : period === "MTD"
            ? "MTD"
            : "LAST_3_MONTHS",
      ),
      whereClause: {
        combinator: "and",
        rules: [
          {
            combinator: "or",
            not: true,
            rules: [...negativeWhereClause],
          },
          {
            combinator: "and",
            rules: whereClause,
          },
        ],
      },
    }),
    [negativeWhereClause, period, whereClause],
  )
  const form = useForm<Filters>({
    initialValues,
  }) as UseFormReturnType<Filters>

  return (
    <>
      <Drawer.Root size="xl" opened={opened} onClose={close} position="right">
        <Drawer.Overlay />
        <Drawer.Content>
          <Group justify="space-between">
            <Title> Explore Cost</Title>
            <Select
              data={["BAR", "WATERFALL"]}
              value={form.getValues().explorerChartType || "BAR"}
              onChange={(value) =>
                form.setFieldValue(
                  "explorerChartType",
                  value as "BAR" | "WATERFALL",
                )
              }
            />
            {setImgPromise && (
              <Button
                leftSection={<IconScreenshot />}
                variant="transparent"
                onClick={() => setImgPromise(form.getValues())}
              >
                Save ScreenShot
              </Button>
            )}
          </Group>
          <Drawer.Body>
            <QueryWrapper query={axesQuery}>
              {({ data: axes }) => (
                <QueryWrapper query={metricsQuery}>
                  {({ data: metrics }) => (
                    <PrefillExplorerUI
                      form={form}
                      axes={axes}
                      metrics={metrics}
                    />
                  )}
                </QueryWrapper>
              )}
            </QueryWrapper>
          </Drawer.Body>
        </Drawer.Content>
      </Drawer.Root>
    </>
  )
}
