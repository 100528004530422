import { showNotification } from "@mantine/notifications"
import { useMutation, useQuery } from "@tanstack/react-query"

import { NewMetric, MetricsResponses } from "@costory/types/endpoints/metrics"

import { apiClient } from "@costory/front/lib/apiClient"
import { queryClient } from "@costory/front/lib/queryClient"

export const useDeleteMetricMutation = () => {
  return useMutation({
    mutationFn: async (id: string) => {
      const response = await apiClient.delete(`/metrics/${id}`)
      return response.data
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ["metrics"] })
      showNotification({ color: "green", title: "Success", message: "Deleted" })
      await queryClient.invalidateQueries({ queryKey: ["metrics"] })
    },
    onError: (error: { error: string }) => {
      showNotification({ color: "red", title: "Error", message: error.error })
    },
  })
}

export const useCreateMetricMutation = () => {
  return useMutation({
    mutationFn: async (data: NewMetric) => {
      const response = await apiClient.post("/metrics/v2", data)
      return response.data
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ["metrics"] })
      showNotification({ color: "green", title: "Success", message: "Saved" })
    },
    onError: (error: { error: string }) => {
      showNotification({ color: "red", title: "Error", message: error.error })
    },
  })
}

export const useUpdateMetricMutation = () => {
  return useMutation({
    mutationFn: async ({ data, id }: { data: NewMetric; id: string }) => {
      const response = await apiClient.put(`/metrics/v2/${id}`, data)
      return response.data
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ["metrics"] })
      showNotification({ color: "green", title: "Success", message: "Saved" })
    },
    onError: (error: { error: string }) => {
      showNotification({ color: "red", title: "Error", message: error.error })
    },
  })
}

export const useMetricsQuery = () => {
  return useQuery({
    queryKey: ["metrics"],
    queryFn: async () => {
      const response =
        await apiClient.get<MetricsResponses.CustomMetric[]>("/metrics")
      return response.data
    },
  })
}
