import {
  ActionIcon,
  Button,
  Checkbox,
  Combobox,
  Group,
  Text,
  Tooltip,
  useCombobox,
  useMantineTheme,
} from "@mantine/core"
import { IconChevronDown, IconFilter } from "@tabler/icons-react"
import { useMemo, useState } from "react"

import { QueryWrapper } from "@costory/front/components/layout/QueryWrapper"
import { useAxesQuery } from "@costory/front/queries/axes"

interface ParentOption {
  value: string
  label: string
  children: ChildOption[]
}

interface ChildOption {
  value: string
  label: string
}

interface MultiSelectWithChildrenOptionsProps {
  data: ParentOption[]
  value?: string[]
  onChange?: (value: string[]) => void
  tooltip?: string
}

export function MultiSelectWithChildrenOptions({
  data,
  value = [],
  onChange,
  tooltip,
}: MultiSelectWithChildrenOptionsProps) {
  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
  })
  const { colors } = useMantineTheme()

  const parentChildMap = useMemo(() => {
    const map = new Map<string, string[]>()
    data.forEach((parent) => {
      map.set(
        parent.value,
        parent.children.map((child) => child.value),
      )
    })
    return map
  }, [data])

  const [expandedParents, setExpandedParents] = useState<Set<string>>(() => {
    const initialExpandedParents = new Set<string>()
    value.forEach((selectedValue) => {
      const parentValue = selectedValue.split(".")[0]
      if (parentValue) {
        initialExpandedParents.add(parentValue)
      }
    })
    return initialExpandedParents
  })

  const getParentCheckboxState = (parentValue: string) => {
    const childrenValues = parentChildMap.get(parentValue) || []
    const selectedChildren = childrenValues.filter((child) =>
      value.includes(child),
    )

    if (selectedChildren.length === 0)
      return { checked: false, indeterminate: false }
    if (selectedChildren.length === childrenValues.length)
      return { checked: true, indeterminate: false }
    return { checked: false, indeterminate: true }
  }

  const handleOptionSubmit = (optionValue: string) => {
    const isParentOption = parentChildMap.has(optionValue)
    if (isParentOption) {
      setExpandedParents((prev) => new Set([...prev, optionValue]))

      const childrenValues = parentChildMap.get(optionValue) || []
      const { indeterminate: hasSelectedChildren, checked } =
        getParentCheckboxState(optionValue)

      const newValues =
        checked || hasSelectedChildren
          ? value.filter((v) => !childrenValues.includes(v))
          : [...value, ...childrenValues]

      onChange?.(Array.from(new Set(newValues)))
    } else {
      const newValues = value.includes(optionValue)
        ? value.filter((v) => v !== optionValue)
        : [...value, optionValue]

      onChange?.(Array.from(new Set(newValues)))
    }
  }

  const toggleParent = (parentValue: string, event: React.MouseEvent) => {
    event.stopPropagation()
    setExpandedParents((prev) => {
      const next = new Set(prev)
      if (next.has(parentValue)) {
        next.delete(parentValue)
      } else {
        next.add(parentValue)
      }
      return next
    })
  }

  return (
    <Combobox
      width={400}
      position="bottom-start"
      store={combobox}
      onOptionSubmit={handleOptionSubmit}
    >
      <Combobox.Target>
        <Tooltip label={tooltip} disabled={!tooltip}>
          <div style={{ position: "relative" }}>
            <ActionIcon onClick={() => combobox.toggleDropdown()}>
              <IconFilter size="inherit" />
            </ActionIcon>
            {value.length > 0 && (
              <div
                style={{
                  position: "absolute",
                  top: -2,
                  right: -2,
                  width: 8,
                  height: 8,
                  borderRadius: "50%",
                  backgroundColor: colors.primary[6],
                }}
              />
            )}
          </div>
        </Tooltip>
      </Combobox.Target>

      <Combobox.Dropdown>
        <Combobox.Options
          mah="40vh"
          style={{
            overflowY: "scroll",
            paddingBottom: value.length > 0 ? 40 : 0,
          }}
        >
          {data.map((parentOption) => (
            <div key={parentOption.value}>
              <Combobox.Option value={parentOption.value}>
                <Group gap="sm" justify="space-between" w="100%">
                  <Group gap="sm">
                    <Checkbox
                      {...getParentCheckboxState(parentOption.value)}
                      readOnly
                      tabIndex={-1}
                    />
                    <Text>{parentOption.label}</Text>
                  </Group>
                  {parentOption.children.length > 0 && (
                    <IconChevronDown
                      color={colors.gray[4]}
                      onClick={(e) => toggleParent(parentOption.value, e)}
                      style={{
                        transform: expandedParents.has(parentOption.value)
                          ? "rotate(180deg)"
                          : "none",
                        transition: "transform 200ms ease",
                        cursor: "pointer",
                      }}
                    />
                  )}
                </Group>
              </Combobox.Option>
              {expandedParents.has(parentOption.value) &&
                parentOption.children.map((childOption) => (
                  <Combobox.Option
                    value={childOption.value}
                    key={childOption.value}
                  >
                    <Group gap="sm" pl={24}>
                      <Checkbox
                        checked={value.includes(childOption.value)}
                        readOnly
                        tabIndex={-1}
                      />
                      <Text>{childOption.label}</Text>
                    </Group>
                  </Combobox.Option>
                ))}
            </div>
          ))}
        </Combobox.Options>
        <Group
          justify="flex-end"
          p="xs"
          style={{
            position: "sticky",
            bottom: 0,
            backgroundColor: "var(--mantine-color-body)",
            borderTop: "1px solid var(--mantine-color-gray-2)",
          }}
        >
          <Button
            disabled={value.length === 0}
            variant="light"
            size="compact-sm"
            onClick={() => {
              onChange?.([])
            }}
          >
            Clear all
          </Button>
        </Group>
      </Combobox.Dropdown>
    </Combobox>
  )
}

export const VdimValuesMultiSelect = ({
  value,
  onChange,
  tooltip,
}: VdimValuesMultiSelectProps) => {
  const query = useAxesQuery()
  return (
    <QueryWrapper
      query={query}
      LoadingComponent={<ActionIcon loading>Loading</ActionIcon>}
    >
      {({ data }) => (
        <MultiSelectWithChildrenOptions
          data={data
            .filter((el) => el.groupTitle == "Virtual Dimensions")
            .map((el) => ({
              value: el.name,
              label: el.label,
              children: el.values.map((ee) => ({
                value: el.name + "." + ee.name,
                label: ee.label,
              })),
            }))}
          value={value}
          onChange={onChange}
          tooltip={tooltip}
        />
      )}
    </QueryWrapper>
  )
}

interface VdimValuesMultiSelectProps {
  value?: string[]
  onChange?: (value: string[]) => void
  tooltip?: string
}
