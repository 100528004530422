import { createContext, PropsWithChildren, useContext, useState } from "react"

import { ComponentType } from "@costory/types/prisma-client"

// Create the context
export type DraggedFile = {
  id: string
  type: "dashboard" | "savedView"
  componentType: ComponentType
}

const DnDContext = createContext<{
  draggedFile: DraggedFile | null
  setDraggedFile: React.Dispatch<React.SetStateAction<DraggedFile | null>>
}>({ draggedFile: null, setDraggedFile: () => {} })

// Context Provider Component
export const DnDContextProvider = ({ children }: PropsWithChildren) => {
  const [draggedFile, setDraggedFile] = useState<DraggedFile | null>(null)

  return (
    <DnDContext.Provider value={{ draggedFile, setDraggedFile }}>
      {children}
    </DnDContext.Provider>
  )
}

// Custom hook to use the context
export const useDnDContext = () => useContext(DnDContext)
