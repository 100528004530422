import {
  Button,
  Grid,
  NumberInput,
  Select,
  TagsInput,
  Text,
  TextInput,
} from "@mantine/core"
import { useForm, zodResolver } from "@mantine/form"
import { useMemo } from "react"

import { DatasourcesResponses } from "@costory/types/endpoints/datasources"
import {
  AGG_METHODS,
  DATA_TRANSFORM,
  GAP_METHODS,
  MetricRequests,
  MetricsResponses,
  metricViewSchema,
} from "@costory/types/endpoints/metrics"

type Props = {
  datasources: DatasourcesResponses.Datasource[]
  selectedMetric?: MetricsResponses.Metric
  isSaving: boolean
  onSave: (data: MetricRequests.EditMetric) => void
  close: () => void
  isOpen: boolean
}

export function MetricForm({
  datasources,
  selectedMetric,
  onSave,
  isSaving,
}: Props) {
  const compatibleDatasources = datasources.filter(
    (elem) => elem.connector === "GSheet",
  )
  const initialValues = useMemo(() => {
    if (selectedMetric) {
      return selectedMetric as MetricRequests.EditMetric
    }
    return {
      deprecatedDatasourceId: "",
      rawMetric: "",
      name: "",
      aggMethod: "SUM" as (typeof AGG_METHODS)[number],
      gapMethod: "Expand" as (typeof GAP_METHODS)[number],
      transform: { type: "None" as (typeof DATA_TRANSFORM)[0] },
      tags: [],
    }
  }, [selectedMetric])

  const form = useForm<MetricRequests.EditMetric>({
    initialValues,
    validate: zodResolver(metricViewSchema),
    validateInputOnBlur: true,
    validateInputOnChange: true,
    mode: "uncontrolled",
  })
  const datasourceNames =
    compatibleDatasources.map(({ name, id }: { name: string; id: string }) => ({
      label: name,
      value: id,
    })) ?? []
  const metricList = compatibleDatasources
    .filter((elem) => form.getValues().deprecatedDatasourceId === elem.id)
    .flatMap(({ metadata }) => metadata.metrics)
  return (
    <form
      className="mx-5 my-3"
      onSubmit={form.onSubmit((values) => onSave(values))}
    >
      <Grid gutter="md">
        <Grid.Col span={12}>
          <Grid gutter="md">
            <Grid.Col span={4}>
              <Text>Base Metric</Text>
            </Grid.Col>
            <Grid.Col span={4}>
              <Select
                placeholder="Pick value"
                data={datasourceNames}
                clearable
                {...form.getInputProps("datasourceId")}
                key={form.key("datasourceId")}
              />
            </Grid.Col>
            <Grid.Col span={4}>
              <Select
                placeholder="Pick value"
                data={metricList}
                clearable
                {...form.getInputProps("rawMetric")}
                key={form.key("rawMetric")}
              />
            </Grid.Col>
          </Grid>
        </Grid.Col>

        <Grid.Col span={12}>
          <Grid gutter="md">
            <Grid.Col span={4}>
              <Text>Metric Alias</Text>
            </Grid.Col>
            <Grid.Col span={8}>
              <TextInput
                placeholder="Name...."
                {...form.getInputProps("name")}
                key={form.key("name")}
              />
            </Grid.Col>
          </Grid>
        </Grid.Col>

        <Grid.Col span={12}>
          <Grid gutter="md">
            <Grid.Col span={4}>
              <Text>Aggregation Method</Text>
            </Grid.Col>
            <Grid.Col span={8}>
              <Select
                placeholder="Pick value"
                data={AGG_METHODS}
                clearable
                key={form.key("aggMethod")}
                {...form.getInputProps("aggMethod")}
              />
            </Grid.Col>
          </Grid>
        </Grid.Col>

        <Grid.Col span={12}>
          <Grid gutter="md">
            <Grid.Col span={4}>
              <Text>Gap Filling Method</Text>
            </Grid.Col>
            <Grid.Col span={8}>
              <Select
                placeholder="Pick value"
                data={GAP_METHODS}
                key={form.key("gapMethod")}
                clearable
                {...form.getInputProps("gapMethod")}
              />
            </Grid.Col>
          </Grid>
        </Grid.Col>

        <Grid.Col span={12}>
          <Grid gutter="md">
            <Grid.Col span={4}>
              <Text>Apply transform</Text>
            </Grid.Col>
            <Grid.Col span={8}>
              <Select
                placeholder="Pick value"
                data={DATA_TRANSFORM}
                clearable
                key={form.key("transform.type")}
                {...form.getInputProps("transform.type")}
              />
            </Grid.Col>
          </Grid>
        </Grid.Col>

        {form.getValues().transform.type != "None" && (
          <Grid.Col span={12}>
            <Grid gutter="md">
              <Grid.Col span={4}>
                <Text>Method</Text>
              </Grid.Col>
              <Grid.Col span={8}>
                <NumberInput
                  placeholder="Value"
                  key={form.key("transform.value")}
                  {...form.getInputProps("transform.value")}
                />
              </Grid.Col>
            </Grid>
          </Grid.Col>
        )}

        <Grid.Col span={12}>
          <Grid gutter="md">
            <Grid.Col span={4}>
              <Text>Tags</Text>
            </Grid.Col>
            <Grid.Col span={8}>
              <TagsInput
                placeholder="Enter tag"
                key={form.key("tags")}
                {...form.getInputProps("tags")}
              />
            </Grid.Col>
          </Grid>
        </Grid.Col>

        <Grid.Col
          span={12}
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          <Button disabled={!form.isValid()} loading={isSaving} type="submit">
            Submit
          </Button>
        </Grid.Col>
      </Grid>
    </form>
  )
}
