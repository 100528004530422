import {
  ActionIcon,
  Button,
  Flex,
  Group,
  InputLabel,
  InputWrapper,
  Modal,
  Space,
  Stack,
  Text,
  TextInput,
  Title,
  Tooltip,
} from "@mantine/core"
import { useField } from "@mantine/form"
import { useDisclosure } from "@mantine/hooks"
import { modals, openConfirmModal } from "@mantine/modals"
import {
  IconCopy,
  IconDeviceFloppy,
  IconDownload,
  IconEyeOff,
  IconFolderOpen,
  IconLayout,
  IconLock,
  IconLockOpen,
  IconLockOpen2,
  IconPdf,
  IconPencil,
  IconPlus,
  IconUserStar,
  IconWorldStar,
  IconX,
} from "@tabler/icons-react"
import _ from "lodash"
import { useMemo, useState } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"

import {
  DashboardRequests,
  DashboardResponses,
} from "@costory/types/endpoints/dashboard"
import { TreeNodeType } from "@costory/types/endpoints/folders"
import { ComponentType } from "@costory/types/prisma-client"

import { FileExplorer } from "@costory/front/components/FileExplorer/FileExplorer"
import { VdimValuesMultiSelect } from "@costory/front/components/MultiSelectWithChildrenOptions"
import { FeatureFlag } from "@costory/front/components/configcat/FeatureFlag"
import useFeatureToggle from "@costory/front/components/configcat/useFeatureToggle"
import { Widget } from "@costory/front/components/dashboard/DashboardLayout"
import { useAuthState } from "@costory/front/queries/auth"
import { useGeneratePdfFromDashboard } from "@costory/front/queries/dashboard"

import {
  SelectedVirtualDimensionsValuesSearchParams,
  selectedVirtualDimensionsValuesSearchParamsSchema,
} from "./searchParamsSchema"

interface DashboardActionBarProps {
  widgets: Widget[]
  onAddWidget: () => void
  isEditing: boolean
  setIsEditing: (value: boolean) => void
  currentDashboard: DashboardResponses.GetDashboard | undefined
  isExistingDashboard: boolean
  onCreate: (
    payload: Omit<DashboardRequests.NewDashboard, "dashboardWidgets">,
  ) => Promise<void>
  isCreateDashboardMutationPending: boolean
  onUpdate: (
    payload: Omit<DashboardRequests.UpdateDashboard, "dashboardWidgets">,
  ) => Promise<void>
  onDiscardChanges: () => void
  isUpdateDashboardMutationPending: boolean
  setDashboardType: (dashboardType: ComponentType) => void
  dashboardType: ComponentType
  savePng: () => void
}

export const DashboardActionBar = ({
  widgets,
  isEditing,
  isExistingDashboard,
  setIsEditing,
  isCreateDashboardMutationPending,
  isUpdateDashboardMutationPending,
  onCreate,
  onUpdate,
  onAddWidget,
  onDiscardChanges,
  currentDashboard,
  dashboardType,
  setDashboardType,
  savePng,
}: DashboardActionBarProps) => {
  const [
    isSaveNewDashboardModalOpen,
    { close: closeSaveNewDashboardModal, open: openSaveNewDashboardModal },
  ] = useDisclosure()
  const [
    isFolderPickerModalOpen,
    { close: closeFolderPickerModal, open: openFolderPickerModal },
  ] = useDisclosure()
  const navigate = useNavigate()
  const [
    isDashboardPickerModalOpen,
    { close: closeDashboardPickerModal, open: openDashboardPickerModal },
  ] = useDisclosure()
  const auth = useAuthState()
  const [searchParams, setSearchParams] = useSearchParams()
  const query = useGeneratePdfFromDashboard(
    currentDashboard?.id ?? "",
    currentDashboard?.name ?? "Dashboard",
  )
  const featureFlagPdf = useFeatureToggle(FeatureFlag.PDF_EXPORT)
  const selectedVirtualDimensionsValues = useMemo(() => {
    const selectedVirtualDimensionsValuesSearchParam =
      searchParams.get("selectedVirtualDimensionsValues") ?? "[]"

    try {
      const selectedVirtualDimensionValues =
        selectedVirtualDimensionsValuesSearchParamsSchema.parse(
          JSON.parse(selectedVirtualDimensionsValuesSearchParam),
        )
      return selectedVirtualDimensionValues.flatMap(({ values, bqName }) =>
        values.map((value) => `${bqName}.${value}`),
      )
    } catch (e) {
      console.error("Failed to parse search params:", e)
    }

    return []
  }, [searchParams])

  const COSTORY_ORGID = import.meta.env.VITE_COSTORY_ORGID
  const COSTORY_EMAIL_DOMAIN = import.meta.env.VITE_COSTORY_EMAIL_DOMAIN

  const isAllowedToEdit =
    (currentDashboard &&
      currentDashboard.createdById == auth.user!.id &&
      currentDashboard?.type !== ComponentType.COMMUNITY) ||
    (auth.user!.isAdmin &&
      ((currentDashboard?.type == ComponentType.COMMUNITY &&
        auth.user?.currentOrg.id === COSTORY_ORGID &&
        auth.user?.email.endsWith(COSTORY_EMAIL_DOMAIN)) ||
        currentDashboard?.type == ComponentType.PUBLIC))

  const toggleDashboardType = (isAdmin?: boolean) => {
    setDashboardType(
      dashboardType === ComponentType.PUBLIC
        ? ComponentType.PRIVATE
        : dashboardType === ComponentType.PRIVATE
          ? isAdmin && auth.user?.currentOrg.id === COSTORY_ORGID
            ? ComponentType.COMMUNITY
            : ComponentType.PUBLIC
          : ComponentType.PUBLIC,
    )
  }

  const dashboardNameField = useField({
    initialValue: currentDashboard?.name ?? "",
  })

  const [defaultOrgDashboard, setDefaultOrgDashboard] = useState<boolean>(
    currentDashboard?.isOrgDefaultDashboard ?? false,
  )
  const [defaultDashboard, setDefaultDashboard] = useState<boolean>(
    currentDashboard?.isDefaultDashboard ?? false,
  )
  const handleVirtualDimensionValuesChange = (
    virtualDimensionsValues: string[],
  ) => {
    if (virtualDimensionsValues.length === 0) {
      setSearchParams({})
      return
    }

    const valuesGroupedByBqName = _.groupBy(
      virtualDimensionsValues,
      (value) => value.split(".")[0],
    )

    const formattedValues: SelectedVirtualDimensionsValuesSearchParams =
      Object.entries(valuesGroupedByBqName).map(([bqName, values]) => ({
        bqName,
        values: values.map((value) => value.split(".")[1]),
      }))

    setSearchParams({
      selectedVirtualDimensionsValues: JSON.stringify(formattedValues),
    })
  }

  const handleOpenDashboard = (dashboardId: string) => {
    navigate("/dashboards/" + dashboardId)
    closeDashboardPickerModal()
  }

  const handleOpenSaveNewDashboardModal = () => {
    if (isExistingDashboard) {
      dashboardNameField.setValue(currentDashboard!.name + " (copy)")
    }
    openSaveNewDashboardModal()
  }

  const handleCloseSaveNewDashboardModal = () => {
    if (isExistingDashboard) {
      dashboardNameField.setValue(currentDashboard!.name)
    }
    closeSaveNewDashboardModal()
  }

  const handleOpenFolder = async (folderId: string) => {
    if (!isExistingDashboard) {
      throw new Error("Cannot duplicate from unexisting dashboard")
    }

    await onCreate({
      name: dashboardNameField.getValue(),
      type: dashboardType,
      defaultDashboard,
      parentFolderId: folderId,
      defaultOrgDashboard: currentDashboard!.isOrgDefaultDashboard,
    })
    closeSaveNewDashboardModal()
  }

  const handleOpenDashboardPickerModal = () => {
    openDashboardPickerModal()
  }

  const handleClickSaveChanges = async () => {
    if (isExistingDashboard) {
      await onUpdate({
        name: dashboardNameField.getValue(),
        type: dashboardType,
        defaultDashboard,
        defaultOrgDashboard,
        parentFolderId: currentDashboard!.parentFolderId,
      })
      setIsEditing(false)
      return
    }

    if (!isExistingDashboard) {
      openFolderPickerModal()
      return
    }
  }

  const handleClickDefaultDashboard = () => {
    if (!defaultDashboard) {
      openConfirmModal({
        title: <Title size="h3">Set dashboard as personal default ?</Title>,
        children: (
          <Text size="sm">
            Setting this dashboard as your default, will remove the current
            default dashboard.
          </Text>
        ),
        labels: { confirm: "Confirm", cancel: "Cancel" },
        onConfirm: () => {
          setDefaultDashboard(true)
        },
      })
    } else {
      setDefaultDashboard(false)
    }
  }

  const handleClickOpenFolder = async (folderId: string) => {
    await onCreate({
      name: dashboardNameField.getValue(),
      type: dashboardType,
      defaultDashboard,
      defaultOrgDashboard,
      parentFolderId: folderId,
    })

    closeFolderPickerModal()
  }

  const handleClickDefaultOrgDashboard = () => {
    if (!defaultOrgDashboard) {
      openConfirmModal({
        title: (
          <Title size="h3">
            Set dashboard as organization&apos;s default ?
          </Title>
        ),
        children: (
          <Text size="sm">
            Setting this dashboard as the organization&apos;s default, will
            remove the current default dashboard.
          </Text>
        ),
        labels: { confirm: "Confirm", cancel: "Cancel" },
        onConfirm: () => {
          setDefaultOrgDashboard(true)
        },
      })
    } else {
      setDefaultOrgDashboard(false)
    }
  }

  const handleClickDiscardChanges = () => {
    dashboardNameField.reset()
    setDashboardType(currentDashboard?.type ?? ComponentType.PUBLIC)
    setDefaultOrgDashboard(currentDashboard?.isOrgDefaultDashboard ?? false)
    setDefaultDashboard(currentDashboard?.isDefaultDashboard ?? false)
    onDiscardChanges()
    setIsEditing(false)
  }

  return (
    <Stack>
      <Group gap={4}>
        <IconLayout size={32} color="var(--mantine-color-gray-4)" />
        {isExistingDashboard && !isEditing ? (
          <Title>{currentDashboard!.name}</Title>
        ) : (
          <TextInput
            {...dashboardNameField.getInputProps()}
            fw="bold"
            styles={{
              root: {
                paddingBottom: 0,
              },
              wrapper: {
                paddingBottom: 0,
              },
              input: {
                minWidth: 300,
                width: 500,
                height: 44,
                fontSize: "var(--mantine-h1-font-size)",
                borderBottom: "1px solid var(--mantine-color-gray-3)",
                borderRadius: 0,
                paddingBottom: 4,
              },
            }}
            variant="unstyled"
            placeholder="Unnamed dashboard"
          />
        )}
      </Group>
      <Group>
        <Group align="start">
          {isEditing ? (
            <>
              <Tooltip label={_.upperFirst(dashboardType.toLowerCase())}>
                <ActionIcon
                  onClick={() => toggleDashboardType(auth.user?.isAdmin)}
                >
                  {dashboardType === ComponentType.PUBLIC ? (
                    <IconLockOpen size="inherit" />
                  ) : dashboardType === ComponentType.COMMUNITY ? (
                    <IconLockOpen2
                      size="inherit"
                      color="var(--mantine-color-green-5"
                    />
                  ) : (
                    <IconEyeOff
                      size="inherit"
                      color="var(--mantine-color-gray-5)"
                    />
                  )}
                </ActionIcon>
              </Tooltip>
              <Tooltip
                label={
                  defaultDashboard
                    ? "Default dashboard for you"
                    : "Not default dashboard for you"
                }
              >
                <ActionIcon
                  onClick={handleClickDefaultDashboard}
                  color={defaultDashboard ? "primary" : "gray.5"}
                  disabled={currentDashboard?.isDefaultDashboard}
                >
                  <IconUserStar size="inherit" />
                </ActionIcon>
              </Tooltip>
              {auth.user!.isAdmin && (
                <Tooltip
                  label={
                    defaultOrgDashboard
                      ? "Default dashboard for your organization, set another dashboard as default to change default"
                      : "Not default dashboard for your organization"
                  }
                >
                  <ActionIcon
                    disabled={currentDashboard?.isOrgDefaultDashboard}
                    color={defaultOrgDashboard ? "primary" : "gray.5"}
                    onClick={handleClickDefaultOrgDashboard}
                  >
                    <IconWorldStar size="inherit" />
                  </ActionIcon>
                </Tooltip>
              )}
            </>
          ) : (
            <Group gap={8}>
              <Tooltip label="Open an existing dashboard">
                <ActionIcon
                  onClick={handleOpenDashboardPickerModal}
                  disabled={isEditing}
                >
                  <IconFolderOpen size="inherit" />
                </ActionIcon>
              </Tooltip>
              {isExistingDashboard && (
                <Tooltip label="Save a copy of this dashboard">
                  <ActionIcon onClick={handleOpenSaveNewDashboardModal}>
                    <IconCopy size="inherit" />
                  </ActionIcon>
                </Tooltip>
              )}
            </Group>
          )}
          <Tooltip label="Download dashboard as PNG">
            <ActionIcon onClick={savePng}>
              <IconDownload size="inherit" />
            </ActionIcon>
          </Tooltip>

          {featureFlagPdf.value && (
            <Tooltip label="Download dashboard as PDF">
              <ActionIcon
                onClick={() => query.refetch()}
                loading={query.isFetching}
              >
                <IconPdf size="inherit" />
              </ActionIcon>
            </Tooltip>
          )}
          <VdimValuesMultiSelect
            value={selectedVirtualDimensionsValues}
            onChange={handleVirtualDimensionValuesChange}
            tooltip="Filter virtual dimensions values"
          />
        </Group>
        <Group justify="flex-end" flex={1}>
          {isEditing ? (
            <>
              <Button
                variant="outline"
                leftSection={<IconPlus />}
                onClick={onAddWidget}
              >
                Add widget
              </Button>
              <Button.Group>
                {isEditing ? (
                  <Button
                    loading={isUpdateDashboardMutationPending}
                    disabled={
                      !dashboardNameField.getValue() ||
                      (!isExistingDashboard && widgets.length === 0)
                    }
                    onClick={handleClickSaveChanges}
                    leftSection={<IconDeviceFloppy />}
                  >
                    Save changes
                  </Button>
                ) : null}
              </Button.Group>
              {isExistingDashboard && (
                <Tooltip label="Discard changes">
                  <ActionIcon
                    variant="outline"
                    size="lg"
                    onClick={() => {
                      // TODO : Need to handle the senarion:
                      // - when I haven't modified anything in which case this is an extra click.
                      // - I redirect to a different dashboard when editing NOT the default one which is a regression and need to be removed.
                      modals.openConfirmModal({
                        title: "Please confirm your action",
                        children: (
                          <Text size="sm">
                            Are you sure you want to discard your changes?
                          </Text>
                        ),
                        labels: { confirm: "Confirm", cancel: "Cancel" },
                        onConfirm: handleClickDiscardChanges,
                      })
                    }}
                  >
                    <IconX />
                  </ActionIcon>
                </Tooltip>
              )}
            </>
          ) : (
            isExistingDashboard &&
            isAllowedToEdit && (
              <Button.Group>
                {currentDashboard &&
                  currentDashboard.dashboardWidgets?.length !== 0 && (
                    <Button
                      leftSection={<IconPencil size={20} />}
                      onClick={() => setIsEditing(true)}
                    >
                      Edit Dashboard
                    </Button>
                  )}
              </Button.Group>
            )
          )}
        </Group>
        <Modal
          opened={isFolderPickerModalOpen}
          onClose={closeFolderPickerModal}
          title={<Title>Select a folder to save this dashboard to</Title>}
          size="70vw"
          styles={{ body: { height: "70vh" } }}
        >
          <Stack h="100%">
            <FileExplorer
              onDoubleClick={(id, type) => {
                if (type !== TreeNodeType.Folder) {
                  return
                }
                handleOpenFolder(id)
              }}
              onOpenFolder={handleClickOpenFolder}
              buttonProps={{
                label: "Save",
                loading: isCreateDashboardMutationPending,
              }}
            />
          </Stack>
        </Modal>
        <Modal
          opened={isDashboardPickerModalOpen}
          onClose={closeDashboardPickerModal}
          title={<Title>Open an existing dashboard </Title>}
          size="70vw"
          styles={{ body: { height: "70vh" } }}
        >
          <Stack h="100%">
            <FileExplorer
              onDoubleClick={(id, type) => {
                if (type !== TreeNodeType.Dashboard) {
                  return
                }
                handleOpenDashboard(id)
              }}
              onOpenDashboard={handleOpenDashboard}
              defaultShouldShowSavedViews={false}
            />
          </Stack>
        </Modal>
        <Modal
          opened={isSaveNewDashboardModalOpen}
          onClose={handleCloseSaveNewDashboardModal}
          title={<Title>Save a copy of this dashboard</Title>}
          size="70vw"
          styles={{ body: { height: "70vh" } }}
        >
          <Stack h="100%" gap={4}>
            <InputWrapper>
              <InputLabel fw="bold">Dashboard name</InputLabel>
              <Flex>
                <TextInput flex={1} {...dashboardNameField.getInputProps()} />
                <Tooltip label={_.upperFirst(dashboardType.toLowerCase())}>
                  <ActionIcon
                    m={10}
                    mt={5}
                    onClick={() =>
                      toggleDashboardType(auth.user?.isAdmin ?? false)
                    }
                  >
                    {dashboardType === ComponentType.PUBLIC ? (
                      <IconLockOpen size="inherit" />
                    ) : dashboardType === ComponentType.COMMUNITY ? (
                      <IconLockOpen2
                        size="inherit"
                        color="var(--mantine-color-green-5"
                      />
                    ) : (
                      <IconLock
                        size="inherit"
                        color="var(--mantine-color-gray-5)"
                      />
                    )}
                  </ActionIcon>
                </Tooltip>
              </Flex>
            </InputWrapper>
            <Space h={8} />
            <InputLabel fw="bold">
              Select a folder to save this dashboard to
            </InputLabel>
            <Stack h="80%">
              <FileExplorer
                onOpenFolder={handleOpenFolder}
                onDoubleClick={(id, type) => {
                  if (type !== TreeNodeType.Folder) {
                    return
                  }
                  handleOpenFolder(id)
                }}
                buttonProps={{
                  label: "Save",
                  loading: isCreateDashboardMutationPending,
                }}
              />
            </Stack>
          </Stack>
        </Modal>
      </Group>
    </Stack>
  )
}
