import { Button, Group, Stack } from "@mantine/core"
import { useDisclosure } from "@mantine/hooks"
import { IconPlus } from "@tabler/icons-react"
import { useState } from "react"

import { ScheduledDigestConfigurationsResponses } from "@costory/types/endpoints/scheduledDigestConfigurations"

import { ScheduledDigestConfigurationsTable } from "@costory/front/components/ScheduledDigestConfigurationsTable"

import { ConfigurationModal } from "./ConfigurationModal"

export const ScheduledDigestConfigurationsPage = () => {
  const [opened, { open, close }] = useDisclosure()
  const [editingConfiguration, setEditingConfiguration] = useState<
    ScheduledDigestConfigurationsResponses.List[0] | undefined
  >()

  const handleOpenModal = (
    configuration?: ScheduledDigestConfigurationsResponses.List[0],
  ) => {
    setEditingConfiguration(configuration)
    open()
  }

  const handleCloseModal = () => {
    setEditingConfiguration(undefined)
    close()
  }

  return (
    <Stack>
      <Group justify="end">
        <Button
          onClick={() => handleOpenModal()}
          leftSection={<IconPlus size={16} />}
        >
          Add a scheduled report
        </Button>
      </Group>
      <ScheduledDigestConfigurationsTable onEdit={handleOpenModal} />
      <ConfigurationModal
        opened={opened}
        onClose={handleCloseModal}
        scheduledDigestConfiguration={editingConfiguration}
        initialValues={editingConfiguration ?? {}}
      />
    </Stack>
  )
}
