import {
  IconAbacus,
  IconActivityHeartbeat,
  IconBell,
  IconBinoculars,
  IconBriefcase,
  IconCalendarEvent,
  IconChartBarPopular,
  IconChartCovariate,
  IconDatabase,
  IconDatabaseDollar,
  IconDirections,
  IconFileAnalytics,
  IconFolder,
  IconHome,
  IconNorthStar,
  IconPackage,
  IconPlugConnected,
  IconReceipt2,
  IconReport,
  IconSearch,
  IconSettings,
  IconTable,
  IconZoomMoney,
} from "@tabler/icons-react"
import { ComponentType } from "react"

import { Permissions } from "@costory/front/utils/permissions"

export type SidebarItem = {
  path?: string
  name: string
  icon: ComponentType
  position?: "top" | "bottom"
  links?: SidebarItem[]
  role?: string
  tooltip?: string
  isFeatureFlagged?: boolean
}

export const SIDEBAR_ITEMS: SidebarItem[] = [
  {
    path: "home",
    name: "Home",
    icon: IconHome,
    position: "top",
    role: Permissions.Member,
  },
  {
    name: "Explore",
    icon: IconSearch,
    position: "top",
    role: Permissions.Member,
    links: [
      {
        path: "reports",
        name: "Library",
        icon: IconFolder,
        position: "top",
        role: Permissions.Member,
      },
      {
        path: "explorer",
        name: "Explorer",
        icon: IconChartBarPopular,
        position: "top",
        role: Permissions.Member,
        tooltip:
          "Explore multi-cloud spend, correlate it to your business growth",
      },
      {
        path: "waterfall",
        name: "Cost Waterfall",
        icon: IconPackage,
        position: "top",
        role: Permissions.Member,
        tooltip: "Understand the contribution factors of cost changes",
      },
      {
        path: "labels-explorer",
        name: "Labels Explorer",
        icon: IconFileAnalytics,
        position: "top",
        role: Permissions.Member,
        tooltip: "Explore the labels available in your stack",
      },
    ],
  },
  {
    name: "Discover",
    icon: IconBinoculars,
    position: "top",
    role: Permissions.Member,
    links: [
      {
        path: "alerts",
        name: "Alerts",
        icon: IconBell,
        position: "top",
        tooltip: "Get notified of cost anomalies.",
        role: Permissions.Member,
      },
      {
        path: "insights",
        name: "Insights",
        icon: IconNorthStar,
        position: "top",
        role: Permissions.Member,
        tooltip: "Have an overall view of your spend",
      },
    ],
  },

  {
    name: "Collaborate",
    icon: IconDirections,
    role: Permissions.Member,
    position: "top",
    links: [
      {
        path: "budgets",
        name: "Budgets",
        icon: IconTable,
        position: "top",
        role: Permissions.Member,
        tooltip: "Plan and manage your cloud spending.",
      },
      {
        path: "initiatives",
        name: "Initiatives",
        icon: IconDatabaseDollar,
        position: "top",
        role: Permissions.Member,
        tooltip: "Track your cloud cost savings initiatives",
        isFeatureFlagged: true,
      },
      {
        path: "digests",
        name: "Digest",
        icon: IconAbacus,
        tooltip: "Report your findings",
      },
      {
        path: "scheduled-reports",
        name: "Scheduled Reports",
        icon: IconReport,
        tooltip: "Configure automated digest reports",
      },
    ],
  },
  {
    name: "Enrich",
    icon: IconChartCovariate,
    links: [
      {
        path: "virtual-dimensions",
        name: "Virtual Dimensions",
        icon: IconBriefcase,
        position: "top",
        role: Permissions.Member,
        tooltip: "Your FinOps Rosetta Stone",
      },
      {
        path: "metrics-v2",
        name: "Metrics",
        icon: IconZoomMoney,
        position: "top",
        role: Permissions.Member,
        tooltip: "Normalize your costs",
      },
      {
        path: "events",
        name: "Events",
        icon: IconCalendarEvent,
        position: "top",
        tooltip: "Contextualize your cost analysis",
        role: Permissions.Member,
      },
    ],
  },
  {
    name: "Settings",
    icon: IconSettings,
    role: Permissions.Member,
    position: "top",
    links: [
      {
        name: "Data Sources",
        icon: IconDatabase,
        role: Permissions.Member,
        position: "top",
        links: [
          {
            path: "datasources/billing",
            name: "Billing",
            icon: IconReceipt2,
            position: "top",
            role: Permissions.Member,
            tooltip: "Connect data sources: Billing",
          },
          {
            path: "datasources/metrics",
            name: "Metrics",
            icon: IconZoomMoney,
            position: "top",
            role: Permissions.Member,
            tooltip: "Connect data sources: Metrics",
            isFeatureFlagged: true,
          },
          {
            name: "Datasources",
            icon: IconDatabase,
            role: Permissions.Member,
            position: "top",
            path: "datasources",
          },
        ],
      },
      {
        path: "data-health",
        name: "Data Health",
        icon: IconActivityHeartbeat,
        position: "top",
        role: Permissions.Member,
        tooltip: "Monitor data freshness",
      },
      {
        path: "integration",
        name: "Integrations",
        icon: IconPlugConnected,
        position: "top",
        role: Permissions.Member,
        tooltip: "Connect Integrations",
      },
    ],
  },
]
