import _ from "lodash"
import { add, RuleGroupType } from "react-querybuilder"

import { DashboardResponses } from "@costory/types/endpoints/dashboard"

export const orderComponentTypes = (value: string) => {
  switch (value) {
    case "Private": {
      return 1
    }
    case "My Public": {
      return 2
    }
    case "Other Public": {
      return 3
    }
    default: {
      return 4
    }
  }
}
export const formatDashboardsList = (
  dashboards: DashboardResponses.GetDashboard[],
) => {
  return _(dashboards)
    .groupBy("category")
    .map((v, k) => ({
      group: k,
      items: v.map((sv) => ({
        value: sv.id!,
        label: sv.name!,
      })),
    }))
    .sortBy((g) => orderComponentTypes(g.group))
    .value()
}

export const addWhereClause = (
  baseWhereClause: RuleGroupType,
  selectedVirtualDimensionsValues: {
    bqName: string
    values: string[]
  }[],
) => {
  const allnewWhereClause = _(selectedVirtualDimensionsValues)
    .groupBy((a) => a.bqName)
    .map((el) => {
      return {
        field: el[0].bqName,
        operator: "in",
        value: el.map((a) => a.values).flat(),
      }
    })
    .value()
  const newWhereClause = add(
    {
      combinator: "and",
      rules: allnewWhereClause,
    },
    baseWhereClause,
    [],
  )
  return newWhereClause
}
