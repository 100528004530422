import { z } from "zod"

export type SelectedVirtualDimensionsValuesSearchParams = z.infer<
  typeof selectedVirtualDimensionsValuesSearchParamsSchema
>
export const selectedVirtualDimensionsValuesSearchParamsSchema = z
  .object({
    bqName: z.string(),
    values: z.array(z.string()),
  })
  .array()
