import { DonutChart as BaseDonutChart } from "@mantine/charts"
import { Button, Flex, Stack } from "@mantine/core"
import qs from "qs"
import { useNavigate } from "react-router-dom"

import { Filters } from "@costory/types/filters"

import { TrendBadge } from "@costory/front/components/TrendBadge"
import { ChartTooltip } from "@costory/front/components/charts/ChartTooltip"
import { QueryWrapper } from "@costory/front/components/layout/QueryWrapper"
import { useWaterfallQuery } from "@costory/front/queries/explore"
import { getCharteSerieColor } from "@costory/front/utils/charts"
import { formatNumber } from "@costory/front/utils/format"

type Props = {
  filters: Filters
  height?: number
  displayTrend?: boolean
  drillDownInto?: (value: string, mode: "sameWindow" | "newTab") => void
}

export function goToWaterfall(filters: Filters) {
  const updatedFilters = {
    ...filters,
    whereClause: encodeURIComponent(JSON.stringify(filters.whereClause)),
  }
  const queryString = qs.stringify(updatedFilters, {
    encode: false,
  })

  return queryString
}
export const DonutChart = ({
  filters,
  height,
  displayTrend,
  drillDownInto,
}: Props) => {
  const navigate = useNavigate()
  const waterfallQuery = useWaterfallQuery({ ...filters })

  return (
    <QueryWrapper query={waterfallQuery}>
      {({ data }) => (
        <Stack h="none">
          {displayTrend && (
            <Flex justify="end" mb="lg">
              <Button
                bd="none"
                bg="transparent"
                onClick={() => {
                  const url = goToWaterfall(filters)
                  navigate(`/waterfall?${url}`)
                }}
              >
                <TrendBadge
                  current={data.donutChartData.total}
                  previous={data.donutChartData.previous}
                />
              </Button>
            </Flex>
          )}
          <BaseDonutChart
            size={height}
            thickness={30}
            styles={{ label: { fontSize: 32, fontWeight: "bold" } }}
            chartLabel={formatNumber(
              data.donutChartData.total,
              "currency",
              2,
              filters.currency,
            )}
            valueFormatter={(value) => formatNumber(value)}
            data={data.donutChartData.arr.map((elem, index) => ({
              ...elem,
              color: getCharteSerieColor(index),
              onClick: () => {
                navigate("/waterfall")
                if (drillDownInto) {
                  drillDownInto(elem.name, "sameWindow")
                }
              },
            }))}
            tooltipProps={{
              content: ({ label, payload }) => (
                <ChartTooltip
                  label={label}
                  payload={payload}
                  tooltipData={data.dataGroupedByValue}
                  currency={filters.currency}
                />
              ),
            }}
          />
        </Stack>
      )}
    </QueryWrapper>
  )
}
