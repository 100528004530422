import { Button, Group, Stack, Text } from "@mantine/core"
import { IconEdit } from "@tabler/icons-react"
import { Link, useParams } from "react-router-dom"

import { BudgetStatus } from "@costory/types/prisma-client"

import { useUser } from "@costory/front/components/auth/UserContext"
import { QueryWrapper } from "@costory/front/components/layout/QueryWrapper"
import { BudgetCSVExportDownloadButton } from "@costory/front/pages/Budgets/components/BudgetCSVExportButton"
import { BudgetTable } from "@costory/front/pages/Budgets/components/BudgetTable"
import { BudgetTableMode } from "@costory/front/pages/Budgets/consts"
import { useGetBudgetQuery } from "@costory/front/queries/budgets"

export const BudgetPage = () => {
  const { budgetId } = useParams<{ budgetId: string }>()
  const query = useGetBudgetQuery(budgetId!)
  const user = useUser()

  return (
    <QueryWrapper query={query}>
      {({ data: { versions, formData } }) => {
        const versionsToDisplay =
          versions.length === 1
            ? versions
            : versions.filter(
                (version) => version.status !== BudgetStatus.DRAFT,
              )

        const hasDraftRevision =
          versions.length !== 1 && versions[0].status === BudgetStatus.DRAFT

        return (
          <Stack>
            <BudgetTable
              formData={formData}
              versions={versionsToDisplay}
              mode={BudgetTableMode.View}
            />
            <Group justify="end">
              <BudgetCSVExportDownloadButton
                budgetId={versions[0].versionId!}
              />
              {versions[0].createdBy.id === user.id && (
                <>
                  {hasDraftRevision && (
                    <Text>This budget has a draft revision</Text>
                  )}
                  <Button
                    component={Link}
                    to="edit"
                    leftSection={<IconEdit />}
                    variant="outline"
                  >
                    {hasDraftRevision ? "Edit draft revision" : "Edit"}
                  </Button>
                </>
              )}
            </Group>
          </Stack>
        )
      }}
    </QueryWrapper>
  )
}
