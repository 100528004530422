import {
  Popover,
  ActionIcon,
  Stack,
  TagsInput,
  Button,
  Tooltip,
} from "@mantine/core"
import { UseFormReturnType } from "@mantine/form"
import { IconSignRightFilled } from "@tabler/icons-react"
import _ from "lodash"
import { useState } from "react"

import { DigestType } from "@costory/types/endpoints/digest"

export function VdimHelper({
  checkedUuid,
  form,
  cleanSelected,
}: {
  checkedUuid: string[]
  form: UseFormReturnType<DigestType>
  cleanSelected: () => void
}) {
  const [value, setValue] = useState<string[]>([])
  const [opened, setOpened] = useState(false)
  const addLabels = (
    form: UseFormReturnType<DigestType>,
    checkedNodes: string[],
    additionalLabels: string[],
  ) => {
    const itemsModified = form.getValues().items.map((el) =>
      checkedNodes.includes(el.id)
        ? {
            ...el,
            labels: el.labels
              ? _.uniqBy(
                  additionalLabels.concat(el.labels),
                  (a) => a.split(":")[0],
                )
              : additionalLabels,
          }
        : el,
    )
    form.setValues({ items: itemsModified })
  }
  return (
    <Popover
      width={300}
      trapFocus
      position="bottom"
      withArrow
      shadow="md"
      opened={opened}
      onChange={setOpened}
      closeOnClickOutside={false}
    >
      <Popover.Target>
        <Tooltip label="Add labels">
          <ActionIcon onClick={() => setOpened((o) => !o)}>
            <IconSignRightFilled
              style={{ width: "70%", height: "70%" }}
              stroke={1.5}
            />
          </ActionIcon>
        </Tooltip>
      </Popover.Target>
      <Popover.Dropdown>
        <Stack>
          <TagsInput
            placeholder="Enter labels"
            value={value}
            onChange={setValue}
            data={_.uniq(
              form
                .getValues()
                .items.map((el) => el.labels)
                .filter((el) => !!el)
                .flat(),
            )}
          />
          <Button
            onClick={() => {
              addLabels(form, checkedUuid, value)
              setOpened(false)
              cleanSelected()
            }}
          >
            Save
          </Button>
        </Stack>
      </Popover.Dropdown>
    </Popover>
  )
}
