import { useForm, UseFormReturnType } from "@mantine/form"
import dayjs from "dayjs"
import _ from "lodash"
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react"
import { add, formatQuery } from "react-querybuilder"
import { v4 as uuidv4 } from "uuid"

import { AxesResponses } from "@costory/types/endpoints/axes"
import { MetricsResponses } from "@costory/types/endpoints/metrics"
import { SavedViewsResponses } from "@costory/types/endpoints/savedViews"
import { filterBarSchema, Filters } from "@costory/types/filters"
import {
  AggBy,
  Currency,
  ExplorerChartType,
} from "@costory/types/prisma-client"

import {
  ALL_OTHERS,
  OTHERS,
  UNLABELLED,
} from "@costory/shared/utils/explorer_data"
import {
  computePresetDates,
  createSafeParsedFilters,
} from "@costory/shared/utils/filters"

import { SavedViewRedirectPage } from "@costory/front/queries/savedViews"
import {
  DEFAULT_QUERY_BUILDER_FILTER,
  DEFAULT_QUERY_BUILDER_FILTER_RECCURING,
  formatDatasources,
  useFiltersSearchParamsBinding,
} from "@costory/front/utils/filters"

type ChartContextType = {
  axes: AxesResponses.Axis[]
  metrics: MetricsResponses.Metric[]
  defaultHideOneTime?: boolean
  savedView?: SavedViewsResponses.GetSavedViewDetails
  redirectPage: SavedViewRedirectPage
  embed?: boolean
  filterForm?: UseFormReturnType<Filters>
  filters?: Filters
  drillDownInto?: (
    groupBy: string,
    value: string,
    newGroupBy: string,
    mode: "sameWindow" | "newTab",
  ) => void
  setThreshold?: (threshold: number | null) => void
  setExplorerChartType?: (chartType: ExplorerChartType) => void
  resetTo?: (filters: Filters) => void
  selectedMetric?: MetricsResponses.Metric | undefined
  queryError?: boolean
  groupByOptions?: {
    group: string
    items: {
      value: string
      label: string
    }[]
  }[]
  urlFilters?: Filters
  setURLFilters?: (filters: Filters) => void
  referenceFilters?: Filters
  metricsOptions?: {
    label: string
    value: string
  }[]
  currentPeriod?: string
  previousPeriod?: string
}

// Do not bother with default context value, see https://www.carlrippon.com/react-context-with-typescript-p4/
export const ChartContext = createContext<ChartContextType>(undefined!)

export const useChartContext = () => {
  const context = useContext(ChartContext)
  if (!context) {
    throw new Error("useChartContext must be used within a ChartContext")
  }
  const { axes, metrics, defaultHideOneTime, savedView, redirectPage, embed } =
    context

  const [queryError, setQueryError] = useState<boolean>(false)
  const metricsOptions = useMemo(
    () =>
      _.sortBy(metrics, (d) => Number(!("technical" in d))).map((metric) => ({
        label: metric.name,
        value: metric.id,
      })),
    [metrics],
  )
  const groupByOptions = useMemo(() => formatDatasources(axes), [axes])

  const filtersValidator = useMemo(
    () => filterBarSchema(axes.map((axis) => axis.name)),
    [axes],
  )

  const validateFilters = useCallback(
    (filters: Filters) => {
      const validatedFilters = filtersValidator.safeParse(filters)
      if (validatedFilters.success) {
        const transformedFilters = {
          ...validatedFilters.data,
          ...(filters.threshold && {
            // @ts-expect-error zod sometimes returns string here
            threshold: parseInt(filters.threshold),
          }),
          whereClause: JSON.parse(
            formatQuery(validatedFilters.data.whereClause, "json_without_ids"),
          ),
          explorerChartType: filters.explorerChartType,
        }
        return {
          ...validatedFilters,
          data: transformedFilters as Filters,
        }
      }
      return validatedFilters
    },
    [filtersValidator],
  )

  const { urlFilters, setURLFilters } =
    useFiltersSearchParamsBinding(validateFilters)

  const defaultFilters = {
    limit: 10,
    aggBy: AggBy.Month,
    metricId: metricsOptions[0]?.value || "cost",
    groupBy: "cos_provider",
    currency: Currency.USD,
    explorerChartType: ExplorerChartType.BAR,
    ...computePresetDates("LAST_MONTH"),
    whereClause: defaultHideOneTime
      ? DEFAULT_QUERY_BUILDER_FILTER_RECCURING
      : DEFAULT_QUERY_BUILDER_FILTER,
    eventsTagsSearch: [],
  }

  const getFiltersFromSavedViewOrDefault = (
    savedView: SavedViewsResponses.SavedView | undefined,
  ) => {
    if (!savedView) {
      return defaultFilters
    }

    const validatedSavedView = validateFilters(savedView)
    if (!validatedSavedView.success) {
      return defaultFilters
    }

    return validatedSavedView.data
  }

  /**
   * Used to decide if the saved view has been touched
   */
  const referenceFilters = useMemo<Filters>(
    () => getFiltersFromSavedViewOrDefault(savedView),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [savedView],
  )

  /**
   * Used to initialize the form with the saved view, url or default filters
   */
  const initialValues = useMemo<Filters>(
    () => (urlFilters ? urlFilters : referenceFilters),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  const [filters, setFilters] = useState<Filters>(initialValues)
  const handleFormValuesChange = (current: Filters) => {
    const { success, data: validatedFilters } = validateFilters(current)
    setQueryError(!success)
    if (!success) {
      return
    }

    setFilters(validatedFilters)
    if (_.isEqual(validatedFilters, referenceFilters)) {
      setURLFilters(null)
    } else {
      setURLFilters(validatedFilters)
    }
  }

  const filterForm = useForm<Filters>({
    initialValues,
    onValuesChange: handleFormValuesChange,
    mode: "uncontrolled",
  })

  /**
   * Change filters on URL change (browser navigation)
   */
  useEffect(() => {
    if (urlFilters && !_.isEqual(urlFilters, filters)) {
      filterForm.setValues(urlFilters)
      return
    }

    if (!urlFilters && !_.isEqual(referenceFilters, filters)) {
      resetTo(referenceFilters)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlFilters])

  const drillDownInto = (
    groupBy: string,
    value: string | undefined,
    newGroupBy: string,
    mode: "sameWindow" | "newTab" = "sameWindow",
  ) => {
    if (value === ALL_OTHERS || value === OTHERS) {
      return
    }
    if (value === "Current" || value === "Previous" || !value) {
      return
    }
    const toSet = { groupBy: newGroupBy }
    if (value == UNLABELLED) {
      const newWhereClause = add(
        filters.whereClause,
        {
          id: uuidv4(),
          field: groupBy,
          operator: "null",
          value: [],
        },
        [],
      )
      if (mode === "sameWindow") {
        filterForm.setValues({
          whereClause: newWhereClause,
          ...toSet,
        })
      } else {
        const filterValues = filterForm.getValues()
        const safeParsedFilters = createSafeParsedFilters({
          ...filterValues,
          whereClause: newWhereClause,
          ...toSet,
        })
        window.open(
          `/explorer?${new URLSearchParams(safeParsedFilters).toString()}`,
          "_blank",
        )
      }
    } else {
      const newRule = {
        id: uuidv4(),
        field: groupBy,
        operator: "in",
        value: [value],
      }

      const newWhereClause =
        filters.whereClause.combinator == "or" &&
        filters.whereClause.rules.length
          ? {
              combinator: "and",
              rules: [
                {
                  combinator: "or",
                  id: uuidv4(),
                  rules: [...filters.whereClause.rules],
                },
                newRule,
              ],
            }
          : add(filters.whereClause, newRule, [])
      if (mode === "sameWindow") {
        filterForm.setValues({
          whereClause: newWhereClause,
          ...toSet,
        })
      } else {
        const filterValues = filterForm.getValues()
        const safeParsedFilters = createSafeParsedFilters({
          ...filterValues,
          ...toSet,
          whereClause: newWhereClause,
        })
        window.open(
          `/explorer?${new URLSearchParams(safeParsedFilters).toString()}`,
          "_blank",
        )
      }
    }
  }

  const setThreshold = (threshold: number | null) => {
    filterForm.setFieldValue("threshold", threshold)
  }

  const setExplorerChartType = (chartType: ExplorerChartType) => {
    filterForm.setFieldValue("explorerChartType", chartType)
  }

  const resetTo = (filters: Filters) => {
    filterForm.setValues(filters)
  }

  const currentPeriod = useMemo(() => {
    return `${toReadableDate(dayjs(filters.from).toDate())} - ${toReadableDate(dayjs(filters.to).toDate())}`
  }, [filters])
  const previousPeriod = useMemo(() => {
    return `${toReadableDate(dayjs(filters.previousFrom).toDate())} - ${toReadableDate(dayjs(filters.previousTo).toDate())}`
  }, [filters])

  return {
    axes,
    metrics,
    defaultHideOneTime,
    savedView,
    filterForm,
    filters,
    queryError,
    groupByOptions,
    drillDownInto,
    setThreshold,
    setExplorerChartType,
    resetTo,
    selectedMetric: metrics.find((m) => m.id === filters.metricId),
    redirectPage,
    embed,
    referenceFilters,
    urlFilters,
    setURLFilters,
    metricsOptions,
    currentPeriod,
    previousPeriod,
  }
}

const toReadableDate = (date: Date | null | undefined): string => {
  if (!date) {
    return ""
  }
  return dayjs(date).format("YYYY MMM DD")
}
