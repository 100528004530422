import {
  OrganizationSwitcher,
  RedirectToSignIn,
  UserButton,
  useUser,
} from "@clerk/clerk-react"
import {
  Alert,
  AppShell,
  Container,
  Group,
  LoadingOverlay,
  NavLink,
  Stack,
} from "@mantine/core"
import * as Sentry from "@sentry/react"
import { IconHome2 } from "@tabler/icons-react"
import { Link, Navigate, Outlet, useLocation } from "react-router-dom"

import { UserProvider } from "@costory/front/components/auth/UserContext"
import { useAuthState } from "@costory/front/queries/auth"

export const AuthGuard = () => {
  const { isSignedIn, isLoading, user, isError } = useAuthState()
  if (user) {
    Sentry.setUser({
      email: user.email,
      id: user.id,
      name: user.firstName + " " + user.lastName,
    })
  }
  if (user?.currentOrg?.slug) {
    Sentry.setTag("organization", user.currentOrg.slug)
  }
  const location = useLocation()
  const redirectUrl = encodeURI(location.pathname + location.search)
  if (isError) {
    return (
      <Navigate
        to="org-selection"
        state={{
          from: redirectUrl,
        }}
      />
    )
  }
  if (isLoading) return <LoadingOverlay visible />
  if (!isSignedIn) return <RedirectToSignIn redirectUrl={redirectUrl} />
  return (
    <UserProvider user={user}>
      <Outlet />
    </UserProvider>
  )
}

export const AuthGuardWithoutOrganization = () => {
  const { isLoaded, isSignedIn } = useUser()

  const location = useLocation()
  const redirectUrl = location.state?.from ?? "/home"

  if (!isLoaded) return <LoadingOverlay visible />
  if (!isSignedIn) return <RedirectToSignIn redirectUrl={redirectUrl} />

  return (
    <AppShell layout="alt">
      <AppShell.Main>
        <Container fluid mih="calc(100dvh)">
          <Stack justify="center" align="center">
            <Alert variant="white" color="gray">
              Welcome to Costory, please choose an organization:
            </Alert>

            <Group>
              <OrganizationSwitcher
                hidePersonal
                afterSelectOrganizationUrl={redirectUrl}
              />
              <UserButton />
            </Group>
            <Group>
              <NavLink
                component={Link}
                style={{ align: "center" }}
                to={redirectUrl}
                label="Access this organization"
                leftSection={<IconHome2 size="1rem" stroke={1.5} />}
              />
            </Group>
          </Stack>
        </Container>
      </AppShell.Main>
    </AppShell>
  )
}
