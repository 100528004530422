import {
  Stack,
  InputWrapper,
  Select,
  Group,
  List,
  Button,
  Text,
} from "@mantine/core"
import { UseFormReturnType } from "@mantine/form"
import { IconCheck, IconX, IconArrowLeft } from "@tabler/icons-react"
import dayjs from "dayjs"
import _ from "lodash"

import {
  AwsDatasourceFormDataWithFileData,
  CSVOrientation,
} from "@costory/types/endpoints/metricsDatasources"

interface AwsColumnMappingFormProps {
  form: UseFormReturnType<AwsDatasourceFormDataWithFileData>
  handleClickImport: () => void
  handleClickBack: () => void
}

export const AwsColumnsMapping = ({
  form,
  handleClickBack,
  handleClickImport,
}: AwsColumnMappingFormProps) => {
  const {
    orientation,
    seriesData,
    date,
    seriesNames: availableHeaders,
    metricNames,
  } = form.getValues() as AwsDatasourceFormDataWithFileData

  const foundMetrics = form.isValid()
    ? orientation === CSVOrientation.Columns
      ? availableHeaders.filter((header) => header !== date)
      : seriesData.map((row) => row[metricNames!])
    : []

  const handleClickImportWithValidation = () => {
    const requiredFields: { key: keyof typeof form.values; name: string }[] = [
      { key: "orientation", name: "Orientation" },
      { key: "date", name: "Date column" },
      ...(orientation === CSVOrientation.Rows
        ? [
            { key: "metricNames" as const, name: "Metric names column" },
            { key: "metricValues" as const, name: "Metric values column" },
          ]
        : []),
    ]

    const hasErrors = requiredFields.some(({ key, name }) => {
      if (!form.values[key]) {
        form.setFieldError(key, `${name} is required`)
        return true
      }
      return false
    })

    if (!hasErrors) handleClickImport()
  }

  return (
    <Stack justify="space-between" h="100%">
      <Stack>
        <InputWrapper
          label={
            <Text fw="bold" span={true}>
              Data orientation
            </Text>
          }
          required
        >
          <Select
            allowDeselect
            {...form.getInputProps("orientation")}
            data={Object.values(CSVOrientation)}
            placeholder="Select data orientation"
          />
        </InputWrapper>
        <InputWrapper
          label={
            <Text fw="bold" span={true}>
              Which column contains the dates?
            </Text>
          }
          required
        >
          <Select
            {...form.getInputProps("date")}
            data={availableHeaders.filter(
              (_) =>
                _ != form.values.metricNames && _ != form.values.metricValues,
            )}
            allowDeselect
            placeholder="Select a column"
          />
        </InputWrapper>
        {orientation === CSVOrientation.Rows && (
          <>
            <InputWrapper
              label={
                <Text fw="bold" span={true}>
                  Which column contains the metric names?
                </Text>
              }
              required
            >
              <Select
                {...form.getInputProps("metricNames")}
                data={availableHeaders.filter(
                  (_) => _ != form.values.date && _ != form.values.metricValues,
                )}
                allowDeselect
                placeholder="Select a column"
              />
            </InputWrapper>
            <InputWrapper
              label={
                <Text fw="bold" span={true}>
                  Which column contains the metric values?
                </Text>
              }
              required
            >
              <Select
                {...form.getInputProps("metricValues")}
                data={availableHeaders.filter(
                  (_) => _ != form.values.date && _ != form.values.metricNames,
                )}
                allowDeselect
                placeholder="Select a column"
              />
            </InputWrapper>
          </>
        )}
        {form.isValid() && (
          <Stack>
            {form.values.date && (
              <Stack>
                <Text>Date column preview:</Text>
                {seriesData.slice(0, 3).map((row, i) => {
                  const rawDate = row[form.values.date].toString()
                  const parsedDate = dayjs(rawDate).isValid()
                    ? dayjs(rawDate)
                    : dayjs(Number(rawDate))
                  const dateValue = parsedDate.format("YYYY-MM-DD")
                  const isValid =
                    parsedDate.isValid() ||
                    dayjs(dateValue, "YYYY-MM-DD", true).isValid() ||
                    dayjs(dateValue, "YYYY/MM/DD", true).isValid()

                  return (
                    <Group key={i}>
                      <Text c={isValid ? "green" : "red"}>{dateValue}</Text>
                      {isValid ? (
                        <Group>
                          <IconCheck
                            size={16}
                            color="var(--mantine-color-green-6)"
                          />
                          <Text size="xs" c="dimmed">
                            Valid
                          </Text>
                        </Group>
                      ) : (
                        <Group>
                          <IconX size={16} color="var(--mantine-color-red-6)" />
                          <Text size="xs" c="red">
                            Invalid: must be YYYY-MM-DD or YYYY/MM/DD
                          </Text>
                        </Group>
                      )}
                    </Group>
                  )
                })}
              </Stack>
            )}
            {foundMetrics.length > 0 && foundMetrics[0] && (
              <>
                <Text>We found the following metrics</Text>
                <List>
                  {_.uniq(foundMetrics).map((metric) => (
                    <List.Item key={metric}>{metric}</List.Item>
                  ))}
                </List>
              </>
            )}
          </Stack>
        )}
      </Stack>
      <Group justify="space-between" pb={20}>
        <Button
          onClick={handleClickBack}
          variant="outline"
          leftSection={<IconArrowLeft />}
        >
          Back
        </Button>
        <Button
          onClick={handleClickImportWithValidation}
          disabled={!form.isValid()}
        >
          Next
        </Button>
      </Group>
    </Stack>
  )
}
