import { useFeatureFlag, User } from "configcat-react"

import { FeatureFlag } from "@costory/front/components/configcat/FeatureFlag"
import { useAuthState } from "@costory/front/queries/auth"

const useFeatureToggle = (key: FeatureFlag) => {
  const auth = useAuthState()
  const userObject = new User(auth.user?.currentOrg.slug || "")

  return useFeatureFlag(key, false, userObject)
}

export default useFeatureToggle
