import "react-grid-layout/css/styles.css"
import "react-resizable/css/styles.css"

import { Modal, Paper, Stack, Text } from "@mantine/core"
import { useDisclosure } from "@mantine/hooks"
import { openConfirmModal } from "@mantine/modals"
import FileSaver from "file-saver"
import { useCallback, useEffect, useMemo, useState } from "react"
import { Layout, Responsive, WidthProvider } from "react-grid-layout"
import { useBlocker, useNavigate, useSearchParams } from "react-router-dom"
import { useGenerateImage } from "recharts-to-png"

import {
  DashboardRequests,
  DashboardResponses,
} from "@costory/types/endpoints/dashboard"
import { SavedViewsResponses } from "@costory/types/endpoints/savedViews"
import { ComponentType } from "@costory/types/prisma-client"

import {
  AddWidget,
  AddWidgetForm,
} from "@costory/front/components/dashboard/AddWidget"
import { DashboardActionBar } from "@costory/front/components/dashboard/DashboardActionBar"
import { DashboardWidget } from "@costory/front/components/dashboard/DashboardWidget"
import {
  CHART_TYPE_WIDGET_CONFIG,
  ChartType,
} from "@costory/front/components/dashboard/constants"
import { selectedVirtualDimensionsValuesSearchParamsSchema } from "@costory/front/components/dashboard/searchParamsSchema"
import { EmptyDashboard } from "@costory/front/pages/Dashboards/EmptyDashboard"
import { useAuthState } from "@costory/front/queries/auth"
import {
  useCreateDashboardMutation,
  useUpdateDashboardMutation,
} from "@costory/front/queries/dashboard"
import { useUpdateWidgetMutation } from "@costory/front/queries/savedViews"
import { addWhereClause } from "@costory/front/utils/dashboards"

const ResponsiveReactGridLayout = WidthProvider(Responsive)

export type Widget = Layout & {
  title: string
  viewId: string
  chartType: ChartType
}

type Props = {
  savedViews: SavedViewsResponses.SavedView[]
  dashboard?: DashboardResponses.GetDashboard
}

export const DashboardLayout = ({ dashboard, savedViews }: Props) => {
  const isExistingDashboard = !!dashboard
  const [isEditing, setIsEditing] = useState(dashboard === undefined)
  const [isOpen, { open, close }] = useDisclosure()
  const [hoveredWidget, setHoveredWidget] = useState<string | null>(null)
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const handleHover = (widgetId: string | null) => {
    setHoveredWidget(widgetId)
  }
  const blocker = useBlocker(() => isEditing && isExistingDashboard)

  useEffect(() => {
    if (blocker.state === "blocked") {
      openConfirmModal({
        title: "You have unsaved changes",
        children: <Text>Are you sure you want to leave ?</Text>,
        onClose: blocker.reset,
        onConfirm: blocker.proceed,
        labels: {
          cancel: "Stay",
          confirm: "Leave",
        },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blocker.state])

  const [widgets, setWidgets] = useState<Widget[]>([])
  useEffect(() => {
    setWidgets(
      isExistingDashboard
        ? dashboard.dashboardWidgets!.map((widget, index) => ({
            i: `${index + 1}`,
            ...widget,
            ...CHART_TYPE_WIDGET_CONFIG[widget.chartType].config,
          }))
        : [],
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dashboard])

  const {
    mutateAsync: updateDashboard,
    isPending: isUpdateDashboardMutationPending,
  } = useUpdateDashboardMutation()
  const {
    mutateAsync: createDashboard,
    isPending: isCreateDashboardMutationPending,
  } = useCreateDashboardMutation()

  const { updateWidget } = useUpdateWidgetMutation()

  const selectedVirtualDimensionsValues = useMemo(() => {
    const selectedVirtualDimensionsValuesSearchParam =
      searchParams.get("selectedVirtualDimensionsValues") ?? "[]"
    try {
      return selectedVirtualDimensionsValuesSearchParamsSchema.parse(
        JSON.parse(selectedVirtualDimensionsValuesSearchParam),
      )
    } catch (e) {
      console.error(
        "Failed to parse selected virtual dimensions values from URL:",
        e,
      )
    }
    return []
  }, [searchParams])

  const handleCreate = async (
    payload: Omit<DashboardRequests.NewDashboard, "dashboardWidgets">,
  ) => {
    try {
      await updateWidget(widgets, savedViews, dashboardType)
      const { dashboardId } = await createDashboard({
        ...payload,
        dashboardWidgets: widgets,
      })
      navigate(`/dashboards/${dashboardId}`)
    } catch (e) {
      console.error(e)
    }
  }

  const handleUpdate = async (
    payload: Omit<DashboardRequests.UpdateDashboard, "dashboardWidgets">,
  ) => {
    await updateWidget(widgets, savedViews, dashboardType)
    await updateDashboard({
      id: dashboard!.id,
      dashboard: {
        ...payload,
        dashboardWidgets: widgets,
      },
    })
    setIsEditing(false)
  }

  const handleModify = (currentLayout: Layout[]) => {
    setWidgets((prevWidgets) =>
      prevWidgets.map((widget) => {
        const matchingLayout = currentLayout.find(
          (layout) => layout.i === widget.i,
        )
        if (!matchingLayout) return widget
        return {
          ...widget,
          ...matchingLayout,
        }
      }),
    )
  }

  const handleAdd = (widgetConfig: AddWidgetForm) => {
    setWidgets((prevWidgets) => [
      ...prevWidgets,
      {
        i: `${widgetConfig.chartType}-${widgets.length + 1}`,
        x: 0,
        y: 0,
        ...widgetConfig,
        ...CHART_TYPE_WIDGET_CONFIG[widgetConfig.chartType].initial,
      },
    ])
    close()
  }

  const handleDelete = (key: string) => {
    setWidgets((prevWidgets) =>
      prevWidgets.filter((widget) => widget.i !== key),
    )
  }
  const handleEditTitle = (key: string, newTitle: string) => {
    setWidgets((prevWidgets) =>
      prevWidgets.map((widget) =>
        widget.i === key ? { ...widget, title: newTitle.trim() } : widget,
      ),
    )
  }

  const handleClickAddWidget = () => {
    setIsEditing(true)
    open()
  }

  const handleDiscardChanges = () => {
    setWidgets(
      dashboard!.dashboardWidgets!.map((widget, index) => ({
        i: `${index + 1}`,
        ...widget,
        ...CHART_TYPE_WIDGET_CONFIG[widget.chartType].config,
      })),
    )
    setIsEditing(false)
  }
  const [getDivJpeg, { ref }] = useGenerateImage<HTMLDivElement>({
    quality: 1,
    type: "image/jpeg",
    // @ts-expect-error quality is not typed
    options: {
      scale: 2,
    },
  })

  const handleDivDownload = useCallback(async () => {
    const jpeg = await getDivJpeg()
    if (jpeg) {
      FileSaver.saveAs(jpeg, `${dashboard?.name || "dashboard"}.jpeg`)
    }
  }, [getDivJpeg, dashboard?.name])
  const COSTORY_ORGID = import.meta.env.VITE_COSTORY_ORGID
  const auth = useAuthState()
  const currentDashboard = isExistingDashboard ? dashboard : undefined
  const [dashboardType, setDashboardType] = useState<ComponentType>(
    currentDashboard?.type === ComponentType.COMMUNITY &&
      auth.user?.currentOrg.id !== COSTORY_ORGID
      ? ComponentType.PUBLIC
      : (currentDashboard?.type ?? ComponentType.PUBLIC),
  )
  const viewsUsedByWidgets = useMemo(() => {
    return widgets.map((widget) => {
      const view = savedViews.find((view) => view.id === widget.viewId)
      if (!view) return null
      return {
        ...view,
        whereClause: addWhereClause(
          view.whereClause,
          selectedVirtualDimensionsValues,
        ),
      }
    })
  }, [widgets, savedViews, selectedVirtualDimensionsValues])

  return (
    <Stack>
      <DashboardActionBar
        widgets={widgets}
        isEditing={isEditing}
        onCreate={handleCreate}
        onUpdate={handleUpdate}
        onAddWidget={open}
        currentDashboard={currentDashboard}
        isExistingDashboard={isExistingDashboard}
        setIsEditing={setIsEditing}
        isCreateDashboardMutationPending={isCreateDashboardMutationPending}
        isUpdateDashboardMutationPending={isUpdateDashboardMutationPending}
        onDiscardChanges={handleDiscardChanges}
        setDashboardType={setDashboardType}
        dashboardType={dashboardType}
        savePng={handleDivDownload}
      />
      {widgets.length ? (
        <div ref={ref}>
          <ResponsiveReactGridLayout
            style={{
              background: isEditing
                ? "var(--mantine-primary-color-2)"
                : "transparent",
            }}
            onDragStop={handleModify}
            onResizeStop={handleModify}
            preventCollision={false}
            margin={[20, 20]}
            containerPadding={[0, 0]}
            draggableHandle=".dragHandle"
            resizeHandles={isEditing ? ["sw", "nw", "se", "ne"] : []}
            cols={{ lg: 8, md: 8, sm: 8, xs: 8, xxs: 8 }}
            autoSize
          >
            {widgets.map((widget) => {
              const view = viewsUsedByWidgets
                .filter((el) => !!el)
                .find((view) => view.id === widget.viewId)
              if (!view) return null
              return (
                <Paper
                  p={0}
                  key={widget.i}
                  onMouseOver={() => handleHover(widget.i)}
                  onMouseOut={() => handleHover(null)}
                  style={{
                    zIndex:
                      hoveredWidget === widget.i
                        ? "100"
                        : widgets.length - widgets.indexOf(widget),
                  }}
                  data-grid={{
                    x: widget.x,
                    y: widget.y,
                    w: widget.w,
                    h: widget.h,
                    i: widget.i,
                    ...CHART_TYPE_WIDGET_CONFIG[widget.chartType].config,
                    isResizable:
                      isEditing &&
                      CHART_TYPE_WIDGET_CONFIG[widget.chartType].isResizable,
                    isDraggable: isEditing,
                  }}
                >
                  <DashboardWidget
                    title={widget.title}
                    height={widget.h * 160}
                    savedView={view}
                    chartType={widget.chartType}
                    onDelete={() => handleDelete(widget.i)}
                    onRename={(title) => handleEditTitle(widget.i, title)}
                    isEditable={isEditing}
                  />
                </Paper>
              )
            })}
          </ResponsiveReactGridLayout>
        </div>
      ) : (
        <EmptyDashboard onClickAddWidget={handleClickAddWidget} />
      )}
      <Modal
        opened={isOpen}
        onClose={close}
        title="Add a new widget"
        size={1000}
      >
        <AddWidget onAdd={handleAdd} componentType={dashboardType} />
      </Modal>
    </Stack>
  )
}
