import { Table, Text } from "@mantine/core"

export const GSheetDocs = () => {
  return (
    <>
      <Text>
        We support importing metrics from Google Sheets. In step 3 you will be
        able to map the columns in the Google Sheet with what they represent;
        The sheet should look like this: the first row is the header.
        <Table withTableBorder withColumnBorders>
          <Table.Thead>
            <Table.Tr>
              <Table.Th>date</Table.Th>
              <Table.Th>metric1</Table.Th>
              <Table.Th>metric2</Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            <Table.Tr>
              <Table.Td>2024-01-01</Table.Td>
              <Table.Td>42</Table.Td>
              <Table.Td>123</Table.Td>
            </Table.Tr>
          </Table.Tbody>
        </Table>
      </Text>
    </>
  )
}
