import { KeysOfUnion } from "@costory/types"
import {
  ActionIcon,
  Button,
  Checkbox,
  Group,
  InputWrapper,
  List,
  ListItem,
  Loader,
  Modal,
  Radio,
  ScrollArea,
  Select,
  Space,
  Stack,
  Stepper,
  Text,
  TextInput,
  ThemeIcon,
  Title,
  Tooltip,
  useMantineTheme,
} from "@mantine/core"
import { DateInput } from "@mantine/dates"
import { useForm, UseFormReturnType, zodResolver } from "@mantine/form"
import { useDisclosure } from "@mantine/hooks"
import { modals } from "@mantine/modals"
import { showNotification } from "@mantine/notifications"
import {
  IconArrowLeft,
  IconArrowRight,
  IconCheck,
  IconCloudX,
  IconPlus,
  IconRefresh,
  IconTrash,
} from "@tabler/icons-react"
import {
  createMRTColumnHelper,
  MantineReactTable,
  useMantineReactTable,
} from "mantine-react-table"
import { useCallback, useEffect, useMemo, useState } from "react"
import { useSearchParams } from "react-router-dom"
import { z } from "zod"

import {
  awsImportParametersSchema,
  azureImportParametersSchema,
  BigQueryBillingDatasourceFormData,
  bigQueryColumnMappingSchema,
  bigQueryImportParametersSchema,
  BillingDatasourceFormData,
  BillingDatasourcesRequests,
  BillingDatasourcesResponses,
  gcpImportParametersSchema,
} from "@costory/types/endpoints/billingDatasources"
import { BillingDatasourceType } from "@costory/types/prisma-client"

import { DEFAULT_DATE_FORMAT } from "@costory/shared/const"
import dayjs from "@costory/shared/dayjs"

import { QueryWrapper } from "@costory/front/components/layout/QueryWrapper"
import { queryClient } from "@costory/front/lib/queryClient"
import {
  useBillingDatasourcesListQuery,
  useCreateBillingDatasourceMutation,
  useDeleteBillingDatasourceMutation,
  useGetBigQueryColumnsQuery,
  useValidateImportParamtersQuery,
} from "@costory/front/queries/billingDatasources"
import { PropsWithData } from "@costory/front/utils/propsWithData"
import { getDefaultTableOptions } from "@costory/front/utils/table"

const _BillingDatasourcesPage = ({
  data: datasources,
}: PropsWithData<BillingDatasourcesResponses.GetAllBillingDatasources>) => {
  const [
    isOpen,
    { open: openImportDatasourceModal, close: closeImportDatasourceModal },
  ] = useDisclosure(false)

  const { mutateAsync: deleteDatasource, isPending: isDeleting } =
    useDeleteBillingDatasourceMutation()
  const openDeleteModal = useCallback(
    (toDelete: BillingDatasourcesResponses.BillingDatasource) =>
      modals.openConfirmModal({
        title: "Please confirm you want to delete " + toDelete.name,
        labels: { confirm: "Confirm", cancel: "Cancel" },
        onConfirm: () => deleteDatasource(toDelete!.id),
      }),
    [deleteDatasource],
  )

  const [searchParams, setSearchParams] = useSearchParams()
  const isDetailsModalOpen = searchParams.get("details") !== null
  const openDetailsModal = (id: string) => {
    setSearchParams({ details: id })
  }
  const closeDetailsModal = () => {
    setSearchParams({})
  }

  const columns = useMemo(() => {
    const columnHelper =
      createMRTColumnHelper<BillingDatasourcesResponses.BillingDatasource>()
    return [
      columnHelper.accessor("type", {
        header: "Type",
        enableColumnActions: false,
        enableSorting: false,
        Header: () => null,
        Cell: ({ cell }) => {
          const datasource = availableDatasources.find(
            ({ value }) => value === cell.getValue(),
          )

          if (!datasource) {
            return null
          }

          const { icon: IconComponent, label } = datasource

          return (
            <Group align="center">
              <Tooltip label={label}>
                <ThemeIcon size={50}>{IconComponent}</ThemeIcon>
              </Tooltip>
            </Group>
          )
        },
      }),
      columnHelper.accessor("name", {
        header: "Name",
        filterVariant: "select",
      }),
      columnHelper.accessor("updatedAt", {
        header: "Last Update",
        Cell: ({ cell }) =>
          dayjs(cell.getValue()).local().format(DEFAULT_DATE_FORMAT),
      }),
    ]
  }, [])

  const table =
    useMantineReactTable<BillingDatasourcesResponses.BillingDatasource>({
      columns,
      data: datasources,
      enableRowActions: true,
      positionActionsColumn: "last",
      renderRowActions: ({ row }) => (
        <ActionIcon
          onClick={() => {
            openDeleteModal(row.original)
          }}
          loading={isDeleting}
        >
          <IconTrash />
        </ActionIcon>
      ),
      ...getDefaultTableOptions(),
      mantineTableBodyRowProps: ({ row: { original: datasource } }) => ({
        onDoubleClick: () => {
          openDetailsModal(datasource.id)
        },
      }),
    })

  return (
    <Stack>
      <Group justify="flex-end">
        <Button onClick={openImportDatasourceModal} leftSection={<IconPlus />}>
          Import new billing datasource
        </Button>
      </Group>

      <MantineReactTable table={table} />
      <Modal
        opened={isOpen}
        onClose={closeImportDatasourceModal}
        size="70vw"
        classNames={{ content: "custom-modal-content" }}
        title={<Title order={2}>Import new billing datasource</Title>}
      >
        <Modal.Body h="70vh">
          <AddBillingDataSourceFunnel onFinish={closeImportDatasourceModal} />
        </Modal.Body>
      </Modal>
      <Modal
        opened={isDetailsModalOpen}
        onClose={closeDetailsModal}
        size="70vw"
      >
        <BillingDatasourceDetails
          billingDatasource={
            datasources.find(({ id }) => id === searchParams.get("details"))!
          }
        />
      </Modal>
    </Stack>
  )
}
const BillingDatasourceDetails = ({
  billingDatasource,
}: {
  billingDatasource: BillingDatasourcesResponses.BillingDatasource
}) => {
  if (!billingDatasource) {
    return null
  }

  const IconComponent = availableDatasources.find(
    ({ value }) => value === billingDatasource.type,
  )!.icon

  return (
    <Stack>
      <Group>
        {IconComponent}
        <Stack gap={0}>
          <Title>Billing datasource {billingDatasource.name}</Title>
          <Group>
            <Text fw="bold">Owner: {billingDatasource.owner}</Text>
            <Text>
              Last update:{" "}
              {dayjs(billingDatasource.updatedAt).format("DD/MM/YYYY")}
            </Text>
          </Group>
        </Stack>
      </Group>
      <Stack align="stretch" w="40%">
        {questions[billingDatasource.type]?.map(({ key, label, type }) =>
          type === "text" ? (
            <InputWrapper label={label} key={key}>
              <TextInput
                // @ts-expect-error metadata is a key of the datasource
                value={billingDatasource.metadata[key]}
                placeholder={label}
                readOnly
              />
            </InputWrapper>
          ) : type === "checkbox" ? (
            <Group>
              <Text key={key}>{label}:</Text>
              {/*  @ts-expect-error metadata is a key of the datasource */}
              <Text>{billingDatasource.metadata[key] ? "Yes" : "No"}</Text>
            </Group>
          ) : (
            <InputWrapper label={label} key={key}>
              <DateInput
                // @ts-expect-error metadata is a key of the datasource
                value={billingDatasource.metadata[key]}
                readOnly
              />
            </InputWrapper>
          ),
        )}
      </Stack>
    </Stack>
  )
}

export const BillingDatasourcesPage = () => {
  const datasourceQuery = useBillingDatasourcesListQuery()

  return (
    <QueryWrapper query={datasourceQuery} allowEmptyArray>
      {_BillingDatasourcesPage}
    </QueryWrapper>
  )
}

interface AddBillingDataSourceFunnelProps {
  onFinish: () => void
}
export const AddBillingDataSourceFunnel = ({
  onFinish,
}: AddBillingDataSourceFunnelProps) => {
  const [activeStep, setActiveStep] = useState(0)

  const { mutateAsync: createDatasource } = useCreateBillingDatasourceMutation()

  const form = useForm<BillingDatasourceFormData>({
    validate: (values) => {
      if (activeStep === 0) {
        return zodResolver(
          z.object({
            name: z.string().min(1, "Name is required"),
            type: z.nativeEnum(BillingDatasourceType),
          }),
        )(values)
      }
      if (activeStep === 3) {
        // @ts-expect-error There's a mapping key in the form values for big query
        return zodResolver(bigQueryColumnMappingSchema)(values.mapping)
      }

      return zodResolver(importParametersSchemasMap[values.type!])(values)
    },
  })

  const hasFourSteps =
    form.getValues().type === BillingDatasourceType.CustomBigQuery

  const handleClickNext = () =>
    setActiveStep((current) => {
      if (form.validate().hasErrors) {
        return current
      }
      return current < 3 ? current + 1 : current
    })
  const handleClickBack = () =>
    setActiveStep((prev) => (prev > 0 ? prev - 1 : prev))

  const handleClickImport = async () => {
    if (form.validate().hasErrors) {
      return
    }

    try {
      const { name, type, ...metadata } = form.getValues()
      const payload = {
        name,
        type,
        metadata,
      }
      await createDatasource(
        payload as BillingDatasourcesRequests.CreateBillingDatasource,
      )

      showNotification({
        title: "Success",
        message: "Datasource has been created",
        color: "green",
      })
      onFinish()
    } catch {
      showNotification({
        title: "Error",
        message: "Failed to create datasource",
        color: "red",
      })
    }
  }

  const { type } = form.getValues()
  return (
    <Stack h="100%" justify="space-between">
      <Stepper
        active={activeStep}
        onStepClick={setActiveStep}
        allowNextStepsSelect={false}
        h="100%"
        styles={{
          content: {
            height: "95%",
          },
          stepIcon: {
            backgroundColor: "var(--mantine-color-primary-6)",
            color: "white",
          },
        }}
      >
        <Stepper.Step label={<Text fw="bold">Select datasource</Text>}>
          <Stack h="100%">
            <Stack flex={1}>
              <Group align="flex-start">
                <InputWrapper
                  label={
                    <Text fw="bold" span={true}>
                      Name
                    </Text>
                  }
                  required
                  w="40%"
                >
                  <TextInput
                    {...form.getInputProps("name")}
                    w="100%"
                    placeholder="Name"
                  />
                </InputWrapper>
              </Group>
              <InputWrapper
                label={
                  <Text fw="bold" span={true}>
                    Datasource type
                  </Text>
                }
                required
              >
                <Radio.Group {...form.getInputProps("type")}>
                  <Group align="left">
                    {availableDatasources.map((datasource) => {
                      const isSelected = type === datasource.value
                      const IconComponent = datasource.icon
                      return (
                        <Radio.Card
                          key={datasource.value}
                          value={datasource.value}
                          w={180}
                          h={180}
                          p={8}
                          radius="md"
                          withBorder
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            borderColor: isSelected
                              ? "var(--mantine-color-primary-6)"
                              : "var(--mantine-color-gray-3)",
                            borderWidth: isSelected ? 2 : 1,
                          }}
                        >
                          <Stack
                            justify="center"
                            align="center"
                            w="100%"
                            h="100%"
                          >
                            {IconComponent}
                            <Text fw="bold" ta="center">
                              {datasource.label}
                            </Text>
                          </Stack>
                        </Radio.Card>
                      )
                    })}
                  </Group>
                </Radio.Group>
              </InputWrapper>
            </Stack>
            <Group justify="flex-end">
              <Button
                onClick={handleClickNext}
                rightSection={<IconArrowRight />}
              >
                Next
              </Button>
            </Group>
          </Stack>
        </Stepper.Step>
        <Stepper.Step label={<Text fw="bold">Import parameters</Text>}>
          <Stack h="100%">
            <Stack flex={1}>
              {type &&
                questions[type]?.map(({ key, label, type, required }) =>
                  type === "text" ? (
                    <InputWrapper
                      label={
                        <Text fw="bold" span={true}>
                          {label}
                        </Text>
                      }
                      key={key}
                      required={required}
                    >
                      <TextInput
                        {...form.getInputProps(key)}
                        placeholder={label}
                      />
                    </InputWrapper>
                  ) : type === "checkbox" ? (
                    <Checkbox
                      {...form.getInputProps(key, { type: "checkbox" })}
                      label={<Text fw="bold">{label}</Text>}
                    />
                  ) : (
                    <InputWrapper
                      label={<Text fw="bold">{label}</Text>}
                      key={key}
                      required={required}
                    >
                      <DateInput
                        {...form.getInputProps(key)}
                        placeholder="01/01/2025"
                      />
                    </InputWrapper>
                  ),
                )}
            </Stack>
            <Group justify="space-between">
              <Button
                onClick={handleClickBack}
                variant="outline"
                leftSection={<IconArrowLeft />}
              >
                Back
              </Button>
              <Button
                onClick={handleClickNext}
                rightSection={<IconArrowRight />}
              >
                Next
              </Button>
            </Group>
          </Stack>
        </Stepper.Step>
        <Stepper.Step label={<Text fw="bold">Verification</Text>}>
          <Stack h="100%">
            <VerificationStep
              // @ts-expect-error form values are correct at this point
              importParameters={form.getValues()}
              handleClickBack={handleClickBack}
              handleClickNext={
                hasFourSteps ? handleClickNext : handleClickImport
              }
              isLastStep={!hasFourSteps}
              connectorType={type!}
            />
          </Stack>
        </Stepper.Step>
        {hasFourSteps && (
          <Stepper.Step
            title="Column mapping"
            label={<Text fw="bold">Column mapping</Text>}
          >
            <BigQueryColumnMappingStep
              // @ts-expect-error form values are correct at this point
              form={form}
              handleClickBack={handleClickBack}
              handleClickImport={handleClickImport}
            />
          </Stepper.Step>
        )}
      </Stepper>
    </Stack>
  )
}

interface VerificationStepProps {
  importParameters: BillingDatasourcesRequests.ValidateBillingDatasourceImportParameters
  handleClickBack: () => void
  handleClickNext: () => void
  isLastStep: boolean
  connectorType: BillingDatasourceType
  currentPrefix?: string
}

const VerificationStep = ({
  importParameters,
  handleClickBack,
  handleClickNext,
  isLastStep,
  connectorType,
  currentPrefix,
}: VerificationStepProps) => {
  const [isCreating, setIsCreating] = useState(false)
  const query = useValidateImportParamtersQuery(importParameters)

  const onClickImport = async () => {
    setIsCreating(true)
    await handleClickNext()
    setIsCreating(false)
  }

  return (
    <Stack h="100%" w="100%">
      <Stack align="center" justify="center" flex={1}>
        <QueryWrapper
          query={query}
          LoadingComponent={
            <Stack align="center">
              <Loader size="xl" />{" "}
              <Title order={2}>Validating datasource...</Title>{" "}
            </Stack>
          }
        >
          {({ data }) => {
            if (data.isSuccess) {
              return (
                <>
                  <IconCheck color="var(--mantine-color-green-6)" size={120} />
                  <Title order={2}>Your datasource is valid</Title>
                  <Space h={10} />
                </>
              )
            }

            return (
              <>
                <IconCloudX color="var(--mantine-color-gray-6)" size={120} />
                <Title order={2}>Your datasource is invalid</Title>

                {data.errorCode === "PREFIX" ? (
                  <Stack align="center">
                    <Text size="lg">Prefix parameter is not valid</Text>
                    {data.options.length === 0 ? (
                      <Text>
                        No data found with this prefix. Make sure you&apos;re
                        using the right bucket or try again with an empty
                        prefix.
                      </Text>
                    ) : (
                      <>
                        <Text size="md" c="dimmed" mb={10}>
                          {connectorType === BillingDatasourceType.AWS ? (
                            <>
                              The folder structure should contain both a
                              &quot;data&quot; and &quot;metadata&quot; folder
                              at the root level. For example: &quot;
                              {currentPrefix ?? "my-prefix"}/data/&quot; and
                              &quot;{currentPrefix ?? "my-prefix"}
                              /metadata/&quot;
                            </>
                          ) : (
                            <>
                              The folder name should contain a date range in the
                              format YYYYMMDD-YYYYMMDD. For example: &quot;
                              {currentPrefix ?? "my-prefix"}/
                              20240101-20240131&quot; for January 2024.
                            </>
                          )}
                        </Text>
                        <Text size="md" fw={500} mb={10}>
                          We found these blobs in your bucket:
                        </Text>
                        <ScrollArea h={100}>
                          <List>
                            {data.options.map((option) => (
                              <ListItem key={option}>
                                <Text size="sm">{option}</Text>
                              </ListItem>
                            ))}
                          </List>
                        </ScrollArea>

                        <Text size="md" c="dimmed" mt={10}>
                          {connectorType === BillingDatasourceType.AWS
                            ? "Please update the prefix to point to a folder containing both data/ and metadata/ folders."
                            : "Please update the prefix to point to a folder containing your billing data with the correct date format."}
                        </Text>
                      </>
                    )}
                  </Stack>
                ) : data.errorCode === "BUCKET" ? (
                  <Text>This bucket does not exist</Text>
                ) : (
                  <Text>
                    Failed to connect to datasource. Please make sure your
                    credentials are correct
                  </Text>
                )}
                <Space h={10} />
                <Group justify="flex-end">
                  <Text>Retry</Text>
                  <ActionIcon
                    loading={query.isLoading}
                    onClick={() =>
                      queryClient.resetQueries({
                        queryKey: ["billingDatasources", "validateImport"],
                      })
                    }
                  >
                    <IconRefresh />
                  </ActionIcon>
                </Group>
              </>
            )
          }}
        </QueryWrapper>
      </Stack>
      <Group justify="space-between">
        <Button
          onClick={handleClickBack}
          variant="outline"
          leftSection={<IconArrowLeft />}
        >
          Back
        </Button>
        {isLastStep ? (
          <Button
            onClick={onClickImport}
            disabled={!query.isSuccess || !query.data.isSuccess}
            loading={isCreating}
          >
            Import
          </Button>
        ) : (
          <Button
            onClick={handleClickNext}
            disabled={!query.isSuccess || !query.data.isSuccess}
            rightSection={<IconArrowRight />}
          >
            Next
          </Button>
        )}
      </Group>
    </Stack>
  )
}

type BigQueryForm = UseFormReturnType<BigQueryBillingDatasourceFormData>
interface BigQueryColumnMappingStepProps {
  form: BigQueryForm
  handleClickBack: () => void
  handleClickImport: () => void
}

const BigQueryColumnMappingStep = ({
  form,
  handleClickBack,
  handleClickImport,
}: BigQueryColumnMappingStepProps) => {
  const query = useGetBigQueryColumnsQuery(form.getValues())

  useEffect(() => {
    const formValues = form.getValues()
    if (!formValues.mapping) {
      form.setValues({
        ...formValues,
        mapping: {
          billedDate: "",
          cost: "",
        },
      })
    }
  }, [form])

  return (
    <>
      <QueryWrapper query={query} allowEmptyArray>
        {({ data }) => (
          <BigQueryColumnMappingForm
            {...data}
            form={form}
            handleClickBack={handleClickBack}
            handleClickImport={handleClickImport}
          />
        )}
      </QueryWrapper>
    </>
  )
}

interface BigQueryColumnMappingFormProps
  extends BillingDatasourcesResponses.GetBigQueryMapping {
  handleClickBack: () => void
  handleClickImport: () => void
  form: BigQueryForm
}

const BigQueryColumnMappingForm = ({
  form,
  columnsToMap,
  availableColumns,
  handleClickBack,
  handleClickImport: onImport,
}: BigQueryColumnMappingFormProps) => {
  const [isCreating, setIsCreating] = useState(false)
  const availableColumnsSelectItems = [
    ...availableColumns.map((column) => ({
      label: column,
      value: column,
    })),
  ]

  const handleClickImport = async () => {
    if (form.validate().hasErrors) {
      return
    }

    setIsCreating(true)
    await onImport()
    setIsCreating(false)
  }

  const { colors } = useMantineTheme()

  return (
    <Stack h="95%">
      <Stack flex={1}>
        <table>
          <thead>
            <tr
              style={{
                backgroundColor: colors.primary[2],
              }}
            >
              <th style={{ padding: 4 }}>Template column</th>
              <th>Column imported from your file</th>
            </tr>
          </thead>
          <tbody>
            {columnsToMap.map(({ label, name, required }) => (
              <tr key={name}>
                <th align="left">
                  {label}
                  {required && (
                    <span style={{ color: "var(--mantine-color-red-6)" }}>
                      {" *"}
                    </span>
                  )}
                </th>
                <td>
                  <Select
                    data={availableColumnsSelectItems}
                    {...form.getInputProps(`mapping.${name}`)}
                    placeholder="Select the corresponding column from your datasource"
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Stack>
      <Group justify="space-between">
        <Button
          onClick={handleClickBack}
          variant="outline"
          leftSection={<IconArrowLeft />}
        >
          Back
        </Button>
        <Button onClick={handleClickImport} loading={isCreating}>
          Import
        </Button>
      </Group>
    </Stack>
  )
}

const availableDatasources = [
  {
    label: "AWS",
    value: BillingDatasourceType.AWS,
    icon: <img src="/img/aws.svg" alt="AWS" width={50} />,
  },
  {
    label: "Manually with Big Query",
    value: BillingDatasourceType.CustomBigQuery,
    icon: <img src="/img/bigquery.svg" alt="Big Query" width={50} />,
  },
  {
    label: "Azure",
    value: BillingDatasourceType.Azure,
    icon: <img src="/img/azure.svg" alt="Azure" width={50} />,
  },
  {
    label: "GCP",
    value: BillingDatasourceType.GCP,
    icon: <img src="/img/gcp.svg" alt="GCP" width={50} />,
  },
]

const questions: Record<
  BillingDatasourceType,
  {
    label: string
    key: KeysOfUnion<BillingDatasourceFormData["metadata"]>
    type: "text" | "date" | "checkbox"
    required: boolean
  }[]
> = {
  [BillingDatasourceType.AWS]: [
    {
      label: "Bucket Name",
      key: "bucketName",
      type: "text",
      required: true,
    },
    {
      label: "Role ARN",
      key: "roleArn",
      type: "text",
      required: true,
    },
    {
      label: "Prefix",
      key: "prefix",
      type: "text",
      required: false,
    },
    {
      label: "EKS Split cost data enabled",
      key: "eksSplitDataEnabled",
      type: "checkbox",
      required: true,
    },
    {
      label: "Start Date",
      key: "startDate",
      type: "date",
      required: false,
    },
    {
      label: "End Date",
      key: "endDate",
      type: "date",
      required: false,
    },
  ],
  [BillingDatasourceType.GCP]: [
    {
      label: "Big Query Table Path",
      key: "bqTablePath",
      type: "text",
      required: true,
    },
    {
      label: "Is Detailed Billing",
      key: "isDetailedBilling",
      type: "checkbox",
      required: true,
    },
    {
      label: "Start Date",
      key: "startDate",
      type: "date",
      required: false,
    },
    {
      label: "End Date",
      key: "endDate",
      type: "date",
      required: false,
    },
  ],
  [BillingDatasourceType.Azure]: [
    {
      label: "SAS Token",
      key: "sasToken",
      type: "text",
      required: true,
    },
    {
      label: "Container Name",
      key: "containerName",
      type: "text",
      required: true,
    },
    {
      label: "Account",
      key: "account",
      type: "text",
      required: true,
    },
    {
      label: "Prefix",
      key: "prefix",
      type: "text",
      required: false,
    },
  ],
  [BillingDatasourceType.CustomBigQuery]: [
    {
      label: "Big Query Table Path",
      key: "bqTablePath",
      type: "text",
      required: true,
    },
    {
      label: "Provider Name",
      key: "providerName",
      type: "text",
      required: true,
    },
    {
      label: "Billing Account Id",
      key: "billingAccountId",
      type: "text",
      required: false,
    },
  ],
}

const importParametersSchemasMap = {
  [BillingDatasourceType.AWS]: awsImportParametersSchema,
  [BillingDatasourceType.GCP]: gcpImportParametersSchema,
  [BillingDatasourceType.Azure]: azureImportParametersSchema,
  [BillingDatasourceType.CustomBigQuery]: bigQueryImportParametersSchema,
}
