import { ActionIcon, Badge, Group, Title, Tooltip } from "@mantine/core"
import { openConfirmModal } from "@mantine/modals"
import { showNotification } from "@mantine/notifications"
import {
  IconCopy,
  IconEdit,
  IconExternalLink,
  IconTrash,
} from "@tabler/icons-react"
import dayjs from "dayjs"
import {
  createMRTColumnHelper,
  MantineReactTable,
  useMantineReactTable,
} from "mantine-react-table"
import { useNavigate } from "react-router-dom"

import { BudgetsResponses } from "@costory/types/endpoints/budgets"
import { BudgetStatus } from "@costory/types/prisma-client"

import { useUser } from "@costory/front/components/auth/UserContext"
import { BudgetCSVExportDownloadButton } from "@costory/front/pages/Budgets/components/BudgetCSVExportButton"
import {
  useDeleteBudgetMutation,
  useDuplicateBudgetMutation,
} from "@costory/front/queries/budgets"
import { getDefaultTableOptions } from "@costory/front/utils/table"

interface BudgetsListTableProps {
  budgets: BudgetsResponses.GetAllBudgets
}

type BudgetListItem = BudgetsListTableProps["budgets"][number]

export const BudgetsListTable = ({ budgets }: BudgetsListTableProps) => {
  const navigate = useNavigate()
  const { mutateAsync: deleteBudget } = useDeleteBudgetMutation()
  const { mutateAsync: duplicateBudget, isPending: isDuplicateBudgetPending } =
    useDuplicateBudgetMutation()
  const user = useUser()

  const columnHelper = createMRTColumnHelper<BudgetListItem>()
  const columns = [
    columnHelper.accessor(([budget]) => budget.name, {
      header: "Name",
    }),
    columnHelper.accessor(([budget]) => budget.tags, {
      header: "Tags",
      Cell: ({ cell }) => {
        return (
          <Group>
            {cell.getValue().map((tag) => (
              <Badge key={tag.name} color={tag.color}>
                {tag.name}
              </Badge>
            ))}
          </Group>
        )
      },
    }),
    columnHelper.accessor(
      ([lastVersion, ...history]) => {
        if (history.length !== 0 && lastVersion.status === BudgetStatus.DRAFT) {
          return "DRAFT_REVISION"
        }

        return lastVersion.status
      },
      {
        header: "Status",
        Cell: ({ cell }) => {
          const budgetStatusColorsMap = {
            [BudgetStatus.VALIDATED]: {
              color: "green",
              label: "Validated",
            },
            [BudgetStatus.DRAFT]: {
              color: "gray",
              label: "Draft",
            },
            DRAFT_REVISION: {
              color: "green",
              label: "Validated with draft revision",
            },
          } as const

          const { color, label } = budgetStatusColorsMap[cell.getValue()]

          return <Badge color={color}>{label}</Badge>
        },
      },
    ),
    columnHelper.accessor(
      ([{ year, startMonth }]) => dayjs().year(year).month(startMonth),
      {
        header: "Period",
        Cell: ({ cell }) => {
          const startDate = cell.getValue()
          const endDate = startDate.add(11, "month")
          return `${startDate.format("MMM YYYY")}-${endDate.format("MMM YYYY")}`
        },
      },
    ),
    // @ts-expect-error doesn't support computed fields
    columnHelper.accessor("createdBy.fullName", {
      header: "Owner",
    }),
  ]

  const handleClickDelete = async (budgetId: string) => {
    openConfirmModal({
      title: <Title order={2}>Are you sure?</Title>,
      confirmProps: { color: "red" },
      children: "Do you really want to delete this budget?",
      labels: {
        cancel: "Cancel",
        confirm: "Delete",
      },
      onConfirm: async () => {
        deleteBudget(budgetId)
      },
    })
  }

  const handleClickDuplicate = async (id: string) => {
    try {
      await duplicateBudget({ id, ownerId: user.id })
      showNotification({
        message: "Budget duplicated",
        color: "green",
      })
    } catch (error) {
      showNotification({
        message: error as string,
        color: "red",
      })
    }
  }

  const table = useMantineReactTable<BudgetListItem>({
    columns,
    data: budgets,
    ...getDefaultTableOptions(),
    enableRowActions: true,
    renderRowActions: ({
      row: {
        original: [budget],
      },
    }) => {
      const isAllowedToEdit = user.id === budget.createdById
      return (
        <Group wrap="nowrap">
          <Tooltip
            label={
              isAllowedToEdit ? "Edit" : "Only the owner is allowed to edit"
            }
          >
            <ActionIcon
              onClick={() => navigate(budget.parentBudgetId + "/edit")}
              size="sm"
              disabled={user.id !== budget.createdById}
              bg="transparent"
            >
              <IconEdit size="inherit" />
            </ActionIcon>
          </Tooltip>
          <Tooltip label="Delete">
            <ActionIcon
              disabled={user.id !== budget.createdById}
              onClick={() => handleClickDelete(budget.parentBudgetId)}
              size="sm"
            >
              <IconTrash size="inherit" />
            </ActionIcon>
          </Tooltip>
          <Tooltip label="Duplicate">
            <ActionIcon
              onClick={() => handleClickDuplicate(budget.parentBudgetId)}
              loading={isDuplicateBudgetPending}
              size="sm"
            >
              <IconCopy size="inherit" />
            </ActionIcon>
          </Tooltip>
          <Tooltip label="Go to budget">
            <ActionIcon
              onClick={() => navigate(budget.parentBudgetId)}
              size="sm"
            >
              <IconExternalLink size="inherit" />
            </ActionIcon>
          </Tooltip>
          <BudgetCSVExportDownloadButton
            budgetId={budget.versionId}
            variant="compact"
          />
        </Group>
      )
    },
    mantineTableBodyRowProps: ({
      row: {
        original: [budget],
      },
    }) => ({
      onDoubleClick: () => {
        navigate(budget.parentBudgetId)
      },
    }),
  })
  return <MantineReactTable table={table} />
}
