import { Stack } from "@mantine/core"

import { SavedViewsResponses } from "@costory/types/endpoints/savedViews"

import { FilterBar } from "@costory/front/components/FilterBar"
import LiveBlocks from "@costory/front/components/Room"
import { SavedViewsSelector } from "@costory/front/components/SavedViewsSelector"
import { QueryWrapper } from "@costory/front/components/layout/QueryWrapper"
import {
  ChartContext,
  useChartContext,
} from "@costory/front/contexts/ChartContext"
import { useAuthState } from "@costory/front/queries/auth"
import { useAxesQuery } from "@costory/front/queries/axes"
import { useBusinessMetricsQuery } from "@costory/front/queries/businessMetrics"
import { SavedViewRedirectPage } from "@costory/front/queries/savedViews"

type Children = React.ReactNode | (() => React.ReactNode)

type RootProps = {
  redirectPage: SavedViewRedirectPage
  savedView?: SavedViewsResponses.GetSavedViewDetails
  defaultHideOneTime?: boolean
  embed?: boolean
  children: Children
}

// The `key` is forced here because it's used to properly rerender form when saving a view
type QueryContainerProps = RootProps & { key: string }

type Props = {
  children: Children
}

const _ChartContainer = ({ children }: Props) => {
  const {
    filterForm,
    metricsOptions,
    groupByOptions,
    referenceFilters,
    urlFilters,
    queryError,
    savedView,
    embed,
    redirectPage,
  } = useChartContext()

  return (
    <Stack gap={16}>
      {!embed && (
        <>
          <SavedViewsSelector
            currentView={savedView}
            redirectPage={redirectPage}
            form={filterForm}
            referenceFilters={referenceFilters}
            urlFilters={urlFilters}
          />
          <FilterBar
            form={filterForm}
            metricsOptions={metricsOptions}
            groupByOptions={groupByOptions}
            queryError={queryError}
          />
        </>
      )}
      {typeof children === "function" ? children() : children}
    </Stack>
  )
}

export const ChartContainer = ({
  savedView,
  redirectPage,
  embed = false,
  children,
  defaultHideOneTime,
}: QueryContainerProps) => {
  const axesQuery = useAxesQuery()
  const metricsQuery = useBusinessMetricsQuery()
  const auth = useAuthState()
  const roomId = savedView
    ? auth.user!.currentOrg.slug + ":" + savedView?.id //+ ":" + redirectPage
    : undefined
  return (
    <QueryWrapper query={axesQuery}>
      {({ data: axes }) => (
        <QueryWrapper query={metricsQuery}>
          {({ data: metrics }) => (
            <ChartContext.Provider
              value={{
                axes,
                metrics,
                defaultHideOneTime,
                savedView,
                redirectPage,
                embed,
              }}
            >
              <_ChartContainer>{children}</_ChartContainer>
              <LiveBlocks roomId={roomId} />
            </ChartContext.Provider>
          )}
        </QueryWrapper>
      )}
    </QueryWrapper>
  )
}
