import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { AxiosResponse } from "axios"
import { z } from "zod"

import {
  ScheduledDigestConfigurationsRequests,
  ScheduledDigestConfigurationsResponses,
  scheduledDigestConfigurationSendingSchema,
} from "@costory/types/endpoints/scheduledDigestConfigurations"
import { ScheduledDigestStatus } from "@costory/types/prisma-client"

import { apiClient } from "@costory/front/lib/apiClient"
import { queryClient } from "@costory/front/lib/queryClient"

export const useScheduledDigestConfigurationsQuery = () => {
  return useQuery({
    queryKey: ["scheduled-digest-configurations"],
    queryFn: async () => {
      const response =
        await apiClient.get<ScheduledDigestConfigurationsResponses.List>(
          "/scheduled-digest-configurations",
        )
      return response.data
    },
  })
}

export const useScheduledDigestConfigurationFormDataQuery = () => {
  return useQuery({
    queryKey: ["scheduled-digest-configurations", "form-data"],
    queryFn: async () => {
      const response =
        await apiClient.get<ScheduledDigestConfigurationsResponses.FormData>(
          "/scheduled-digest-configurations/form-data",
        )
      return response.data
    },
  })
}

export const useCreateScheduledDigestConfigurationMutation = () => {
  return useMutation({
    mutationFn: async (
      configuration: ScheduledDigestConfigurationsRequests.Create,
    ) => {
      const response = await apiClient.post<
        ScheduledDigestConfigurationsResponses.Created,
        AxiosResponse<ScheduledDigestConfigurationsResponses.Created>,
        ScheduledDigestConfigurationsRequests.Create
      >("/scheduled-digest-configurations", configuration)
      return response.data
    },
    onSuccess: async (_, scheduledDigestConfiguration) => {
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: ["scheduled-digest-configurations"],
        }),
        queryClient.invalidateQueries({
          queryKey: ["saved-views", scheduledDigestConfiguration.savedViewId],
        }),
      ])
    },
  })
}

export const useDeleteScheduledDigestConfigurationMutation = () => {
  return useMutation({
    mutationFn: async (id: string) => {
      const response = await apiClient.delete<
        ScheduledDigestConfigurationsResponses.Deleted,
        AxiosResponse<ScheduledDigestConfigurationsResponses.Deleted>,
        string
      >(`/scheduled-digest-configurations/${id}`)
      return response.data
    },
    onSuccess: async (data) => {
      await queryClient.invalidateQueries({
        queryKey: ["scheduled-digest-configurations"],
      })
      data.savedViewId &&
        queryClient.invalidateQueries({
          queryKey: ["saved-views", data.savedViewId],
        })
    },
  })
}

export const useUpdateScheduledDigestConfigurationMutation = () => {
  return useMutation({
    mutationFn: async ({
      id,
      data,
    }: {
      id: string
      data: ScheduledDigestConfigurationsRequests.Update
    }): Promise<ScheduledDigestConfigurationsResponses.Updated> => {
      const response = await apiClient.put<
        ScheduledDigestConfigurationsResponses.Updated,
        AxiosResponse<ScheduledDigestConfigurationsResponses.Updated>,
        ScheduledDigestConfigurationsRequests.Update
      >(`/scheduled-digest-configurations/${id}`, data)
      return response.data
    },
    onSuccess: async ({ savedViewId }) => {
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: ["scheduled-digest-configurations"],
        }),
        savedViewId &&
          queryClient.invalidateQueries({
            queryKey: ["saved-views", savedViewId],
          }),
      ])
    },
  })
}

export const useOptimisticToggleScheduledDigestConfigurationStatusMutation =
  () => {
    const queryClient = useQueryClient()

    return useMutation({
      mutationFn: async (id: string) => {
        await apiClient.put(
          `/scheduled-digest-configurations/${id}/toggle-status`,
        )
      },
      onMutate: async (id) => {
        await queryClient.cancelQueries({
          queryKey: ["scheduled-digest-configurations"],
        })

        const previousConfigs =
          queryClient.getQueryData<ScheduledDigestConfigurationsResponses.List>(
            ["scheduled-digest-configurations"],
          )

        queryClient.setQueryData<ScheduledDigestConfigurationsResponses.List>(
          ["scheduled-digest-configurations"],
          (old) =>
            old?.map((config) =>
              config.id === id
                ? {
                    ...config,
                    status:
                      config.status === ScheduledDigestStatus.ENABLED
                        ? ScheduledDigestStatus.DISABLED
                        : ScheduledDigestStatus.ENABLED,
                  }
                : config,
            ),
        )

        return { previousConfigs }
      },
      onError: (_, __, context) => {
        if (context?.previousConfigs) {
          queryClient.setQueryData(
            ["scheduled-digest-configurations"],
            context.previousConfigs,
          )
        }
      },
    })
  }

export const useTestScheduledDigestMutation = () => {
  return useMutation({
    mutationFn: async (
      data: z.infer<typeof scheduledDigestConfigurationSendingSchema>,
    ) => {
      await apiClient.post("/scheduled-digest-configurations/test", data)
    },
  })
}
