import {
  Stack,
  InputWrapper,
  TextInput,
  Select,
  Group,
  Button,
  Text,
  PasswordInput,
} from "@mantine/core"
import { UseFormReturnType } from "@mantine/form"
import { IconArrowLeft, IconArrowRight } from "@tabler/icons-react"

import { DatadogDatasourceFormData } from "@costory/types/endpoints/metricsDatasources"

const Regions = [
  { value: "datadoghq.com", label: "datadoghq.com" },
  { value: "us3.datadoghq.com", label: "us3.datadoghq.com" },
  { value: "us5.datadoghq.com", label: "us5.datadoghq.com" },
  { value: "datadoghq.eu", label: "datadoghq.eu" },
  { value: "ddog-gov.com", label: "ddog-gov.com" },
]
export const DatadogConfiguration = ({
  form,
  handleClickBack,
  handleClickNext,
}: {
  form: UseFormReturnType<DatadogDatasourceFormData>
  handleClickBack: () => void
  handleClickNext: () => void
}) => {
  return (
    <Stack h="100%">
      <Stack flex={1}>
        <InputWrapper
          label={
            <Text fw="bold" span={true}>
              Application key
            </Text>
          }
          required
        >
          <PasswordInput
            {...form.getInputProps("applicationKey")}
            placeholder="Application key"
          />
        </InputWrapper>
        <InputWrapper
          label={
            <Text fw="bold" span={true}>
              API key
            </Text>
          }
          required
        >
          <PasswordInput
            {...form.getInputProps("apiKey")}
            placeholder="API key"
          />
        </InputWrapper>
        <InputWrapper
          label={
            <Text fw="bold" span={true}>
              Region
            </Text>
          }
          required
        >
          <Select
            {...form.getInputProps("region")}
            data={Regions}
            placeholder="Select a region"
          />
        </InputWrapper>
        <InputWrapper
          label={
            <Text fw="bold" span={true}>
              Query
            </Text>
          }
          required
        >
          <Group>
            <Select data={["Metrics"]} value="Metrics" w="9%" />
            <TextInput
              w="89%"
              {...form.getInputProps("query")}
              placeholder="Query"
            />
          </Group>
        </InputWrapper>
      </Stack>
      <Group justify="space-between">
        <Button
          onClick={handleClickBack}
          variant="outline"
          leftSection={<IconArrowLeft />}
        >
          Back
        </Button>
        <Button onClick={handleClickNext} rightSection={<IconArrowRight />}>
          Next
        </Button>
      </Group>
    </Stack>
  )
}
