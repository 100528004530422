import { LineChart } from "@mantine/charts"
import { Stack, Group, Button, Loader, Title, Space } from "@mantine/core"
import { UseFormReturnType } from "@mantine/form"
import { useShallowEffect } from "@mantine/hooks"
import { IconArrowLeft, IconArrowRight, IconCloudX } from "@tabler/icons-react"
import _ from "lodash"

import { DatadogDatasourceFormData } from "@costory/types/endpoints/metricsDatasources"

import dayjs from "@costory/shared/dayjs"

import { QueryWrapper } from "@costory/front/components/layout/QueryWrapper"
import { useValidateDatadog } from "@costory/front/queries/metricsDatasources"
import { getCharteSerieColor } from "@costory/front/utils/charts"

export const DatadogValidation = ({
  form,
  handleClickBack,
  handleClickNext,
  setMetricsNames,
}: {
  form: UseFormReturnType<DatadogDatasourceFormData>
  handleClickBack: () => void
  handleClickNext: () => void
  setMetricsNames: (metrics: string[]) => void
}) => {
  const query = useValidateDatadog(form.getValues())
  const { data } = query

  useShallowEffect(() => {
    if (data?.seriesData) {
      const updatedMetricsDefinition = data.seriesData.map(
        (series) => series.metric,
      )
      setMetricsNames(_.uniq(updatedMetricsDefinition))
      form.setFieldValue("seriesData", data.seriesData)
    }
  }, [data?.seriesData])

  return (
    <Stack h="100%">
      <Stack flex={1}>
        <QueryWrapper
          query={query}
          LoadingComponent={
            <Stack align="center">
              <Loader size="xl" />{" "}
              <Title order={2}>Validating Datadog...</Title>{" "}
            </Stack>
          }
          allowEmptyArray
        >
          {({ data }) => {
            if (data.isSuccess) {
              const transformedChartData = _.chain(data.seriesData)
                .flatMap(({ metricValue, data }) =>
                  data.map(({ date, value }) => ({
                    date,
                    [metricValue]: value,
                  })),
                )
                .groupBy("date")
                .map((group, date) => ({
                  date,
                  ...Object.fromEntries(group.flatMap(Object.entries)),
                }))
                .sortBy((item) => dayjs(item.date).valueOf())
                .value()
              const seriesNames = data.seriesData.map(
                ({ metricValue }, index) => ({
                  name: metricValue,
                  color: getCharteSerieColor(index),
                }),
              )
              return (
                <Stack h="80%" mt={20}>
                  <LineChart
                    h="100%"
                    data={transformedChartData}
                    dataKey="date"
                    series={seriesNames}
                    valueFormatter={(value) => value.toFixed(2)}
                    curveType="linear"
                    xAxisProps={{
                      angle: 30,
                      textAnchor: "start",
                    }}
                  />
                </Stack>
              )
            }
            return (
              <Group justify="center">
                <IconCloudX color="var(--mantine-color-gray-6)" size={120} />
                <Title order={2}>
                  {data.errorCode
                    ? data.errorCode
                    : "Your datadog is not valid"}
                </Title>
                <Space h={10} />
              </Group>
            )
          }}
        </QueryWrapper>
      </Stack>
      <Group justify="space-between">
        <Button
          onClick={handleClickBack}
          variant="outline"
          leftSection={<IconArrowLeft />}
        >
          Back
        </Button>
        <Button
          onClick={handleClickNext}
          disabled={!data?.isSuccess}
          rightSection={<IconArrowRight />}
        >
          Next
        </Button>
      </Group>
    </Stack>
  )
}
