import {
  Badge,
  Button,
  Card,
  Center,
  Group,
  InputWrapper,
  Modal,
  Paper,
  Popover,
  SegmentedControl,
  Select,
  Stack,
  Text,
  Tooltip,
} from "@mantine/core"
import { useForm, zodResolver } from "@mantine/form"
import { showNotification } from "@mantine/notifications"
import {
  IconArrowDownRight,
  IconArrowUpRight,
  IconChartBar,
  IconCheck,
  IconInfoCircle,
  IconSend,
} from "@tabler/icons-react"
import { useEffect, useMemo } from "react"
import { z } from "zod"

import {
  scheduledDigestConfigurationCreationSchema,
  ScheduledDigestConfigurationsResponses,
} from "@costory/types/endpoints/scheduledDigestConfigurations"
import {
  DatePreset,
  DestinationType,
  ScheduledDigestStatus,
  ScheduledPeriod,
} from "@costory/types/prisma-client"

import { QueryWrapper } from "@costory/front/components/layout/QueryWrapper"
import {
  useCreateScheduledDigestConfigurationMutation,
  useScheduledDigestConfigurationFormDataQuery,
  useTestScheduledDigestMutation,
  useUpdateScheduledDigestConfigurationMutation,
} from "@costory/front/queries/scheduledDigestConfigurations"

import { availableIntegrations } from "./integrations"

type ScheduledDigestConfigurationFormData = z.infer<
  typeof scheduledDigestConfigurationFormSchema
>

const scheduledDigestConfigurationFormSchema =
  scheduledDigestConfigurationCreationSchema.omit({
    status: true,
  })

const defaultValues: ScheduledDigestConfigurationFormData = {
  savedViewId: "",
  destination: "",
  destinationType: DestinationType.SLACK,
  scheduledPeriod: ScheduledPeriod.WEEKLY,
  overrideDatePreset: null as DatePreset | null,
}

interface _ConfigurationModalProps {
  formData: ScheduledDigestConfigurationsResponses.FormData
  opened: boolean
  onClose: () => void
  scheduledDigestConfiguration?: ScheduledDigestConfigurationsResponses.List[0]
  initialValues: Partial<ScheduledDigestConfigurationFormData>
}

const _ConfigurationModal = ({
  formData,
  opened,
  onClose,
  scheduledDigestConfiguration,
  initialValues,
}: _ConfigurationModalProps) => {
  const { mutateAsync: createConfiguration, isPending: isCreating } =
    useCreateScheduledDigestConfigurationMutation()
  const { mutateAsync: updateConfiguration, isPending: isUpdating } =
    useUpdateScheduledDigestConfigurationMutation()
  const { mutateAsync: testDigest, isPending: isTesting } =
    useTestScheduledDigestMutation()
  const { availableSavedViews } = formData

  const form = useForm<ScheduledDigestConfigurationFormData>({
    initialValues: { ...defaultValues, ...initialValues },
    validate: zodResolver(scheduledDigestConfigurationFormSchema),
  })

  useEffect(() => {
    if (scheduledDigestConfiguration) {
      form.setValues({
        savedViewId: scheduledDigestConfiguration.savedViewId,
        destination: scheduledDigestConfiguration.destination,
        destinationType: scheduledDigestConfiguration.destinationType,
        scheduledPeriod: scheduledDigestConfiguration.scheduledPeriod,
        overrideDatePreset: scheduledDigestConfiguration.overrideDatePreset,
      })
    } else {
      form.reset()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scheduledDigestConfiguration])

  const selectedView = availableSavedViews.find(
    (view) => view.id === form.values.savedViewId,
  )

  const handleSubmit = async (values: ScheduledDigestConfigurationFormData) => {
    try {
      const payload = { ...values, status: ScheduledDigestStatus.ENABLED }
      if (scheduledDigestConfiguration) {
        await updateConfiguration({
          id: scheduledDigestConfiguration.id,
          data: payload,
        })
        showNotification({
          title: "Success",
          message: "Configuration updated successfully",
          color: "green",
        })
      } else {
        await createConfiguration(payload)
        showNotification({
          title: "Success",
          message: "Configuration created successfully",
          color: "green",
        })
      }
      onClose()
      form.reset()
    } catch {
      showNotification({
        title: "Error",
        message: scheduledDigestConfiguration
          ? "Failed to update configuration"
          : "Failed to create configuration",
        color: "red",
      })
    }
  }

  const handleTest = async () => {
    const { hasErrors } = form.validate()
    if (hasErrors) {
      return
    }

    try {
      await testDigest(form.getValues())
      showNotification({
        title: "Success",
        message: "Test digest sent successfully",
        color: "green",
      })
    } catch {
      showNotification({
        title: "Error",
        message: "Failed to send test digest",
        color: "red",
      })
    }
  }

  const datePresetSelectItems = useMemo(() => {
    return Object.values(DatePreset).map((preset) => ({
      value: preset,
      label:
        preset
          .split("_")
          .map((word) => word.toLowerCase())
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" ") +
        (preset === selectedView?.datePreset
          ? " (Current view's date preset)"
          : ""),
    }))
  }, [selectedView])

  const availableSavedViewsSelectItems = useMemo(() => {
    return (
      formData?.availableSavedViews.map((view) => ({
        value: view.id,
        label: view.name,
      })) ?? []
    )
  }, [formData])

  const destinationTypeSelectItems = useMemo(() => {
    return Object.entries(availableIntegrations).map(([type, info]) => ({
      value: type,
      label: (
        <Tooltip
          label={info.isEnabled ? undefined : "Coming soon"}
          disabled={info.isEnabled}
        >
          <Group justify="center">
            {info.icon}
            <Text>{info.label}</Text>
          </Group>
        </Tooltip>
      ),
      disabled: !info.isEnabled,
    }))
  }, [])

  const isPending = isCreating || isUpdating || isTesting

  return (
    <Modal
      opened={opened}
      onClose={onClose}
      title={
        scheduledDigestConfiguration
          ? "Edit scheduled report"
          : "Add a scheduled report"
      }
      size="lg"
    >
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <Stack gap="md">
          <InputWrapper label="Report type">
            <SegmentedControl
              value="savedview"
              color="primary"
              fullWidth
              data={[
                {
                  label: (
                    <Popover width={300} withArrow position="right">
                      <Popover.Target>
                        <Group gap="xs" justify="center">
                          <Text>Saved View</Text>
                          <IconInfoCircle
                            size={16}
                            style={{ cursor: "pointer" }}
                          />
                        </Group>
                      </Popover.Target>
                      <Popover.Dropdown>
                        <Stack>
                          <Card withBorder>
                            <Text size="sm" fw={500} mb="xs" ta="center">
                              Graph Section uses saved view date preset
                            </Text>
                            <Center>
                              <IconChartBar size={100} />
                            </Center>
                          </Card>
                          <Card withBorder>
                            <Stack>
                              <Text size="sm" fw={500} mb="xs">
                                Top increases / decreases Section uses
                                comparison date preset
                              </Text>
                              <Center>
                                <Stack gap="xs" w="100%">
                                  <Group>
                                    <IconArrowUpRight color="red" size={20} />
                                    <Text size="sm" fw={500}>
                                      Top Increases
                                    </Text>
                                  </Group>
                                  <Paper withBorder p="xs">
                                    <Stack gap="xs">
                                      <Group>
                                        <Text size="sm">Backup</Text>
                                        <Badge color="red">+51.65%</Badge>
                                        <Text size="xs" c="dimmed">
                                          i.e. +$832.84 compared to last period
                                        </Text>
                                      </Group>
                                      <Group>
                                        <Text size="sm">Data Analytics</Text>
                                        <Badge color="red">+25.75%</Badge>
                                        <Text size="xs" c="dimmed">
                                          i.e. +$509.85 compared to last period
                                        </Text>
                                      </Group>
                                    </Stack>
                                  </Paper>

                                  <Group>
                                    <IconArrowDownRight
                                      color="green"
                                      size={20}
                                    />
                                    <Text size="sm" fw={500}>
                                      Top Decreases
                                    </Text>
                                  </Group>
                                  <Paper withBorder p="xs">
                                    <Stack gap="xs">
                                      <Group>
                                        <Text size="sm">ML experiments</Text>
                                        <Badge color="green">-33.9%</Badge>
                                        <Text size="xs" c="dimmed">
                                          i.e. -$786.11 compared to last period
                                        </Text>
                                      </Group>
                                      <Group>
                                        <Text size="sm">Web hosting / DB</Text>
                                        <Badge color="green">-49.6%</Badge>
                                        <Text size="xs" c="dimmed">
                                          i.e. -$421 compared to last period
                                        </Text>
                                      </Group>
                                    </Stack>
                                  </Paper>
                                </Stack>
                              </Center>
                            </Stack>
                          </Card>
                        </Stack>
                      </Popover.Dropdown>
                    </Popover>
                  ),
                  value: "savedview",
                },
              ]}
            />
          </InputWrapper>
          <InputWrapper label="View" required>
            <Select
              placeholder="Select a view"
              data={availableSavedViewsSelectItems}
              {...form.getInputProps("savedViewId")}
            />
          </InputWrapper>

          <Select
            label="Comparison date preset"
            placeholder="Use view's date preset"
            clearable
            data={datePresetSelectItems}
            {...form.getInputProps("overrideDatePreset")}
          />
          <InputWrapper label="Integration">
            <SegmentedControl
              color="primary"
              fullWidth
              data={destinationTypeSelectItems}
              {...form.getInputProps("destinationType")}
            />
          </InputWrapper>

          <InputWrapper label="Slack Channel" required>
            <Select
              placeholder="Select a channel"
              data={formData.availableSlackConversations}
              searchable
              {...form.getInputProps("destination")}
            />
          </InputWrapper>

          <InputWrapper label="Frequency">
            <Stack gap={2}>
              <SegmentedControl
                color="primary"
                fullWidth
                data={[
                  { label: "Weekly", value: ScheduledPeriod.WEEKLY },
                  { label: "Monthly", value: ScheduledPeriod.MONTHLY },
                ]}
                {...form.getInputProps("scheduledPeriod")}
              />
              <Text size="xs" color="dimmed">
                The digest will be sent{" "}
                {form.values.scheduledPeriod === ScheduledPeriod.WEEKLY
                  ? "every Tuesday"
                  : "every second day of the month"}
              </Text>
            </Stack>
          </InputWrapper>

          <Group justify="space-between">
            <Button
              variant="outline"
              onClick={handleTest}
              loading={isTesting}
              disabled={isPending}
              leftSection={<IconSend />}
            >
              Send a preview report
            </Button>
            <Button
              type="submit"
              loading={isCreating || isUpdating}
              disabled={isPending}
              leftSection={<IconCheck />}
            >
              {scheduledDigestConfiguration ? "Save changes" : "Create"}
            </Button>
          </Group>
        </Stack>
      </form>
    </Modal>
  )
}

interface ConfigurationModalProps {
  opened: boolean
  onClose: () => void
  scheduledDigestConfiguration?: ScheduledDigestConfigurationsResponses.List[0]
  initialValues: Partial<ScheduledDigestConfigurationFormData>
}

export const ConfigurationModal = ({
  opened,
  onClose,
  scheduledDigestConfiguration,
  initialValues,
}: ConfigurationModalProps) => {
  const query = useScheduledDigestConfigurationFormDataQuery()
  return (
    <QueryWrapper query={query}>
      {({ data }) => (
        <_ConfigurationModal
          formData={data}
          opened={opened}
          onClose={onClose}
          scheduledDigestConfiguration={scheduledDigestConfiguration}
          initialValues={initialValues}
        />
      )}
    </QueryWrapper>
  )
}
