import { Stack, Title, Space, Button, Group, Text } from "@mantine/core"
import { Dropzone } from "@mantine/dropzone"
import { UseFormReturnType } from "@mantine/form"
import {
  IconCheck,
  IconFileX,
  IconUpload,
  IconX,
  IconTablePlus,
  IconArrowLeft,
  IconArrowRight,
} from "@tabler/icons-react"
import Papa from "papaparse"
import { useState, useEffect } from "react"

import {
  MetricsDatasourceFormData,
  CsvDatasourceFormData,
} from "@costory/types/endpoints/metricsDatasources"

interface CSVDropStepProps {
  form: UseFormReturnType<MetricsDatasourceFormData>
  handleClickNext: () => void
  handleClickBack: () => void
}

export const CSVDropStep = ({
  form,
  handleClickBack,
  handleClickNext,
}: CSVDropStepProps) => {
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<string | undefined>()

  const reset = () => {
    setIsLoading(false)
    setError(undefined)
    form.setFieldValue("csvData", "")
  }

  // Reset values when getting (or geting back) to this step
  useEffect(() => {
    reset()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { csvData } = form.getValues() as CsvDatasourceFormData

  return (
    <Stack justify="space-between" h="100%">
      <Stack flex={1} justify="center" align="center" pb={40}>
        {csvData ? (
          <Stack align="center" justify="center">
            <IconCheck color="var(--mantine-color-green-6)" size={120} />
            <Title order={2}>CSV imported</Title>
            <Space h={10} />
          </Stack>
        ) : error ? (
          <Stack align="center" justify="center">
            <IconFileX color="var(--mantine-color-gray-6)" size={120} />
            <Title order={2}>There was an error parsing your csv file</Title>
            <Button onClick={reset}>Try again</Button>
            <Space h={10} />
          </Stack>
        ) : (
          <Dropzone
            loading={isLoading}
            onDrop={(files) => {
              setIsLoading(true)

              const file = files[0]

              Papa.parse(file as File, {
                complete: ({ data }) => {
                  form.setFieldValue("csvData", Papa.unparse(data))
                  setIsLoading(false)
                },
                error: (err) => {
                  setIsLoading(false)
                  setError(err.message)
                },
              })
            }}
            maxSize={5 * 1024 ** 2}
            accept={["text/csv"]}
          >
            <Group
              justify="center"
              gap="xl"
              mih={220}
              style={{ pointerEvents: "none" }}
            >
              <Dropzone.Accept>
                <IconUpload
                  size={52}
                  color="var(--mantine-color-blue-6)"
                  stroke={1.5}
                />
              </Dropzone.Accept>
              <Dropzone.Reject>
                <IconX
                  size={52}
                  color="var(--mantine-color-red-6)"
                  stroke={1.5}
                />
              </Dropzone.Reject>
              <Dropzone.Idle>
                <IconTablePlus
                  size={52}
                  color="var(--mantine-color-dimmed)"
                  stroke={1.5}
                />
              </Dropzone.Idle>

              <div>
                <Text fw="bold" size="xl" inline>
                  Drag your csv file here or click to browse your files
                </Text>
              </div>
            </Group>
          </Dropzone>
        )}
      </Stack>
      <Group justify="space-between">
        <Button
          onClick={handleClickBack}
          variant="outline"
          leftSection={<IconArrowLeft />}
        >
          Back
        </Button>
        <Button
          onClick={handleClickNext}
          disabled={!csvData}
          rightSection={<IconArrowRight />}
        >
          Next
        </Button>
      </Group>
    </Stack>
  )
}
