import { OrganizationSwitcher, UserButton } from "@clerk/clerk-react"
import {
  AppShell,
  Group,
  ThemeIcon,
  Title,
  Tooltip,
  useMantineColorScheme,
} from "@mantine/core"
import { IconHelpCircle, IconMoon, IconSun } from "@tabler/icons-react"
import { useEffect, useMemo } from "react"
import { Helmet } from "react-helmet"
import { useLocation } from "react-router-dom"

import {
  SIDEBAR_ITEMS,
  SidebarItem,
} from "@costory/front/components/layout/constants"
import { usePageContext } from "@costory/front/contexts/PageContext"

interface FlattenedRoute {
  path: string
  name: string
  tooltip?: string
}
export const AppHeader = () => {
  const location = useLocation()

  const { pageTitle, tooltipText } = useMemo(() => {
    const flattenRoutes = (
      routes: SidebarItem[],
      basePath = "",
    ): FlattenedRoute[] =>
      routes.flatMap((route) => {
        const fullPath = route.path ? `${basePath}/${route.path}` : basePath
        const flattened = route.path
          ? [{ path: fullPath, name: route.name, tooltip: route.tooltip }]
          : []
        const subRoutes = route.links
          ? flattenRoutes(route.links, fullPath)
          : []
        return [...flattened, ...subRoutes]
      })
    const currentRoute = flattenRoutes(SIDEBAR_ITEMS).find((route) =>
      route.path.includes(location.pathname),
    )

    return {
      pageTitle: currentRoute?.name || "",
      tooltipText: currentRoute?.tooltip || "",
    }
  }, [location.pathname])

  const { colorScheme, toggleColorScheme } = useMantineColorScheme()

  const { secondaryTitle, setSecondaryTitle } = usePageContext()

  // Reset secondary title when navigating to a new page
  useEffect(() => {
    setSecondaryTitle(undefined)
  }, [location.pathname, setSecondaryTitle])

  return (
    <>
      <Helmet>
        <title>Costory - {pageTitle}</title>
      </Helmet>
      <AppShell.Header h={64} bg={colorScheme === "dark" ? "gray.8" : "white"}>
        <Group px={32} py={16} justify="space-between">
          <Group gap={0} align="center">
            <Title size="h3" mr="xs" fw={600}>
              {pageTitle}
              {tooltipText && (
                <Tooltip label={tooltipText}>
                  <ThemeIcon c="primary.6" mt={2} size="sm">
                    <IconHelpCircle />
                  </ThemeIcon>
                </Tooltip>
              )}
              {secondaryTitle && " - " + secondaryTitle}
            </Title>
          </Group>
          <Group>
            <ThemeIcon
              c={colorScheme === "dark" ? "yellow.8" : "gray.7"}
              onClick={toggleColorScheme}
            >
              {colorScheme === "dark" ? <IconSun /> : <IconMoon />}
            </ThemeIcon>
            <OrganizationSwitcher
              hidePersonal
              afterSelectOrganizationUrl="/home"
            />
            <UserButton />
          </Group>
        </Group>
      </AppShell.Header>
    </>
  )
}
