import {
  ActionIcon,
  Button,
  Group,
  Indicator,
  InputWrapper,
  Loader,
  Menu,
  Modal,
  Stack,
  Text,
  TextInput,
  Title,
  Tooltip,
} from "@mantine/core"
import { useField, UseFormReturnType } from "@mantine/form"
import { useDisclosure } from "@mantine/hooks"
import { openConfirmModal } from "@mantine/modals"
import {
  IconAbacus,
  IconArrowRight,
  IconClock,
  IconCopy,
  IconDatabaseDollar,
  IconDatabaseShare,
  IconDeviceFloppy,
  IconFolderOpen,
  IconListSearch,
  IconLock,
  IconLockOpen2,
  IconRestore,
  IconReport,
  IconPencil,
} from "@tabler/icons-react"
import { useFeatureFlag, User } from "configcat-react"
import _ from "lodash"
import { useEffect, useState } from "react"
import { Link, useBlocker, useNavigate } from "react-router-dom"

import { TreeNodeType } from "@costory/types/endpoints/folders"
import { SavedViewsResponses } from "@costory/types/endpoints/savedViews"
import { Filters } from "@costory/types/filters"
import { ComponentType } from "@costory/types/prisma-client"

import { FileExplorer } from "@costory/front/components/FileExplorer/FileExplorer"
import { CurrencyPicker } from "@costory/front/components/FilterBar"
import { ScheduledDigestConfigurationsTable } from "@costory/front/components/ScheduledDigestConfigurationsTable"
import { ModalCurrency } from "@costory/front/components/charts/ModalCurrency"
import { useChartContext } from "@costory/front/contexts/ChartContext"
import { CreateInitiativeForm } from "@costory/front/pages/Initiatives/CreateInitiativeForm"
import { ConfigurationModal } from "@costory/front/pages/ScheduledDigestConfigurations/ConfigurationModal"
import { useAuthState } from "@costory/front/queries/auth"
import {
  SavedViewRedirectPage,
  useCreateSavedViewMutation,
  useUpdateSavedViewMutation,
} from "@costory/front/queries/savedViews"

type Props = {
  currentView?: SavedViewsResponses.GetSavedViewDetails
  redirectPage: SavedViewRedirectPage
  form: UseFormReturnType<Filters>
  referenceFilters?: Filters
  urlFilters?: Filters
}

export const SavedViewsSelector = ({
  currentView,
  redirectPage,
  form,
  urlFilters,
}: Props) => {
  const isExistingView = Boolean(currentView)
  const isLinkedToInitiative = Boolean(currentView?.initiatives.length)
  const isMonitoringOn = Boolean(currentView?.CostMonitor.length)
  const auth = useAuthState()
  const { value: isInitiativeEnabled } = useFeatureFlag(
    "initiatives",
    false,
    new User(auth.user?.currentOrg.slug ?? ""),
  )

  const navigate = useNavigate()
  const viewNameField = useField({ initialValue: currentView?.name ?? "" })
  const newViewNameField = useField({
    initialValue: currentView ? currentView.name + " (copy)" : "",
  })
  const [viewType, setViewType] = useState<ComponentType>(
    currentView?.type ?? ComponentType.PUBLIC,
  )
  const [parentFolderId, setParentFolderId] = useState<string | null>(
    currentView?.parentFolderId || null,
  )
  const [isTouched, { open: setIsTouched, close: resetIsTouched }] =
    useDisclosure()
  const [isOpen, { close: closeSaveViewModal, open: openSaveViewModal }] =
    useDisclosure(false, {
      onOpen: () => setParentFolderId(currentView?.parentFolderId || null),
    })

  const { mutateAsync: createSavedView, isPending: isCreatingSavedView } =
    useCreateSavedViewMutation(redirectPage)
  const { mutateAsync: updateSavedView, isPending: isUpdatingSavedView } =
    useUpdateSavedViewMutation(currentView?.id)
  const { filterForm } = useChartContext()
  const [openedCurrencyModal, currencyModalHandlers] = useDisclosure()

  const COSTORY_ORGID = import.meta.env.VITE_COSTORY_ORGID
  const COSTORY_EMAIL_DOMAIN = import.meta.env.VITE_COSTORY_EMAIL_DOMAIN
  const isAllowedToEdit =
    (currentView &&
      currentView.createdById == auth.user!.id &&
      currentView?.type !== ComponentType.COMMUNITY) ||
    (auth.user!.isAdmin &&
      ((currentView?.type == ComponentType.COMMUNITY &&
        auth.user?.currentOrg.id === COSTORY_ORGID &&
        auth.user?.email.endsWith(COSTORY_EMAIL_DOMAIN)) ||
        currentView?.type == ComponentType.PUBLIC))

  const blocker = useBlocker(({ currentLocation, nextLocation }) => {
    if (currentLocation.pathname === nextLocation.pathname) {
      return false
    }
    return isTouched && isExistingView
  })

  useEffect(() => {
    if (blocker.state === "blocked") {
      openConfirmModal({
        title: "You have unsaved changes",
        children: <Text>Are you sure you want to leave ?</Text>,
        onClose: blocker.reset,
        onConfirm: blocker.proceed,
        labels: {
          cancel: "Stay",
          confirm: "Leave",
        },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blocker.state])

  const isFormTouched = !!urlFilters

  useEffect(() => {
    if (
      isFormTouched ||
      (isExistingView &&
        (viewNameField.getValue() !== currentView!.name ||
          viewType !== currentView!.type))
    ) {
      setIsTouched()
    } else {
      resetIsTouched()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewNameField, viewType])

  const handleClickSave = () => {
    if (!isExistingView) {
      openSaveViewModal()
      return
    }
  }

  const handleClickReset = () => {
    const path = currentView ? `/explorer/views/${currentView.id}` : "/explorer"
    navigate(path)
  }

  const handleOpenFolder = async (folderId: string) => {
    try {
      await createSavedView({
        name: newViewNameField.getValue(),
        type: viewType,
        parentFolderId: folderId,
        ...form.getValues(),
      })
      closeSaveViewModal()
    } catch (e) {
      console.error(e)
    }
  }

  const handleUpdateView = async () => {
    try {
      await updateSavedView({
        name: viewNameField.getValue(),
        type: viewType,
        parentFolderId,
        ...form.getValues(),
      })
      closeSaveViewModal()
      closeEditTitleModal()
      navigate(`/explorer/views/${currentView?.id}`)
    } catch (e) {
      console.error(e)
    }
  }

  const [
    openedSelectViewModal,
    { close: closeSelectViewModal, open: openSelectViewModal },
  ] = useDisclosure()

  const [
    openedCreateInitiativeModal,
    { close: closeCreateInitiativeModal, open: openCreateInitiativeModal },
  ] = useDisclosure()

  const [
    openedScheduledDigestModal,
    { close: closeScheduledDigestModal, open: openScheduledDigestModal },
  ] = useDisclosure()

  const [
    openedScheduledDigestListModal,
    {
      close: closeScheduledDigestListModal,
      open: openScheduledDigestListModal,
    },
  ] = useDisclosure()

  const [
    openedEditTitleModal,
    { open: openEditTitleModal, close: closeEditTitleModal },
  ] = useDisclosure(false)

  const toggleViewType = (isAdmin?: boolean) => {
    setViewType((prev) =>
      prev === ComponentType.PUBLIC
        ? ComponentType.PRIVATE
        : prev === ComponentType.PRIVATE
          ? isAdmin && auth.user?.currentOrg.id === COSTORY_ORGID
            ? ComponentType.COMMUNITY
            : ComponentType.PUBLIC
          : ComponentType.PUBLIC,
    )
  }

  const handleOpenSavedView = (id: string) => {
    if (id === currentView?.id) {
      closeSelectViewModal()
    }
    navigate(`/${redirectPage}/views/` + id)
  }

  const handleClickCreateInitiative = () => {
    openCreateInitiativeModal()
  }

  const canHaveScheduledDigests =
    currentView?.type !== ComponentType.PRIVATE &&
    currentView?.datePreset !== null

  return (
    <>
      <ModalCurrency
        opened={openedCurrencyModal}
        close={currencyModalHandlers.close}
      />
      <Modal
        size="80vw"
        opened={openedSelectViewModal}
        onClose={closeSelectViewModal}
        title={<Title>Select an existing view</Title>}
        styles={{ body: { height: "70vh" } }}
      >
        <FileExplorer
          onDoubleClick={(viewId, type) => {
            if (type !== TreeNodeType.SavedView) {
              return
            }
            handleOpenSavedView(viewId)
          }}
          defaultShouldShowDashboards={false}
          onOpenSavedView={handleOpenSavedView}
        />
      </Modal>
      {currentView && (
        <>
          <Modal
            size="90vw"
            opened={openedCreateInitiativeModal}
            onClose={closeCreateInitiativeModal}
            title={<Title>Create an Initiative</Title>}
            styles={{ content: { overflowY: "scroll" } }}
          >
            <CreateInitiativeForm savedViewId={currentView!.id} />
          </Modal>
          <ConfigurationModal
            opened={openedScheduledDigestModal}
            onClose={closeScheduledDigestModal}
            initialValues={{
              savedViewId: currentView.id,
            }}
          />
        </>
      )}
      <Modal
        size="90vw"
        opened={openedScheduledDigestListModal}
        onClose={closeScheduledDigestListModal}
        title={<Title>Related scheduled reports</Title>}
      >
        <Stack>
          <ScheduledDigestConfigurationsTable />
          <Group justify="end">
            <Button
              rightSection={<IconArrowRight />}
              variant="transparent"
              onClick={() => {
                navigate("/scheduled-reports")
              }}
            >
              Go to scheduled reports page
            </Button>
          </Group>
        </Stack>
      </Modal>
      <Modal
        opened={openedEditTitleModal}
        onClose={() => {
          closeEditTitleModal()
          viewNameField.reset()
        }}
        title={<Title>Edit View Title</Title>}
      >
        <TextInput
          {...viewNameField.getInputProps()}
          placeholder="Unnamed view"
        />
        <Group justify="end" mt="md">
          <Button variant="filled" onClick={handleUpdateView}>
            Save
          </Button>
        </Group>
      </Modal>
      <Stack>
        <Group>
          <Group justify="space-between" flex={1}>
            <Group justify="start" align="bottom">
              <Group flex={1} align="bottom">
                {isExistingView && isAllowedToEdit && (
                  <Tooltip label="Edit title">
                    <ActionIcon onClick={openEditTitleModal}>
                      <IconPencil size="inherit" />
                    </ActionIcon>
                  </Tooltip>
                )}
                {!isExistingView && (
                  <Tooltip label="Save as new">
                    <ActionIcon onClick={handleClickSave}>
                      <IconDeviceFloppy size="inherit" />
                    </ActionIcon>
                  </Tooltip>
                )}
                <Tooltip label="Open an existing view">
                  <ActionIcon onClick={openSelectViewModal} size="md">
                    <IconFolderOpen size="inherit" />
                  </ActionIcon>
                </Tooltip>
                {isExistingView && (
                  <Tooltip label="Duplicate">
                    <ActionIcon onClick={openSaveViewModal} size="md">
                      <IconCopy size="inherit" />
                    </ActionIcon>
                  </Tooltip>
                )}
                {isExistingView && (
                  <>
                    {isAllowedToEdit && (
                      <Tooltip label={_.upperFirst(viewType.toLowerCase())}>
                        <ActionIcon
                          onClick={() =>
                            toggleViewType(auth.user?.isAdmin ?? false)
                          }
                        >
                          {viewType === ComponentType.PUBLIC ? (
                            <IconLockOpen2 size="inherit" />
                          ) : viewType === ComponentType.COMMUNITY ? (
                            <IconLockOpen2
                              size="inherit"
                              color="var(--mantine-color-green-5"
                            />
                          ) : (
                            <IconLock size="inherit" />
                          )}
                        </ActionIcon>
                      </Tooltip>
                    )}
                    {isInitiativeEnabled &&
                      (isLinkedToInitiative ? (
                        <Tooltip label="Go to linked initiative">
                          <ActionIcon
                            component={Link}
                            to={`/initiatives/${currentView!.initiatives[0].id}`}
                            size="md"
                          >
                            <IconDatabaseShare size="inherit" />
                          </ActionIcon>
                        </Tooltip>
                      ) : (
                        <Tooltip
                          label={
                            isTouched
                              ? "Cannot create an initiative from this view because it has unsaved changes"
                              : "Create an Initiative from this view"
                          }
                        >
                          <ActionIcon
                            disabled={isTouched}
                            onClick={handleClickCreateInitiative}
                            size="md"
                          >
                            <IconDatabaseDollar size="inherit" />
                          </ActionIcon>
                        </Tooltip>
                      ))}
                    {canHaveScheduledDigests &&
                      (currentView!.scheduledDigestConfigurations.length > 0 ? (
                        <Menu shadow="md" position="bottom-start">
                          <Menu.Target>
                            <Tooltip label="Manage digests">
                              <Indicator
                                withBorder
                                label={
                                  currentView!.scheduledDigestConfigurations
                                    .length
                                }
                                size={16}
                                offset={4}
                                position="top-end"
                              >
                                <ActionIcon variant="transparent" size="md">
                                  <IconAbacus size="inherit" />
                                </ActionIcon>
                              </Indicator>
                            </Tooltip>
                          </Menu.Target>
                          <Menu.Dropdown>
                            <Menu.Item
                              onClick={openScheduledDigestListModal}
                              leftSection={
                                <ActionIcon size="sm">
                                  <IconListSearch size="inherit" />
                                </ActionIcon>
                              }
                            >
                              View related reports configurations
                            </Menu.Item>
                            <Menu.Item
                              onClick={openScheduledDigestModal}
                              leftSection={
                                <ActionIcon size="sm">
                                  <IconClock size="inherit" />
                                </ActionIcon>
                              }
                            >
                              Schedule new report
                            </Menu.Item>
                          </Menu.Dropdown>
                        </Menu>
                      ) : (
                        <Tooltip label="Schedule new report">
                          <ActionIcon
                            variant="transparent"
                            size="md"
                            onClick={openScheduledDigestModal}
                          >
                            <IconReport size="inherit" />
                          </ActionIcon>
                        </Tooltip>
                      ))}
                  </>
                )}
              </Group>
            </Group>
            {isCreatingSavedView || isUpdatingSavedView ? (
              <Loader />
            ) : (
              isTouched && (
                <Group>
                  {isExistingView && isAllowedToEdit && (
                    <>
                      <Text c="gray">You have unsaved changes</Text>
                      <Tooltip
                        label={
                          isLinkedToInitiative || isMonitoringOn
                            ? "You cannot edit this view because it is linked to a Github Cost monitor"
                            : "Save changes"
                        }
                      >
                        <ActionIcon
                          onClick={handleUpdateView}
                          disabled={isLinkedToInitiative || isMonitoringOn}
                        >
                          <IconDeviceFloppy size="inherit" />
                        </ActionIcon>
                      </Tooltip>
                    </>
                  )}
                  {currentView?.id && (
                    <Tooltip
                      label={
                        isExistingView
                          ? "Reset changes"
                          : "Reset to default filters"
                      }
                    >
                      <ActionIcon onClick={handleClickReset}>
                        <IconRestore size="inherit" />
                      </ActionIcon>
                    </Tooltip>
                  )}
                </Group>
              )
            )}
          </Group>
          <CurrencyPicker
            form={filterForm}
            openModal={currencyModalHandlers.open}
          />
        </Group>
      </Stack>
      <Modal
        size="70vw"
        opened={isOpen}
        onClose={closeSaveViewModal}
        title={<Title order={2}>Save view</Title>}
        styles={{ body: { height: "70vh" } }}
      >
        <Stack align="stretch" h="90%">
          <InputWrapper>
            <Text fw="bold">1. Choose a view name</Text>
            <TextInput
              {...newViewNameField.getInputProps()}
              flex={1}
              placeholder="Production cost breakdown"
            />
          </InputWrapper>
          <Text fw="bold">2. Select a folder</Text>
          <Stack h="90%">
            <FileExplorer
              onOpenFolder={handleOpenFolder}
              buttonProps={{ label: "Save", loading: isCreatingSavedView }}
              onDoubleClick={(id, type) => {
                if (type !== TreeNodeType.Folder) {
                  return
                }

                handleOpenFolder(id)
              }}
            />
          </Stack>
        </Stack>
      </Modal>
    </>
  )
}
