import {
  Group,
  InputWrapper,
  NumberInput,
  Paper,
  Select,
  Stack,
  TextInput,
} from "@mantine/core"
import { MonthPickerInput } from "@mantine/dates"
import { UseFormReturnType } from "@mantine/form"

import {
  InitiativeDetails,
  InitiativesRequests,
  InitiativesResponses,
} from "@costory/types/endpoints/initiatives"
import {
  InitiativeEffort,
  InitiativeSnoozeType,
  InitiativeStatus,
} from "@costory/types/prisma-client"

import dayjs from "@costory/shared/dayjs"

import LiveBlocks from "@costory/front/components/Room"
import { SavingsOverview } from "@costory/front/pages/Initiatives/SavingsOverview"
import { useAuthState } from "@costory/front/queries/auth"
import { PropsWithData } from "@costory/front/utils/propsWithData"

interface InitiativeFormProps
  extends PropsWithData<InitiativesResponses.GetFormData> {
  form: UseFormReturnType<InitiativesRequests.CreateInitiative>
  existingInitiative?: InitiativeDetails
  isReadOnly?: boolean
}

export const InitiativeForm = ({
  data: { availableOwners, savedView },
  form,
  existingInitiative,
  isReadOnly = false,
}: InitiativeFormProps) => {
  const auth = useAuthState()
  const availableStatuses = Object.values(InitiativeStatus).map((status) => ({
    label: status,
    value: status,
  }))

  const availableEfforts = Object.values(InitiativeEffort).map((effort) => ({
    label: effort,
    value: effort,
  }))
  const roomId = savedView
    ? auth.user!.currentOrg.slug + ":" + savedView?.id //+ ":" + redirectPage
    : undefined
  const formValues = form.getValues()

  return (
    <Stack align="stretch" gap={8}>
      <Paper component={Stack}>
        <Group align="start" wrap="nowrap">
          <InputWrapper label="Initiative name" required>
            <TextInput
              readOnly={isReadOnly}
              flex={1}
              placeholder="Initiative name"
              {...form.getInputProps("name")}
            />
          </InputWrapper>
          <InputWrapper label="Effort" required>
            <Select
              allowDeselect={false}
              readOnly={isReadOnly}
              data={availableEfforts}
              placeholder="Select effort"
              {...form.getInputProps("effort")}
            />
          </InputWrapper>
          <InputWrapper label="Status" required>
            <Select
              allowDeselect={false}
              readOnly={isReadOnly}
              data={availableStatuses}
              placeholder="Select status"
              {...form.getInputProps("status")}
            />
          </InputWrapper>
          <InputWrapper label="Owner">
            <Select
              readOnly={isReadOnly}
              data={availableOwners}
              placeholder="No owner"
              {...form.getInputProps("ownerId")}
            />
          </InputWrapper>
          <InputWrapper label="Ticket link">
            <TextInput
              readOnly={isReadOnly}
              placeholder="jira.com/example-id"
              {...form.getInputProps("ticketUrl")}
            />
          </InputWrapper>
        </Group>
        <Group align="start">
          <InputWrapper label="Description" flex={1}>
            <TextInput
              readOnly={isReadOnly}
              placeholder="Description"
              {...form.getInputProps("description")}
            />
          </InputWrapper>
        </Group>
      </Paper>
      <Paper component={Stack}>
        <Group align="start">
          <InputWrapper label="Go Live Target" required>
            <MonthPickerInput
              disabled={isReadOnly}
              placeholder="Select Go Live date"
              {...form.getInputProps("goLiveTargetDate")}
            />
          </InputWrapper>
          <InputWrapper label="Expected savings" required>
            <NumberInput
              readOnly={isReadOnly}
              stepHoldDelay={500}
              stepHoldInterval={100}
              placeholder="Expected savings"
              min={0}
              suffix="%"
              allowNegative={false}
              {...form.getInputProps("expectedSavings")}
            />
          </InputWrapper>
          <InputWrapper label="Actual Go Live">
            <MonthPickerInput
              readOnly={true}
              {...form.getInputProps("goLiveDate")}
            />
          </InputWrapper>
        </Group>
        {/* <Group wrap="nowrap">
          <InputWrapper label="Snooze trigger">
            <Select
              allowDeselect={false}
              readOnly={isReadOnly}
              data={availableSnoozeTypes}
              placeholder="Select a trigger type"
              {...form.getInputProps("snoozeType")}
            />
          </InputWrapper>
          {formValues.snoozeType === InitiativeSnoozeType.Date ? (
            <InputWrapper label="Snooze until">
              <DateInput
                placeholder="Snooze date"
                readOnly={isReadOnly}
                {...form.getInputProps("snoozeDate")}
              />
            </InputWrapper>
          ) : (
            <InputWrapper label="Snooze until">
              <NumberInput
                disabled={
                  formValues.snoozeType === InitiativeSnoozeType.NoSnooze
                }
                readOnly={isReadOnly}
                allowNegative={false}
                placeholder={
                  formValues.snoozeType === InitiativeSnoozeType.Savings
                    ? "Monthly savings "
                    : "Monthly cost"
                }
                {...form.getInputProps("snoozeValue")}
              />
            </InputWrapper>
          )}
        </Group> */}
        {/* <Group align="stretch" h="50%"> */}
        <SavingsOverview
          isExisting={existingInitiative ? true : false}
          isReadOnly={isReadOnly}
          savedViewId={savedView.id}
          goLiveTargetDate={formValues.goLiveTargetDate}
          createdAt={
            existingInitiative
              ? existingInitiative.createdAt
              : dayjs().utc().toDate()
          }
          goLiveDate={existingInitiative ? existingInitiative.goLiveDate : null}
          expectedSavings={formValues.expectedSavings}
          snoozeDate={
            formValues.snoozeType === InitiativeSnoozeType.Date
              ? (formValues.snoozeDate ?? null)
              : null
          }
          snoozeValue={
            formValues.snoozeType === InitiativeSnoozeType.Cost
              ? (formValues.snoozeValue ?? null)
              : null
          }
        />
      </Paper>
      {/* </Group> */}
      <LiveBlocks roomId={roomId} />
    </Stack>
  )
}
