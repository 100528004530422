import { Alert, Flex, Loader } from "@mantine/core"
import { IconAlertCircle } from "@tabler/icons-react"
import { UseQueryResult } from "@tanstack/react-query"
import _ from "lodash"
import { JSX } from "react"

type AlertProps = {
  title?: string
  message: string
}

type DontAllowNullProps<TData, TError> = {
  query: UseQueryResult<TData, TError>
  children: (props: { data: TData }) => JSX.Element | null
  errorAlert?: AlertProps
  emptyAlert?: AlertProps
  allowEmptyArray?: boolean
  allowNull?: false
  LoadingComponent?: JSX.Element
}

type AllowNullProps<TData, TError> = {
  query: UseQueryResult<TData | null, TError>
  children: (props: { data: TData | null }) => JSX.Element | null
  errorAlert?: AlertProps
  emptyAlert?: AlertProps
  allowEmptyArray?: boolean
  allowNull: true
  LoadingComponent?: JSX.Element
}

type Props<TData, TError> =
  | DontAllowNullProps<TData, TError>
  | AllowNullProps<TData, TError>

export const QueryWrapper = <TData, TError>({
  query,
  allowEmptyArray = false,
  allowNull = false,
  errorAlert,
  emptyAlert,
  LoadingComponent = <Loader />,
  children,
}: Props<TData, TError>) => {
  const { data, isLoading, isError } = query

  if (isError) {
    return (
      <Flex align="center" justify="center" p={32}>
        <Alert
          color="red"
          title={errorAlert?.title ?? "Something went wrong"}
          icon={<IconAlertCircle />}
        >
          {errorAlert?.message ??
            "We couldn‘t proceed your request. Please try again or contact our support."}
        </Alert>
      </Flex>
    )
  }

  if (isLoading)
    return (
      <Flex align="center" justify="center" p={32} h="100%">
        {LoadingComponent}
      </Flex>
    )

  if (data === null && allowNull) {
    return (children as AllowNullProps<TData, TError>["children"])({
      data: null,
    })
  }

  if (!data || (_.isEmpty(data) && !allowEmptyArray)) {
    return (
      <Flex align="center" justify="center" p={32}>
        <Alert
          color="red"
          title={emptyAlert?.title ?? "No results"}
          icon={<IconAlertCircle />}
        >
          {emptyAlert?.message ??
            "We couldn‘t find any results for your request."}
        </Alert>
      </Flex>
    )
  }

  return children({ data })
}
