import { Stack, Group, Box, Button, Code, Text, Card } from "@mantine/core"
import { DatePicker } from "@mantine/dates"
import { useForm, zodResolver } from "@mantine/form"
import { modals } from "@mantine/modals"
import Markdown from "react-markdown"

import { ReportSchema, reportSchema } from "@costory/types/endpoints/analyze"

import { QueryBuilder } from "@costory/front/components/QueryBuilder"
import { useMutateAnalyze } from "@costory/front/queries/analyze"
import { toUTCDate } from "@costory/front/utils/filters"

export const Analyze = () => {
  const form = useForm<ReportSchema>({
    validate: zodResolver(reportSchema),
  })

  const { mutateAsync, data, isPending } = useMutateAnalyze()

  return (
    <>
      <Stack>
        <Group>
          <div>
            <h3 className="text-lg font-medium mb-2">Reference Period</h3>
            <DatePicker
              type="range"
              value={[form.values.previousFrom, form.values.previousTo]}
              onChange={(value) => {
                if (value) {
                  form.setFieldValue("previousFrom", toUTCDate(value[0]!)!)
                  form.setFieldValue("previousTo", toUTCDate(value[1]!)!)
                }
              }}
            />
          </div>

          <div>
            <h3 className="text-lg font-medium mb-2">Selected Period</h3>
            <DatePicker
              type="range"
              value={[form.values.from, form.values.to]}
              onChange={(value) => {
                if (value) {
                  form.setFieldValue("from", toUTCDate(value[0]!)!)
                  form.setFieldValue("to", toUTCDate(value[1]!)!)
                }
              }}
            />
          </div>
        </Group>

        <div>
          <h3 className="text-lg font-medium mb-2">Filters</h3>
          <QueryBuilder
            value={form.getValues().whereClause}
            onChange={form.getInputProps("whereClause").onChange}
          />
        </div>

        <Button
          loading={isPending}
          disabled={!form.isValid()}
          onClick={async () => {
            if (form.isValid()) {
              await mutateAsync(form.getValues())
            }
          }}
        >
          Analyze
        </Button>
      </Stack>

      {data && (
        <>
          <Button
            onClick={() => {
              navigator.clipboard.writeText(data.markdownText)
            }}
          >
            {" "}
            Copy as markdown{" "}
          </Button>
          <Markdown>{data.markdownText}</Markdown>

          <Card w={300}>
            <Stack>
              <Text fw={700}>Analysis Steps</Text>
              {data.steps.map((step, index) => (
                <Button
                  key={index}
                  variant="subtle"
                  onClick={() => {
                    modals.open({
                      title: step.toolName,
                      children: (
                        <Stack>
                          <Box>
                            <Text fw={500}>Input:</Text>
                            <Code block>
                              {JSON.stringify(step.input, null, 2)}
                            </Code>
                          </Box>
                          <Box>
                            <Text fw={500}>Output:</Text>
                            {step.output.includes("\\n") ? (
                              <Markdown>{JSON.parse(step.output)}</Markdown>
                            ) : (
                              <Code block>{step.output}</Code>
                            )}
                          </Box>
                        </Stack>
                      ),
                      size: "xl",
                    })
                  }}
                >
                  {step.toolName}
                </Button>
              ))}
            </Stack>
          </Card>
        </>
      )}
    </>
  )
}
