import {
  Stack,
  Select,
  Textarea,
  Group,
  TextInput,
  Button,
  Modal,
} from "@mantine/core"
import { useForm, zodResolver } from "@mantine/form"

import { NewMetric, newMetricSchema } from "@costory/types/endpoints/metrics"
import { MetricsDatasourceResponses } from "@costory/types/endpoints/metricsDatasources"
import { CostType } from "@costory/types/prisma-client"

import { QueryWrapper } from "@costory/front/components/layout/QueryWrapper"
import {
  useUpdateMetricMutation,
  useCreateMetricMutation,
} from "@costory/front/queries/metrics"
import { useMetricsDatasourcesListWithMetricsDefinitionQuery } from "@costory/front/queries/metricsDatasources"

const _MetricForm = ({
  datasources,
  initialValues,
  id,
  setOpened,
}: {
  datasources: MetricsDatasourceResponses.GetAllMetrics
  initialValues?: NewMetric
  id?: string
  setOpened: (opened: boolean) => void
}) => {
  const form = useForm<NewMetric>({
    validate: zodResolver(newMetricSchema),
    initialValues,
    validateInputOnChange: true,
    validateInputOnBlur: true,
  })
  const mutationCreate = useCreateMetricMutation()
  const mutationUpdate = useUpdateMetricMutation()
  return (
    <div>
      <Stack justify="space-between" h="100%">
        <Stack>
          <TextInput
            label="Name"
            placeholder="Enter a name"
            {...form.getInputProps("name")}
          />
          <Group>
            <Select
              label="Cost Type"
              placeholder="Select a cost type"
              {...form.getInputProps("costType")}
              data={Object.values(CostType).map((el) => ({
                value: el,
                label: el.replace("_", " ").toLowerCase(),
              }))}
            />
          </Group>
          <Group>
            <Select
              label="Datasource"
              placeholder="Select a datasource"
              {...form.getInputProps("datasourceId")}
              data={datasources.map((datasource) => ({
                value: datasource.id,
                label: datasource.name,
              }))}
            />
            <Select
              w={400}
              label="Metric"
              placeholder="Select a metric"
              {...form.getInputProps("rawMetric")}
              data={datasources
                .filter((el) => el.id == form.getValues().datasourceId)
                .flatMap((datasource) =>
                  (datasource.metadata.metricsDefinition || []).map(
                    (metric) => ({
                      value: metric.name,
                      label: metric.name,
                    }),
                  ),
                )}
            />
          </Group>
          <Textarea
            label="Formula: use cost variable and metric in your equation for example: cost / (metric * 100) "
            placeholder="Enter your formula..."
            minRows={3}
            {...form.getInputProps("formula")}
          />
        </Stack>
        <Group justify="flex-end">
          <Button
            variant="filled"
            disabled={!form.isValid()}
            onClick={() => {
              id
                ? mutationUpdate.mutate({ data: form.values, id })
                : mutationCreate.mutate(form.values)
              setOpened(false)
            }}
          >
            Save
          </Button>
        </Group>
      </Stack>
    </div>
  )
}

export const MetricForm = ({
  opened,
  setOpened,
  initialValues,
  id,
}: {
  opened: boolean
  setOpened: (opened: boolean) => void
  initialValues?: NewMetric
  id?: string
}) => {
  const query = useMetricsDatasourcesListWithMetricsDefinitionQuery()
  return (
    <Modal opened={opened} onClose={() => setOpened(false)} size="xl">
      <QueryWrapper query={query}>
        {({ data: datasources }) => (
          <_MetricForm
            datasources={datasources}
            initialValues={initialValues}
            id={id}
            setOpened={setOpened}
          />
        )}
      </QueryWrapper>
    </Modal>
  )
}
