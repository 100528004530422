import {
  Stack,
  List,
  Radio,
  Group,
  ThemeIcon,
  InputWrapper,
  TextInput,
  ActionIcon,
  Text,
  Button,
} from "@mantine/core"
import { UseFormReturnType } from "@mantine/form"
import { modals } from "@mantine/modals"
import {
  IconFileImport,
  IconFilePlus,
  IconBrandGoogleDrive,
  IconArrowLeft,
  IconArrowRight,
} from "@tabler/icons-react"
import { useState } from "react"

import { GSheetDatasourceFormData } from "@costory/types/endpoints/metricsDatasources"

import { useCreateGoogleSheetMutation } from "@costory/front/queries/metricsDatasources"

export const GSheetParamStep = ({
  form,
  handleClickBack,
  handleClickNext,
}: {
  form: UseFormReturnType<GSheetDatasourceFormData>
  handleClickBack: () => void
  handleClickNext: () => void
}) => {
  const { mutateAsync: createGoogleSheet, isPending } =
    useCreateGoogleSheetMutation()
  const createGsheet = async () => {
    const gsheetId = await createGoogleSheet()
    setGsheetIdCreated(gsheetId.gsheetId)
    form.setFieldValue("gSheetId", gsheetId.gsheetId)
  }
  const [gsheetMode, setGsheetMode] = useState<"existing" | "create">(
    "existing",
  )
  const [gsheetIdCreated, setGsheetIdCreated] = useState<string | undefined>()

  return (
    <Stack justify="space-between" h="100%">
      <Stack>
        <Text>
          You can either use an existing Google Sheet or create a new one then:
        </Text>
        <List size="sm">
          <List.Item>
            Check if the sheet is accesible: please share sheet to
            importer-sheets@costory.io
          </List.Item>
          <List.Item>
            Check if the sheet has the first row set: this will be the header of
            your sheet
          </List.Item>
          <List.Item>
            Ask you to map each column to the metric values / dates
          </List.Item>
        </List>
        <Radio.Group
          value={gsheetMode}
          onChange={(value) => {
            setGsheetMode(value as "existing" | "create")
            if (value === "create" && gsheetIdCreated) {
              form.setFieldValue("gSheetId", gsheetIdCreated)
            }
          }}
          defaultValue="existing"
        >
          <Group justify="center" gap="xl">
            <Radio.Card
              value="existing"
              w={200}
              h={100}
              withBorder
              style={{
                borderColor:
                  gsheetMode === "existing"
                    ? "var(--mantine-color-primary-6)"
                    : "var(--mantine-color-gray-3)",
              }}
            >
              <Group>
                <ThemeIcon size={25}>
                  <IconFileImport />
                </ThemeIcon>
                <Stack justify="center" align="center" h="100%">
                  <Text>Use existing Sheet</Text>
                </Stack>
              </Group>
            </Radio.Card>
            <Radio.Card
              value="create"
              w={200}
              h={100}
              withBorder
              style={{
                borderColor:
                  gsheetMode === "create"
                    ? "var(--mantine-color-primary-6)"
                    : "var(--mantine-color-gray-3)",
              }}
            >
              <Group>
                <ThemeIcon size={25}>
                  <IconFilePlus />
                </ThemeIcon>
                <Stack justify="center" align="center" h="100%">
                  <Text>Create new Sheet</Text>
                </Stack>
              </Group>
            </Radio.Card>
          </Group>
        </Radio.Group>

        {gsheetMode === "existing" ? (
          <InputWrapper label="Google Sheet ID: https://docs.google.com/spreadsheets/d/<GoogleSheetId>/edit">
            <Group>
              <TextInput
                {...form.getInputProps("gSheetId")}
                style={{ flex: 1 }}
              />
              <ActionIcon
                onClick={() =>
                  window.open(
                    `https://docs.google.com/spreadsheets/d/${form.values.gSheetId}`,
                    "_blank",
                  )
                }
                disabled={!form.values.gSheetId}
              >
                <IconBrandGoogleDrive />
              </ActionIcon>
            </Group>
          </InputWrapper>
        ) : (
          <Group justify="center">
            <Button
              onClick={async () => {
                await createGsheet()
              }}
              loading={isPending}
              disabled={!!gsheetIdCreated}
            >
              Create
            </Button>
            <Button
              leftSection={<IconBrandGoogleDrive />}
              onClick={() =>
                window.open(
                  `https://docs.google.com/spreadsheets/d/${form.values.gSheetId}`,
                  "_blank",
                )
              }
              disabled={!gsheetIdCreated}
            >
              Open
            </Button>
          </Group>
        )}
      </Stack>
      <Group justify="space-between">
        <Button
          onClick={handleClickBack}
          variant="outline"
          leftSection={<IconArrowLeft />}
        >
          Back
        </Button>
        <Button
          onClick={() => {
            modals.openConfirmModal({
              title: "Confirm headers setup",
              children: (
                <Text>
                  Please make sure you have added all the necessary column
                  headers to your Google Sheet. If you add new metrics later,
                  you will need to recreate this datasource.
                </Text>
              ),
              labels: { confirm: "Continue", cancel: "Cancel" },
              onConfirm: handleClickNext,
            })
          }}
          disabled={!form.values.gSheetId}
          rightSection={<IconArrowRight />}
        >
          Next
        </Button>
      </Group>
    </Stack>
  )
}
