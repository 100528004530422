import { Group, Paper, Stack } from "@mantine/core"
import { useEffect } from "react"
import { useParams } from "react-router-dom"

import { SavedViewsResponses } from "@costory/types/endpoints/savedViews"

import { ChartContainer } from "@costory/front/components/ChartContainer"
import { DonutChart } from "@costory/front/components/charts/DonutChart"
import { QueryWrapper } from "@costory/front/components/layout/QueryWrapper"
import { useChartContext } from "@costory/front/contexts/ChartContext"
import { usePageContext } from "@costory/front/contexts/PageContext"
import { ExplorerMainChart } from "@costory/front/pages/Explorer/ExplorerMainChart"
import { ExplorerTable } from "@costory/front/pages/Explorer/ExplorerTable"
import { useSavedViewQuery } from "@costory/front/queries/savedViews"
import { getBestDrillDown } from "@costory/front/utils/columns"

type Props = {
  savedView?: SavedViewsResponses.GetSavedViewDetails
}

// Keep this as a separate component to make eslint happy:
// "React Hooks must be called in a React function component or a custom React Hook function  react-hooks/rules-of-hooks"
const ChartContent = () => {
  const {
    filters,
    drillDownInto,
    setExplorerChartType,
    setThreshold,
    selectedMetric,
  } = useChartContext()
  const { explorerChartType, threshold, ...explorerFilters } = filters

  return (
    <Stack>
      <Group flex={1} align="stretch" h={400}>
        <Paper flex={1} px={32} py={24}>
          <Stack h={400} justify="center">
            <ExplorerMainChart
              explorerChartType={explorerChartType}
              filters={explorerFilters}
              drillDownInto={drillDownInto}
              setExplorerChartType={setExplorerChartType}
              setThreshold={setThreshold}
              threshold={threshold}
              selectedMetric={selectedMetric}
            />
          </Stack>
        </Paper>
        <Paper w={350} px={32} py={24}>
          <Stack h="none" align="center" justify="center">
            <DonutChart
              filters={explorerFilters}
              height={300}
              displayTrend={true}
              drillDownInto={(value) =>
                drillDownInto(
                  explorerFilters.groupBy,
                  value,
                  getBestDrillDown(explorerFilters.groupBy),
                )
              }
            />
          </Stack>
        </Paper>
      </Group>
      <ExplorerTable filters={explorerFilters} drillDownInto={drillDownInto} />
    </Stack>
  )
}

const Explorer = ({ savedView }: Props) => {
  const { setSecondaryTitle } = usePageContext()

  useEffect(() => {
    setSecondaryTitle(savedView ? savedView.name : undefined)
  }, [savedView, setSecondaryTitle])

  return (
    <ChartContainer
      savedView={savedView}
      redirectPage="explorer"
      key={savedView ? savedView.id : "no-view"}
    >
      <ChartContent />
    </ChartContainer>
  )
}

export const ExplorerPage = () => {
  const { viewId } = useParams()
  const savedViewQuery = useSavedViewQuery(viewId)

  if (viewId) {
    return (
      <QueryWrapper query={savedViewQuery}>
        {({ data: savedView }) => <Explorer savedView={savedView} />}
      </QueryWrapper>
    )
  }

  return <Explorer />
}
