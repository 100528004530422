import { useMutation, useQuery } from "@tanstack/react-query"
import { AxiosResponse } from "axios"

import {
  BillingDatasourcesRequests,
  BillingDatasourcesResponses,
} from "@costory/types/endpoints/billingDatasources"

import { apiClient } from "@costory/front/lib/apiClient"
import { queryClient } from "@costory/front/lib/queryClient"

export const useBillingDatasourcesListQuery = () => {
  return useQuery({
    queryKey: ["billingDatasources"],
    queryFn: async () => {
      const response =
        await apiClient.get<BillingDatasourcesResponses.GetAllBillingDatasources>(
          "/billingDatasources",
        )
      return response.data
    },
  })
}

export const useCreateBillingDatasourceMutation = () => {
  return useMutation({
    mutationKey: ["createBillingDatasource"],
    mutationFn: async (
      data: BillingDatasourcesRequests.CreateBillingDatasource,
    ) => {
      await apiClient.post<
        null,
        null,
        BillingDatasourcesRequests.CreateBillingDatasource
      >("/billingDatasources", data)
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ["billingDatasources"] })
    },
  })
}

export const useValidateImportParamtersQuery = (
  data: BillingDatasourcesRequests.ValidateBillingDatasourceImportParameters,
) => {
  return useQuery({
    queryKey: ["billingDatasources", "validateImport", data],
    queryFn: async () => {
      const response = await apiClient.post<
        BillingDatasourcesResponses.ValidateBillingDatasourceImportParameters,
        AxiosResponse<BillingDatasourcesResponses.ValidateBillingDatasourceImportParameters>,
        BillingDatasourcesRequests.ValidateBillingDatasourceImportParameters
      >("/billingDatasources/validate", data)
      return response.data
    },
  })
}

export const useGetBigQueryColumnsQuery = (
  importParameters: BillingDatasourcesRequests.GetBigQueryMapping,
) => {
  return useQuery({
    queryKey: ["billingDatasources", "bigQueryColumns"],
    queryFn: async () => {
      const response = await apiClient.post<
        BillingDatasourcesResponses.GetBigQueryMapping,
        AxiosResponse<BillingDatasourcesResponses.GetBigQueryMapping>,
        BillingDatasourcesRequests.GetBigQueryMapping
      >("/billingDatasources/mapping", importParameters)
      return response.data
    },
  })
}

export const useDeleteBillingDatasourceMutation = () => {
  return useMutation({
    mutationFn: async (id: string) => {
      const response = await apiClient.delete(`/billingDatasources/${id}`)
      return response.data
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ["billingDatasources"] })
    },
  })
}
