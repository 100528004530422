import {
  Stack,
  InputWrapper,
  Select,
  Group,
  Button,
  Text,
  PasswordInput,
  MultiSelect,
  TextInput,
} from "@mantine/core"
import { UseFormReturnType } from "@mantine/form"
import { IconArrowLeft, IconArrowRight } from "@tabler/icons-react"

import {
  datadoCiVisibilityMetricOptions,
  DatadogCIVisibilityDatasourceFormData,
} from "@costory/types/endpoints/metricsDatasources"

const Regions = [
  { value: "datadoghq.com", label: "datadoghq.com" },
  { value: "us3.datadoghq.com", label: "us3.datadoghq.com" },
  { value: "us5.datadoghq.com", label: "us5.datadoghq.com" },
  { value: "datadoghq.eu", label: "datadoghq.eu" },
  { value: "ddog-gov.com", label: "ddog-gov.com" },
]
export const DatadogCIVisibilityConfiguration = ({
  form,
  handleClickBack,
  handleClickNext,
}: {
  form: UseFormReturnType<DatadogCIVisibilityDatasourceFormData>
  handleClickBack: () => void
  handleClickNext: () => void
}) => {
  return (
    <Stack h="100%">
      <Stack flex={1}>
        <InputWrapper
          label={
            <Text fw="bold" span={true}>
              Application key
            </Text>
          }
          required
        >
          <PasswordInput
            {...form.getInputProps("applicationKey")}
            placeholder="Application key"
          />
        </InputWrapper>
        <InputWrapper
          label={
            <Text fw="bold" span={true}>
              API key
            </Text>
          }
          required
        >
          <PasswordInput
            {...form.getInputProps("apiKey")}
            placeholder="API key"
          />
        </InputWrapper>
        <InputWrapper
          label={
            <Text fw="bold" span={true}>
              Region
            </Text>
          }
          required
        >
          <Select
            {...form.getInputProps("region")}
            data={Regions}
            placeholder="Select a region"
          />
        </InputWrapper>
        <InputWrapper
          label={
            <Text fw="bold" span={true}>
              Query
            </Text>
          }
          required
        >
          <TextInput
            {...form.getInputProps("query")}
            placeholder="Enter your query (ci_level:pipeline)"
          />
        </InputWrapper>
        <Group>
          <InputWrapper
            label={
              <Text fw="bold" span={true}>
                Aggregation
              </Text>
            }
            required
          >
            <Select
              {...form.getInputProps("aggregation")}
              data={datadoCiVisibilityMetricOptions}
            />
          </InputWrapper>
          <InputWrapper
            label={
              <Text fw="bold" span={true}>
                Metric
              </Text>
            }
            required
          >
            <Select
              {...form.getInputProps("metric")}
              defaultValue={null}
              placeholder="*"
              data={[
                "@ci.execution_time",
                "@duration",
                "@ci.queue_time",
                "@ci.wait_time",
              ]}
            />
          </InputWrapper>

          <InputWrapper
            label={
              <Text fw="bold" span={true}>
                Group By
              </Text>
            }
            required
          >
            <MultiSelect
              {...form.getInputProps("groupBy")}
              data={[
                "@ci.pipeline_name",
                "@ci.provider.name",
                "@git.branch",
                "@git.repository_url",
              ]}
              placeholder={
                form.getInputProps("groupBy").value &&
                form.getInputProps("groupBy").value.length > 0
                  ? ""
                  : "(Everything)"
              }
              searchable
              maxValues={1}
            />
          </InputWrapper>
        </Group>
      </Stack>
      <Group justify="space-between">
        <Button
          onClick={handleClickBack}
          variant="outline"
          leftSection={<IconArrowLeft />}
        >
          Back
        </Button>
        <Button
          disabled={!form.isValid()}
          onClick={handleClickNext}
          rightSection={<IconArrowRight />}
        >
          Next
        </Button>
      </Group>
    </Stack>
  )
}
