import {
  Stack,
  Table,
  Select,
  Group,
  Button,
  ActionIcon,
  Popover,
  Text,
} from "@mantine/core"
import { UseFormReturnType } from "@mantine/form"
import { useShallowEffect } from "@mantine/hooks"
import { IconArrowLeft, IconInfoCircle } from "@tabler/icons-react"
import _ from "lodash"
import Papa from "papaparse"
import { useMemo } from "react"

import {
  MetricsDatasourceFormData,
  gapFillingMethods,
  CsvDatasourceFormData,
  GSheetDatasourceFormData,
  CSVOrientation,
  aggregationFormulas,
  GapFillingMethod,
  AggregationFormula,
  DatadogDatasourceFormData,
  DatadogCIVisibilityDatasourceFormData,
  AwsDatasourceFormDataWithFileData,
} from "@costory/types/endpoints/metricsDatasources"
import { MetricsDatasourceType } from "@costory/types/prisma-client"

export const MetricsGapConfig = ({
  form,
  handleClickBack,
  handleClickImport,
  isImporting,
  metricsNames,
}: {
  form: UseFormReturnType<MetricsDatasourceFormData>
  handleClickBack: () => void
  handleClickImport: () => void
  isImporting: boolean
  metricsNames: string[]
}) => {
  const formValues = form.getValues()
  const metrics = useMemo(() => getMetricNames(formValues), [formValues])

  useShallowEffect(() => {
    // Initialize metricsDefinition with default values for each metric
    form.setFieldValue(
      "metricsDefinition",
      (metrics || metricsNames || []).map((metric) => ({
        name: metric,
        gapFillingMethod: "ZERO" as GapFillingMethod,
        aggregationFormula: "SUM" as AggregationFormula,
      })),
    )
  }, [metrics])

  return (
    <Stack h="100%" justify="space-between">
      <Table>
        <Table.Thead>
          <Table.Tr>
            <Table.Th>Metric Name</Table.Th>
            <Table.Th>
              <Group gap={4}>
                Aggregation Method
                <Popover width={400} position="bottom" withArrow>
                  <Popover.Target>
                    <ActionIcon variant="subtle" size="sm">
                      <IconInfoCircle size={16} />
                    </ActionIcon>
                  </Popover.Target>
                  <Popover.Dropdown>
                    <Text size="sm" mb="sm">
                      Aggregation method to use when we need to aggregate
                      multiple days:
                    </Text>
                    <Table>
                      <Table.Thead>
                        <Table.Tr>
                          <Table.Th>Method</Table.Th>
                          <Table.Th>Definition</Table.Th>
                          <Table.Th>Example</Table.Th>
                        </Table.Tr>
                      </Table.Thead>
                      <Table.Tbody>
                        <Table.Tr>
                          <Table.Td>Sum</Table.Td>
                          <Table.Td>Adds up all values in the period</Table.Td>
                          <Table.Td>
                            Daily visitors → Monthly total visitors is the SUM
                            of daily visitors
                          </Table.Td>
                        </Table.Tr>
                        <Table.Tr>
                          <Table.Td>Average</Table.Td>
                          <Table.Td>
                            Takes average value in the aggregation period
                          </Table.Td>
                          <Table.Td>
                            Number of R&D employees → Average number of R&D in
                            the period
                          </Table.Td>
                        </Table.Tr>
                      </Table.Tbody>
                    </Table>
                  </Popover.Dropdown>
                </Popover>
              </Group>
            </Table.Th>
            <Table.Th>
              <Group gap={4}>
                Gap Filling Method
                <Popover width={400} position="bottom" withArrow>
                  <Popover.Target>
                    <ActionIcon variant="subtle" size="sm">
                      <IconInfoCircle size={16} />
                    </ActionIcon>
                  </Popover.Target>
                  <Popover.Dropdown>
                    <Text size="sm" mb="sm">
                      Method used to fill gaps in data:
                    </Text>
                    <Table>
                      <Table.Thead>
                        <Table.Tr>
                          <Table.Th>Method</Table.Th>
                          <Table.Th>Definition</Table.Th>
                        </Table.Tr>
                      </Table.Thead>
                      <Table.Tbody>
                        <Table.Tr>
                          <Table.Td>Zero</Table.Td>
                          <Table.Td>
                            When there is no value for a day, we consider it as
                            0
                          </Table.Td>
                        </Table.Tr>
                        <Table.Tr>
                          <Table.Td>Forward Fill</Table.Td>
                          <Table.Td>
                            Propagate the last known value until a new value is
                            available
                          </Table.Td>
                        </Table.Tr>
                        <Table.Tr>
                          <Table.Td>Linear Interpolation</Table.Td>
                          <Table.Td>
                            Compute a linear interpolation between available
                            data points
                          </Table.Td>
                        </Table.Tr>
                        <Table.Tr>
                          <Table.Td>Spread</Table.Td>
                          <Table.Td>
                            For monthly metrics: spreads the monthly value
                            uniformly across days (value / number of days in
                            month)
                          </Table.Td>
                        </Table.Tr>
                      </Table.Tbody>
                    </Table>
                  </Popover.Dropdown>
                </Popover>
              </Group>
            </Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {form.values.metricsDefinition?.map((metric, index) => (
            <Table.Tr key={metric.name}>
              <Table.Td>{metric.name}</Table.Td>
              <Table.Td>
                <Select
                  {...form.getInputProps(
                    `metricsDefinition.${index}.aggregationFormula`,
                  )}
                  data={aggregationFormulas.map((el) => ({
                    value: el,
                    label: el,
                  }))}
                />
              </Table.Td>
              <Table.Td>
                <Select
                  {...form.getInputProps(
                    `metricsDefinition.${index}.gapFillingMethod`,
                  )}
                  data={gapFillingMethods.map((el) => ({
                    value: el,
                    label: el,
                  }))}
                />
              </Table.Td>
            </Table.Tr>
          ))}
        </Table.Tbody>
      </Table>
      <Group
        justify="space-between"
        pb={formValues.type === MetricsDatasourceType.AWS ? 20 : undefined}
      >
        <Button
          onClick={handleClickBack}
          variant="outline"
          leftSection={<IconArrowLeft />}
        >
          Back
        </Button>
        <Button
          onClick={handleClickImport}
          loading={isImporting}
          disabled={!form.isValid()}
        >
          Import
        </Button>
      </Group>
    </Stack>
  )
}
function getMetricNames(
  formValues:
    | CsvDatasourceFormData
    | GSheetDatasourceFormData
    | DatadogDatasourceFormData
    | DatadogCIVisibilityDatasourceFormData
    | AwsDatasourceFormDataWithFileData,
) {
  if (formValues.type === MetricsDatasourceType.CSV) {
    if (formValues.orientation === CSVOrientation.Columns) {
      const { data: csvData } = Papa.parse(formValues.csvData)
      return (csvData[0] as string[]).filter((el) => el != formValues.date)
    } else {
      return _.uniq(
        Papa.parse<Record<string, string>>(formValues.csvData, {
          header: true,
        }).data.map((row) => row[formValues.metricNames!]),
      )
    }
  } else if (formValues.type === MetricsDatasourceType.GoogleSheet) {
    return formValues.metricsColumns
  } else if (formValues.type === MetricsDatasourceType.AWS) {
    const foundMetrics =
      formValues.orientation === CSVOrientation.Columns
        ? formValues.seriesNames.filter((header) => header !== formValues.date)
        : formValues.seriesData
            .map((row) => row[formValues.metricNames!] as string)
            .slice(0, 1)
    return foundMetrics
  }
}
