import { IconBrandTeams, IconMail } from "@tabler/icons-react"

import { DestinationType } from "@costory/types/prisma-client"

export interface IntegrationInfo {
  icon: React.ReactNode
  label: string
  isEnabled: boolean
}

export const availableIntegrations: Record<DestinationType, IntegrationInfo> = {
  [DestinationType.SLACK]: {
    icon: <img src="/img/slack.svg" width={25} alt="Slack" />,
    label: "Slack",
    isEnabled: true,
  },
  [DestinationType.EMAIL]: {
    icon: <IconMail />,
    label: "Email",
    isEnabled: false,
  },
  [DestinationType.TEAMS]: {
    icon: <IconBrandTeams />,
    label: "Teams",
    isEnabled: false,
  },
}
