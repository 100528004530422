import { showNotification } from "@mantine/notifications"
import { useMutation, useQuery } from "@tanstack/react-query"
import { AxiosResponse } from "axios"
import saveAs from "file-saver"

import {
  DashboardRequests,
  DashboardResponses,
} from "@costory/types/endpoints/dashboard"

import { apiClient } from "@costory/front/lib/apiClient"
import { queryClient } from "@costory/front/lib/queryClient"

const ENV = import.meta.env.VITE_ENVIRONMENT
export const useDashboardQuery = (dashboardId: string) => {
  return useQuery({
    queryKey: ["dashboard", dashboardId],
    queryFn: async () => {
      const response =
        await apiClient.get<DashboardResponses.GetDashboardPageData>(
          `/dashboard/${dashboardId}`,
        )

      return response.data
    },
  })
}

export const useDashboardCreationPageDataQuery = () => {
  return useQuery({
    queryKey: ["dashboard", "new"],
    queryFn: async () => {
      const response =
        await apiClient.get<DashboardResponses.GetDashboardCreationPageData>(
          "/dashboard/new",
        )
      return response.data
    },
  })
}

export const useDefaultDashboardQuery = () => {
  return useQuery({
    queryKey: ["dashboard", "default"],
    queryFn: async () => {
      const response =
        await apiClient.get<DashboardResponses.GetDefaultDashboard>(
          "/dashboard/default",
        )

      if (response.status === 204) {
        return null
      }

      return response.data
    },
  })
}

export const useDashboardListQuery = () => {
  return useQuery({
    queryKey: ["dashboard"],
    queryFn: async () => {
      const response =
        await apiClient.get<DashboardResponses.GetDashboard[]>(
          "/dashboard/list",
        )
      return response.data
    },
  })
}

export const useCreateDashboardMutation = () =>
  useMutation({
    mutationFn: async (dash: DashboardRequests.NewDashboard) => {
      const response = await apiClient.post<DashboardResponses.NewDashboard>(
        "/dashboard",
        dash,
      )
      return response.data
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ["dashboard"] })
      await queryClient.invalidateQueries({ queryKey: ["folders", "tree"] })
      showNotification({
        color: "green",
        title: "Dashboard saved !",
        message: "Dashboard saved successfully",
      })
    },
    onError: (error) => {
      showNotification({
        color: "red",
        title: "Error creating dashboard",
        message: error.message,
      })
    },
  })

export const useUpdateDashboardMutation = () => {
  return useMutation({
    mutationFn: async ({
      id,
      dashboard,
    }: {
      id: string
      dashboard: DashboardRequests.UpdateDashboard
    }) => {
      const response = await apiClient.put<DashboardResponses.GetDashboard>(
        `/dashboard/${id}`,
        dashboard,
      )
      return response.data
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["dashboard"],
      })
      await queryClient.invalidateQueries({
        queryKey: ["folders"],
      })
      showNotification({
        color: "green",
        title: "Dashboard saved !",
        message: "Dashboard saved successfully ",
      })
    },
    onError: (error) => {
      showNotification({
        color: "red",
        title: "Error",
        message: error.message,
      })
    },
  })
}

export const useDeleteDashboard = () =>
  useMutation({
    mutationFn: async (id: string) => {
      await apiClient.delete(`/dashboard/${id}`)
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ["dashboard"] })
      await queryClient.invalidateQueries({ queryKey: ["folders"] })
    },
  })

export const useSetDefaultDashboardMutation = () =>
  useMutation({
    mutationFn: async (dashboardId: string) => {
      await apiClient.post<
        DashboardResponses.SetDefaultDashboard,
        AxiosResponse<DashboardResponses.SetDefaultDashboard>,
        DashboardRequests.SetDefaultDashboard
      >("/dashboard/default", { dashboardId })
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ["dashboard"] })
    },
  })

export const useRenameDashboard = () =>
  useMutation({
    mutationFn: async ({ id, name }: { id: string; name: string }) => {
      const response = await apiClient.put<DashboardResponses.RenameDashboard>(
        `/dashboard/${id}`,
        { name },
      )
      return response.data
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ["dashboard"] })
      await queryClient.invalidateQueries({ queryKey: ["folders"] })
    },
  })

export const useGeneratePdfFromDashboard = (
  dashboardId: string,
  dashboardName: string,
) => {
  return useQuery({
    enabled: false,
    queryKey: ["dashboard", "pdf", dashboardId],
    queryFn: async () => {
      const response = await apiClient.get(`/graph/dashboard/${dashboardId}`, {
        responseType: "blob",
      })
      const blob = new Blob([response.data], { type: "application/pdf" })
      saveAs(blob, `${dashboardName}_env_${ENV}.pdf`)
    },
  })
}
