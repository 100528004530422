import { Paper } from "@mantine/core"
import { useParams } from "react-router-dom"

import { ChartContainer } from "@costory/front/components/ChartContainer"
import { WaterfallChart } from "@costory/front/components/charts/WaterfallChart"
import { QueryWrapper } from "@costory/front/components/layout/QueryWrapper"
import { useChartContext } from "@costory/front/contexts/ChartContext"
import { useSavedViewQuery } from "@costory/front/queries/savedViews"
import { getBestDrillDown } from "@costory/front/utils/columns"

const Waterfall = () => {
  const { filters, drillDownInto, setThreshold } = useChartContext()
  return (
    <Paper px={32} h={642} py={40}>
      <WaterfallChart
        filters={filters}
        setThreshold={setThreshold}
        height={500}
        drillDownInto={(value, mode) =>
          drillDownInto(
            filters.groupBy,
            value,
            getBestDrillDown(filters.groupBy),
            mode,
          )
        }
        tooltipYPosition={250}
      />
    </Paper>
  )
}

export const WaterfallPage = () => {
  const { viewId } = useParams()
  const savedViewQuery = useSavedViewQuery(viewId)

  if (viewId) {
    return (
      <QueryWrapper query={savedViewQuery}>
        {({ data: savedView }) => (
          <ChartContainer
            redirectPage="waterfall"
            savedView={savedView}
            key={viewId}
          >
            <Waterfall />
          </ChartContainer>
        )}
      </QueryWrapper>
    )
  }
  return (
    <ChartContainer redirectPage="waterfall" key="no-view" defaultHideOneTime>
      <Waterfall />
    </ChartContainer>
  )
}
