import {
  Accordion,
  Button,
  Group,
  Pill,
  Stack,
  Text,
  Tooltip,
} from "@mantine/core"
import { useModals } from "@mantine/modals"
import {
  IconBrandGithub,
  IconBrandSlack,
  IconMilitaryRank,
} from "@tabler/icons-react"
import { useMutation } from "@tanstack/react-query"
import { useContext, useEffect } from "react"
import { Link, useSearchParams } from "react-router-dom"

import {
  UserContext,
  useUser,
} from "@costory/front/components/auth/UserContext"
import { QueryWrapper } from "@costory/front/components/layout/QueryWrapper"
import { apiClient } from "@costory/front/lib/apiClient"
import { IntegrationAccordionItem } from "@costory/front/pages/Integration/IntegrationAccordionItem"
import {
  useSlackChannelsQuery,
  useTestSlackChannelMutation,
} from "@costory/front/queries/integrations"

const SLACK_INSTALLATION_URL = `${import.meta.env.VITE_API_URL}/slack/install`
const LINEAR_INSTALLATION_URL = "/linear/install"

const SlackIntegration = () => {
  const user = useUser()
  const query = useSlackChannelsQuery()
  const mutation = useTestSlackChannelMutation()
  const { openConfirmModal } = useModals()

  const handleChannelClick = (channelName: string) => {
    openConfirmModal({
      title: "Test Slack Channel",
      children: (
        <Text size="sm">
          Do you want to test the connection with channel #{channelName}? It
          will send a message to the slack channel
        </Text>
      ),
      labels: { confirm: "Test", cancel: "Cancel" },
      onConfirm: () => mutation.mutateAsync(channelName),
    })
  }
  return (
    <IntegrationAccordionItem
      name="Slack"
      icon={<img src="/img/slack.svg" width={25} alt="Slack" />}
      isConnected={!!user.currentOrg.slackInstallation}
    >
      <Stack>
        <Group>
          Install App
          <Link to={SLACK_INSTALLATION_URL} target="_blank">
            <Button color="gray" leftSection={<IconBrandSlack />}>
              {user.currentOrg.slackInstallation ? "Update" : "Install"} Costory
              Slack App
            </Button>
          </Link>
        </Group>

        {!!user.currentOrg.slackInstallation && (
          <QueryWrapper query={query} allowEmptyArray>
            {({ data }) => (
              <>
                <Stack>
                  <Group>
                    <Text>Channels Accessible: (click on channel to test)</Text>
                    {data.map((el, index) => (
                      <Tooltip key={index} label="Test Channel">
                        <Pill
                          style={{ cursor: "pointer" }}
                          key={index}
                          onClick={() => handleChannelClick(el.name)}
                        >
                          {el.name}
                        </Pill>
                      </Tooltip>
                    ))}
                  </Group>

                  <Text>
                    To add more channels, please invite @costory bot in the
                    channel by typing @costory in your slack channel.
                  </Text>
                </Stack>
              </>
            )}
          </QueryWrapper>
        )}
      </Stack>
    </IntegrationAccordionItem>
  )
}

const LinearIntegration = () => {
  const user = useUser()
  return (
    <IntegrationAccordionItem
      name="Linear"
      icon={<img src="/img/linear.svg" width={25} alt="Linear" />}
      isConnected={!!user.currentOrg.linearInstallation}
    >
      <Group>
        Install App
        <Link to={LINEAR_INSTALLATION_URL} target="_blank">
          <Button color="gray" leftSection={<IconMilitaryRank />}>
            {user.currentOrg.linearInstallation ? "Update" : "Install"} Costory
            Linear App
          </Button>
        </Link>
      </Group>
    </IntegrationAccordionItem>
  )
}

const GITHUB_INSTALLATION_URL =
  import.meta.env.VITE_ENVIRONMENT !== "production"
    ? "https://github.com/apps/dev-costory-agent/installations/new"
    : "https://github.com/apps/costory-agent/installations/new"

const GitHubIntegration = () => {
  const { user, updateUser } = useContext(UserContext)
  const [searchParams] = useSearchParams()

  const { mutate: saveGitHubAppIdIfExists, isPending } = useMutation({
    mutationFn: async () => {
      const githubAppId = searchParams.get("installation_id")
      if (githubAppId && user.currentOrg.githubAppId !== githubAppId) {
        await apiClient.post("/github/installation", { githubAppId })
        updateUser((prev) => ({
          ...prev,
          currentOrg: { ...prev.currentOrg, githubAppId },
        }))
      }
    },
  })

  useEffect(() => {
    saveGitHubAppIdIfExists()
  }, [saveGitHubAppIdIfExists])

  const isConnected = !!user.currentOrg.githubAppId

  return (
    <IntegrationAccordionItem
      name="GitHub"
      icon={<img src="/img/github-mark.svg" width={25} alt="GitHub" />}
      isConnected={isConnected}
      isLoading={isPending}
    >
      <Group>
        Install App
        <Link to={GITHUB_INSTALLATION_URL}>
          <Button color="gray" leftSection={<IconBrandGithub />}>
            {isConnected
              ? "Update Costory Agent permissions"
              : "Install Costory Agent on GitHub"}
          </Button>
        </Link>
      </Group>
    </IntegrationAccordionItem>
  )
}

export const IntegrationPage = () => {
  return (
    <Stack>
      <Stack gap={2}>
        <Text>Connect Costory to your tools</Text>
      </Stack>
      <Accordion chevronPosition="left">
        <SlackIntegration />
        <GitHubIntegration />
        <LinearIntegration />
      </Accordion>
    </Stack>
  )
}
