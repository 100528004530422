import { ActionIcon, Button, Flex, Text } from "@mantine/core"
import { UseFormReturnType } from "@mantine/form"
import { showNotification } from "@mantine/notifications"
import { IconEdit, IconTrash } from "@tabler/icons-react"
import _ from "lodash"
import {
  createMRTColumnHelper,
  createRow,
  MantineReactTable,
  MRT_Cell,
  MRT_Column,
  MRT_Row,
  MRT_TableInstance,
  MRT_TableOptions,
  useMantineReactTable,
} from "mantine-react-table"
import { useMemo, useState } from "react"
import { ZodIssue } from "zod"

import {
  reallocationRule,
  ReallocationRule,
  VirtualDimensionsRequests,
} from "@costory/types/endpoints/virtualDimensions"

import { SelectCreatable } from "@costory/front/components/SelectCreatable"

import { NumberCell } from "../../../components/table/NumberCell"

type Props = {
  form: UseFormReturnType<VirtualDimensionsRequests.EditVirtualDimension>
  prefixForm: string
  costRule: number
  allocation: VirtualDimensionsRequests.EditVirtualDimension["rules"][0]["allocation"]
  vdDict: string[]
}

export default function SplitCostResult({
  form,
  prefixForm,
  allocation,
  costRule,
  vdDict,
}: Props) {
  const allocationWeights = useMemo(
    () =>
      allocation.allocationType === "splitCost"
        ? allocation.reAllocationParams.partitions
        : [],
    [allocation],
  )

  const [validationErrors, setValidationErrors] = useState<ZodIssue[]>([])
  const [isAddDisabled, setIsAddDisabled] = useState(false)

  const total = useMemo(
    () => _.sumBy(allocationWeights, "weight"),
    [allocationWeights],
  )

  const availableAllocationLabel = useMemo(
    () =>
      vdDict.filter(
        (vd) => !allocationWeights.map((w) => w.label).includes(vd),
      ),
    [vdDict, allocationWeights],
  )

  const allocatedLabel = useMemo(
    () => _.map(allocationWeights, "label"),
    [allocationWeights],
  )

  const columns = useMemo(() => {
    const columnHelper = createMRTColumnHelper<ReallocationRule>()
    return [
      columnHelper.accessor("label", {
        header: "Label",
        Edit: (propsEdit) => (
          <>
            <SelectCreatableForTable
              propsEdit={propsEdit}
              initialValue={propsEdit.cell.getValue()}
              form={form}
              vdDict={vdDict}
              validationErrors={validationErrors}
            />
          </>
        ),
      }),
      columnHelper.accessor("weight", {
        header: "Percentage",
        editVariant: "text",
        mantineEditTextInputProps: {
          error: _.find(validationErrors, (t) => t.path[0] === "weight")
            ?.message,
          type: "number",
        },
        Footer: () => (
          <Text c={total != 100 ? "red" : "green"}>{Math.round(total)}%</Text>
        ),
      }),
      {
        id: "Estimated",
        header: "EstimatedCost",
        enableEditing: false,
        Cell: ({ row }) => (
          <NumberCell
            value={row.original.weight * costRule * 0.01}
            currency="USD"
          />
        ),
      },
    ]
  }, [total, vdDict, costRule, validationErrors, form])

  const handleDelete = (row: ReallocationRule) => {
    form.setFieldValue(
      `${prefixForm}.reAllocationParams.partitions`,
      allocationWeights.filter((r: { label: string }) => r.label != row.label),
    )
  }

  const handleCreate: MRT_TableOptions<ReallocationRule>["onCreatingRowSave"] =
    async ({ exitCreatingMode, values }) => {
      const newLine = {
        label: values.label,
        weight: +values.weight,
      }

      // Check if the new label is a duplicate only if it is different from the current labels
      if (allocatedLabel.includes(newLine.label)) {
        showNotification({
          title: "Duplicate not allowed",
          message: "Label already exists!",
          color: "red",
        })
        return
      }

      const validationResult = reallocationRule.safeParse(newLine)
      if (validationResult.success) {
        setIsAddDisabled(false)
        form.setFieldValue(`${prefixForm}.reAllocationParams.partitions`, [
          ...allocationWeights,
          newLine,
        ])
        setValidationErrors([])
        exitCreatingMode()
      } else {
        setValidationErrors(validationResult.error.errors)
      }
    }

  const handleEdit: MRT_TableOptions<ReallocationRule>["onEditingRowSave"] =
    async ({ exitEditingMode, values, row }) => {
      const newLine = {
        label: values.label,
        weight: +values.weight,
      }
      if (
        allocatedLabel.includes(newLine.label) &&
        newLine.label != row.original.label
      ) {
        showNotification({
          title: "Duplicate not allowed",
          message: "Label already exists!",
          color: "red",
        })
        return
      }

      const validationResult = reallocationRule.safeParse(newLine)
      if (validationResult.success) {
        setIsAddDisabled(false)
        form.setFieldValue(
          `${prefixForm}.reAllocationParams.partitions`,
          allocationWeights.map((item) =>
            item.label === row.original.label ? newLine : item,
          ),
        )
        setValidationErrors([])
        exitEditingMode()
      } else {
        setValidationErrors(validationResult.error.errors)
      }
    }

  const tableData = useMantineReactTable<ReallocationRule>({
    renderEmptyRowsFallback: () => (
      <Flex justify="center">
        <Text m="md">No records to display</Text>
      </Flex>
    ),
    columns,
    data: allocationWeights,
    enableToolbarInternalActions: false,
    createDisplayMode: "row",
    editDisplayMode: "row",
    enableRowActions: true,
    enableEditing: true,
    enablePagination: false,
    positionActionsColumn: "last",
    onCreatingRowSave: handleCreate,
    onEditingRowSave: handleEdit,
    onCreatingRowCancel: () => {
      setIsAddDisabled(false)
      setValidationErrors([])
    },
    onEditingRowCancel: () => {
      setIsAddDisabled(false)
      setValidationErrors([])
    },
    renderRowActions: ({ row, table }) => (
      <Flex gap="md">
        <ActionIcon onClick={() => handleDelete(row.original)}>
          <IconTrash />
        </ActionIcon>
        <ActionIcon
          disabled={isAddDisabled}
          onClick={() => {
            table.setEditingRow(row)
            setIsAddDisabled(true)
          }}
        >
          <IconEdit />
        </ActionIcon>
      </Flex>
    ),
    renderTopToolbarCustomActions: ({ table }) => (
      <Button
        onClick={() => {
          table.setCreatingRow(true)
          table.setCreatingRow(
            createRow(table, {
              weight: Math.max(0, 100 - total),
              label: availableAllocationLabel[0],
            }),
          )
          setIsAddDisabled(true)
        }}
        disabled={isAddDisabled}
      >
        Add
      </Button>
    ),
  })

  if (!(allocation.allocationType === "splitCost")) return null

  if (allocation.reAllocationParams.type === "custom")
    return (
      <div className="split_cost">
        <MantineReactTable table={tableData} />
      </div>
    )

  return null
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type MantineTableCellProps<TData extends Record<string, any>> = {
  cell: MRT_Cell<TData>
  column: MRT_Column<TData>
  row: MRT_Row<TData>
  table: MRT_TableInstance<TData>
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const SelectCreatableForTable = <TData extends Record<string, any>>({
  initialValue,
  form,
  vdDict,
  validationErrors,
  propsEdit,
}: {
  initialValue: string
  form: UseFormReturnType<VirtualDimensionsRequests.EditVirtualDimension>
  vdDict: string[]
  validationErrors: ZodIssue[]
  propsEdit: MantineTableCellProps<TData>
}) => {
  const [val, setVal] = useState<string>(initialValue)
  const { column, row, table } = propsEdit
  const { getState, setEditingRow, setCreatingRow } = table
  const { editingRow, creatingRow } = getState()
  const isCreating = creatingRow?.id === row.id
  const isEditing = editingRow?.id === row.id
  // copy from https://github.com/KevinVandy/mantine-react-table/discussions/8
  const handleOnChange = (newValue: string) => {
    //@ts-expect-error copy from the repo
    propsEdit.row._valuesCache[column.id] = newValue
    if (isCreating) setCreatingRow(row)
    else if (isEditing) setEditingRow(row)
    setVal(newValue)
  }
  return (
    <>
      <SelectCreatable
        withinPortal
        data={vdDict}
        value={val}
        onChange={handleOnChange}
        handleCreate={(e) => {
          form.insertListItem("values", e)
        }}
        error={_.find(validationErrors, (t) => t.path[0] === "label")?.message}
      />
    </>
  )
}
