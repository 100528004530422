import { BarChart, ChartReferenceLineProps } from "@mantine/charts"
import { Box, Group, Paper, Space, Stack, Text, Tooltip } from "@mantine/core"
import dayjs from "dayjs"
import utc from "dayjs/plugin/utc"
import _ from "lodash"
import {
  createMRTColumnHelper,
  MantineReactTable,
  useMantineReactTable,
} from "mantine-react-table"
import { useMemo } from "react"
import { LabelPosition } from "recharts/types/component/Label"

import { InitiativesResponses } from "@costory/types/endpoints/initiatives"

import { QueryWrapper } from "@costory/front/components/layout/QueryWrapper"
import { NumberCell } from "@costory/front/components/table/NumberCell"
import { useInitiativeSavingsOverviewData } from "@costory/front/queries/initiatives"
import { getCharteSerieColor } from "@costory/front/utils/charts"
import { formatNumber } from "@costory/front/utils/format"
import { PropsWithData } from "@costory/front/utils/propsWithData"
import { getDefaultTableOptions } from "@costory/front/utils/table"

dayjs.extend(utc)
interface SavingsOverviewProps {
  isReadOnly: boolean
  isExisting: boolean
  savedViewId: string
  goLiveTargetDate: Date
  goLiveDate: Date | null
  createdAt: Date
  expectedSavings: number
  snoozeDate: Date | null
  snoozeValue: number | null
}

const _SavingsOverview = ({
  isExisting,
  data,
  goLiveTargetDate,
  createdAt,
  goLiveDate,
  //snoozeDate,
  //snoozeValue,
}: SavingsOverviewProps &
  PropsWithData<InitiativesResponses.GetInitiativeSavingsOverviewData>) => {
  const { chartData, forecast, actuals } = data
  //Generate the series object for all values in actuals
  const series = _(chartData)
    .filter((t) => dayjs.utc(t!.agg_date) < dayjs().utc().subtract(1, "month"))
    .map((t) => _.omit(t, ["forecastedCost", "forecastedCostAfterSavings"]))
    .map((t) => _.keys(t))
    .flatten()
    .uniq()
    .map((key, index) => {
      if (key !== "agg_date") {
        return { name: key, color: getCharteSerieColor(index) }
      } else {
        return null
      }
    })
    .filter((t) => t !== null)
    .value()
  const createdAtReferenceLine = dayjs
    .utc(createdAt)
    .endOf("day")
    .format("MMM YYYY")
  // const snoozeReferenceline = snoozeDate
  //   ? {
  //       label: "Snooze until",
  //       labelPosition: "right",
  //       x: dayjs(snoozeDate).format("MMM YYYY"),
  //       strokeWidth: 1,
  //       stroke: "var(--mantine-color-primary-6)",
  //     }
  //   : snoozeValue
  //     ? {
  //         label: "Snooze until",
  //         labelPosition: "top",
  //         position: "end",
  //         y: snoozeValue,
  //         strokeWidth: 1,
  //         stroke: "var(--mantine-color-primary-6)",
  //       }
  //     : undefined

  const columns = useMemo(() => {
    const columnHelper =
      createMRTColumnHelper<InitiativesResponses.tableSummary>()
    return [
      columnHelper.accessor("label", {
        header: "",
        Cell: ({ row }) =>
          row.original.tooltip ? (
            <Tooltip label={row.original.tooltip}>
              <Text style={{ cursor: "pointer" }}>{row.original.label}</Text>
            </Tooltip>
          ) : (
            <Text>{row.original.label}</Text>
          ),
      }),
      // columnHelper.accessor("cost", {
      //   header: "Forecast",
      //   Cell: ({ row }) =>
      //     row.original.isSavings ? (
      //       <Box
      //         c={row.original.cost > 0 ? "green.6" : "red.6"}
      //         style={{ cursor: "pointer" }}
      //       >
      //         <NumberCell value={row.original.cost} currency="USD" />
      //       </Box>
      //     ) : (
      //       <NumberCell value={row.original.cost} currency="USD" />
      //     ),
      // }),
      columnHelper.accessor("delta", {
        header: "Savings",
        Cell: ({ cell }) =>
          cell.getValue() ? (
            <Box c={cell.getValue()! > 0 ? "green.6" : "red.6"}>
              <NumberCell value={cell.getValue()!} currency="USD" />
            </Box>
          ) : (
            ""
          ),
      }),
    ]
  }, [])

  const columnsInProgress = useMemo(() => {
    const columnHelper =
      createMRTColumnHelper<InitiativesResponses.tableSummary>()
    return [
      columnHelper.accessor("label", {
        header: "Label",
        Cell: ({ row }) =>
          row.original.tooltip ? (
            <Tooltip label={row.original.tooltip}>
              <Text style={{ cursor: "pointer" }}>{row.original.label}</Text>
            </Tooltip>
          ) : (
            <Text>{row.original.label}</Text>
          ),
      }),
      columnHelper.accessor("cost", {
        header: "Actuals",
        Cell: ({ cell }) =>
          cell.getValue() ? (
            <NumberCell value={cell.getValue()!} currency="USD" />
          ) : (
            ""
          ),
      }),
      columnHelper.accessor("forecastRemaining", {
        header: "Remaining Forecasted",
        Cell: ({ cell }) =>
          cell.getValue() ? (
            typeof cell.getValue() === "string" ? (
              <Text>{cell.getValue()}</Text>
            ) : typeof cell.getValue() === "number" ? (
              <NumberCell value={cell.getValue() as number} currency="USD" />
            ) : (
              <Text>N/A</Text>
            )
          ) : (
            ""
          ),
      }),
      // columnHelper.accessor("plan", {
      //   header: "Plan",
      //   Cell: ({ cell }) =>
      //     cell.getValue() ? (
      //       <NumberCell value={cell.getValue()} currency="USD" />
      //     ) : (
      //       ""
      //     ),
      // }),
      columnHelper.accessor("delta", {
        header: "Delta",
        Cell: ({ cell }) =>
          cell.getValue() ? (
            <NumberCell
              numberType="percent"
              value={cell.getValue()!}
              currency="USD"
            />
          ) : (
            ""
          ),
      }),
    ]
  }, [])

  const columnsClosed = useMemo(() => {
    const columnHelper =
      createMRTColumnHelper<InitiativesResponses.tableSummary>()
    return [
      columnHelper.accessor("label", {
        header: "Label",
        Cell: ({ row }) =>
          row.original.tooltip ? (
            <Tooltip label={row.original.tooltip}>
              <Text style={{ cursor: "pointer" }}>{row.original.label}</Text>
            </Tooltip>
          ) : (
            <Text>{row.original.label}</Text>
          ),
      }),
      columnHelper.accessor("cost", {
        header: "Actuals",
        Cell: ({ cell }) =>
          cell.getValue() ? (
            <NumberCell value={cell.getValue()!} currency="USD" />
          ) : (
            ""
          ),
      }),
      columnHelper.accessor("additional", {
        header: "Additional Spend",
        Cell: ({ cell }) =>
          cell.getValue() ? (
            <NumberCell value={cell.getValue()!} currency="USD" />
          ) : (
            ""
          ),
      }),
      columnHelper.accessor("delta", {
        header: "Delta",
        Cell: ({ cell }) =>
          cell.getValue() ? (
            <NumberCell
              numberType="percent"
              value={cell.getValue()!}
              currency="USD"
            />
          ) : (
            ""
          ),
      }),
    ]
  }, [])

  //console.log("goLiveDate", goLiveDate ?? null)
  const forecastTable = useMantineReactTable<InitiativesResponses.tableSummary>(
    {
      data: _.filter(forecast, (t) => !t.hidden),
      columns,
      enablePagination: false,
      ...getDefaultTableOptions(),
    },
  )
  const actualsTable = useMantineReactTable<InitiativesResponses.tableSummary>({
    data: _.filter(actuals, (t) => !t.hidden),
    columns:
      goLiveDate && dayjs.utc().isAfter(dayjs.utc(goLiveDate))
        ? columnsClosed
        : columnsInProgress,
    enablePagination: false,
    ...getDefaultTableOptions(),
  })

  const goLiveDateReferenceLine = goLiveDate
    ? {
        label: "Actual Go Live",
        labelPosition: "bottom" as LabelPosition,
        position: "start",
        x: dayjs
          .utc(goLiveDate)
          .add(1, "month")
          .startOf("month")
          .format("MMM YYYY"),
        strokeWidth: 3,
        stroke: "var(--mantine-color-primary-6)",
      }
    : undefined
  const costGoal = _.find(forecast, (t) => {
    return t.id === "COST_1_MONTH"
  })!.cost
  const showForecastTab = !goLiveDate || dayjs(goLiveDate).isAfter(dayjs())

  return (
    <>
      <Group grow align="stretch" h="100%">
        {showForecastTab && (
          <Paper h="100%" withBorder={false}>
            <Text fw={700}> Forecast</Text>
            <Space h={6} />
            <MantineReactTable table={forecastTable} />
          </Paper>
        )}
        {isExisting && (
          <Paper h="100%" withBorder={false}>
            <Text fw={700}> Actuals</Text>
            <Space h={6} />
            <MantineReactTable table={actualsTable} />
          </Paper>
        )}
      </Group>
      <Paper component={Stack} align="stretch" gap={16} h="100%">
        <BarChart
          type="stacked"
          h={250}
          data={chartData.map((el) => ({
            ...el,
            agg_date: dayjs(el.agg_date).format("MMM YYYY"),
          }))}
          valueFormatter={(value) =>
            formatNumber(value, "currency", undefined, "USD")
          }
          referenceLines={[
            {
              label: "Initiative Created",
              labelPosition: "bottom",
              position: "start",
              x: createdAtReferenceLine,
              strokeWidth: 2,
              stroke: "var(--mantine-color-dark-5)",
            },
            {
              label: "Go Live Target",
              labelPosition: "top",
              position: "start",
              x: dayjs
                .utc(goLiveTargetDate)
                .add(1, "month")
                .startOf("month")
                .format("MMM YYYY"),
              strokeWidth: 2,
              stroke: "var(--mantine-color-dark-5)",
            },
            {
              label: "EOY",
              labelPosition: "top",
              position: "end",
              x: dayjs.utc(goLiveTargetDate).endOf("year").format("MMM YYYY"),
              strokeWidth: 1,
              stroke: "var(--mantine-color-primary-6)",
            },
            {
              label: "Cost Goal",
              labelPosition: "top",
              position: "end",
              y: costGoal,
              strokeWidth: 2,
              stroke: "var(--mantine-color-indigo-4)",
            },
            ...(goLiveDate
              ? [goLiveDateReferenceLine! as ChartReferenceLineProps]
              : []),
            //...(snoozeReferenceline ? [snoozeReferenceline] : []),
          ]}
          series={[
            {
              name: "forecastedCost",
              color: "var(--mantine-color-primary-3)",
              label: "Incremental cost forecast",
            },
            {
              name: "forecastedCostAfterSavings",
              color: "var(--mantine-color-primary-2)",
              label: "Forecasted Cost After Savings",
            },
            ...series,
          ]}
          dataKey="agg_date"
        />
      </Paper>
    </>
  )
}

export const SavingsOverview = (props: SavingsOverviewProps) => {
  const savingsOverviewDataQuery = useInitiativeSavingsOverviewData({
    savedViewId: props.savedViewId,
    goLiveTargetDate: dayjs(props.goLiveTargetDate).toDate(),
    createdAt: dayjs(props.createdAt).toDate(),
    expectedSavings: props.expectedSavings,
    goLiveDate: dayjs(props.goLiveDate).toDate() || null,
  })

  return (
    <QueryWrapper query={savingsOverviewDataQuery}>
      {({ data }) => {
        return <_SavingsOverview data={data} {...props} />
      }}
    </QueryWrapper>
  )
}
