import { showNotification } from "@mantine/notifications"
import { useMutation } from "@tanstack/react-query"

import {
  AnalyzeRequests,
  AnalyzeResponses,
} from "@costory/types/endpoints/analyze"

import { apiClient } from "@costory/front/lib/apiClient"

export const useMutateAnalyze = () => {
  return useMutation({
    mutationKey: ["analyze"],
    mutationFn: async (data: AnalyzeRequests.Report) => {
      const response = await apiClient.post<AnalyzeResponses.Report>(
        "/analyze/report",
        data,
      )
      return response.data
    },
    onError: (error) => {
      showNotification({
        title: "Error",
        message: `An error occurred while analyzing the data ${error}`,
        color: "red",
      })
    },
  })
}
