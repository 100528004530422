import { useClerk } from "@clerk/clerk-react"
import { Group, Paper, Stack } from "@mantine/core"
import { useParams } from "react-router-dom"

import { SavedViewsResponses } from "@costory/types/endpoints/savedViews"

import { ChartContainer } from "@costory/front/components/ChartContainer"
import { Chart } from "@costory/front/components/charts/Chart"
import { ChartType } from "@costory/front/components/dashboard/constants"
import { useChartContext } from "@costory/front/contexts/ChartContext"

type Props = {
  savedView?: SavedViewsResponses.GetSavedViewDetails
  chartTypeInput: ChartType
}

// Keep this as a separate component to make eslint happy:
// "React Hooks must be called in a React function component or a custom React Hook function  react-hooks/rules-of-hooks"
const ChartContent = ({ chartTypeInput }: { chartTypeInput: ChartType }) => {
  const { filters } = useChartContext()
  return (
    <Stack>
      <Group flex={1} align="stretch" h={400}>
        <Paper flex={1} px={32} py={24}>
          <Stack h={400} justify="center">
            <Chart embed={true} filters={filters} chartType={chartTypeInput} />
          </Stack>
        </Paper>
      </Group>
    </Stack>
  )
}

const Embed = ({ savedView, chartTypeInput }: Props) => (
  <ChartContainer
    savedView={savedView}
    redirectPage="explorer"
    key={savedView ? savedView.id : "no-view"}
    embed={true}
  >
    <ChartContent chartTypeInput={chartTypeInput} />
  </ChartContainer>
)

export const EmbedPage = () => {
  const clerk = useClerk()
  const { orgId, chartType } = useParams()
  clerk.setActive({ organization: orgId })
  return <Embed chartTypeInput={(chartType as ChartType) || "TREND"} />
}
