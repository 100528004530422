import { UseFormReturnType } from "@mantine/form"
import dayjs, { Dayjs } from "dayjs"
import _ from "lodash"
import qs from "qs"
import { formatQuery, add } from "react-querybuilder"
import { v4 as uuidv4 } from "uuid"

import { AxesResponses } from "@costory/types/endpoints/axes"
import {
  DigestResponses,
  DigestType,
  Position,
} from "@costory/types/endpoints/digest"

import { computePresetDates } from "@costory/shared/utils/filters"

import { formatNumber } from "@costory/front/utils/format"

export const getDefaultRootElem = () => {
  return {
    id: uuidv4(),
    parentId: null,
    position: [],
    direction: "Overall Spend",
    displayOptions: {
      text: "",
      displayContext: false,
      orderByColumn: "absChange",
      order: "desc",
    },
    imgUrl: null,
    filtersUrl: null,
  } as DigestType["items"][0]
}
export type NodeTree = {
  value: string
  label: string
  nodeProps: DigestType["items"][0]
  children: NodeTree[]
  isOthers: boolean
  data: {
    costPrevious: number
    costCurrent: number
    minDate?: Dayjs
    maxDate?: Dayjs
  }

  othersValues?: DigestResponses.Digest["items"][0]["position"][]
}
export function getChild(
  parent: { key: string; value: string }[],
  filtered: DigestResponses.Investigation,
  level: number = 1,
) {
  return filtered.filter((elem) => {
    return (
      parent.every((p) =>
        elem.arr.some((c) => c.key === p.key && c.value === p.value),
      ) && (level == -1 ? true : elem.arr.length === level + parent.length)
    )
  })
}

export function comparator(val: Position): string {
  return JSON.stringify(_.orderBy(val, (d) => d.key))
}

export function convertToTree(
  nodes: DigestType["items"],
  getDigestData: Map<string, DigestResponses.Investigation[0]>,
) {
  const nodesMap = new Map<string, NodeTree>()
  nodes.forEach((node) => {
    const data = getDigestData.get(comparator(node.position))
    nodesMap.set(node.id, {
      children: [],
      isOthers: false,
      label: node.id,
      value: node.id,
      nodeProps: node,
      data: {
        costCurrent: data?.cost_test || 0.0,
        costPrevious: data?.cost_previous || 0.0,
        minDate: data?.minDate ? dayjs(data.minDate) : undefined,
        maxDate: data?.maxDate ? dayjs(data.maxDate) : undefined,
      },
    })
  })
  nodes.forEach((node) => {
    const parent = node.parentId
    if (parent) {
      nodesMap.get(parent)?.children.push(nodesMap.get(node.id)!)
    }
  })
  nodes.forEach((node) => {
    const nodeData = nodesMap.get(node.id)
    if (nodeData?.children.length && nodeData.nodeProps.parentId) {
      const grouped = _(nodeData?.children)
        .groupBy((d) => d.nodeProps.direction)
        .map((vals, direction) => {
          const parentNode = getDigestData.get(
            comparator(nodeData.nodeProps.position),
          )
          if (parentNode) {
            const dataChildNodes = vals
              .map((el) => getDigestData.get(comparator(el.nodeProps.position)))
              .filter((el) => !!el)
            return {
              parentId: nodeData.nodeProps.id,
              positions: vals.map((el) => el.nodeProps.position),
              direction,
              diff: parentNode.diff - _.sumBy(dataChildNodes, (d) => d.diff),
              previousCost:
                (parentNode.cost_previous || 0.0) -
                _.sumBy(dataChildNodes, (d) => d.cost_previous),
              testCost:
                (parentNode.cost_test || 0.0) -
                _.sumBy(dataChildNodes, (d) => d.cost_test),
            }
          }
          if (node.position.length === 0) {
            return { direction }
          }
          return { direction }
        })
        .value()
      const getOtherTree = (
        direction: string,
        costCurrent: number,
        costPrevious: number,
        parentPosition: Position,
        positiions: Position[],
      ) =>
        ({
          label: Math.random().toString(),
          value: Math.random().toString(),
          isOthers: true,
          othersValues: positiions,
          nodeProps: {
            id: "",
            position: parentPosition,
            parentId: "",
            direction,
            displayOptions: {
              text: "",
              displayContext: false,
              orderByColumn: "absChange",
              order: "desc",
            },
            imgUrl: null,
            filtersUrl: null,
          },
          children: [],
          data: {
            costCurrent,
            costPrevious,
          },
        }) as NodeTree
      grouped
        .map((el) => {
          return (
            el.testCost &&
            getOtherTree(
              el.direction,
              el.testCost,
              el.previousCost,
              nodesMap.get(el.parentId!)?.nodeProps.position || [],
              el.positions,
            )
          )
        })
        .filter((el) => !!el)
        .forEach((el) => nodeData?.children.push(el))
    }
  })
  nodes.forEach((node) => {
    const parent = node.parentId
    if (parent) {
      const parentNode = nodesMap.get(parent!)

      if (parentNode) {
        const col = parentNode.nodeProps.displayOptions.orderByColumn
        parentNode.children = _.orderBy(
          parentNode.children,
          (d) => {
            const node = getDigestData.get(comparator(d.nodeProps.position))
            if (!node) {
              return -1
            }
            return col === "absChange"
              ? Math.abs(node.diff)
              : col === "cost"
                ? Math.abs(node.cost_test || 0.00001)
                : Math.abs(
                    node.diff / ((node.cost_previous || 0.0000001) + 0.0000001),
                  )
          },
          parentNode.nodeProps.displayOptions.order,
        )
      }
    }
  })
  const parentNodes = nodes.filter((el) => !el.parentId).map((el) => el.id)
  return parentNodes.map((el) => nodesMap.get(el)).filter((el) => !!el)
}

export function getQuery(node: NodeTree) {
  const bestGroupBy =
    node.children.map((el) => el.nodeProps.direction)[0] ?? "cos_provider"
  let query = { combinator: "and", rules: [] }
  const position = node.nodeProps.position as Position
  position.forEach((el) => {
    query = add(
      query,
      {
        id: uuidv4(),
        field: el.key,
        operator: "in",
        value: [el.value],
      },
      [],
    )
  })
  const queryFormatted =
    node.nodeProps.position.length !== 0
      ? qs.stringify(
          {
            metricId: "cost",
            currency: "USD",
            limit: 10,
            aggBy: "Day",
            groupBy: bestGroupBy,
            ...computePresetDates("LAST_MONTH"),
            whereClause: encodeURIComponent(
              formatQuery(query, "json_without_ids"),
            ),
          },
          {
            encode: false,
          },
        )
      : null
  return { queryFormatted }
}

export function deleteChild(id: string, form: UseFormReturnType<DigestType>) {
  const nodes = form.getValues().items
  const idx = nodes.findIndex((el) => el.id === id)
  form.removeListItem("items", idx)
  // if parent has no child and is a top --> delete as well:
  const parentId = nodes[idx].parentId
  const nodesAfterDeletion = form.getValues().items
  const allChilds = nodesAfterDeletion.filter(
    (el) => el.parentId == parentId,
  ).length
  if (
    allChilds === 0 &&
    parentId &&
    !nodesAfterDeletion.find((el) => el.id === parentId)?.parentId
  ) {
    deleteChild(parentId, form)
  }
}

export function getOptions(
  data: DigestResponses.Investigation,
  parent: { key: string; value: string }[],
) {
  const optionsAvailable = _(getChild(parent, data, 1))
    .groupBy(
      (d) =>
        d.arr.filter((k) => parent.map((l) => l.key).indexOf(k.key) == -1)[0]
          .key,
    )
    .map((values, key) => {
      return {
        direction: key,
        totalDiff: Math.round(
          _.sum(
            values
              .filter(
                (el) => _.sum(el.arr.map((e) => e.value === "__NULL_")) == 0,
              )
              .map((el) => el.diff),
          ),
        ),
        nbrValue: values.length,
      }
    })
    .value()
  const filtered = _(optionsAvailable)
    .orderBy((d) => d.totalDiff, "desc")
    .value()
  return filtered
}

export function addChild(
  form: UseFormReturnType<DigestType>,
  direction: string,
  data: DigestResponses.Investigation,
  parentId: string | null,
  expandTree: () => void,
) {
  const nodes = form.getValues().items
  const childs = getChild(
    parentId == null
      ? []
      : (nodes.filter((el) => el.id === parentId)[0].position ?? []),
    data,
    1,
  ).filter((elem) => elem.arr.map((e) => e.key).indexOf(direction) > -1)

  childs.forEach((el) => {
    form.insertListItem("items", {
      id: uuidv4(),
      displayOptions: {
        text: "",
        displayContext: false,
        orderByColumn: "absChange",
        order: "desc",
      },
      direction,
      position: el.arr,
      parentId,
      imgUrl: null,
      filtersUrl: null,
    } as DigestType["items"][0])
  })
  expandTree()
}

export function deleteAllChilds(
  value: string,
  form: UseFormReturnType<DigestType>,
) {
  const nodes = form.getValues().items
  const toKeep = nodes.filter((el) => el.parentId != value)
  form.setValues({ items: toKeep })
}

export function treeToBulletPoints(
  node: NodeTree,
  mapper: Map<string, string>,
  dateThreshold: string,
  depth = 0,
) {
  let bulletPoints = ""

  // Indentation based on depth
  const indentation = "\t".repeat(depth)
  const dateCheck = detectOldNew(node, dateThreshold)
  // Add the current node as a bullet point
  const label = formatSlice(node, node.nodeProps.direction, mapper, true)
  const { queryFormatted } = getquery(node)
  const link = "[Open](https://app.costory.io/explorer?" + queryFormatted + ")"
  const text = queryFormatted ? link : ""
  bulletPoints +=
    `${indentation}- ${label} ` +
    text +
    `${dateCheck.conditionDropped ? ` (Dropped Resource last seen ${node.data.maxDate} first seen ${node.data.minDate}) ` : ""}` +
    ` ${dateCheck.conditionNew ? ` ( New Resource first seen ${node.data.minDate} last seen ${node.data.maxDate}) ` : ""}`
  bulletPoints += node.nodeProps.imgUrl
    ? `![img](${node.nodeProps.imgUrl})`
    : ""
  bulletPoints += "\n"
  node.children.forEach((child) => {
    bulletPoints += treeToBulletPoints(child, mapper, dateThreshold, depth + 1)
  })

  return bulletPoints
}

export function detectOldNew(node: NodeTree, thresholdMin: string) {
  const conditionNew =
    node.data.minDate && node.data.minDate > dayjs(thresholdMin)
  const conditionDropped =
    node.data.maxDate && node.data.maxDate < dayjs(thresholdMin)
  return {
    conditionNew,
    conditionDropped,
    minDate: node.data.minDate,
    maxDate: node.data.maxDate,
  }
}

export function formatSlice(
  node: NodeTree,
  direction?: string,
  mapper?: Map<string, string>,
  showText?: boolean,
  hideDiff?: boolean,
) {
  const label = `: ${formatNumber(node.data.costPrevious)} -> ${formatNumber(node.data.costCurrent)}`
  const directionLabel = mapper
    ? mapper.get(direction!) || direction
    : direction
  const relativeDiff = formatNumber(
    (node.data.costCurrent - node.data.costPrevious) / node.data.costPrevious,
    "percent",
    1,
  )
  const diffFormatted = formatNumber(
    node.data.costCurrent - node.data.costPrevious,
  )
  if (node.isOthers) {
    return `${directionLabel} others: ${diffFormatted} ${label || ""} ${showText ? node.nodeProps.displayOptions.text : ""}`
  }
  if (node.nodeProps.position.length == 0) {
    return `${directionLabel} ${label || ""} ${showText ? node.nodeProps.displayOptions.text : ""}`
  }
  if (direction) {
    const axis = node.nodeProps.position.filter(
      (el: Position[0]) => el.key === direction,
    )[0].value
    return `${directionLabel}: ${axis}:  ${diffFormatted} ${!hideDiff ? `(\` ${relativeDiff}\`)` : ""} ${label || ""} ${showText ? node.nodeProps.displayOptions.text : ""}`
  }
}

export const getReactQueryBuilderQuery = (el: Position[0]) =>
  el.value != "__NULL_"
    ? {
        field: el.key,
        operator: "in",
        value: [el.value],
      }
    : { field: el.key, operator: "null", value: null }

export function getquery(node: NodeTree) {
  const bestGroupBy =
    node.children.map((el) => el.nodeProps.direction)[0] ?? "cos_provider"
  let query = { combinator: "and", rules: [] }
  node.nodeProps.position.forEach((el: Position[0]) => {
    query = add(query, getReactQueryBuilderQuery(el), [])
  })
  const queryFormatted =
    node.nodeProps.position.length !== 0
      ? qs.stringify(
          {
            metricId: "cost",
            currency: "USD",
            limit: 10,
            aggBy: "Day",
            groupBy: bestGroupBy,
            ...computePresetDates("LAST_MONTH"),
            whereClause: encodeURIComponent(
              formatQuery(query, "json_without_ids"),
            ),
          },
          {
            encode: false,
          },
        )
      : null
  return { queryFormatted }
}

export function formatMarkdown(
  form: UseFormReturnType<DigestType>,
  axes: AxesResponses.Axis[],
  digestData: Map<string, DigestResponses.Investigation[0]>,
  dateThreshold: string,
) {
  const mapper = new Map<string, string>()
  axes.forEach((elem) => mapper.set(elem.name, elem.label))
  const data = convertToTree(form.getValues().items, digestData)
  return data.map((el) => treeToBulletPoints(el, mapper, dateThreshold))
}

export const replaceDigestItem = (
  form: UseFormReturnType<DigestType>,
  value: Partial<DigestType["items"][0]>,
  id: string,
) => {
  const items = form.getValues().items
  const index = items.findIndex((el) => el.id == id)
  form.replaceListItem("items", index, { ...items[index], ...value })
}
