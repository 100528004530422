import { Tooltip } from "@mantine/core"

import { mediumGrayBorder } from "@costory/front/pages/Budgets/consts"

interface SectionHeadProps {
  name: string
}

export const SectionHead = ({ name }: SectionHeadProps) => (
  <Tooltip label={name} position="top">
    <th
      style={{
        borderRight: mediumGrayBorder,
        padding: "4px 8px",
        maxWidth: 120,
        textOverflow: "ellipsis",
        overflow: "hidden",
        textWrap: "nowrap",
        textAlign: "left",
      }}
    >
      {name}
    </th>
  </Tooltip>
)
