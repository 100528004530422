import {
  Stack,
  InputWrapper,
  Select,
  Group,
  List,
  Button,
  Text,
} from "@mantine/core"
import { UseFormReturnType } from "@mantine/form"
import { IconCheck, IconX, IconArrowLeft } from "@tabler/icons-react"
import dayjs from "dayjs"
import _ from "lodash"
import Papa from "papaparse"
import { useEffect } from "react"

import {
  CsvDatasourceFormData,
  CSVOrientation,
} from "@costory/types/endpoints/metricsDatasources"

interface CsvColumnMappingFormProps {
  form: UseFormReturnType<CsvDatasourceFormData>
  handleClickImport: () => void
  handleClickBack: () => void
}

export const CsvColumnMappingForm = ({
  form,
  handleClickBack,
  handleClickImport,
}: CsvColumnMappingFormProps) => {
  const {
    orientation,
    csvData: csvString,
    date,
    metricNames,
  } = form.getValues() as CsvDatasourceFormData

  // Reset values when getting to this step
  useEffect(() => {
    form.setValues((values) => ({
      ...values,
      orientation: undefined,
      date: "",
      metricNames: "",
      metricValues: "",
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { data: csvData } = Papa.parse(csvString)

  const availableHeaders = csvData[0] as string[]

  const foundMetrics = form.isValid()
    ? orientation === CSVOrientation.Columns
      ? availableHeaders.filter((header) => header !== date)
      : _.uniq(
          Papa.parse<Record<string, string>>(csvString, {
            header: true,
          }).data.map((row) => row[metricNames!]),
        )
    : []
  return (
    <Stack justify="space-between" h="100%">
      <Stack>
        <InputWrapper label={<Text fw="bold">Data orientation</Text>}>
          <Select
            allowDeselect={false}
            {...form.getInputProps("orientation")}
            data={Object.values(CSVOrientation)}
            placeholder="Select data orientation"
          />
        </InputWrapper>
        <InputWrapper
          label={<Text fw="bold">Which column contains the dates?</Text>}
        >
          <Select
            {...form.getInputProps("date")}
            data={availableHeaders}
            allowDeselect={false}
            placeholder="Select a column"
          />
        </InputWrapper>
        {orientation === CSVOrientation.Rows && (
          <>
            <InputWrapper
              label={
                <Text fw="bold">Which column contains the metric names?</Text>
              }
            >
              <Select
                {...form.getInputProps("metricNames")}
                data={availableHeaders}
                allowDeselect={false}
                placeholder="Select a column"
              />
            </InputWrapper>
            <InputWrapper
              label={
                <Text fw="bold">Which column contains the metric values?</Text>
              }
            >
              <Select
                {...form.getInputProps("metricValues")}
                data={availableHeaders}
                allowDeselect={false}
                placeholder="Select a column"
              />
            </InputWrapper>
          </>
        )}
        {form.isValid() && (
          <Stack>
            {form.values.date && (
              <Stack>
                <Text>Date column preview:</Text>
                {Papa.parse<Record<string, string>>(csvString, {
                  header: true,
                })
                  .data.slice(0, 3)
                  .map((row, i) => {
                    const dateValue = row[form.values.date]
                    const isValid =
                      dayjs(dateValue, "YYYY-MM-DD", true).isValid() ||
                      dayjs(dateValue, "YYYY/MM/DD", true).isValid()
                    return (
                      <Group key={i}>
                        <Text c={isValid ? "green" : "red"}>{dateValue}</Text>
                        {isValid ? (
                          <Group>
                            <IconCheck
                              size={16}
                              color="var(--mantine-color-green-6)"
                            />
                            <Text size="xs" c="dimmed">
                              Valid
                            </Text>
                          </Group>
                        ) : (
                          <Group>
                            <IconX
                              size={16}
                              color="var(--mantine-color-red-6)"
                            />
                            <Text size="xs" c="red">
                              Invalid: must be YYYY-MM-DD or YYYY/MM/DD
                            </Text>
                          </Group>
                        )}
                      </Group>
                    )
                  })}
              </Stack>
            )}
            <Text>We found the following metrics</Text>
            <List>
              {foundMetrics.map((metric) => (
                <List.Item key={metric}>{metric}</List.Item>
              ))}
            </List>
          </Stack>
        )}
      </Stack>
      <Group justify="space-between">
        <Button
          onClick={handleClickBack}
          variant="outline"
          leftSection={<IconArrowLeft />}
        >
          Back
        </Button>
        <Button onClick={handleClickImport} disabled={!form.isValid()}>
          Next
        </Button>
      </Group>
    </Stack>
  )
}
