import { useState, useEffect } from "react"

const AWS_REGIONS_URL = "https://ip-ranges.amazonaws.com/ip-ranges.json"

export const useAwsRegions = () => {
  const [regions, setRegions] = useState<string[]>([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<string | null>(null)

  useEffect(() => {
    const fetchRegions = async () => {
      try {
        const response = await fetch(AWS_REGIONS_URL)
        if (!response.ok) throw new Error("Failed to fetch AWS regions")
        const data = await response.json()

        const allRegions: string[] = data.prefixes.reduce(
          (acc: string[], { region }: { region: string }) => {
            if (region.includes("eu-")) acc.push(region)
            return acc
          },
          [],
        )

        const uniqueRegions = [...new Set(allRegions)]

        setRegions(uniqueRegions.sort())
      } catch (err) {
        setError(err instanceof Error ? err.message : "Unknown error")
      } finally {
        setLoading(false)
      }
    }

    fetchRegions()
  }, [])

  return { regions, loading, error }
}
