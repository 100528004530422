import {
  ActionIcon,
  Box,
  Button,
  Group,
  Stack,
  Text,
  Title,
  Tooltip,
} from "@mantine/core"
import { openConfirmModal } from "@mantine/modals"
import { showNotification } from "@mantine/notifications"
import {
  IconArrowRight,
  IconEdit,
  IconExternalLink,
  IconTargetOff,
  IconTrash,
} from "@tabler/icons-react"
import _ from "lodash"
import {
  createMRTColumnHelper,
  MantineReactTable,
  useMantineReactTable,
} from "mantine-react-table"
import { Link, useNavigate } from "react-router-dom"

import { InitiativesResponses } from "@costory/types/endpoints/initiatives"

import { useUser } from "@costory/front/components/auth/UserContext"
import { QueryWrapper } from "@costory/front/components/layout/QueryWrapper"
import { NumberCell } from "@costory/front/components/table/NumberCell"
import {
  useDeleteInitiativeMutation,
  useInitiativesListPageQuery,
} from "@costory/front/queries/initiatives"
import { PropsWithData } from "@costory/front/utils/propsWithData"
import { getDefaultTableOptions } from "@costory/front/utils/table"

const EmptyInitiativesList = () => (
  <Stack align="center" pt="10vh">
    <IconTargetOff size="8vh" />
    <Title>There are no initiatives yet.</Title>
    <Text>Create a new initiative from an existing explorer view</Text>
    <Button component={Link} to="/reports" rightSection={<IconArrowRight />}>
      Go to reports
    </Button>
  </Stack>
)

const InitiativeList = ({
  data: initiatives,
}: PropsWithData<InitiativesResponses.InitiativesListPage>) => {
  const navigate = useNavigate()
  const { mutateAsync: deleteInitiative } = useDeleteInitiativeMutation()
  const user = useUser()
  const columnHelper =
    createMRTColumnHelper<InitiativesResponses.InitiativeListPageItem>()
  const columns = [
    // columnHelper.accessor("code", {
    //   header: "Code",
    //   Cell: ({ cell }) => `CSTRY-${cell.getValue()}`,
    // }),
    columnHelper.accessor("name", {
      header: "Name",
    }),
    columnHelper.accessor("expectedMonthlySavings", {
      header: "Estimated Yearly savings",
      Cell: ({ row }) => {
        const value = row.original.costData
          ? _.find(
              row.original.costData.forecast,
              (t) => t.id === "SAVINGS_12_MONTHS",
            )!.delta
          : 0
        return (
          <Box c={value! > 0 ? "green.6" : "red.6"}>
            <NumberCell value={value!} currency="USD" />
          </Box>
        )
      },
    }),
    columnHelper.accessor("status", {
      header: "Status",
      filterVariant: "select",
    }),
    columnHelper.accessor("effort", {
      header: "Effort",
      filterVariant: "select",
    }),
    // @ts-expect-error doesn't support computed fields
    columnHelper.accessor("owner.fullName", {
      header: "Owner",
      Cell: ({ cell }) => cell.getValue() || "No owner",
    }),
    //@ts-expect-error doesn't support computed fields
    columnHelper.accessor("createdBy.fullName", {
      header: "Created by",
      Cell: ({ cell }) => cell.getValue() || "No owner",
    }),
  ]

  const handleClickDelete = async (initiativeId: string) => {
    openConfirmModal({
      title: <Title order={2}>Are you sure?</Title>,
      confirmProps: { color: "red" },
      children: "Do you really want to delete this initiative?",
      labels: {
        cancel: "Cancel",
        confirm: "Delete",
      },
      onConfirm: async () => {
        try {
          await deleteInitiative(initiativeId)
          showNotification({
            message: "Initiative deleted",
            color: "green",
          })
        } catch (error) {
          showNotification({
            message: error as string,
            color: "red",
          })
        }
      },
    })
  }

  const table =
    useMantineReactTable<InitiativesResponses.InitiativeListPageItem>({
      columns,
      data: initiatives,
      ...getDefaultTableOptions(),
      enableRowActions: true,
      initialState: {
        columnVisibility: {
          name: true,
          expectedMonthlySavings: true,
          effort: true,
          status: true,
          owner: true,
          createdBy: false,
        },
      },
      renderRowActions: ({ row: { original: initiative } }) => {
        const isAllowedToEdit = user.id === initiative.createdById
        return (
          <Group wrap="nowrap">
            <Tooltip
              label={
                isAllowedToEdit ? "Edit" : "Only the owner is allowed to edit"
              }
            >
              <ActionIcon
                onClick={() => navigate(initiative.id)}
                disabled={!isAllowedToEdit}
              >
                <IconEdit />
              </ActionIcon>
            </Tooltip>
            <ActionIcon
              onClick={() => handleClickDelete(initiative.id)}
              disabled={!isAllowedToEdit}
            >
              <IconTrash />
            </ActionIcon>
            <Tooltip label="Go to initiative">
              <ActionIcon onClick={() => navigate(initiative.id)}>
                <IconExternalLink />
              </ActionIcon>
            </Tooltip>
          </Group>
        )
      },
      mantineTableBodyRowProps: ({ row: { original: initiative } }) => ({
        onDoubleClick: () => {
          navigate(initiative.id)
        },
      }),
    })

  if (!initiatives.length) {
    return <EmptyInitiativesList />
  }

  return <MantineReactTable table={table} />
}

export const InitiativeListPage = () => {
  const query = useInitiativesListPageQuery()
  return (
    <QueryWrapper query={query} allowEmptyArray>
      {({ data }) => <InitiativeList data={data} />}
    </QueryWrapper>
  )
}
