import {
  Stack,
  TextInput,
  Group,
  Button,
  Text,
  InputWrapper,
  Select,
} from "@mantine/core"
import { UseFormReturnType } from "@mantine/form"
import { IconArrowLeft, IconArrowRight } from "@tabler/icons-react"

import { AwsDatasourceFormDataWithFileData } from "@costory/types/endpoints/metricsDatasources"

import { useAwsRegions } from "@costory/front/pages/Datasources/components/AWS/useAwsRegions"

const fields = [
  { name: "roleArn", label: "Role ARN", placeholder: "Role ARN" },
  { name: "bucketName", label: "Bucket Name", placeholder: "Bucket Name" },
  {
    name: "filePath",
    label: "File Path (must end with .parquet)",
    placeholder: "File Path",
  },
] as const

export const AwsConfiguration = ({
  form,
  handleClickBack,
  handleClickNext,
}: {
  form: UseFormReturnType<AwsDatasourceFormDataWithFileData>
  handleClickBack: () => void
  handleClickNext: () => void
}) => {
  const validateAndNext = () => {
    if (!form.values.bucketName) {
      form.setFieldError("bucketName", "Bucket name is required")
      return
    }
    if (!form.values.roleArn) {
      form.setFieldError("roleArn", "Role ARN is required")
      return
    }
    if (!form.values.awsRegion) {
      form.setFieldError("awsRegion", "AWS region is required")
      return
    }
    const filePath = form.values.filePath
    if (!filePath?.endsWith(".parquet")) {
      form.setFieldError("filePath", "File path must end with .parquet")
      return
    }
    if (form.isValid()) handleClickNext()
  }
  const { regions } = useAwsRegions()
  return (
    <Stack h="100%">
      <Stack flex={1}>
        {fields.map(({ name, label, placeholder }) => (
          <TextInput
            key={name}
            name={name}
            label={
              <Text fw="bold" span>
                {label}
              </Text>
            }
            placeholder={placeholder}
            autoComplete="on"
            {...form.getInputProps(
              name as keyof AwsDatasourceFormDataWithFileData,
            )}
            required
          />
        ))}
        <InputWrapper
          label={
            <Text fw="bold" span={true}>
              Region
            </Text>
          }
          required
        >
          <Select {...form.getInputProps("awsRegion")} data={regions} />
        </InputWrapper>
      </Stack>
      <Group justify="space-between" pb={20}>
        <Button
          onClick={handleClickBack}
          variant="outline"
          leftSection={<IconArrowLeft />}
        >
          Back
        </Button>
        <Button onClick={validateAndNext} rightSection={<IconArrowRight />}>
          Next
        </Button>
      </Group>
    </Stack>
  )
}
