import {
  Button,
  Group,
  Modal,
  Paper,
  RingProgress,
  Stack,
  Text,
  Textarea,
  Tooltip,
} from "@mantine/core"
import { useForm, UseFormReturnType, zodResolver } from "@mantine/form"
import { useDisclosure } from "@mantine/hooks"
import {
  IconChevronDown,
  IconChevronUp,
  IconPencil,
  IconPlus,
} from "@tabler/icons-react"
import _ from "lodash"
import { useMemo, useState } from "react"
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "react-beautiful-dnd"
import { add, RuleGroupType, RuleType } from "react-querybuilder"
import { v4 as uuid4, v4 as uuidv4 } from "uuid"

import {
  allocationSchema,
  editVirtualDimensionSchema,
  VirtualDimensionRule,
  VirtualDimensionsRequests,
  VirtualDimensionsResponses,
} from "@costory/types/endpoints/virtualDimensions"

import { PrefillExplorerDrawer } from "@costory/front/pages/VirtualDimensions/components/PrefillExplorerDrawer"
import { useRefreshEstimation } from "@costory/front/queries/virtualDimensions"
import { formatNumber } from "@costory/front/utils/format"
import { showError } from "@costory/front/utils/validation"

import { DimensionValuesModal } from "./DimensionValuesModal"
import { Leftovers } from "./Leftovers"
import { RuleEditor } from "./RuleEditor"

const createNewRule = (
  name: string,
  isLeftovers: boolean,
  initialQuery: RuleGroupType = {
    combinator: "and",
    rules: [{ field: "cos_provider", value: [], operator: "in" }],
  },
): VirtualDimensionRule => ({
  name,
  query: initialQuery,
  // @ts-expect-error mantine form doesn't like partial initial values, but we don't wanna fill it in advance
  allocation: {
    allocationType: "dimensionValue",
  },
  isLeftovers,
  id: uuid4(),
})
const getDefaultRule = () => createNewRule("", false)
const LEFTOVERS_RULE = createNewRule("Leftovers", true, {
  combinator: "and",
  rules: [],
})

type InputData =
  | { data: VirtualDimensionsResponses.VirtualDimension; type: "vdim" }
  | {
      type: "form"
      data: VirtualDimensionsRequests.NewDraftVirtualDimensions["data"] & {
        id: string
      }
    }
type Props = {
  inputData?: InputData
  onSave: (vdim: VirtualDimensionsRequests.NewDraftVirtualDimensions) => void
  isSaving: boolean
  onSaveDraft: (
    vdim: VirtualDimensionsRequests.NewDraftVirtualDimensions,
  ) => void
  isSavingDraft: boolean
}

export const VirtualDimensionForm = ({
  inputData,
  onSave,
  isSaving,
  onSaveDraft,
  isSavingDraft,
}: Props) => {
  const [isOpen, { open, close }] = useDisclosure(!inputData)
  const [
    descriptionVersionIsOpen,
    { open: openDescription, close: closeDescription },
  ] = useDisclosure(false)

  const [accordionOpenState, setAccordionOpenState] = useState<boolean[]>(
    inputData ? _.times(inputData.data.rules.length, () => false) : [true],
  )

  const initialValues = useMemo(() => {
    if (inputData && inputData.type == "form") {
      return inputData.data
    }
    if (inputData && inputData.type == "vdim") {
      return {
        name: inputData.data.name,
        values: _.uniq(
          inputData.data.values
            .filter((elem) => elem.length > 0)
            .concat(
              inputData.data.rules
                .map((elem) => allocationSchema.parse(elem.allocation))
                .map((elem) =>
                  elem.allocationType === "dimensionValue"
                    ? [elem.dimensionValue]
                    : elem.allocationType === "splitCost"
                      ? elem.reAllocationParams.partitions.map((el) => el.label)
                      : [],
                )
                .flat(),
            ),
        ),
        rules: inputData.data.rules.filter((r) => !r.isLeftovers),
        leftoverRule: inputData.data.rules.filter((r) => r.isLeftovers)[0],
      }
    }
    return {
      name: "New Dimension Name",
      values: ["Other"],
      rules: [getDefaultRule()],
      leftoverRule: LEFTOVERS_RULE,
    }
  }, [inputData])

  const form = useForm<VirtualDimensionsRequests.EditVirtualDimension>({
    initialValues,
    validate: zodResolver(editVirtualDimensionSchema),
    validateInputOnBlur: true,
    validateInputOnChange: true,
    initialErrors: zodResolver(editVirtualDimensionSchema)(initialValues),
    mode: "uncontrolled",
  })

  const handleMoveRule = (dropResult: DropResult) => {
    const { destination, source } = dropResult
    if (!destination || destination.index === source.index) return
    form.reorderListItem("rules", {
      from: source.index,
      to: destination.index,
    })
    setResultRefresh(undefined)
    form.validate()
  }
  const preFillData = (index: number, groupBy: string, values: string[]) => {
    const newRule: RuleType = {
      id: uuidv4(),
      field: groupBy,
      operator: "in",
      value: values,
    }
    const isLeftOver = index == form.getValues().rules.length

    const formValues = form.getValues()
    const ruleList = isLeftOver
      ? (formValues.leftoverRule.query.rules ?? [])
      : (formValues.rules[index].query.rules ?? [])

    const errorIndex = _.findIndex(
      ruleList,
      (rule) =>
        "value" in rule && (_.isString(rule.value) || _.isEmpty(rule.value)),
    )

    if (index === formValues.rules.length) {
      const query = {
        id: uuidv4(),
        combinator: "and",
        rules: [newRule],
      }
      form.insertListItem("rules", createNewRule("", false, query))
      setAccordionOpenState((prev) => [...prev, true])
    } else {
      const currentQuery = formValues.rules[index].query
      if (errorIndex === -1) {
        form.setFieldValue(
          `rules.${index}.query`,
          add(currentQuery, newRule, []),
        )
      } else {
        const updatedRules = [...currentQuery.rules]
        updatedRules[errorIndex] = newRule
        form.setFieldValue(`rules.${index}.query`, {
          ...currentQuery,
          rules: updatedRules,
        })
      }
    }
    closeDrawer()
    form.validate()
  }

  const [resultRefresh, setResultRefresh] = useState<
    VirtualDimensionsResponses.RefreshEstimationDataResult | undefined
  >(undefined)
  const [indexSelectedDrawer, setIndexSelectedDrawer] = useState<number>(0)

  const { mutateAsync: refreshResult, isPending: isPendingRefreshResult } =
    useRefreshEstimation(setResultRefresh)
  const [drawerExploreIsOpen, { open: openDrawer, close: closeDrawer }] =
    useDisclosure(false)

  const error = showError({ prefix: "", errors: form.errors }).join(", ")

  return (
    <form>
      <Stack gap={24}>
        {/* I have added style here because zIndex doesn't exist as a prop */}
        <Paper pos="sticky" top="-0.5rem" style={{ zIndex: 20 }}>
          <Stack>
            <Group justify="space-between">
              <Text size="xl" fw="bold">
                {form.getValues().name}
              </Text>
              <Group>
                <Text>Estimate cost allocation on last 30 days</Text>
                <Text>
                  {resultRefresh &&
                    formatNumber(resultRefresh.filtered, "currency")}{" "}
                  /{" "}
                  {resultRefresh &&
                    formatNumber(resultRefresh.total, "currency")}{" "}
                </Text>
                <RingProgress
                  size={110}
                  label={
                    <Text ta="center">
                      {resultRefresh &&
                        formatNumber(resultRefresh.percentage, "percent")}
                    </Text>
                  }
                  roundCaps
                  sections={[
                    {
                      value: resultRefresh ? resultRefresh.percentage * 100 : 0,
                      color: "green",
                    },
                  ]}
                />
              </Group>
            </Group>
            <Group gap={8}>
              <Button
                size="xs"
                variant="outline"
                onClick={open}
                leftSection={<IconPencil size={16} />}
              >
                Edit definition
              </Button>
              <Button
                size="xs"
                variant="outline"
                loading={isPendingRefreshResult}
                onClick={() => {
                  refreshResult({
                    rules: [
                      ...form
                        .getValues()
                        .rules.map((elem) => ({ query: elem.query })),
                      { query: form.getValues().leftoverRule.query },
                    ],
                  })
                }}
              >
                Refresh Result
              </Button>
              <Button.Group>
                <Button
                  size="xs"
                  variant="outline"
                  onClick={() =>
                    setAccordionOpenState((prev) => prev.map(() => false))
                  }
                  leftSection={
                    <Stack gap={0}>
                      <IconChevronDown size={12} style={{ marginBottom: -2 }} />
                      <IconChevronUp size={12} style={{ marginTop: -2 }} />
                    </Stack>
                  }
                >
                  Collapse all
                </Button>
                <Button
                  size="xs"
                  variant="outline"
                  onClick={() =>
                    setAccordionOpenState((prev) => prev.map(() => true))
                  }
                  style={{ borderLeft: 0 }}
                  leftSection={
                    <Stack gap={0}>
                      <IconChevronUp size={12} style={{ marginBottom: -2 }} />
                      <IconChevronDown size={12} style={{ marginTop: -2 }} />
                    </Stack>
                  }
                >
                  Expand all
                </Button>
              </Button.Group>
            </Group>
          </Stack>
        </Paper>
        <DragDropContext onDragEnd={handleMoveRule}>
          <Droppable droppableId="rules-dnd-zone" mode="standard">
            {(provided) => (
              <Stack ref={provided.innerRef} {...provided.droppableProps}>
                {form.getValues().rules.map((rule, index) => (
                  <Draggable
                    key={rule.id}
                    draggableId={`rule-${rule.id}`}
                    index={index}
                  >
                    {(dragProvided) => (
                      <Stack
                        ref={dragProvided.innerRef}
                        {...dragProvided.draggableProps}
                      >
                        <RuleEditor
                          exploreCost={() => {
                            setIndexSelectedDrawer(index)
                            openDrawer()
                          }}
                          index={index}
                          dragHandleProps={dragProvided.dragHandleProps}
                          isExpanded={accordionOpenState[index]}
                          allocatedCost={
                            resultRefresh
                              ? resultRefresh.resultRefresh
                                  .filter(
                                    (elem) =>
                                      elem.rule == index && !elem.isLeftOver,
                                  )
                                  .reduce(
                                    (previous, curre) => curre.cost + previous,
                                    0,
                                  )
                              : 0
                          }
                          onToggleAccordion={() =>
                            setAccordionOpenState((prev) => {
                              const newState = [...prev]
                              newState[index] = !newState[index]
                              return newState
                            })
                          }
                          onDuplicate={() => {
                            form.insertListItem(
                              "rules",
                              {
                                ...form.getValues().rules[index],
                                id: uuidv4(),
                              },
                              index + 1,
                            )
                            setAccordionOpenState((prev) => [...prev, true])
                            form.validate()
                          }}
                          key={`rule-${index}`}
                          form={form}
                          ruleIndex={index}
                        />
                      </Stack>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </Stack>
            )}
          </Droppable>
        </DragDropContext>
        <Button
          variant="outline"
          onClick={() => {
            setResultRefresh(undefined)
            form.insertListItem("rules", getDefaultRule())
            form.validate()
            setAccordionOpenState((prev) => [...prev, true])
          }}
        >
          <IconPlus size={20} />
        </Button>
        <Leftovers
          exploreCost={() => {
            setIndexSelectedDrawer(form.getValues().rules.length)
            openDrawer()
          }}
          form={form}
          allocatedCost={
            resultRefresh
              ? resultRefresh.resultRefresh
                  .filter((elem) => elem.isLeftOver)
                  .reduce((previous, curre) => curre.cost + previous, 0)
              : 0
          }
        />
        <Group justify="flex-end">
          <Button
            size="lg"
            loading={isSavingDraft}
            type="button"
            onClick={openDescription}
          >
            Save
          </Button>
        </Group>
      </Stack>
      <DimensionValuesModal form={form} isOpen={isOpen} onClose={close} />
      <ModalSave
        descriptionVersionIsOpen={descriptionVersionIsOpen}
        closeDescription={closeDescription}
        isSavingDraft={isSavingDraft}
        onSaveDraft={onSaveDraft}
        form={form}
        inputData={inputData}
        error={error}
        isSaving={isSaving}
        onSave={onSave}
      />
      <PrefillExplorerDrawer
        preFillData={preFillData}
        index={indexSelectedDrawer}
        opened={drawerExploreIsOpen}
        close={closeDrawer}
        rules={[
          ...form.getValues().rules.map((elem) => ({ query: elem.query })),
          { query: form.getValues().leftoverRule.query },
        ]}
      />
    </form>
  )
}
function ModalSave({
  descriptionVersionIsOpen,
  closeDescription,
  isSavingDraft,
  onSaveDraft,
  form,
  inputData,
  error,
  isSaving,
  onSave,
}: {
  descriptionVersionIsOpen: boolean
  closeDescription: () => void
  isSavingDraft: boolean
  onSaveDraft: (
    vdim: VirtualDimensionsRequests.NewDraftVirtualDimensions,
  ) => void
  form: UseFormReturnType<VirtualDimensionsRequests.EditVirtualDimension>
  inputData: InputData | undefined
  error: string
  isSaving: boolean
  onSave: (vdim: VirtualDimensionsRequests.NewDraftVirtualDimensions) => void
}) {
  const [description, setDescription] = useState("")
  const isButtonDisabled = description.trim() === ""

  return (
    <Modal
      opened={descriptionVersionIsOpen}
      onClose={closeDescription}
      centered
      size="xl"
    >
      <Stack>
        <Textarea
          size="lg"
          radius="md"
          value={description}
          onChange={(txt) => setDescription(txt.target.value)}
          description="Version description"
        />
        <Group justify="center">
          <Button
            size="lg"
            loading={isSavingDraft}
            type="button"
            disabled={isButtonDisabled}
            onClick={() => {
              onSaveDraft({
                data: form.getValues(),
                description,
                virtualDimensionId: inputData ? inputData.data.id : undefined,
              })
            }}
          >
            Save As Draft
          </Button>
          <Tooltip label={`${error} is not valid`} hidden={!error}>
            <Button
              size="lg"
              disabled={!form.isValid() || isButtonDisabled}
              loading={isSaving}
              type="button"
              onClick={() => onSave({ data: form.getValues(), description })}
            >
              Save as completed and exit
            </Button>
          </Tooltip>
        </Group>
      </Stack>
    </Modal>
  )
}
