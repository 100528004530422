import {
  ActionIcon,
  Badge,
  Button,
  Group,
  InputWrapper,
  Loader,
  MultiSelect,
  Paper,
  Select,
  Stack,
  Text,
  TextInput,
  Title,
  Tooltip,
} from "@mantine/core"
import { MonthPickerInput } from "@mantine/dates"
import { useForm, UseFormReturnType, zodResolver } from "@mantine/form"
import { showNotification } from "@mantine/notifications"
import {
  IconBriefcase,
  IconBriefcaseOff,
  IconCheck,
  IconDeviceFloppy,
  IconRotateClockwise2,
} from "@tabler/icons-react"
import { useEffect, useMemo, useState } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"
import { z } from "zod"

import {
  BudgetsRequests,
  BudgetsResponses,
} from "@costory/types/endpoints/budgets"
import { CurrencyOptions } from "@costory/types/filters"
import { BudgetStatus, CostType, Currency } from "@costory/types/prisma-client"

import dayjs from "@costory/shared/dayjs"

import { QueryWrapper } from "@costory/front/components/layout/QueryWrapper"
import { AllocationsRow } from "@costory/front/pages/Budgets/components/AllocationsRow"
import { BudgetTableHead } from "@costory/front/pages/Budgets/components/BudgetTableHead"
import { EmptyBudgetTable } from "@costory/front/pages/Budgets/components/EmptyBudgetTable"
import { FormatedTd } from "@costory/front/pages/Budgets/components/FormatedTd"
import { RevisionRow } from "@costory/front/pages/Budgets/components/RevisionRow"
import { SectionHead } from "@costory/front/pages/Budgets/components/SectionHead"
import {
  BudgetTableMode,
  mediumGrayBorder,
} from "@costory/front/pages/Budgets/consts"
import { BudgetTableContext } from "@costory/front/pages/Budgets/context/BudgetTableContext"
import { useBudgetTableContext } from "@costory/front/pages/Budgets/context/useBudgetTableContext"
import { sumOrNull, sumYTDOrNull } from "@costory/front/pages/Budgets/utils"
import {
  useCreateBudgetMutation,
  useUpdateBudgetMutation,
} from "@costory/front/queries/budgets"
import { useBusinessMetricValueQuery } from "@costory/front/queries/businessMetrics"
import { useVirtualDimensionCostQuery } from "@costory/front/queries/virtualDimensions"

interface ToolbarFormValues
  extends Omit<BudgetsRequests.CreateBudget, "year" | "month" | "lines"> {
  startDate: Date
}

type TableRowFormValues = {
  allocations: number[]
  ownerId: string | null
}
type TableFormValues = Record<string, TableRowFormValues>

export type BudgetFormValues = ToolbarFormValues & {
  lines: TableFormValues
}

export const BudgetTable = ({ formData, versions, mode }: BudgetTableProps) => {
  const [selectedVersionsIds, setSelectedVersionsIds] = useState<string[]>([])
  const lastVersion = versions?.[0]
  const { budgetId } = useParams<{ budgetId?: string }>()
  const navigate = useNavigate()
  const {
    mutateAsync: createBudgetVersion,
    isPending: isCreateBudgetMutationPending,
  } = useCreateBudgetMutation()
  const {
    mutateAsync: updateBudget,
    isPending: isUpdateBudgetMutationPending,
  } = useUpdateBudgetMutation()

  const isFormPending =
    isCreateBudgetMutationPending || isUpdateBudgetMutationPending

  const form = useForm<BudgetFormValues>({
    initialValues: {
      versionName: lastVersion?.versionName ?? "",
      costType: lastVersion?.costType ?? CostType.cost,
      parentBudgetId: budgetId,
      name: lastVersion?.name ?? "",
      tagsIds: lastVersion?.tags.map((t) => t.id) ?? [],
      virtualDimensionId: lastVersion?.virtualDimensionId ?? "",
      startDate: lastVersion
        ? dayjs()
            .year(lastVersion.year)
            .month(lastVersion.startMonth)
            .add(1, "day")
            .startOf("month")
            .toDate()
        : new Date(),
      excludedValues: lastVersion?.excludedValues ?? [],
      currency: lastVersion?.currency ?? Currency.USD,
      status: lastVersion?.status ?? BudgetStatus.DRAFT,
      lines:
        lastVersion?.lines.reduce((acc, line) => {
          return {
            ...acc,
            [line.virtualDimensionValue]: line,
          }
        }, {}) ?? {},
    },
    validate: zodResolver(
      z.object({
        name: z.string().min(3, "Name must be at least 3 characters"),
        startDate: z.date(),
        virtualDimensionId: z.string(),
        tagsIds: z.array(z.string()),
      }),
    ),
  })

  const monthsOffset = form.getValues().startDate.getMonth()

  const { virtualDimensionId, startDate, excludedValues, costType, currency } =
    form.getValues()
  const selectedVirtualDimension = formData.availableVirtualDimensions.find(
    (vd) => vd.id === virtualDimensionId,
  )
  const query = useVirtualDimensionCostQuery({
    id: virtualDimensionId,
    startDate,
    costType,
    currency,
  })

  const onLineChange = (
    values: TableRowFormValues,
    virtualDimensionValue: string,
  ) => {
    form.setFieldValue(`lines.${virtualDimensionValue}`, values)
  }

  // Reset lines when the virtual dimension changes
  useEffect(() => {
    // Prevents resetting lines when the form is initialized
    if (mode !== "create") {
      const currentLines = form.getValues().lines
      form.setFieldValue(
        "lines",
        selectedVirtualDimension?.values.reduce((acc, value) => {
          return {
            ...acc,
            [value]: currentLines[value] || {
              allocations: Array(12).fill(0),
              ownerId: "",
              virtualDimensionValue: value,
            },
          }
        }, {}) ?? {},
      )

      return
    }

    form.setFieldValue(
      "lines",
      selectedVirtualDimension?.values.reduce((acc, value) => {
        return {
          ...acc,
          [value]: {
            allocations: Array(12).fill(0),
            ownerId: "",
            virtualDimensionValue: value,
          },
        }
      }, {}) ?? {},
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedVirtualDimension])

  const availableVersionSelectItems = useMemo(() => {
    return (
      versions?.slice(1).map((version) => ({
        value: version.versionId,
        label:
          version.versionName && version.versionName.length > 0
            ? version.versionName
            : `Revision ${dayjs(version.createdAt).format("DD MMM YYYY hh:mm:ss")}`,
      })) ?? []
    )
  }, [versions])

  const handleClickCreate = async (status: BudgetStatus) => {
    const res = form.validate()
    if (res.hasErrors) {
      return
    }
    const { startDate, lines, ...formValues } = form.getValues()
    const formatedLines = Object.keys(lines).map((key) => ({
      ...lines[key],
      virtualDimensionValue: key,
    }))

    const submittedValues = {
      ...formValues,
      status,
      year: dayjs(startDate).year(),
      month: dayjs(startDate).month(),
      lines: formatedLines,
    }
    try {
      const createdBudget = await createBudgetVersion(submittedValues)
      showNotification({
        title: "Budget created",
        message: "Your budget has been created",
        color: "green",
        position: "top-right",
      })
      navigate(`/budgets/${createdBudget.parentBudgetId}`)
    } catch (error) {
      showNotification({
        title: "An error occured",
        message: error as string,
        color: "red",
        position: "top-right",
      })
    }
  }

  const handleClickSave = async (status: BudgetStatus) => {
    const res = form.validate()
    if (res.hasErrors) {
      return
    }
    const { startDate, lines, ...formValues } = form.getValues()

    const formatedLines = Object.keys(lines).map((key) => ({
      ...lines[key],
      virtualDimensionValue: key,
    }))

    const submittedValues = {
      ...formValues,
      id: budgetId!,
      year: dayjs(startDate).year(),
      month: dayjs(startDate).month(),
      lines: formatedLines,
      status,
    }

    try {
      await updateBudget(submittedValues)
      showNotification({
        title: "Budget updated",
        message: "Your budget has been updated",
        color: "green",
        position: "top-right",
      })
      navigate("/budgets")
    } catch (error) {
      showNotification({
        title: "An error occured",
        message: error as string,
        color: "red",
        position: "top-right",
      })
    }
  }

  const handleClickCreateRevision = async (status: BudgetStatus) => {
    const res = form.validate()
    if (res.hasErrors) {
      return
    }
    const { startDate, lines, ...formValues } = form.getValues()
    const formatedLines = Object.keys(lines).map((key) => ({
      ...lines[key],
      virtualDimensionValue: key,
    }))

    const submittedValues = {
      ...formValues,
      status,
      year: dayjs(startDate).year(),
      month: dayjs(startDate).month(),
      lines: formatedLines,
    }
    try {
      const createdBudget = await createBudgetVersion(submittedValues)
      showNotification({
        title: "Revision created",
        message: "Your revision has been created",
        color: "green",
        position: "top-right",
      })
      navigate(`/budgets/${createdBudget.parentBudgetId}`)
    } catch (error) {
      showNotification({
        title: "An error occured",
        message: error as string,
        color: "red",
        position: "top-right",
      })
    }
  }

  const selectedVersions = useMemo(() => {
    return versions?.filter((version) =>
      selectedVersionsIds?.includes(version.versionId),
    )
  }, [selectedVersionsIds, versions])

  return (
    <BudgetTableContext.Provider
      value={{
        mode,
        formData,
        currency,
        costType,
      }}
    >
      <Stack>
        {mode === BudgetTableMode.View ? (
          <Stack>
            <Group>
              <Group align="end">
                <Title order={2}>
                  {lastVersion!.name} - {dayjs(startDate).format("MMMM YYYY")} /{" "}
                  {dayjs(startDate).add(11, "month").format("MMMM YYYY")}
                </Title>
                {/* @ts-expect-error computed property... */}
                <Text>Owned by: {lastVersion.createdBy.fullName}</Text>
              </Group>
              {lastVersion!.tags.map((tag) => (
                <Badge key={tag.name} color={tag.color}>
                  {tag.name}
                </Badge>
              ))}
            </Group>
            <Group>
              <InputWrapper label="Compare to older versions">
                <MultiSelect
                  data={availableVersionSelectItems}
                  value={selectedVersionsIds}
                  onChange={setSelectedVersionsIds}
                  placeholder="Select versions"
                />
              </InputWrapper>
            </Group>
          </Stack>
        ) : (
          <BudgetToolbar
            form={form}
            availableVersionSelectItems={availableVersionSelectItems}
            selectedVersionsIds={selectedVersionsIds}
            setSelectedVersionsIds={setSelectedVersionsIds}
            lastVersionStatus={
              lastVersion?.parentBudgetId
                ? BudgetStatus.VALIDATED
                : (lastVersion?.status ?? BudgetStatus.DRAFT)
            }
          />
        )}
        {selectedVirtualDimension &&
        selectedVirtualDimension.values.filter(
          (el) => !excludedValues.includes(el),
        ).length > 0 ? (
          <QueryWrapper query={query}>
            {({ data: { actualCosts, forecastedCosts } }) => {
              const allBudgetLines = selectedVirtualDimension.values
                .filter((el) => !excludedValues.includes(el))
                .map((virtualDimensionValue) => {
                  const budgetLine =
                    form.getValues().lines[virtualDimensionValue]

                  const isCreateMode = mode === "create"

                  const lastVersionLine = !isCreateMode
                    ? lastVersion!.lines.find(
                        (line) =>
                          line.virtualDimensionValue === virtualDimensionValue,
                      )
                    : undefined

                  const revisions = lastVersionLine
                    ? [
                        {
                          name: `Current budget ${dayjs(lastVersion!.createdAt).format("DD MMM YYYY hh:mm:ss")}`,
                          values: lastVersionLine.allocations,
                        },
                        ...selectedVersions!.map((version) => {
                          const line = version.lines.find(
                            (line) =>
                              line.virtualDimensionValue ===
                              virtualDimensionValue,
                          )
                          return {
                            name:
                              version.versionName &&
                              version.versionName.length > 0
                                ? version.versionName
                                : `Revision ${dayjs(version!.createdAt).format("DD MMM YYYY hh:mm:ss")}`,
                            values:
                              line?.allocations ?? new Array(12).fill(null),
                          }
                        }),
                      ]
                    : [
                        {
                          name: `Current budget ${dayjs(lastVersion?.createdAt || new Date()).format("DD MMM YYYY hh:mm:ss")}`,
                          values: budgetLine.allocations,
                        },
                      ]

                  if (!budgetLine) {
                    console.error(
                      "No budget line found for",
                      virtualDimensionValue,
                    )
                    return null
                  }

                  return {
                    allocations: budgetLine.allocations,
                    revisions,
                    virtualDimensionValue,
                    ownerId: budgetLine.ownerId,
                    actualCosts: actualCosts[virtualDimensionValue],
                    forecastedCosts: forecastedCosts[virtualDimensionValue],
                  }
                })
                .filter((line) => line !== null)
              const valuesLines = allBudgetLines.filter(
                (line) => line.virtualDimensionValue !== "Others",
              )
              const othersLine = allBudgetLines.find(
                (line) => line.virtualDimensionValue === "Others",
              )

              const totalLine = allBudgetLines.reduce(
                (acc, line) => {
                  return {
                    ...acc,
                    allocations: acc.allocations.map(
                      (value, index) => value + line.allocations[index],
                    ),
                    revisions: acc.revisions.map((revision, revisionIndex) => ({
                      name: revision.name,
                      values: revision.values.map(
                        (value, index) =>
                          value +
                            line.revisions[revisionIndex]?.values[index] || 0.0,
                      ),
                    })),
                    ownerId: null,
                    actualCosts: acc.actualCosts.map((value, index) => {
                      const cost = line.actualCosts[index]

                      if (value === null) {
                        return cost
                      }
                      return value + (cost ?? 0)
                    }),
                    forecastedCosts: acc.forecastedCosts.map((value, index) => {
                      const cost = line.forecastedCosts[index]

                      if (value === null) {
                        return cost
                      }
                      return value + (cost ?? 0)
                    }),
                  }
                },
                {
                  allocations: new Array(12).fill(0),
                  revisions: allBudgetLines[0].revisions.map((el) => ({
                    ...el,
                    values: new Array(12).fill(0),
                  })),
                  virtualDimensionValue: "Total",
                  ownerId: null,
                  actualCosts: new Array(12).fill(0),
                  forecastedCosts: new Array(12).fill(0),
                },
              )
              const sortedLines = [
                totalLine,
                ...valuesLines,
                ...(othersLine ? [othersLine] : []),
              ]

              return (
                <Paper
                  flex={1}
                  mah={mode === BudgetTableMode.View ? "68vh" : "65vh"}
                  styles={{ root: { overflowY: "scroll" } }}
                  p={0}
                >
                  <table
                    style={{
                      borderCollapse: "separate",
                      borderSpacing: 0,
                    }}
                  >
                    <BudgetTableHead monthsOffset={monthsOffset} />
                    <tbody>
                      {sortedLines.map((line) => (
                        <EditableBudgetTableRow
                          key={line.virtualDimensionValue}
                          revisions={line.revisions}
                          actualCosts={line.actualCosts}
                          forecastedCosts={line.forecastedCosts}
                          virtualDimensionValue={line.virtualDimensionValue}
                          values={line}
                          onChange={(values) => {
                            onLineChange(values, line.virtualDimensionValue)
                          }}
                          virtualDimensionId={virtualDimensionId}
                          startDate={startDate}
                        />
                      ))}
                    </tbody>
                  </table>
                </Paper>
              )
            }}
          </QueryWrapper>
        ) : (
          <EmptyBudgetTable />
        )}
        {mode !== BudgetTableMode.View && (
          <Group justify="flex-end">
            {isFormPending ? (
              <>
                <Loader mr={80} />
              </>
            ) : mode === BudgetTableMode.Edit ? (
              <>
                {lastVersion?.status === BudgetStatus.VALIDATED ? (
                  <>
                    <Button
                      component={Link}
                      to="../new"
                      leftSection={<IconRotateClockwise2 />}
                      variant="outline"
                    >
                      New revision
                    </Button>
                    <Button
                      onClick={() => handleClickSave(BudgetStatus.VALIDATED)}
                      leftSection={<IconDeviceFloppy />}
                    >
                      Save changes
                    </Button>
                  </>
                ) : (
                  <>
                    <Button
                      onClick={() => handleClickSave(BudgetStatus.DRAFT)}
                      leftSection={<IconDeviceFloppy />}
                      variant="outline"
                    >
                      Save as draft
                    </Button>
                    <Button
                      onClick={() => handleClickSave(BudgetStatus.VALIDATED)}
                      leftSection={<IconCheck />}
                    >
                      Save and validate
                    </Button>
                  </>
                )}
              </>
            ) : mode === BudgetTableMode.Revision ? (
              <>
                <Button
                  onClick={() => handleClickCreateRevision(BudgetStatus.DRAFT)}
                  leftSection={<IconDeviceFloppy />}
                  variant="outline"
                >
                  Create draft revision
                </Button>
                <Button
                  onClick={() =>
                    handleClickCreateRevision(BudgetStatus.VALIDATED)
                  }
                  leftSection={<IconCheck />}
                >
                  Create revision
                </Button>
              </>
            ) : (
              <>
                <Button
                  onClick={() => handleClickCreate(BudgetStatus.DRAFT)}
                  variant="outline"
                >
                  Save as draft
                </Button>
                <Button
                  onClick={() => handleClickCreate(BudgetStatus.VALIDATED)}
                >
                  Create budget
                </Button>
              </>
            )}
          </Group>
        )}
      </Stack>
    </BudgetTableContext.Provider>
  )
}

interface BudgetTableProps {
  formData: BudgetsResponses.BudgetFormData
  versions?: BudgetsResponses.BudgetDetail[]
  mode: BudgetTableMode
}

interface EditableBudgetTableRowProps {
  actualCosts: (number | null)[]
  virtualDimensionValue: string
  forecastedCosts: (number | null)[]
  values: TableRowFormValues
  onChange: (values: TableRowFormValues) => void
  virtualDimensionId: string
  startDate: Date
  revisions: { name: string; values: number[] }[]
}

const EditableBudgetTableRow = ({
  actualCosts,
  virtualDimensionValue,
  virtualDimensionId,
  startDate,
  forecastedCosts,
  values: { allocations, ownerId },
  revisions,
  onChange,
}: EditableBudgetTableRowProps) => {
  const { mode, formData, currency } = useBudgetTableContext()
  const deltas =
    allocations?.map((allocation, i) => {
      const actual = actualCosts[i]
      if (actual === null) {
        return undefined
      }
      return allocation - actual
    }) ?? new Array(12).fill(null)
  const [businessMetricId, setBusinessMetricId] = useState<string | null>(null)

  const actualCostsYTD = sumYTDOrNull(actualCosts)
  const deltasYTD = sumYTDOrNull(deltas)
  const forecastedCostsYTD = sumYTDOrNull(forecastedCosts)

  const forecastedCostsTotal = sumOrNull(forecastedCosts)
  const actualCostsTotal = sumOrNull(actualCosts)
  const deltasTotal = sumOrNull(deltas)

  const onClickCompareToBusinessMetric = () => {
    setBusinessMetricId((prev) => (prev === null ? "" : null))
  }

  const extraLines = Math.max(
    0,
    (revisions?.length ?? 0) - (mode === BudgetTableMode.Edit ? 1 : 0),
  )

  const ownersSelectItems = useMemo(() => {
    return formData.organizationUsers.map((owner) => ({
      value: owner.id,
      label: owner.fullName,
    }))
  }, [formData.organizationUsers])

  const selectedOwnerFullname = useMemo(() => {
    const owner = formData.organizationUsers.find((user) => user.id === ownerId)
    return owner?.fullName
  }, [formData.organizationUsers, ownerId])

  const onAllocationChange = (newAllocations: number[]) => {
    onChange({ allocations: newAllocations, ownerId })
  }

  const onOwnerChange = (newOwnerId: string | null) => {
    onChange({ allocations, ownerId: newOwnerId })
  }

  return (
    <>
      {virtualDimensionValue !== "Total" && (
        <tr>
          <td colSpan={18} style={{ backgroundColor: "black" }} />
        </tr>
      )}
      <tr>
        <th rowSpan={4 + extraLines} style={{ padding: 4 }}>
          <Tooltip label="Compare to business metric">
            <ActionIcon onClick={onClickCompareToBusinessMetric}>
              {businessMetricId !== null ? (
                <IconBriefcaseOff />
              ) : (
                <IconBriefcase />
              )}
            </ActionIcon>
          </Tooltip>
        </th>
        <th
          rowSpan={4 + extraLines}
          style={{
            borderRight: mediumGrayBorder,
            padding: 4,
            height: 40,
            maxWidth: 150,
            wordWrap: "break-word",
          }}
        >
          {virtualDimensionValue}
        </th>
        {mode !== BudgetTableMode.View &&
          (virtualDimensionValue !== "Total" ? (
            <>
              <AllocationsRow
                allocations={allocations}
                onChange={onAllocationChange}
                currency={currency}
                name={
                  mode === BudgetTableMode.Edit
                    ? "Current budget"
                    : mode === BudgetTableMode.Create
                      ? "Budget"
                      : "New revision"
                }
              />
              <td
                rowSpan={4 + extraLines + (businessMetricId !== null ? 2 : 0)}
              >
                <Select
                  data={ownersSelectItems}
                  value={ownerId}
                  onChange={onOwnerChange}
                  placeholder="Select owner"
                />
              </td>
            </>
          ) : (
            <RevisionRow
              name="Current budget total"
              allocations={allocations}
              currency={currency}
            />
          ))}
      </tr>
      {revisions.map(({ name, values }, index) => {
        if (
          (mode === BudgetTableMode.Edit && index === 0) ||
          mode === BudgetTableMode.Create
        ) {
          return null
        }

        return (
          <tr key={name}>
            <RevisionRow
              key={index}
              name={name}
              allocations={values}
              currency={currency}
            />
            {mode !== BudgetTableMode.Revision && index === 0 && (
              <td
                rowSpan={4 + extraLines + (businessMetricId !== null ? 2 : 0)}
              >
                {mode === BudgetTableMode.View ? (
                  <Text ta="center" fw="bold">
                    {selectedOwnerFullname}
                  </Text>
                ) : (
                  <Select
                    data={ownersSelectItems}
                    value={ownerId}
                    onChange={onOwnerChange}
                    placeholder="Select owner"
                  />
                )}
              </td>
            )}
          </tr>
        )
      })}
      <tr>
        <SectionHead name="Actual" />
        {actualCosts.map((actualCost, index) => (
          <FormatedTd
            key={index}
            style={{ borderRight: index === 11 ? mediumGrayBorder : undefined }}
            data={actualCost}
            currency={currency}
          />
        ))}
        <FormatedTd
          style={{ borderRight: mediumGrayBorder }}
          data={actualCostsYTD}
          currency={currency}
        />
        <FormatedTd
          style={{ borderRight: mediumGrayBorder }}
          currency={currency}
          data={actualCostsTotal}
        />
      </tr>
      <tr>
        <SectionHead name="Forecast" />
        {forecastedCosts.map((forecastCost, index) => (
          <FormatedTd
            key={index}
            style={{ borderRight: index === 11 ? mediumGrayBorder : undefined }}
            data={forecastCost}
            currency={currency}
          />
        ))}

        <FormatedTd
          style={{ borderRight: mediumGrayBorder }}
          data={forecastedCostsYTD}
          currency={currency}
        />
        <FormatedTd
          style={{ borderRight: mediumGrayBorder }}
          data={forecastedCostsTotal}
          currency={currency}
        />
      </tr>
      <tr>
        <SectionHead name="Delta" />
        {deltas.map((delta, index) => (
          <FormatedTd
            key={index}
            style={{ borderRight: index === 11 ? mediumGrayBorder : undefined }}
            data={delta}
            currency={currency}
            formatForDelta
          />
        ))}
        <FormatedTd
          currency={currency}
          style={{ borderRight: mediumGrayBorder }}
          data={deltasYTD}
          formatForDelta
        />
        <FormatedTd
          style={{ borderRight: mediumGrayBorder }}
          currency={currency}
          data={deltasTotal}
          formatForDelta
        />
      </tr>
      {businessMetricId !== null && (
        <>
          <tr>
            <td colSpan={17} style={{ backgroundColor: "black" }} />
          </tr>
          <BusinessMetricRow
            virtualDimensionValue={virtualDimensionValue}
            virtualDimensionId={virtualDimensionId}
            startDate={startDate}
          />
        </>
      )}
    </>
  )
}

interface BusinessMetricRowProps {
  virtualDimensionId: string
  virtualDimensionValue: string
  startDate: Date
}

const BusinessMetricRow = ({
  virtualDimensionId,
  virtualDimensionValue,
  startDate,
}: BusinessMetricRowProps) => {
  const { formData, currency, costType } = useBudgetTableContext()

  const [businessMetricId, setBusinessMetricId] = useState<string>("")

  const handleBusinessMetricChange = (value: string | null) => {
    setBusinessMetricId(value ?? "")
  }

  const businessMetricsSelectItems = useMemo(() => {
    return formData.availableBusinessMetrics.map((metric) => ({
      value: metric.id,
      label: metric.name,
    }))
  }, [formData.availableBusinessMetrics])

  const { data, isPending } = useBusinessMetricValueQuery({
    metricId: businessMetricId,
    virtualDimensionValueId: virtualDimensionId,
    startDate,
    currency,
    costType,
  })

  const vdimCosts = data?.costs[virtualDimensionValue]
  const bm = vdimCosts?.map((el) => ({
    cost: el.businessMetric,
    date: el.date,
  }))
  const businessMetric = bm
    ? bm.map((el) => el.cost)
    : (new Array(12).fill(null) as (number | null)[])
  const metric = vdimCosts?.map((el) => ({
    cost: el.metric,
    date: el.date,
  }))
    ? vdimCosts.map((el) => el.metric)
    : (new Array(12).fill(null) as (number | null)[])

  const businessMetricYtd = sumYTDOrNull(businessMetric)
  const metricYtd = sumOrNull(metric)

  return (
    <>
      <tr>
        <th
          rowSpan={2}
          colSpan={2}
          style={{ borderRight: mediumGrayBorder, padding: 4, width: 150 }}
        >
          <Select
            variant="unstyled"
            leftSection={isPending ? <Loader size="sm" /> : undefined}
            data={businessMetricsSelectItems}
            value={businessMetricId === "" ? null : businessMetricId}
            onChange={handleBusinessMetricChange}
            placeholder="Select a business metric"
          />
        </th>
        <SectionHead name="Metric value" />
        {businessMetric.map((cost, index) => (
          <FormatedTd
            key={index}
            style={{
              borderRight: index === 11 ? mediumGrayBorder : undefined,
            }}
            data={cost}
          />
        ))}
        <FormatedTd
          style={{ borderRight: mediumGrayBorder }}
          data={businessMetricYtd}
        />
        <FormatedTd style={{ borderRight: mediumGrayBorder }} />
      </tr>
      <tr>
        <SectionHead name="Actual/Metric" />
        {metric.map((cost, index) => (
          <FormatedTd
            currency={currency}
            key={index}
            style={{
              borderRight: index === 11 ? mediumGrayBorder : undefined,
            }}
            data={cost}
          />
        ))}
        <FormatedTd
          currency={currency}
          style={{ borderRight: mediumGrayBorder }}
          data={metricYtd}
        />
        <FormatedTd
          style={{ borderRight: mediumGrayBorder }}
          data={null}
          currency={currency}
        />
      </tr>
    </>
  )
}

interface BudgetToolbarProps {
  form: UseFormReturnType<BudgetFormValues>
  availableVersionSelectItems: { value: string; label: string }[]
  selectedVersionsIds: string[]
  setSelectedVersionsIds: (value: string[]) => void
  lastVersionStatus: BudgetStatus
}

const BudgetToolbar = ({
  form,
  availableVersionSelectItems,
  selectedVersionsIds,
  setSelectedVersionsIds,
  lastVersionStatus,
}: BudgetToolbarProps) => {
  const {
    mode,
    formData: { availableVirtualDimensions, availableTechnicalMetrics },
  } = useBudgetTableContext()
  const isCreateMode = mode === "create"

  const selectedVirtualDimension = availableVirtualDimensions.find(
    (vd) => vd.id === form.getValues().virtualDimensionId,
  )

  const virtualDimensionsSelectItems = useMemo(
    () =>
      availableVirtualDimensions.map((vd) => ({
        value: vd.id,
        label: vd.name,
      })),
    [availableVirtualDimensions],
  )

  const availableTechnicalMetricsSelectItems = useMemo(
    () =>
      availableTechnicalMetrics.map((bm) => ({
        value: bm.id,
        label: bm.name,
      })),
    [availableTechnicalMetrics],
  )

  const excludedValuesSelectItems = useMemo(() => {
    return (
      selectedVirtualDimension?.values.map((value) => ({
        value,
        label: value,
      })) ?? []
    )
  }, [selectedVirtualDimension])

  return (
    <Stack>
      <Group justify="space-between" align="start" wrap="nowrap">
        <Group wrap="nowrap">
          <InputWrapper label="Budget name" required>
            <TextInput
              disabled={mode === "view"}
              {...form.getInputProps("name")}
              maw="30vw"
            />
          </InputWrapper>
          <InputWrapper label="Version name">
            <TextInput
              disabled={mode === "view"}
              {...form.getInputProps("versionName")}
              maw="30vw"
            />
          </InputWrapper>
          <InputWrapper label="Currency" required>
            <Select
              disabled={!isCreateMode}
              data={CurrencyOptions}
              placeholder="Select Currency"
              {...form.getInputProps("currency")}
            />
          </InputWrapper>
        </Group>
      </Group>
      <Group justify="space-between" align="start" wrap="nowrap">
        <Group wrap="nowrap">
          <InputWrapper label="Virtual dimension" required>
            <Select
              disabled={lastVersionStatus === BudgetStatus.VALIDATED}
              data={virtualDimensionsSelectItems}
              placeholder="Select virtual dimension"
              {...form.getInputProps("virtualDimensionId")}
            />
          </InputWrapper>

          <InputWrapper label="Cost type">
            <Select
              disabled={lastVersionStatus === BudgetStatus.VALIDATED}
              data={availableTechnicalMetricsSelectItems}
              placeholder="Select a cost type"
              {...form.getInputProps("costType")}
            />
          </InputWrapper>

          <InputWrapper label="Excluded values">
            <MultiSelect
              maw="25vw"
              styles={{
                pillsList: {
                  flexWrap: "nowrap",
                  overflow: "hidden",
                },
              }}
              data={excludedValuesSelectItems}
              disabled={lastVersionStatus === BudgetStatus.VALIDATED}
              {...form.getInputProps("excludedValues")}
              placeholder="Select values"
            />
          </InputWrapper>

          {mode !== "create" && (
            <InputWrapper label="Compare to older versions">
              <MultiSelect
                disabled={availableVersionSelectItems.length === 0}
                data={availableVersionSelectItems}
                value={selectedVersionsIds}
                onChange={setSelectedVersionsIds}
                placeholder="Select versions"
              />
            </InputWrapper>
          )}
        </Group>
        <Group wrap="nowrap">
          <InputWrapper label="First month" required>
            <MonthPickerInput
              disabled={!isCreateMode}
              value={form.getValues().startDate}
              onChange={(value) => {
                if (!value) {
                  return
                }
                form.setFieldValue(
                  "startDate",
                  // Add one day because depending on local time, the month picker can return the last day of the month in UTC
                  dayjs(value).add(1, "day").toDate(),
                )
              }}
              placeholder="Select first month"
            />
          </InputWrapper>
          <InputWrapper label="Last month" required>
            <MonthPickerInput
              disabled
              value={dayjs(form.getValues().startDate)
                .add(11, "month")
                .toDate()}
            />
          </InputWrapper>
        </Group>
      </Group>
    </Stack>
  )
}
