import { ActionIcon, Flex, Group, Pill, Text, Tooltip } from "@mantine/core"
import {
  IconChartBarPopular,
  IconDoorExit,
  IconLayout,
  IconLock,
  IconLockOpen2,
} from "@tabler/icons-react"
import dayjs from "dayjs"
import _ from "lodash"

import { TreeNodeType } from "@costory/types/endpoints/folders"
import { ComponentType, Tag } from "@costory/types/prisma-client"

import { DEFAULT_DATE_FORMAT } from "@costory/shared/const"

export interface FileListItemProps {
  id: string
  type: TreeNodeType
  label: string
  componentType: string
  ownerFullName: string
  ownerId: string
  lastEdited: Date
  parentFolderId: string
  tags: Tag[]
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClick: (a: any) => void
}

export const FileListItem = ({
  type,
  label,
  componentType,
  ownerFullName,
  lastEdited,
  tags,
  onClick,
}: FileListItemProps) => (
  <Group gap={2} justify="space-between" flex={1} wrap="nowrap">
    <Group gap={8} wrap="nowrap">
      {type === TreeNodeType.Dashboard ? (
        <IconLayout size={20} strokeWidth={1.5} />
      ) : (
        <IconChartBarPopular size={20} strokeWidth={1.5} />
      )}
      <Text
        maw="30vw"
        styles={{
          root: {
            textWrap: "nowrap",
            textOverflow: "ellipsis",
            overflow: "hidden",
          },
        }}
      >
        {label}
      </Text>
      <ActionIcon
        size="sm"
        color="gray.8"
        variant="transparent"
        aria-label="Settings"
        onClick={(e) => onClick(e)}
      >
        <IconDoorExit />
      </ActionIcon>
    </Group>
    <Flex
      direction="row-reverse"
      ml={20}
      justify="space-around"
      w={600}
      gap={0}
      wrap="nowrap"
    >
      <Group flex={1} justify="center" wrap="nowrap">
        <Tooltip label={`This file is ${_.capitalize(componentType)}`}>
          {componentType === ComponentType.PUBLIC ? (
            <IconLockOpen2 color="var(--mantine-color-primary-4)" />
          ) : componentType === ComponentType.PRIVATE ? (
            <IconLock color="var(--mantine-color-gray-4)" />
          ) : (
            <IconLockOpen2 color="var(--mantine-color-green-4)" />
          )}
        </Tooltip>
      </Group>
      <Group
        flex={3}
        justify="center"
        styles={{ root: { overflowX: "hidden" } }}
      >
        <Text
          styles={{
            root: {
              textWrap: "nowrap",
              textOverflow: "ellipsis",
              overflow: "hidden",
            },
          }}
        >
          {ownerFullName}
        </Text>
      </Group>
      <Group flex={3} justify="center">
        <Text>{dayjs(lastEdited).format(DEFAULT_DATE_FORMAT)}</Text>
      </Group>
      <Group flex={4} styles={{ root: { overflowX: "hidden" } }} wrap="nowrap">
        {tags.map((tag) => (
          <Pill key={tag.id}>{tag.name}</Pill>
        ))}
      </Group>
    </Flex>
  </Group>
)
