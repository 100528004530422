import { useQuery } from "@tanstack/react-query"

import {
  MetricRequests,
  MetricsResponses,
} from "@costory/types/endpoints/metrics"

import { apiClient } from "@costory/front/lib/apiClient"

export const useBusinessMetricsQuery = () => {
  return useQuery({
    queryKey: ["metrics"],
    queryFn: async () => {
      const response =
        await apiClient.get<MetricsResponses.Metric[]>("/metrics")
      return response.data
    },
  })
}

export const useBusinessMetricValueQuery = ({
  metricId,
  virtualDimensionValueId,
  startDate,
  currency,
  costType,
}: MetricRequests.GetMetricCost & { metricId: string }) => {
  return useQuery({
    queryKey: ["metrics", metricId, "cost", startDate, currency],
    queryFn: async () => {
      if (!metricId) {
        return undefined
      }
      const response = await apiClient.post<MetricsResponses.GetMetricCost>(
        `/metrics/${metricId}/cost`,
        { virtualDimensionValueId, startDate, currency, costType },
      )

      return response.data
    },
  })
}
