import { GridChartBaseProps } from "@mantine/charts"
import { useMantineTheme } from "@mantine/core"
import { useState } from "react"
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Label,
  Legend,
  Line,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts"

import { EventsResponses } from "@costory/types/endpoints/events"
import { ExploreResponses } from "@costory/types/endpoints/explorer"
import { Filters } from "@costory/types/filters"

import {
  BUSINESS_METRIC_NAME,
  BUSINESS_METRIC_TECHNICAL_NAME,
  ChartTooltip,
} from "@costory/front/components/charts/ChartTooltip"
import { QueryWrapper } from "@costory/front/components/layout/QueryWrapper"
import { HandlerEvents } from "@costory/front/pages/Explorer/ExplorerMainChart"
import { useExplorerQuery } from "@costory/front/queries/explore"
import {
  COLORS_FOR_PALETTE,
  getCharteSerieColor,
  SHADES_FOR_PALETTE,
  tickFormatter,
} from "@costory/front/utils/charts"
import { formatNumber } from "@costory/front/utils/format"

type Props = {
  filters: Filters
  height?: number
  withLegend?: boolean
  drillDownInto?: (value: string, mode: "sameWindow" | "newTab") => void
  referenceLines?: GridChartBaseProps["referenceLines"]
  handlerEvents?: HandlerEvents
  events?: EventsResponses.Aggregated[]
  withMetricsLines?: boolean
}

export const StackedBarChart = ({
  filters,
  height,
  drillDownInto,
  referenceLines,
  handlerEvents,
  events,
  withLegend,
  withMetricsLines = false,
}: Props) => {
  const { colors } = useMantineTheme()
  const [hoveredItem, setHoveredItem] = useState<string | null>(null)
  const explorerQuery = useExplorerQuery(filters)
  function mergeWithEvents(data: ExploreResponses.ExplorerData) {
    if (events) {
      return data.stackedBarChartData.arr.map((el) => ({
        ...el,
        events: events.filter((ev) => el.agg_date === ev.date)[0]?.events,
      }))
    }
    return data.stackedBarChartData.arr
  }

  const getOpacity = (itemName: string) => {
    if (!hoveredItem) return 1
    return hoveredItem === itemName ? 1 : 0.5
  }

  const mantineColorNameToColor = (name: string) => {
    const [color, shade] = name.split(".")
    return `var(--mantine-color-${color}-${shade})`
  }

  return (
    <QueryWrapper query={explorerQuery}>
      {({ data }) => (
        <ResponsiveContainer
          width="100%"
          height={height ?? "100%"}
          style={{ paddingRight: 0 }}
        >
          <ComposedChart
            data={mergeWithEvents(data)}
            stackOffset="sign"
            onClick={(val) => {
              handlerEvents &&
                events &&
                handlerEvents.eventClickOnChart(events, val)
            }}
          >
            <CartesianGrid vertical={false} strokeDasharray="5 5" />
            <XAxis
              dataKey="agg_date"
              angle={30}
              height={60}
              textAnchor="start"
              fontSize={12}
              tickFormatter={(val: string) => tickFormatter(val, filters.aggBy)}
              onClick={(event) => {
                handlerEvents &&
                  handlerEvents.createEventFromXAxisClick(
                    event as unknown as { value: string },
                  )
              }}
            />

            <YAxis
              axisLine={false}
              tickLine={false}
              tickFormatter={(value) =>
                formatNumber(value, "currency", 2, filters.currency)
              }
              fontSize={12}
            />
            <Tooltip
              content={({ label, payload, coordinate }) => (
                <ChartTooltip
                  label={label}
                  payload={payload}
                  currency={filters.currency}
                  coordinate={{
                    x: coordinate?.x ?? 0,
                  }}
                  aggBy={filters.aggBy}
                />
              )}
            />
            {withLegend && (
              <Legend
                iconType="circle"
                verticalAlign="top"
                align="right"
                wrapperStyle={{
                  paddingBottom: 10,
                  fontSize: "var(--mantine-font-size-sm)",
                  alignItems: "center",
                }}
                iconSize={12}
                formatter={(value) => (
                  <span style={{ color: "var(--mantine-color-text" }}>
                    {value}
                  </span>
                )}
                content={({ payload }) => (
                  <div
                    style={{
                      flexWrap: "wrap",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-end",
                    }}
                  >
                    {payload?.map((entry) => (
                      <div
                        key={entry.value}
                        onClick={() => {
                          setHoveredItem(
                            entry.dataKey === hoveredItem
                              ? null
                              : (entry.dataKey as string),
                          )
                        }}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "flex-end",
                          gap: 5,
                          padding: 5,
                          lineHeight: 1,
                          opacity: hoveredItem
                            ? hoveredItem === entry.dataKey
                              ? 1
                              : 0.5
                            : 1,
                        }}
                      >
                        <div
                          style={{
                            width: 12,
                            height: 12,
                            borderRadius: "50%",
                            backgroundColor: entry.color,
                          }}
                        />
                        <span
                          style={{
                            color: "var(--mantine-color-text)",
                            fontSize: "var(--mantine-font-size-sm)",
                          }}
                        >
                          {entry.value}
                        </span>
                      </div>
                    ))}
                  </div>
                )}
              />
            )}
            {referenceLines?.map(({ label, ...line }, index) => (
              <ReferenceLine
                key={index}
                {...line}
                fill="var(--mantine-color-cyan-6)"
              >
                <Label
                  value={label}
                  position="top"
                  fontSize={12}
                  fill="var(--mantine-color-gray-6)"
                />
              </ReferenceLine>
            ))}
            <YAxis
              axisLine={false}
              tickLine={false}
              yAxisId={BUSINESS_METRIC_TECHNICAL_NAME}
              orientation="right"
              fontSize={12}
              tickFormatter={(value) => formatNumber(value, "decimal", 2)}
            />
            {data.stackedBarChartData.items.map((item, index) => (
              <Bar
                key={item.name}
                dataKey={item.name}
                fillOpacity={getOpacity(item.name)}
                fill={
                  colors[COLORS_FOR_PALETTE[index % COLORS_FOR_PALETTE.length]][
                    SHADES_FOR_PALETTE[index % 2]
                  ]
                }
                stackId="a"
                onClick={(data, _index, event) => {
                  if (!drillDownInto) return
                  const mode = event.metaKey ? "newTab" : "sameWindow"
                  drillDownInto(data.tooltipPayload[0].dataKey, mode)
                }}
              />
            ))}
            {withMetricsLines && (
              <Line
                strokeWidth={2}
                stroke={mantineColorNameToColor(
                  getCharteSerieColor(data.stackedBarChartData.items.length),
                )}
                fill={mantineColorNameToColor(
                  getCharteSerieColor(data.stackedBarChartData.items.length),
                )}
                dataKey={BUSINESS_METRIC_TECHNICAL_NAME}
                yAxisId={BUSINESS_METRIC_TECHNICAL_NAME}
                name={BUSINESS_METRIC_NAME}
                strokeOpacity={getOpacity(BUSINESS_METRIC_NAME)}
              />
            )}
          </ComposedChart>
        </ResponsiveContainer>
      )}
    </QueryWrapper>
  )
}
