import { Anchor, Stack, Text } from "@mantine/core"

export const DatadogCIVisibilityDocs = () => {
  return (
    <Stack>
      <Text>
        You can import CI metrics from Datadog CI Visibility.
        <br />
        The metrics will be aggregated by day, using your aggregation function.
      </Text>
      <Text>
        We rely on{" "}
        <Anchor
          href="https://docs.datadoghq.com/api/latest/ci-visibility-pipelines/#aggregate-pipelines-events"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://docs.datadoghq.com/api/latest/ci-visibility-pipelines/#aggregate-pipelines-events
        </Anchor>{" "}
        api.
      </Text>
    </Stack>
  )
}
