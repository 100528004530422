import { Group, TextInput, Title } from "@mantine/core"
import { UseFormReturnType } from "@mantine/form"
import dayjs from "dayjs"

import { DigestResponses, DigestType } from "@costory/types/endpoints/digest"

import { DEFAULT_DATE_FORMAT } from "@costory/shared/const"

export function DigestTitle({
  digestData,
  form,
  isDiff,
}: {
  digestData: DigestResponses.Investigation
  form: UseFormReturnType<DigestType>
  isDiff: boolean
}) {
  return (
    <>
      <Group align="end">
        <TextInput
          {...form.getInputProps("name")}
          fw="bold"
          styles={{
            root: {
              paddingBottom: 0,
            },
            wrapper: {
              paddingBottom: 0,
            },
            input: {
              width: 500,
              maxWidth: 500,
              height: 44,
              fontSize: "var(--mantine-h1-font-size)",
              borderBottom: "1px solid var(--mantine-color-gray-3)",
              borderRadius: 0,
              paddingBottom: 4,
            },
          }}
          variant="unstyled"
          placeholder="New Cost Digest"
        />

        <Group>
          <Title order={5} c="gray.4">
            {dayjs(digestData[0].ffrom).format(DEFAULT_DATE_FORMAT)} {" -> "}{" "}
            {dayjs(digestData[0].tto).format(DEFAULT_DATE_FORMAT)}
          </Title>
        </Group>

        {isDiff && (
          <>
            <Group>
              <Title order={5} c="gray.4">
                Compared to
              </Title>
              <Title order={5} c="gray.4">
                {dayjs(digestData[0].previousFrom).format(DEFAULT_DATE_FORMAT)}{" "}
                {" -> "}{" "}
                {dayjs(digestData[0].previousTo).format(DEFAULT_DATE_FORMAT)}
              </Title>
            </Group>
          </>
        )}
      </Group>
    </>
  )
}
