import { Group, Stack, Table, Text } from "@mantine/core"

export const AwsDocs = () => {
  return (
    <Text>
      <Stack>
        <Text>
          We support two Parquet file formats to import metrics: a flat and a
          columnar one:
        </Text>
        <Text>
          {" "}
          date should be in DATE or TIMESTAMP and metric values should be
          numeric (FLOAT*, INT*, DOUBLE).
        </Text>
        <Text>
          In step 3 you will be able to map the columns in the CSV with what
          they represent;
        </Text>
        <Group align="flex-start" grow>
          <Stack>
            <Text fw={600}>Flat Format</Text>
            <Table withTableBorder withColumnBorders>
              <Table.Thead>
                <Table.Tr>
                  <Table.Th>date</Table.Th>
                  <Table.Th>metricName</Table.Th>
                  <Table.Th>metricValue</Table.Th>
                </Table.Tr>
              </Table.Thead>
              <Table.Tbody>
                <Table.Tr>
                  <Table.Td>2024-01-01</Table.Td>
                  <Table.Td>metric1</Table.Td>
                  <Table.Td>42</Table.Td>
                </Table.Tr>
                <Table.Tr>
                  <Table.Td>2024-01-01</Table.Td>
                  <Table.Td>metric2</Table.Td>
                  <Table.Td>123</Table.Td>
                </Table.Tr>
              </Table.Tbody>
            </Table>
          </Stack>

          <Stack>
            <Text fw={600}>Columnar Format</Text>
            <Table withTableBorder withColumnBorders>
              <Table.Thead>
                <Table.Tr>
                  <Table.Th>date</Table.Th>
                  <Table.Th>metric1</Table.Th>
                  <Table.Th>metric2</Table.Th>
                </Table.Tr>
              </Table.Thead>
              <Table.Tbody>
                <Table.Tr>
                  <Table.Td>2024-01-01</Table.Td>
                  <Table.Td>42</Table.Td>
                  <Table.Td>123</Table.Td>
                </Table.Tr>
              </Table.Tbody>
            </Table>
          </Stack>
        </Group>
      </Stack>
    </Text>
  )
}
