import {
  Stack,
  Loader,
  Title,
  Table,
  InputWrapper,
  Box,
  Group,
  Select,
  Button,
  MultiSelect,
  Text,
  ActionIcon,
} from "@mantine/core"
import { UseFormReturnType } from "@mantine/form"
import {
  IconCheck,
  IconX,
  IconCloudX,
  IconArrowLeft,
  IconRefresh,
} from "@tabler/icons-react"
import dayjs from "dayjs"

import { GSheetDatasourceFormData } from "@costory/types/endpoints/metricsDatasources"

import { QueryWrapper } from "@costory/front/components/layout/QueryWrapper"
import { queryClient } from "@costory/front/lib/queryClient"
import { useValidateGoogleSheetQuery } from "@costory/front/queries/metricsDatasources"

export const GSheetImportStep = ({
  form,
  handleClickBack,
  handleClickNext,
}: {
  form: UseFormReturnType<GSheetDatasourceFormData>
  handleClickBack: () => void
  handleClickNext: () => void
}) => {
  const query = useValidateGoogleSheetQuery(form.getValues().gSheetId)
  return (
    <Stack justify="space-between" h="100%">
      <Stack>
        <Group justify="space-between">
          <Text>Importing metrics from Google Sheet</Text>
          <Group>
            <Text>Retry</Text>
            <ActionIcon
              loading={query.isLoading}
              onClick={() => {
                queryClient.resetQueries({
                  queryKey: ["validateGoogleSheet"],
                })
              }}
            >
              <IconRefresh />
            </ActionIcon>
          </Group>
        </Group>
        <QueryWrapper
          query={query}
          LoadingComponent={
            <Stack align="center">
              <Loader size="xl" />{" "}
              <Title order={2}>Validating datasource...</Title>{" "}
            </Stack>
          }
        >
          {({ data }) => {
            if (!query.isLoading && data.isSuccess) {
              const isDateValid = data.headerValues
                .find(({ name }) => name === form.values.dateColumn)
                ?.dateValue.every(
                  (date) =>
                    dayjs(date, "YYYY-MM-DD", true).isValid() ||
                    dayjs(date, "YYYY/MM/DD", true).isValid(),
                )
              return (
                <Stack>
                  <Table withRowBorders={false}>
                    <Table.Tr>
                      <Table.Td style={{ width: "50%", paddingLeft: 0 }}>
                        <InputWrapper label="Metrics columns">
                          <MultiSelect
                            data={data?.headerValues.map(({ name }) => name)}
                            {...form.getInputProps("metricsColumns")}
                            placeholder="Select metrics columns"
                          />
                        </InputWrapper>
                      </Table.Td>
                      <Table.Td style={{ width: "50%" }}>
                        {form.values.metricsColumns?.length > 0 && (
                          <Box>
                            <Table>
                              <Table.Thead>
                                <Table.Tr>
                                  {form.values.metricsColumns.map(
                                    (metricName) => (
                                      <Table.Th key={metricName}>
                                        {metricName} (Preview)
                                      </Table.Th>
                                    ),
                                  )}
                                </Table.Tr>
                              </Table.Thead>
                              <Table.Tbody>
                                <Table.Tr>
                                  {form.values.metricsColumns.map(
                                    (metricName) => {
                                      const metricData = data.headerValues.find(
                                        ({ name }) => name === metricName,
                                      )
                                      const isValid = metricData?.data.every(
                                        (value) => !isNaN(Number(value)),
                                      )
                                      return (
                                        <Table.Td key={metricName}>
                                          <Text c={isValid ? "green" : "red"}>
                                            {metricData?.data
                                              .slice(0, 3)
                                              .join(", ")}
                                          </Text>
                                        </Table.Td>
                                      )
                                    },
                                  )}
                                </Table.Tr>
                                <Table.Tr>
                                  {form.values.metricsColumns.map(
                                    (metricName) => {
                                      const metricData = data.headerValues.find(
                                        ({ name }) => name === metricName,
                                      )
                                      const isValid = metricData?.data.every(
                                        (value) => !isNaN(Number(value)),
                                      )
                                      return (
                                        <Table.Td key={metricName}>
                                          {isValid ? (
                                            <Group>
                                              <IconCheck
                                                size={16}
                                                color="var(--mantine-color-green-6)"
                                              />
                                              <Text size="xs" c="dimmed">
                                                Valid
                                              </Text>
                                            </Group>
                                          ) : (
                                            <Group>
                                              <IconX
                                                size={16}
                                                color="var(--mantine-color-red-6)"
                                              />
                                              <Text size="xs" c="red">
                                                Invalid: must contain only
                                                numbers
                                              </Text>
                                            </Group>
                                          )}
                                        </Table.Td>
                                      )
                                    },
                                  )}
                                </Table.Tr>
                              </Table.Tbody>
                            </Table>
                          </Box>
                        )}
                      </Table.Td>
                    </Table.Tr>
                    <Table.Tr>
                      <Table.Td style={{ width: "50%", paddingLeft: 0 }}>
                        <InputWrapper label="Date Column">
                          <Select
                            data={data?.headerValues.map(({ name }) => name)}
                            {...form.getInputProps("dateColumn")}
                            placeholder="Select date column"
                          />
                        </InputWrapper>
                      </Table.Td>
                      <Table.Td style={{ width: "50%" }}>
                        {form.values.dateColumn && (
                          <Box>
                            <Text size="sm" fw={500} mb="xs">
                              Date column preview:
                            </Text>
                            <Text c={isDateValid ? "green" : "red"} mb="xs">
                              {data.headerValues
                                .find(
                                  ({ name }) => name === form.values.dateColumn,
                                )
                                ?.data.slice(0, 3)
                                .join(", ")}
                            </Text>
                            <Text size="xs" c="dimmed">
                              {isDateValid ? (
                                <Group>
                                  <IconCheck
                                    size={16}
                                    color="var(--mantine-color-green-6)"
                                  />
                                  <Text size="xs" c="dimmed">
                                    Dates are valid
                                  </Text>
                                </Group>
                              ) : (
                                <Group>
                                  <IconX
                                    size={16}
                                    color="var(--mantine-color-red-6)"
                                  />
                                  <Text size="xs" c="dimmed">
                                    Dates are invalid: must be in YYYY-MM-DD or
                                    YYYY/MM/DD format
                                  </Text>
                                </Group>
                              )}
                            </Text>
                          </Box>
                        )}
                      </Table.Td>
                    </Table.Tr>
                  </Table>
                </Stack>
              )
            }
            return (
              <>
                <Stack align="center">
                  <Stack align="center">
                    <IconCloudX
                      color="var(--mantine-color-gray-6)"
                      size={120}
                    />
                    <Title order={2}>
                      Your Google Sheet datasource is not accesible or the
                      header of the Google Sheet is empty
                    </Title>
                    <Text>{data.errorCode}</Text>
                  </Stack>
                </Stack>
              </>
            )
          }}
        </QueryWrapper>
      </Stack>
      <Group justify="space-between">
        <Button
          onClick={handleClickBack}
          variant="outline"
          leftSection={<IconArrowLeft />}
        >
          Back
        </Button>
        <Button onClick={handleClickNext}>Next</Button>
      </Group>
    </Stack>
  )
}
