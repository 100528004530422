import { ActionIcon, Button, Flex, Popover, Textarea } from "@mantine/core"
import { useDisclosure, useClickOutside } from "@mantine/hooks"
import { IconXboxXFilled } from "@tabler/icons-react"
import { useEffect, useState } from "react"
import { RuleGroupType, formatQuery } from "react-querybuilder"
import { parseCEL } from "react-querybuilder/parseCEL"

import { AxesResponses } from "@costory/types/endpoints/axes"

import { canParse } from "@costory/front/components/queryBuilder/canParse"

import SearchAcrossColumnsAndValues from "./CEL/SearchAcrossColumnsAndValues"

const NUMBER_OF_INITIAL_VALUES_PER_FIELD_TO_SHOW = 5

const CELQueryBuilder = ({
  initialFields,
  onQueryChange,
  queryError,
  initialQuery,
}: {
  initialFields: AxesResponses.Axis[]
  onQueryChange: (query: RuleGroupType | null) => void
  queryError: boolean
  initialQuery?: RuleGroupType
}) => {
  // Popover management
  const [opened, { open, close }] = useDisclosure(true)
  // Close the popover when clicking outside this ref's element
  const ref = useClickOutside(() => close())

  // Query and error management
  const [rawQuery, setRawQuery] = useState<string>(
    initialQuery?.rules?.length ? formatQuery(initialQuery, "cel") : "",
  )
  const [validQuery, setValidQuery] = useState<string | null>(null)
  const [isError, setIsError] = useState(false)

  useEffect(() => {
    const parseSuccess = canParse(rawQuery)
    if (parseSuccess) {
      setValidQuery(rawQuery)
    }
    // queryError comes from the top level RQB parser and can catch things like
    // invalid field names, and perhaps other things.
    setIsError(!parseSuccess || queryError)
  }, [rawQuery, queryError])

  useEffect(() => {
    if (validQuery) {
      onQueryChange(parseCEL(validQuery))
    } else if (validQuery?.trim() === "") {
      // If the query is empty, we want to show all the data, so use an identity rule
      onQueryChange(parseCEL("1 == 1"))
    }
  }, [validQuery, onQueryChange])

  const onInsert = (celRule: string) => {
    setRawQuery((rawQuery + " " + celRule).trim())
    close()
  }

  const rightSection = rawQuery && (
    <ActionIcon
      style={{
        alignSelf: "center",
      }}
      aria-label="Remove rule"
      onClick={() => {
        setRawQuery("")
        setValidQuery(null)
      }}
    >
      <IconXboxXFilled />
    </ActionIcon>
  )
  return (
    <Flex gap="sm">
      <Textarea
        autosize
        value={rawQuery}
        onChange={(e) => {
          setRawQuery(e.target.value)
        }}
        error={rawQuery && isError && "Invalid CEL query"}
        rightSection={rightSection}
        style={{
          flexGrow: 1,
        }}
      />

      <Popover
        opened={opened}
        trapFocus
        position="left"
        withArrow
        arrowSize={10}
        shadow="md"
        styles={{
          dropdown: {
            minWidth: "350px",
          },
        }}
      >
        <Popover.Target>
          <Button onClick={open} style={{}} size="sm">
            Add a condition
          </Button>
        </Popover.Target>
        <Popover.Dropdown ref={ref}>
          <SearchAcrossColumnsAndValues
            onInsert={onInsert}
            initialFields={initialFields.map((field) => ({
              ...field,
              values: field.values.slice(
                0,
                NUMBER_OF_INITIAL_VALUES_PER_FIELD_TO_SHOW,
              ),
            }))}
          />
        </Popover.Dropdown>
      </Popover>
    </Flex>
  )
}

export default CELQueryBuilder
