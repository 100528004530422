import { ActionIcon, Anchor, Group, Switch, Text, Tooltip } from "@mantine/core"
import { showNotification } from "@mantine/notifications"
import { IconArchive, IconEdit, IconExternalLink } from "@tabler/icons-react"
import dayjs from "dayjs"
import {
  createMRTColumnHelper,
  MantineReactTable,
  useMantineReactTable,
  type MRT_ColumnDef,
} from "mantine-react-table"
import { Link } from "react-router-dom"

import { ScheduledDigestConfigurationsResponses } from "@costory/types/endpoints/scheduledDigestConfigurations"
import {
  ScheduledDigestStatus,
  ScheduledPeriod,
} from "@costory/types/prisma-client"

import { useUser } from "@costory/front/components/auth/UserContext"
import { QueryWrapper } from "@costory/front/components/layout/QueryWrapper"
import { availableIntegrations } from "@costory/front/pages/ScheduledDigestConfigurations/integrations"
import {
  useDeleteScheduledDigestConfigurationMutation,
  useOptimisticToggleScheduledDigestConfigurationStatusMutation,
  useScheduledDigestConfigurationsQuery,
} from "@costory/front/queries/scheduledDigestConfigurations"
import { getDefaultTableOptions } from "@costory/front/utils/table"

const _ScheduledDigestConfigurationsTable = ({
  configurations,
  onEdit,
}: Props & { configurations: ScheduledDigestConfigurationsResponses.List }) => {
  const user = useUser()
  const { mutateAsync: deleteConfiguration, isPending: isDeleting } =
    useDeleteScheduledDigestConfigurationMutation()
  const { mutateAsync: toggleStatus } =
    useOptimisticToggleScheduledDigestConfigurationStatusMutation()

  const columnHelper =
    createMRTColumnHelper<ScheduledDigestConfigurationsResponses.List[0]>()
  const columns: MRT_ColumnDef<
    ScheduledDigestConfigurationsResponses.List[0]
  >[] = [
    columnHelper.accessor("savedView.name", {
      header: "Saved View",
      Cell: ({ row }) => (
        <Anchor
          component={Link}
          to={`/explorer/views/${row.original.savedViewId}`}
        >
          <Group align="center" gap={4}>
            {row.original.savedView.name}
            <IconExternalLink size={16} />
          </Group>
        </Anchor>
      ),
    }),
    columnHelper.accessor("status", {
      header: "Active",
      Cell: ({ row }) => {
        const canEdit = user.isAdmin || row.original.createdById === user.id
        const isEnabled = row.original.status === ScheduledDigestStatus.ENABLED

        return (
          <Switch
            checked={isEnabled}
            onChange={async () => {
              if (!onEdit) return
              try {
                await toggleStatus(row.original.id)
                showNotification({
                  title: "Success",
                  message: `Scheduled digest successfully ${
                    isEnabled ? "disabled" : "enabled"
                  }`,
                  color: "green",
                })
              } catch (error) {
                showNotification({
                  title: "Error",
                  message: "Failed to update scheduled digest status",
                  color: "red",
                })
              }
            }}
            disabled={!canEdit}
            readOnly={!onEdit}
          />
        )
      },
    }),
    columnHelper.accessor("channelName", {
      header: "Destination",
      Cell: ({ cell, row }) => {
        const channelName = cell.getValue()
        const { icon: Icon } =
          availableIntegrations[row.original.destinationType]
        return (
          <Group>
            {Icon}
            <Text fw="bold">{channelName}</Text>
          </Group>
        )
      },
    }),
    columnHelper.accessor("scheduledPeriod", {
      header: "Next run date",
      Cell: ({ cell, row }) => {
        const period = cell.getValue()
        if (row.original.status === ScheduledDigestStatus.DISABLED) {
          return "Disabled"
        }
        switch (period) {
          case ScheduledPeriod.WEEKLY:
            return dayjs()
              .add(1, "week")
              .startOf("week")
              .add(1, "day")
              .format("MMM DD")
          case ScheduledPeriod.MONTHLY:
            return dayjs()
              .add(1, "month")
              .startOf("month")
              .add(2, "day")
              .format("MMM DD")
          default:
            return period
        }
      },
    }),
    columnHelper.accessor("createdBy.firstName", {
      header: "Created By",
      Cell: ({ row }) =>
        `${row.original.createdBy.firstName} ${row.original.createdBy.lastName}`,
    }),
    ...(onEdit
      ? [
          columnHelper.display({
            id: "actions",
            header: "Actions",
            Cell: ({ row }) => {
              const canEdit =
                user.isAdmin || row.original.createdById === user.id
              if (!canEdit) return null

              return (
                <Group gap="xs">
                  <Tooltip label="Edit">
                    <ActionIcon onClick={() => onEdit(row.original)}>
                      <IconEdit size={20} />
                    </ActionIcon>
                  </Tooltip>
                  <Tooltip label="Archive">
                    <ActionIcon
                      onClick={() => {
                        deleteConfiguration(row.original.id, {
                          onSuccess: () => {
                            showNotification({
                              title: "Success",
                              message: "Configuration deleted successfully",
                              color: "green",
                            })
                          },
                          onError: () => {
                            showNotification({
                              title: "Error",
                              message: "Failed to delete configuration",
                              color: "red",
                            })
                          },
                        })
                      }}
                      loading={isDeleting}
                    >
                      <IconArchive size={20} />
                    </ActionIcon>
                  </Tooltip>
                </Group>
              )
            },
          }),
        ]
      : []),
  ]

  const table = useMantineReactTable<
    ScheduledDigestConfigurationsResponses.List[number]
  >({
    columns,
    data: configurations,
    ...getDefaultTableOptions(),
  })

  return <MantineReactTable table={table} />
}

type Props = {
  onEdit?: (
    configuration: ScheduledDigestConfigurationsResponses.List[0],
  ) => void
}

export const ScheduledDigestConfigurationsTable = ({ onEdit }: Props) => {
  const query = useScheduledDigestConfigurationsQuery()
  return (
    <QueryWrapper query={query} allowEmptyArray>
      {({ data }) => (
        <_ScheduledDigestConfigurationsTable
          configurations={data}
          onEdit={onEdit}
        />
      )}
    </QueryWrapper>
  )
}
