import { showNotification } from "@mantine/notifications"
import { useMutation, useQuery } from "@tanstack/react-query"

import { IntegrationResponses } from "@costory/types/endpoints/integration"

import { apiClient } from "@costory/front/lib/apiClient"

export const useSlackChannelsQuery = () => {
  return useQuery({
    queryKey: ["slack", "channels"],
    queryFn: async () => {
      const response = await apiClient.get<IntegrationResponses.Channels>(
        "/integrations/slack/accessible-channels",
      )
      return response.data
    },
  })
}

export const useTestSlackChannelMutation = () => {
  return useMutation({
    mutationFn: async (channelName: string) => {
      const response = await apiClient.get(
        `/integrations/slack/test-channel/${channelName}`,
      )
      return response.data
    },
    onSuccess: async (data) => {
      showNotification({
        color: data.status === "SUCCESS" ? "green" : "red",
        title: data.status,
        message: data.message,
      })
    },
    onError: (error) => {
      showNotification({
        color: "red",
        title: "Error",
        message: error.message,
      })
    },
  })
}
