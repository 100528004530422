import { Group, useComputedColorScheme } from "@mantine/core"
import { useHover } from "@mantine/hooks"
import { MouseEventHandler } from "react"

import { useDnDContext } from "@costory/front/components/FileExplorer/DnDContext"
import { FileListItem } from "@costory/front/components/FileExplorer/FileListItem"
import {
  DashboardLeafProps,
  SavedViewLeafProps,
} from "@costory/front/components/FileExplorer/TreeExplorer"

export const DefaultFileLeaf = ({
  node: {
    value,
    label,
    nodeProps: { onSelect, type, onDoubleClick, ...nodeProps },
  },
  selected,
  elementProps,
}: DashboardLeafProps | SavedViewLeafProps) => {
  const { ref, hovered } = useHover()
  const colorScheme = useComputedColorScheme()
  const { setDraggedFile } = useDnDContext()

  const handleDoubleClick: MouseEventHandler<HTMLDivElement> = (e) => {
    e.stopPropagation()
    onDoubleClick?.(e)
  }

  const fileLeafColorScheme = {
    dark: {
      root: {
        bg: selected ? "dark.6" : hovered ? "dark.7" : "transparent",
      },
      caret: {
        color: "dark.1",
      },
    },
    light: {
      root: {
        bg: selected ? "primary.1" : hovered ? "gray.1" : "transparent",
      },
      caret: {
        color: selected ? "gray.9" : hovered ? "gray.7" : "gray.5",
      },
    },
  } as const
  const handleDragStart = () => {
    setDraggedFile({ id: value, type, componentType: nodeProps.componentType })
  }

  const handleDragEnd = () => {
    setDraggedFile(null)
  }

  return (
    <Group
      {...elementProps}
      ref={ref}
      h={32}
      justify="space-between"
      mb={4}
      bg={fileLeafColorScheme[colorScheme].root.bg}
      styles={{
        root: {
          borderRadius: 8,
        },
      }}
      onClick={onSelect}
      onDoubleClick={handleDoubleClick}
      pr={8}
      draggable
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
    >
      <FileListItem
        {...nodeProps}
        id={value}
        type={type}
        label={label as string}
        onClick={(e: React.MouseEvent<HTMLDivElement>) =>
          onDoubleClick && onDoubleClick(e)
        }
      />
    </Group>
  )
}
