import { useQuery } from "@tanstack/react-query"

import { AnomalyResponses } from "@costory/types/endpoints/anomalies"

import { apiClient } from "@costory/front/lib/apiClient"

export const useListAnomaliesQuery = () => {
  return useQuery({
    queryKey: ["anomalies"],
    queryFn: async () => {
      const response =
        await apiClient.get<AnomalyResponses.Anomaly[]>("/anomaly/anomalies")
      return response.data
    },
  })
}
