import { Button, Group, Text, Tooltip } from "@mantine/core"
import { IconFileTypeCsv } from "@tabler/icons-react"
import { generateCsv, download, mkConfig } from "export-to-csv"
import {
  createMRTColumnHelper,
  useMantineReactTable,
  MantineReactTable,
  MRT_ShowHideColumnsButton,
  MRT_ToggleFullScreenButton,
  MRT_ToggleGlobalFilterButton,
  MRT_ToggleFiltersButton,
  MRT_ToggleDensePaddingButton,
} from "mantine-react-table"
import { useMemo } from "react"
import { useNavigate } from "react-router-dom"

import { ExploreResponses } from "@costory/types/endpoints/explorer"

import { TrendBadge } from "@costory/front/components/TrendBadge"
import { NumberCell } from "@costory/front/components/table/NumberCell"
import { useChartContext } from "@costory/front/contexts/ChartContext"
import { formatNumber } from "@costory/front/utils/format"
import { CURRENCY_FILTER_VARIANT } from "@costory/front/utils/table"

type Props = {
  data: ExploreResponses.Waterfall["dataGroupedByValue"]
  drillDownInto: (value: string, mode: "sameWindow" | "newTab") => void
  currency: "USD" | "EUR"
}

export const TableChart = ({ data, drillDownInto, currency }: Props) => {
  const navigate = useNavigate()
  const { currentPeriod, previousPeriod } = useChartContext()

  const csvConfig = mkConfig({
    fieldSeparator: ",",
    decimalSeparator: ".",
    useKeysAsHeaders: true,
  })
  const handleExportData = () => {
    const csv = generateCsv(csvConfig)(data)
    download(csvConfig)(csv)
  }

  const { filterForm, groupByOptions } = useChartContext()
  const { groupBy } = filterForm.getValues()
  const valueLabel =
    groupByOptions
      .map((g) => g.items)
      .flat()
      .find((i) => i.value === groupBy)?.label || "Value"

  const columns = useMemo(() => {
    const calculateRange = (
      key: keyof ExploreResponses.Waterfall["dataGroupedByValue"][0],
    ) => {
      const values = data
        .map((item) => item[key])
        .filter((value): value is number => typeof value === "number")

      return {
        min: Math.min(...values),
        max: Math.max(...values),
      }
    }
    const columnHelper =
      createMRTColumnHelper<
        ExploreResponses.Waterfall["dataGroupedByValue"][0]
      >()

    const changeRange = calculateRange("change")
    const relativeChangeRange = calculateRange("relativeChange")
    const periodCost = calculateRange("periodCost")
    const previousPeriodCost = calculateRange("previousPeriodCost")
    return [
      columnHelper.accessor("groupByValue", {
        header: valueLabel,
        filterVariant: "autocomplete",
        Cell: ({ cell }) => (
          <Button
            style={{ userSelect: "text" }}
            onClick={(event) => {
              const mode = event.metaKey ? "newTab" : "sameWindow"
              drillDownInto(cell.getValue(), mode)
            }}
            variant="transparent"
          >
            {cell.getValue()}
          </Button>
        ),
      }),
      columnHelper.accessor("periodCost", {
        header: "Selected Period",
        ...CURRENCY_FILTER_VARIANT,
        mantineFilterRangeSliderProps: {
          min: periodCost.min - 1,
          max: periodCost.max + 2,
          label: formatNumber,
        },
        Cell: ({ cell }) => (
          <NumberCell value={cell.getValue()} currency={currency} />
        ),
      }),
      columnHelper.accessor("previousPeriodCost", {
        header: "Reference Period",
        ...CURRENCY_FILTER_VARIANT,
        mantineFilterRangeSliderProps: {
          min: previousPeriodCost.min - 1,
          max: previousPeriodCost.max + 2,
          label: formatNumber,
        },
        Cell: ({ cell }) => (
          <NumberCell value={cell.getValue()} currency={currency} />
        ),
      }),
      columnHelper.accessor("change", {
        header: "Change (Absolute)",
        ...CURRENCY_FILTER_VARIANT,
        mantineFilterRangeSliderProps: {
          min: changeRange.min - 1,
          max: changeRange.max + 2,
          label: formatNumber,
        },
        Cell: ({ cell }) => (
          <NumberCell value={cell.getValue()} currency={currency} />
        ),
      }),
      columnHelper.accessor("relativeChange", {
        header: "Relative change (%)",
        ...CURRENCY_FILTER_VARIANT,
        mantineFilterRangeSliderProps: {
          min: relativeChangeRange.min * 100 - 1,
          max: relativeChangeRange.max * 100 + 2,
          label: (value) => {
            return formatNumber(value / 100, "percent")
          },
          step: 0.1,
        },
        filterFn: (row, columnId, filterValue) => {
          const value: number = row.getValue(columnId)
          // Return true if the value falls within the range specified by filterValue
          return value >= filterValue[0] / 100 && value <= filterValue[1] / 100
        },
        Cell: ({ cell, row }) => (
          <Button
            variant="transparent"
            onClick={(event) => {
              const mode = event.metaKey ? "newTab" : "sameWindow"
              if (mode == "sameWindow") {
                navigate("/waterfall")
              }
              drillDownInto(cell.row.original.groupByValue, mode)
            }}
          >
            <TrendBadge
              current={row.original.periodCost}
              previous={row.original.previousPeriodCost}
            />
          </Button>
        ),
      }),
    ]
  }, [drillDownInto, currency, navigate, data, valueLabel])

  const table = useMantineReactTable<
    ExploreResponses.Waterfall["dataGroupedByValue"][0]
  >({
    columns,
    data,
    initialState: {
      sorting: [{ id: "periodCost", desc: true }],
    },
    renderToolbarInternalActions: ({ table }) => {
      return (
        <>
          <Tooltip label="Export to CSV">
            <Button
              onClick={handleExportData}
              aria-label="Export to CSV"
              variant="white"
            >
              <IconFileTypeCsv />
            </Button>
          </Tooltip>

          {/* built-in buttons (must pass in table prop for them to work!) */}
          <MRT_ToggleGlobalFilterButton table={table} />
          <MRT_ToggleFiltersButton table={table} />
          <MRT_ShowHideColumnsButton table={table} />
          <MRT_ToggleDensePaddingButton table={table} />
          <MRT_ToggleFullScreenButton table={table} />
        </>
      )
    },
    renderTopToolbarCustomActions: () => (
      <Group gap="lg">
        <Group gap="xs">
          <Text fw={700}>Selected Period:</Text>
          <Text>{currentPeriod}</Text>
        </Group>
        <Group gap="xs">
          <Text fw={700}>Reference Period:</Text>
          <Text>{previousPeriod}</Text>
        </Group>
      </Group>
    ),
  })

  return <MantineReactTable table={table} />
}
