import {
  AppShell,
  Container,
  Group,
  Image,
  ScrollArea,
  Stack,
  ThemeIcon,
  Title,
  useMantineColorScheme,
} from "@mantine/core"
import { IconChevronLeftPipe } from "@tabler/icons-react"
import { User } from "configcat-react"
import _ from "lodash"
import { useMemo, useState } from "react"
import { Link, Outlet } from "react-router-dom"

import Gtm from "@costory/front/components/layout/Gtm"
import NativeIntercom from "@costory/front/components/layout/Intercom"
import { PageContextProvider } from "@costory/front/contexts/PageContext"
import { useAuthState } from "@costory/front/queries/auth"

import { AppHeader } from "./AppHeader"
import { SidebarLink } from "./SidebarLink"
import { SIDEBAR_ITEMS } from "./constants"

export const AppLayout = () => {
  const { colorScheme } = useMantineColorScheme()
  const auth = useAuthState()
  const userObject = new User(
    auth.user?.currentOrg.slug ? auth.user?.currentOrg.slug : "",
  )
  const [isCollapsed, setIsCollapsed] = useState(false)
  const { top, bottom = [] } = useMemo(
    () => _.groupBy(SIDEBAR_ITEMS, (d) => d.position || "top"),
    [],
  )

  return (
    <PageContextProvider>
      <AppShell
        header={{ height: 64 }}
        navbar={{ width: isCollapsed ? 70 : 220, breakpoint: "sm" }}
        footer={{ height: 0 }}
        layout="alt"
      >
        <AppHeader />
        <AppShell.Navbar
          withBorder={false}
          bg="gray.7"
          p={12}
          style={{ transition: "width 200ms" }}
        >
          <Link to="/">
            <Group p={8} wrap="nowrap">
              <Image w={32} h={32} src="/img/logo.png" alt="Costory logo" />
              {!isCollapsed && (
                <Title c="white" size="h2" fw={600}>
                  Costory
                </Title>
              )}
            </Group>
          </Link>

          <Stack h="100%" justify="space-between" mt={32}>
            <ScrollArea h="85%">
              <Stack py={16} gap={8}>
                {top.map((route) => (
                  <SidebarLink
                    isParent
                    key={route.path}
                    route={route}
                    isCollapsed={isCollapsed}
                    user={userObject}
                  />
                ))}
              </Stack>
            </ScrollArea>
            <Stack py={16} gap={8}>
              {bottom.map((route) => (
                <SidebarLink
                  key={route.path}
                  route={route}
                  isCollapsed={isCollapsed}
                  user={userObject}
                />
              ))}
              <ThemeIcon
                size="lg"
                c="gray.4"
                style={{
                  transition: "transform 200ms",
                  transform: isCollapsed ? "rotate(180deg)" : "rotate(0)",
                }}
                onClick={() =>
                  setIsCollapsed((prevCollapsed) => !prevCollapsed)
                }
              >
                <IconChevronLeftPipe />
              </ThemeIcon>
            </Stack>
          </Stack>
        </AppShell.Navbar>
        <AppShell.Main>
          <Gtm />
          <NativeIntercom />
          <Container
            fluid
            mih="calc(100dvh - 64px)"
            h="calc(100dvh - 64px)"
            mah="calc(100dvh - 64px)"
            px={32}
            pt={16}
            pb={64}
            bg={colorScheme === "dark" ? "gray.9" : "gray.0"}
            styles={{ root: { overflowY: "scroll" } }}
          >
            <Outlet />
          </Container>
        </AppShell.Main>
      </AppShell>
    </PageContextProvider>
  )
}
