import { LineChart as BaseLineChart } from "@mantine/charts"

import { Filters } from "@costory/types/filters"

import {
  BUSINESS_METRIC_NAME,
  BUSINESS_METRIC_TECHNICAL_NAME,
  ChartTooltip,
} from "@costory/front/components/charts/ChartTooltip"
import { QueryWrapper } from "@costory/front/components/layout/QueryWrapper"
import { useExplorerQuery } from "@costory/front/queries/explore"
import { getCharteSerieColor, tickFormatter } from "@costory/front/utils/charts"
import { formatNumber } from "@costory/front/utils/format"

type Props = {
  filters: Filters
  height?: number
  withLegend?: boolean
  withMetricsLine: boolean
}

export const LineChart = ({
  filters,
  height,
  withLegend = true,
  withMetricsLine,
}: Props) => {
  const explorerQuery = useExplorerQuery(filters)
  return (
    <QueryWrapper query={explorerQuery}>
      {({ data }) => (
        <BaseLineChart
          h={height ?? "100%"}
          pr="50px"
          data={data.stackedBarChartData.arr}
          xAxisProps={{
            angle: 30,
            height: 60,
            textAnchor: "start",
            tickFormatter: (val: string) => tickFormatter(val, filters.aggBy),
          }}
          dataKey="agg_date"
          withLegend={withLegend}
          withRightYAxis={withMetricsLine}
          curveType="linear"
          valueFormatter={(value) =>
            formatNumber(value, "currency", 2, filters.currency)
          }
          rightYAxisProps={{
            tickFormatter: (value) => formatNumber(value, "decimal", 2),
          }}
          series={[
            ...data.stackedBarChartData.items.map((item, index) => ({
              name: item.name,
              color: getCharteSerieColor(index),
            })),
            ...(withMetricsLine
              ? [
                  {
                    name: BUSINESS_METRIC_TECHNICAL_NAME,
                    color: getCharteSerieColor(
                      data.stackedBarChartData.items.length,
                    ),
                    label: BUSINESS_METRIC_NAME,
                    yAxisId: "right",
                  },
                ]
              : []),
          ]}
          tooltipProps={{
            content: ({ label, payload, coordinate }) => (
              <ChartTooltip
                label={label}
                payload={payload}
                currency={filters.currency}
                coordinate={{
                  x: coordinate?.x ?? 0,
                }}
                aggBy={filters.aggBy}
              />
            ),
          }}
        />
      )}
    </QueryWrapper>
  )
}
