import { createContext, useContext, useState, ReactNode } from "react"

type PageContextType = {
  secondaryTitle?: string
  setSecondaryTitle: (title: string | undefined) => void
}

const PageContext = createContext<PageContextType | undefined>(undefined)

export const PageContextProvider = ({ children }: { children: ReactNode }) => {
  const [secondaryTitle, setSecondaryTitle] = useState<string | undefined>(
    undefined,
  )

  return (
    <PageContext.Provider
      value={{
        secondaryTitle,
        setSecondaryTitle,
      }}
    >
      {children}
    </PageContext.Provider>
  )
}

export const usePageContext = () => {
  const context = useContext(PageContext)
  if (!context) {
    throw new Error("usePageContext must be used within a PageProvider")
  }
  return context
}
