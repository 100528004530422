import { DashboardLayout } from "@costory/front/components/dashboard/DashboardLayout"
import { QueryWrapper } from "@costory/front/components/layout/QueryWrapper"
import { useDashboardCreationPageDataQuery } from "@costory/front/queries/dashboard"

export const DashboardCreationPage = () => {
  const query = useDashboardCreationPageDataQuery()

  return (
    <QueryWrapper query={query} allowEmptyArray>
      {({ data: { savedViews } }) => (
        <DashboardLayout savedViews={savedViews} />
      )}
    </QueryWrapper>
  )
}
