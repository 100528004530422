import { Anchor, Code, Stack, Text } from "@mantine/core"

export const DatadogDocs = () => {
  return (
    <Stack>
      <Text>
        You can import any metric of your Datadog account: the metric should
        already aggregate the data per day for example using a rollup with fixed
        rate:
      </Text>
      <Code>
        avg:system.cpu.user&#123;*&#125; by &#123;host&#125;.rollup(avg, daily,
        &apos;UTC&apos;)
      </Code>
      <Text>
        We rely on{" "}
        <Anchor
          href="https://docs.datadoghq.com/api/latest/metrics/#query-timeseries-points"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://docs.datadoghq.com/api/latest/metrics/#query-timeseries-points
        </Anchor>{" "}
        api.
      </Text>
    </Stack>
  )
}
