import { Stack, Group, Button, Loader, Title, Space } from "@mantine/core"
import { UseFormReturnType } from "@mantine/form"
import {
  IconArrowLeft,
  IconArrowRight,
  IconCloudX,
  IconRosetteDiscountCheck,
} from "@tabler/icons-react"
import _ from "lodash"
import { useMemo } from "react"

import { AwsDatasourceFormDataWithFileData } from "@costory/types/endpoints/metricsDatasources"

import { QueryWrapper } from "@costory/front/components/layout/QueryWrapper"
import { useValidateAws } from "@costory/front/queries/metricsDatasources"
import { getCharteSerieColor } from "@costory/front/utils/charts"

export const AwsValidation = ({
  form,
  handleClickBack,
  handleClickNext,
}: {
  form: UseFormReturnType<AwsDatasourceFormDataWithFileData>
  handleClickBack: () => void
  handleClickNext: () => void
}) => {
  const query = useValidateAws(form.getValues())
  const { data } = query
  useMemo(() => {
    if (!data) return { mappedData: [], seriesNames: [] }

    const groupedData = Object.entries(_.groupBy(data.records, "date")).map(
      ([date, metrics]) =>
        metrics.reduce(
          (acc, { metric, value }) => ({ ...acc, [metric]: value }),
          { date },
        ),
    )

    const uniqueMetrics = Array.from(
      new Set(data.records.map(({ metric }) => metric)),
    ).map((metric, index) => ({
      name: metric,
      color: getCharteSerieColor(index),
    }))

    form.setFieldValue("seriesNames", data.headers ?? [])
    form.setFieldValue("seriesData", data?.records || [])

    return { mappedData: groupedData, seriesNames: uniqueMetrics }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.records])

  return (
    <Stack h="100%">
      <Stack flex={1}>
        <QueryWrapper
          query={query}
          LoadingComponent={
            <Stack align="center">
              <Loader size="xl" />{" "}
              <Title order={2}>Validating AWS Parquet file...</Title>{" "}
            </Stack>
          }
          allowEmptyArray
        >
          {({ data }) => {
            if (data.isSuccess) {
              return (
                <Group justify="center">
                  <IconRosetteDiscountCheck
                    color="var(--mantine-color-green-6)"
                    size={120}
                  />
                  <Title order={2}>Your AWS credentials are validated</Title>
                  <Space h={10} />
                </Group>
              )
            }
            return (
              <Group justify="center">
                <IconCloudX color="var(--mantine-color-gray-6)" size={120} />
                <Title order={2}>
                  {data.errorCode
                    ? data.errorCode
                    : "Your AWS credentials are not valid"}
                </Title>
                <Space h={10} />
              </Group>
            )
          }}
        </QueryWrapper>
      </Stack>
      <Group justify="space-between" pb={20}>
        <Button
          onClick={handleClickBack}
          variant="outline"
          leftSection={<IconArrowLeft />}
        >
          Back
        </Button>
        <Button
          onClick={handleClickNext}
          disabled={!data?.isSuccess}
          rightSection={<IconArrowRight />}
        >
          Next
        </Button>
      </Group>
    </Stack>
  )
}
