import { showNotification } from "@mantine/notifications"
import { useMutation, useQuery } from "@tanstack/react-query"

import {
  AwsDatasourceFormData,
  DatadogCIVisibilityValidationFormData,
  DatadogValidationFormData,
  MetricsDatasourceRequests,
  MetricsDatasourceResponses,
} from "@costory/types/endpoints/metricsDatasources"

import { apiClient } from "@costory/front/lib/apiClient"
import { queryClient } from "@costory/front/lib/queryClient"

export const useMetricsDatasourcesListQuery = () => {
  return useQuery({
    queryKey: ["metricsDatasources"],
    queryFn: async () => {
      const response =
        await apiClient.get<MetricsDatasourceResponses.GetAllMetrics>(
          "/metricsDatasources",
        )
      return response.data
    },
  })
}
export const useMetricsDatasourcesListWithMetricsDefinitionQuery = () => {
  return useQuery({
    queryKey: ["metricsDatasourcesWithMetricsDefinition"],
    queryFn: async () => {
      const response =
        await apiClient.get<MetricsDatasourceResponses.GetAllMetrics>(
          "/metricsDatasources/with-metrics-definition",
        )
      return response.data
    },
  })
}

export const useCreateMetricsDatasourceMutation = () => {
  return useMutation({
    mutationKey: ["createMetricsDatasource"],
    mutationFn: async (
      data: MetricsDatasourceRequests.CreateMetricsDatasource,
    ) => {
      await apiClient.post<
        null,
        null,
        MetricsDatasourceRequests.CreateMetricsDatasource
      >("/metricsDatasources", data)
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ["metricsDatasources"] })
      showNotification({
        title: "Success",
        message:
          "Datasource created: you can now create metrics from the datasource using the button +",
        color: "green",
      })
    },
  })
}

export const useDeleteDatasourceMutation = () => {
  return useMutation({
    mutationFn: async (id: string) => {
      const response = await apiClient.delete(`/metricsDatasources/${id}`)
      return response.data
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ["metricsDatasources"] })
      showNotification({
        title: "Success",
        message: "Datasource deleted successfully",
        color: "green",
      })
    },
    onError: (error) => {
      showNotification({
        title: "Error",
        message: error.message,
        color: "red",
      })
    },
  })
}

export const useValidateGoogleSheetQuery = (id: string) => {
  return useQuery({
    queryKey: ["validateGoogleSheet", id],
    queryFn: async () => {
      const response =
        await apiClient.get<MetricsDatasourceResponses.ValidateGoogleSheet>(
          `/metricsDatasources/gsheet/validate/${id}`,
        )
      return response.data
    },
  })
}

export const useCreateGoogleSheetMutation = () => {
  return useMutation({
    mutationFn: async () => {
      const response =
        await apiClient.get<MetricsDatasourceResponses.CreateGoogleSheet>(
          "/metricsDatasources/gsheet/create/",
        )
      return response.data
    },
  })
}

export const useValidateDatadog = (data: DatadogValidationFormData) => {
  return useQuery({
    queryKey: ["validateDatadog", data],
    queryFn: async () => {
      const response =
        await apiClient.post<MetricsDatasourceResponses.ValidateDatadog>(
          "/metricsDatasources/datadog/validate/",
          data,
        )
      return response.data
    },
  })
}
export const useValidateDatadogCIVisibility = (
  data: DatadogCIVisibilityValidationFormData,
) => {
  return useQuery({
    queryKey: ["validateDatadogCIVisibility", data],
    queryFn: async () => {
      const response =
        await apiClient.post<MetricsDatasourceResponses.ValidateDatadog>(
          "/metricsDatasources/datadog-ci-visibility/validate/",
          data,
        )
      return response.data
    },
  })
}

export const useValidateAws = (data: AwsDatasourceFormData) => {
  return useQuery({
    queryKey: ["importFileFromContainer", data],
    queryFn: async () => {
      const response =
        await apiClient.post<MetricsDatasourceResponses.ParquetRecord>(
          "/metricsDatasources/aws/import-file-from-container",
          data,
        )
      return response.data
    },
  })
}
