import {
  Group,
  Menu,
  ActionIcon,
  UseTreeReturnType,
  Image,
  Stack,
  Modal,
  Button,
  Spoiler,
  Tooltip,
  Checkbox,
  Badge,
} from "@mantine/core"
import { UseFormReturnType } from "@mantine/form"
import { useDisclosure } from "@mantine/hooks"
import {
  IconTrash,
  IconChevronDown,
  IconArrowsSort,
  IconTrashX,
  IconBinoculars,
  IconX,
} from "@tabler/icons-react"

import {
  DigestResponses,
  DigestType,
  DisplayOptions,
  Position,
} from "@costory/types/endpoints/digest"

import { QueryWrapper } from "@costory/front/components/layout/QueryWrapper"
import { ButtonAddTo } from "@costory/front/pages/DigestV2/ButtonAddToDigest"
import { VdimHelper } from "@costory/front/pages/DigestV2/VdimHelper"
import {
  NodeTree,
  getQuery,
  deleteChild,
  addChild,
  deleteAllChilds,
  replaceDigestItem,
} from "@costory/front/pages/DigestV2/helper"
import { useAxesMapperQuery } from "@costory/front/queries/axes"
import { formatNumber } from "@costory/front/utils/format"

export function NodeRendering({
  node,
  isDiffMode,
  hasChildren,
  elementProps,
  expanded,
  tree,
  digest,
  checkedNodes,
  form,
  setCheckedNodes,
  exploreInDrawer,
}: {
  node: NodeTree
  isDiffMode: boolean
  form: UseFormReturnType<DigestType>
  hasChildren: boolean
  expanded: boolean
  tree: UseTreeReturnType
  checkedNodes: Map<string, boolean>
  digest: DigestResponses.Investigation
  setCheckedNodes: (newMap: Map<string, boolean>) => void
  elementProps: {
    className: string
    style: React.CSSProperties
    onClick: (event: React.MouseEvent) => void
    "data-selected": boolean | undefined
    "data-value": string
    "data-hovered": boolean | undefined
  }
  exploreInDrawer: () => void
}) {
  const changeOrder = (
    orderByColumn: DisplayOptions["orderByColumn"],
    order: DisplayOptions["order"],
  ) => {
    replaceDigestItem(
      form,
      {
        displayOptions: {
          displayContext: true,
          orderByColumn,
          order,
        },
      },
      node.nodeProps.id,
    )
  }
  const [openedZoomImage, handlersZoomImage] = useDisclosure()
  const checkedNodesIds = Array.from(checkedNodes.keys()).filter((key) =>
    checkedNodes.get(key),
  )
  return (
    <>
      <ModalZoomImage
        node={node}
        opened={openedZoomImage}
        close={handlersZoomImage.close}
      />
      <Stack>
        <Group
          gap={5}
          style={elementProps.style}
          className={elementProps.className}
        >
          <Checkbox.Indicator
            checked={checkedNodes.get(node.nodeProps.id) || false}
            onClick={() =>
              setCheckedNodes(
                new Map(checkedNodes).set(
                  node.nodeProps.id,
                  !(checkedNodes.get(node.nodeProps.id) || false),
                ),
              )
            }
          />
          <NodeText node={node} isDiffMode={isDiffMode} />
          <Menu shadow="md" width={200}>
            <Menu.Target>
              <Tooltip label="Observe / Add Context">
                <ActionIcon variant="transparent" aria-label="Settings">
                  <IconBinoculars
                    style={{ width: "70%", height: "70%" }}
                    stroke={1.5}
                  />
                </ActionIcon>
              </Tooltip>
            </Menu.Target>

            <Menu.Dropdown>
              <Menu.Label>Explore</Menu.Label>
              <Menu.Item onClick={exploreInDrawer}>
                See in Explorer Drawer
              </Menu.Item>
              <Menu.Item
                disabled={node.isOthers}
                onClick={() =>
                  window.open(
                    `/explorer?${getQuery(node).queryFormatted}`,
                    "_blank",
                  )
                }
              >
                See in Explorer
              </Menu.Item>
              <Menu.Label>Contextualize</Menu.Label>
            </Menu.Dropdown>
          </Menu>
          <Tooltip label="Hide Item (merge with Others)">
            <ActionIcon
              variant="transparent"
              aria-label="Settings"
              onClick={() => deleteChild(node.nodeProps.id, form)}
              disabled={node.isOthers}
            >
              <IconTrash style={{ width: "70%", height: "70%" }} stroke={1.5} />
            </ActionIcon>
          </Tooltip>
          <ButtonAddTo
            digest={digest}
            addChild={(direction) => {
              deleteAllChilds(node.nodeProps.id, form)
              addChild(form, direction, digest, node.nodeProps.id, () =>
                tree.expand(node.value),
              )
            }}
            node={node}
            disabled={node.isOthers}
          />
          {checkedNodesIds.includes(node.nodeProps.id) && (
            <VdimHelper
              checkedUuid={checkedNodesIds}
              form={form}
              cleanSelected={() => setCheckedNodes(new Map<string, boolean>())}
            />
          )}

          {hasChildren && (
            <>
              <Menu>
                <Tooltip label="Change ordering">
                  <Menu.Target>
                    <ActionIcon variant="transparent" aria-label="Settings">
                      <IconArrowsSort
                        style={{ width: "70%", height: "70%" }}
                        stroke={1.5}
                      />
                    </ActionIcon>
                  </Menu.Target>
                </Tooltip>
                <Menu.Dropdown>
                  <Menu.Item onClick={() => changeOrder("absChange", "desc")}>
                    Order by Absolute Diff Desc
                  </Menu.Item>
                  <Menu.Item onClick={() => changeOrder("cost", "desc")}>
                    Order by Absolute Cost Desc
                  </Menu.Item>
                </Menu.Dropdown>
              </Menu>
              <Tooltip label="Delete All Childs">
                <ActionIcon
                  onClick={() => deleteAllChilds(node.nodeProps.id, form)}
                >
                  <IconTrashX
                    style={{ width: "70%", height: "70%" }}
                    stroke={1.5}
                  />
                </ActionIcon>
              </Tooltip>

              <IconChevronDown
                onClick={() => tree.toggleExpanded(node.value)}
                size={18}
                style={{
                  transform: expanded ? "rotate(180deg)" : "rotate(0deg)",
                }}
              />
            </>
          )}
          {node.nodeProps.labels?.map((el) => (
            <Badge
              key={el}
              autoContrast
              color={
                COLORS[
                  el
                    .split("")
                    .reduce((acc, char) => acc + char.charCodeAt(0), 0) %
                    COLORS.length
                ]
              }
            >
              {el}
            </Badge>
          ))}
        </Group>
        {node.nodeProps.imgUrl && (
          <Group justify="left">
            <Spoiler maxHeight={50} showLabel="Show more" hideLabel="Hide">
              <div style={{ position: "relative", display: "inline-block" }}>
                <Image
                  onClick={() => handlersZoomImage.open()}
                  w={400}
                  src={node.nodeProps.imgUrl}
                  radius={2}
                />
                <Tooltip label="Delete Image">
                  <Button
                    variant="subtle"
                    color="black"
                    size="xs"
                    style={{
                      position: "absolute",
                      top: 5,
                      right: 5,
                      padding: 0,
                      width: 24,
                      height: 24,
                      borderRadius: "50%",
                    }}
                    onClick={() =>
                      replaceDigestItem(
                        form,
                        { imgUrl: null, filtersUrl: null },
                        node.nodeProps.id,
                      )
                    }
                  >
                    <IconX size={16} />
                  </Button>
                </Tooltip>
              </div>
            </Spoiler>
          </Group>
        )}
      </Stack>
    </>
  )
}

function NodeText({
  node,
  isDiffMode,
}: {
  node: NodeTree
  isDiffMode: boolean
}) {
  const queryMapper = useAxesMapperQuery()

  const value = node.isOthers
    ? "Others"
    : node.nodeProps.position.filter(
        (el: Position[0]) => el.key === node.nodeProps.direction,
      )[0]?.value || ""
  return (
    <QueryWrapper query={queryMapper}>
      {({ data }) => (
        <>
          {data.get(node.nodeProps.direction) || node.nodeProps.direction}{" "}
          {value}
          {isDiffMode && (
            <>
              {formatNumber(node.data.costPrevious)} {"->"}{" "}
            </>
          )}
          {formatNumber(node.data.costCurrent)}
        </>
      )}
    </QueryWrapper>
  )
}

function ModalZoomImage({
  node,
  opened,
  close,
}: {
  node: NodeTree
  opened: boolean
  close: () => void
}) {
  return (
    <>
      <Modal
        size="90%"
        opened={opened}
        onClose={close}
        title="Zoom"
        style={{ backgroundColor: "#F9FAFC" }}
      >
        <Group justify="center">
          <Image w={800} src={node.nodeProps.imgUrl} radius={2} />
        </Group>
      </Modal>
    </>
  )
}
const COLORS = [
  "red.6",
  "yellow.5",
  "yellow.2",
  "orange.5",
  "green.5",
  "blue.5",
  "indigo.5",
  "lime.5",
  "lime.9",
  "cyan.5",
]
