import {
  ActionIcon,
  Button,
  Group,
  InputWrapper,
  Modal,
  Radio,
  Space,
  Stack,
  Stepper,
  Text,
  TextInput,
  ThemeIcon,
  Title,
  Tooltip,
  useMantineTheme,
} from "@mantine/core"
import { useForm, UseFormReturnType, zodResolver } from "@mantine/form"
import { useDisclosure } from "@mantine/hooks"
import { modals } from "@mantine/modals"
import { showNotification } from "@mantine/notifications"
import {
  IconArrowRight,
  IconBrandGoogleDrive,
  IconFileTypeCsv,
  IconPlus,
  IconTrash,
} from "@tabler/icons-react"
import {
  createMRTColumnHelper,
  MantineReactTable,
  useMantineReactTable,
} from "mantine-react-table"
import { useCallback, useMemo, useState } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"
import { z, ZodSchema } from "zod"

import {
  AwsDatasourceCreationSchema,
  AwsDatasourceFormDataWithFileData,
  CsvDatasourceFormData,
  csvImportParametersSchema,
  CSVOrientation,
  DatadogCIVisibilityConfigurationSchema,
  DatadogCIVisibilityDatasourceFormData,
  DatadogCIVisibilityValidationSchema,
  DatadogConfigurationSchema,
  DatadogDatasourceFormData,
  DatadogValidationSeriesDataSchema,
  GSheetDatasourceFormData,
  gSheetImportParametersSchema,
  MetricsDatasourceFormData,
  MetricsDatasourceResponses,
  metricsDefinitionSchemaBase,
  metricsGeneralParametersSchema,
} from "@costory/types/endpoints/metricsDatasources"
import { MetricsDatasourceType } from "@costory/types/prisma-client"

import { DEFAULT_DATE_FORMAT } from "@costory/shared/const"
import dayjs from "@costory/shared/dayjs"

import { FeatureFlag } from "@costory/front/components/configcat/FeatureFlag"
import useFeatureToggle from "@costory/front/components/configcat/useFeatureToggle"
import { QueryWrapper } from "@costory/front/components/layout/QueryWrapper"
import { AwsColumnsMapping } from "@costory/front/pages/Datasources/components/AWS/AwsColumnsMapping"
import { AwsConfiguration } from "@costory/front/pages/Datasources/components/AWS/configuration"
import { AwsDocs } from "@costory/front/pages/Datasources/components/AWS/docs"
import { AwsValidation } from "@costory/front/pages/Datasources/components/AWS/validation"
import { DatadogCIVisibilityDocs } from "@costory/front/pages/Datasources/components/DataDogCIVisibility/docs"
import { DatadogCIVisibilityValidation } from "@costory/front/pages/Datasources/components/DataDogCIVisibility/validation"
import { DatadogConfiguration } from "@costory/front/pages/Datasources/components/Datadog/configuration"
import { DatadogValidation } from "@costory/front/pages/Datasources/components/Datadog/validation"
import { MetricsGapConfig } from "@costory/front/pages/Datasources/components/MetricsSettings"
import {
  useCreateMetricsDatasourceMutation,
  useDeleteDatasourceMutation,
  useMetricsDatasourcesListQuery,
} from "@costory/front/queries/metricsDatasources"
import { PropsWithData } from "@costory/front/utils/propsWithData"
import { getDefaultTableOptions } from "@costory/front/utils/table"

import { CsvColumnMappingForm } from "./components/CSV/CsvMapping"
import { CSVDropStep } from "./components/CSV/DropStep"
import { CSVDocs } from "./components/CSV/docs"
import { DatadogCIVisibilityConfiguration } from "./components/DataDogCIVisibility/configuration"
import { DatadogDocs } from "./components/Datadog/docs"
import { GSheetImportStep } from "./components/GSheet/ImportStep"
import { GSheetParamStep } from "./components/GSheet/ParamsStep"
import { GSheetDocs } from "./components/GSheet/docs"

const _MetricsDatasourcesPage = ({
  data: datasources,
}: PropsWithData<MetricsDatasourceResponses.GetAllMetrics>) => {
  const [
    isImportModalOpen,
    { open: openImportModal, close: closeImportModal },
  ] = useDisclosure(false)

  const { value: isAwsParquetEnabled } = useFeatureToggle(
    FeatureFlag.AWS_PARQUET,
  )

  const filteredDatasources = useMemo(
    () =>
      datasources.filter((datasource) =>
        isAwsParquetEnabled
          ? true
          : datasource.type !== MetricsDatasourceType.AWS,
      ),
    [datasources, isAwsParquetEnabled],
  )

  const [searchParams, setSearchParams] = useSearchParams()
  const isDetailsModalOpen = searchParams.get("details") !== null
  const openDetailsModal = (id: string) => {
    setSearchParams({ details: id })
  }
  const closeDetailsModal = () => {
    setSearchParams({})
  }

  const { mutateAsync: deleteDatasource, isPending: isDeleting } =
    useDeleteDatasourceMutation()

  const openDeleteModal = useCallback(
    (toDelete: MetricsDatasourceResponses.GetMetric) =>
      modals.openConfirmModal({
        title: "Please confirm you want to delete " + toDelete.name,
        labels: { confirm: "Confirm", cancel: "Cancel" },
        onConfirm: () => deleteDatasource(toDelete!.id),
      }),
    [deleteDatasource],
  )

  const columns = useMemo(() => {
    const columnHelper =
      createMRTColumnHelper<MetricsDatasourceResponses.GetMetric>()
    return [
      columnHelper.accessor("type", {
        header: "Type",
        enableColumnActions: false,
        enableSorting: false,
        Header: () => null,
        Cell: ({ cell }) => {
          const datasource = availableDatasources(isAwsParquetEnabled).find(
            ({ value }) => value === cell.getValue(),
          )

          if (!datasource) {
            return null
          }

          const { icon: IconComponent, label } = datasource

          return (
            <Group align="center">
              <Tooltip label={label}>
                <ThemeIcon size={50}>{IconComponent}</ThemeIcon>
              </Tooltip>
            </Group>
          )
        },
      }),
      columnHelper.accessor("name", {
        header: "Name",
        filterVariant: "select",
      }),
      columnHelper.accessor("updatedAt", {
        header: "Last Update",
        Cell: ({ cell }) =>
          dayjs(cell.getValue()).local().format(DEFAULT_DATE_FORMAT),
      }),
    ]
  }, [isAwsParquetEnabled])
  const navigate = useNavigate()
  const table = useMantineReactTable<MetricsDatasourceResponses.GetMetric>({
    columns,
    data: filteredDatasources,
    enableRowActions: true,
    positionActionsColumn: "last",
    renderRowActions: ({ row }) => (
      <>
        <Tooltip label="Delete datasource">
          <ActionIcon
            onClick={() => {
              openDeleteModal(row.original)
            }}
            loading={isDeleting}
          >
            <IconTrash />
          </ActionIcon>
        </Tooltip>
        <Tooltip label="Create new metric from this datasource">
          <ActionIcon
            onClick={() => {
              navigate(`/metrics-v2/new/${row.original.id}`)
            }}
          >
            <IconPlus />
          </ActionIcon>
        </Tooltip>
      </>
    ),
    ...getDefaultTableOptions(),
    mantineTableBodyRowProps: ({ row: { original: datasource } }) => ({
      onDoubleClick: () => {
        openDetailsModal(datasource.id)
      },
    }),
  })

  return (
    <Stack>
      <Group justify="flex-end">
        <Button
          onClick={() => {
            // setSelectedRow(undefined)
            openImportModal()
          }}
          leftSection={<IconPlus />}
        >
          Import new metrics datasource
        </Button>
      </Group>

      <MantineReactTable table={table} />
      <Modal
        classNames={{ content: "custom-modal-content" }}
        opened={isImportModalOpen}
        onClose={closeImportModal}
        size="70vw"
        title={<Title order={2}>Import new metrics datasource</Title>}
      >
        <Modal.Body h="70vh">
          <AddMetricsDatasourceFunnel onFinish={closeImportModal} />
        </Modal.Body>
      </Modal>
      <Modal
        opened={isDetailsModalOpen}
        onClose={closeDetailsModal}
        size="70vw"
      >
        <MetricsDatasourceDetails
          metricsDatasource={
            filteredDatasources.find(
              ({ id }) => id === searchParams.get("details"),
            )!
          }
        />
      </Modal>
    </Stack>
  )
}

const MetricsDatasourceDetails = ({
  metricsDatasource,
}: {
  metricsDatasource: MetricsDatasourceResponses.GetMetric
}) => {
  const { colors } = useMantineTheme()
  if (!metricsDatasource) {
    return null
  }

  return (
    <Stack>
      <Group>
        {metricsDatasource.type === MetricsDatasourceType.CSV && (
          <IconFileTypeCsv size={70} color={colors.gray[5]} />
        )}
        {metricsDatasource.type === MetricsDatasourceType.GoogleSheet && (
          <IconBrandGoogleDrive size={70} color={colors.gray[5]} />
        )}
        {metricsDatasource.type === MetricsDatasourceType.Datadog && (
          <img src="/img/datadog.svg" alt="Datadog" width={40} />
        )}
        <Stack gap={0}>
          <Title>Metrics datasource {metricsDatasource.name}</Title>
          <Group>
            <Text fw="bold">Owner: {metricsDatasource.owner}</Text>
            <Text>
              Last update:{" "}
              {dayjs(metricsDatasource.updatedAt).format("DD/MM/YYYY")}
            </Text>
          </Group>
        </Stack>
      </Group>
      <Space h={20} />
      <Title order={3}>Parameters</Title>
      {metricsDatasource.type === MetricsDatasourceType.CSV && (
        <>
          <InputWrapper label="Data orientation">
            <TextInput
              value={metricsDatasource.metadata.orientation}
              readOnly
            />
          </InputWrapper>
          <InputWrapper label="Dates column">
            <TextInput value={metricsDatasource.metadata.date} readOnly />
          </InputWrapper>
          {metricsDatasource.metadata.orientation === CSVOrientation.Rows && (
            <>
              <InputWrapper label="Metrics names column">
                <TextInput
                  value={metricsDatasource.metadata.metricNames}
                  readOnly
                />
              </InputWrapper>
              <InputWrapper label="Metrics values column">
                <TextInput
                  value={metricsDatasource.metadata.metricValues}
                  readOnly
                />
              </InputWrapper>
            </>
          )}
        </>
      )}
      {metricsDatasource.type === MetricsDatasourceType.GoogleSheet && (
        <>
          <Button
            onClick={() => {
              window.open(
                `https://docs.google.com/spreadsheets/d/${metricsDatasource.metadata.gSheetId}`,
                "_blank",
              )
            }}
            leftSection={<IconBrandGoogleDrive />}
          >
            Open in Google Drive
          </Button>
          <InputWrapper label="Google Sheet">
            <TextInput value={metricsDatasource.metadata.gSheetId} readOnly />
          </InputWrapper>
        </>
      )}
      {metricsDatasource.type === MetricsDatasourceType.Datadog && (
        <>
          <InputWrapper label="Datadog query">
            <TextInput value={metricsDatasource.metadata.query} readOnly />
          </InputWrapper>
        </>
      )}
    </Stack>
  )
}

export const MetricsDatasourcesPage = () => {
  const datasourceQuery = useMetricsDatasourcesListQuery()

  return (
    <QueryWrapper query={datasourceQuery} allowEmptyArray>
      {_MetricsDatasourcesPage}
    </QueryWrapper>
  )
}

interface AddMetricsDatasourceFunnelProps {
  onFinish: () => void
}
export const AddMetricsDatasourceFunnel = ({
  onFinish,
}: AddMetricsDatasourceFunnelProps) => {
  const [activeStep, setActiveStep] = useState(0)

  const { mutateAsync: createDatasource, isPending: isImporting } =
    useCreateMetricsDatasourceMutation()

  const form = useForm<MetricsDatasourceFormData>({
    validate: (values) => {
      if (activeStep === 0) {
        return zodResolver(metricsGeneralParametersSchema)(values)
      }

      if (activeStep === 1) {
        if (type === MetricsDatasourceType.Datadog) {
          return zodResolver(DatadogConfigurationSchema)(values)
        }
        if (type === MetricsDatasourceType.DatadogCIVisibility) {
          return zodResolver(DatadogCIVisibilityConfigurationSchema)(values)
        }
        return {}
      }
      if (activeStep == 3) {
        return zodResolver(importParametersSchemasFinalMap[values.type!])(
          values,
        )
      }

      return zodResolver(importParametersSchemasMap[values.type!])(values)
    },
  })

  const handleClickNext = () =>
    setActiveStep((current) => {
      if (form.validate().hasErrors) {
        console.log(form.errors)
        return current
      }
      return current < 4 ? current + 1 : current
    })
  const handleClickBack = () => {
    if (form.values.type == MetricsDatasourceType.AWS && activeStep === 3) {
      form.setValues((prev) => ({
        ...prev,
        metricsNames: [],
        metricsDefinition: [],
      }))
    }
    setActiveStep((prev) => (prev > 0 ? prev - 1 : prev))
  }

  const handleClickImport = async () => {
    if (form.validate().hasErrors) {
      return
    }

    try {
      await createDatasource(form.getValues())

      onFinish()
    } catch {
      showNotification({
        title: "Error",
        message: "Failed to create datasource",
        color: "red",
      })
    }
  }

  const { type } = form.getValues()
  const [metricsNames, setMetricsNames] = useState<string[]>([])
  const { value: isAwsParquetEnabled } = useFeatureToggle(
    FeatureFlag.AWS_PARQUET,
  )
  return (
    <Stack h="100%" justify="space-between">
      <Stepper
        active={activeStep}
        onStepClick={setActiveStep}
        allowNextStepsSelect={false}
        h="100%"
        styles={{
          content: {
            height: "95%",
          },
          stepIcon: {
            backgroundColor: "var(--mantine-color-primary-6)",
            color: "white",
          },
        }}
      >
        <Stepper.Step label={<Text fw="bold">Select datasource</Text>}>
          <Stack h="100%">
            <Stack flex={1}>
              <Group align="flex-start">
                <InputWrapper
                  label={
                    <Text fw="bold" span={true}>
                      Name
                    </Text>
                  }
                  required
                  w="40%"
                >
                  <TextInput {...form.getInputProps("name")} w="100%" />
                </InputWrapper>
              </Group>
              <InputWrapper
                label={
                  <Text fw="bold" span={true}>
                    Datasource type
                  </Text>
                }
                required
              >
                <Radio.Group {...form.getInputProps("type")}>
                  <Group align="left">
                    {availableDatasources(isAwsParquetEnabled).map(
                      ({ label, value, icon, disabled }) => {
                        const isSelected = type === value
                        const IconComponent = icon
                        return (
                          <Radio.Card
                            disabled={disabled}
                            key={value}
                            value={value}
                            w={180}
                            h={180}
                            p={8}
                            radius="md"
                            withBorder
                            style={{
                              cursor: disabled ? "not-allowed" : "pointer",
                              display: "flex",
                              flexDirection: "column",
                              borderColor: isSelected
                                ? "var(--mantine-color-primary-6)"
                                : "var(--mantine-color-gray-3)",
                              borderWidth: isSelected ? 2 : 1,
                            }}
                          >
                            <Stack
                              justify="center"
                              align="center"
                              w="100%"
                              h="100%"
                            >
                              {IconComponent}
                              <Text fw="bold" c={disabled ? "gray.8" : "black"}>
                                {disabled ? "Coming soon" : label}
                              </Text>
                            </Stack>
                          </Radio.Card>
                        )
                      },
                    )}
                  </Group>
                </Radio.Group>
              </InputWrapper>
            </Stack>
            {type === MetricsDatasourceType.CSV && <CSVDocs />}
            {type === MetricsDatasourceType.GoogleSheet && <GSheetDocs />}
            {type === MetricsDatasourceType.Datadog && <DatadogDocs />}
            {type === MetricsDatasourceType.DatadogCIVisibility && (
              <DatadogCIVisibilityDocs />
            )}
            {type === MetricsDatasourceType.AWS && <AwsDocs />}
            <Group justify="flex-end" pb={20}>
              <Button
                onClick={handleClickNext}
                disabled={!form.isValid()}
                rightSection={<IconArrowRight />}
              >
                Next
              </Button>
            </Group>
          </Stack>
        </Stepper.Step>

        {type === MetricsDatasourceType.Datadog && (
          <Stepper.Step label={<Text fw="bold">Configuration</Text>}>
            <DatadogConfiguration
              form={form as UseFormReturnType<DatadogDatasourceFormData>}
              handleClickBack={handleClickBack}
              handleClickNext={handleClickNext}
            />
          </Stepper.Step>
        )}
        {type === MetricsDatasourceType.DatadogCIVisibility && (
          <Stepper.Step label={<Text fw="bold">Configuration</Text>}>
            <DatadogCIVisibilityConfiguration
              form={
                form as UseFormReturnType<DatadogCIVisibilityDatasourceFormData>
              }
              handleClickBack={handleClickBack}
              handleClickNext={handleClickNext}
            />
          </Stepper.Step>
        )}
        {type === MetricsDatasourceType.AWS && (
          <Stepper.Step label={<Text fw="bold">Configuration</Text>}>
            <AwsConfiguration
              form={
                form as UseFormReturnType<AwsDatasourceFormDataWithFileData>
              }
              handleClickNext={handleClickNext}
              handleClickBack={handleClickBack}
            />
          </Stepper.Step>
        )}
        {type === MetricsDatasourceType.Datadog && (
          <Stepper.Step label={<Text fw="bold">Validation</Text>}>
            <DatadogValidation
              form={form as UseFormReturnType<DatadogDatasourceFormData>}
              handleClickBack={handleClickBack}
              handleClickNext={handleClickNext}
              setMetricsNames={setMetricsNames}
            />
          </Stepper.Step>
        )}
        {type === MetricsDatasourceType.DatadogCIVisibility && (
          <Stepper.Step label={<Text fw="bold">Validation</Text>}>
            <DatadogCIVisibilityValidation
              form={
                form as UseFormReturnType<DatadogCIVisibilityDatasourceFormData>
              }
              handleClickBack={handleClickBack}
              handleClickNext={handleClickNext}
              setMetricsNames={setMetricsNames}
            />
          </Stepper.Step>
        )}

        {type !== MetricsDatasourceType.Datadog &&
          type !== MetricsDatasourceType.DatadogCIVisibility &&
          type !== MetricsDatasourceType.AWS && (
            <Stepper.Step label={<Text fw="bold">File import</Text>}>
              {type === MetricsDatasourceType.CSV && (
                <CSVDropStep
                  form={form}
                  handleClickBack={handleClickBack}
                  handleClickNext={handleClickNext}
                />
              )}
              {type === MetricsDatasourceType.GoogleSheet && (
                <GSheetParamStep
                  form={form as UseFormReturnType<GSheetDatasourceFormData>}
                  handleClickBack={handleClickBack}
                  handleClickNext={handleClickNext}
                />
              )}
            </Stepper.Step>
          )}
        {type === MetricsDatasourceType.AWS && (
          <Stepper.Step label={<Text fw="bold">Validation</Text>}>
            <AwsValidation
              form={
                form as UseFormReturnType<AwsDatasourceFormDataWithFileData>
              }
              handleClickBack={handleClickBack}
              handleClickNext={handleClickNext}
            />
          </Stepper.Step>
        )}

        {type !== MetricsDatasourceType.Datadog &&
          type !== MetricsDatasourceType.DatadogCIVisibility && (
            <Stepper.Step label={<Text fw="bold">Columns mapping</Text>}>
              {type === MetricsDatasourceType.CSV && (
                <CsvColumnMappingForm
                  form={form as UseFormReturnType<CsvDatasourceFormData>}
                  handleClickBack={handleClickBack}
                  handleClickImport={handleClickNext}
                />
              )}
              {type === MetricsDatasourceType.GoogleSheet && (
                <GSheetImportStep
                  form={form as UseFormReturnType<GSheetDatasourceFormData>}
                  handleClickBack={handleClickBack}
                  handleClickNext={handleClickNext}
                />
              )}
              {type === MetricsDatasourceType.AWS && (
                <AwsColumnsMapping
                  form={
                    form as UseFormReturnType<AwsDatasourceFormDataWithFileData>
                  }
                  handleClickBack={handleClickBack}
                  handleClickImport={handleClickNext}
                />
              )}
            </Stepper.Step>
          )}
        <Stepper.Step label={<Text fw="bold">Metrics gap configuration</Text>}>
          <MetricsGapConfig
            form={form}
            handleClickBack={handleClickBack}
            handleClickImport={handleClickImport}
            isImporting={isImporting}
            metricsNames={metricsNames}
          />
        </Stepper.Step>
      </Stepper>
    </Stack>
  )
}

const availableDatasources = (isAwsParquetEnabled: boolean) => [
  {
    label: "CSV",
    value: MetricsDatasourceType.CSV,
    icon: <img src="/img/csv.svg" alt="CSV" width={50} />,
  },
  {
    label: "Google Sheet",
    value: MetricsDatasourceType.GoogleSheet,
    icon: <img src="/img/gsheet.svg" alt="Google Sheets" width={50} />,
  },
  {
    label: "Datadog",
    value: MetricsDatasourceType.Datadog,
    icon: <img src="/img/datadog.svg" alt="Datadog" width={50} />,
  },
  {
    label: "Datadog CI Visibility",
    value: MetricsDatasourceType.DatadogCIVisibility,
    icon: <img src="/img/datadog.svg" alt="Datadog" width={50} />,
  },
  ...(isAwsParquetEnabled
    ? [
        {
          label: "AWS S3 bucket",
          value: MetricsDatasourceType.AWS,
          icon: <img src="/img/aws.svg" alt="Aws" width={50} />,
        },
      ]
    : []),
  {
    label: "Big Query",
    value: "BigQuery",
    icon: <img src="/img/bigquery.svg" alt="Big Query" width={50} />,
    disabled: true,
  },
]

const importParametersSchemasMap = {
  [MetricsDatasourceType.CSV]: csvImportParametersSchema.superRefine(
    ({ orientation, metricNames, metricValues }, ctx) => {
      if (orientation === CSVOrientation.Rows && !metricValues) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "Required",
          path: ["metricValues"],
        })
      }
      if (orientation === CSVOrientation.Rows && !metricNames) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "Required",
          path: ["metricNames"],
        })
      }
    },
  ),
  [MetricsDatasourceType.GoogleSheet]: gSheetImportParametersSchema,
  [MetricsDatasourceType.Datadog]: DatadogValidationSeriesDataSchema,
  [MetricsDatasourceType.DatadogCIVisibility]:
    DatadogCIVisibilityValidationSchema,
  [MetricsDatasourceType.AWS]: AwsDatasourceCreationSchema,
} as const satisfies Record<MetricsDatasourceType, ZodSchema>

const importParametersSchemasFinalMap = {
  [MetricsDatasourceType.CSV]: csvImportParametersSchema
    .extend({ metricsDefinition: metricsDefinitionSchemaBase })
    .superRefine(({ orientation, metricNames, metricValues }, ctx) => {
      if (orientation === CSVOrientation.Rows && !metricValues) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "Required",
          path: ["metricValues"],
        })
      }
      if (orientation === CSVOrientation.Rows && !metricNames) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "Required",
          path: ["metricNames"],
        })
      }
    }),
  [MetricsDatasourceType.GoogleSheet]: gSheetImportParametersSchema.extend({
    metricsDefinition: metricsDefinitionSchemaBase,
  }),
  [MetricsDatasourceType.Datadog]: DatadogValidationSeriesDataSchema.extend({
    metricsDefinition: metricsDefinitionSchemaBase,
  }),
  [MetricsDatasourceType.DatadogCIVisibility]:
    DatadogCIVisibilityValidationSchema.extend({
      metricsDefinition: metricsDefinitionSchemaBase,
    }),
  [MetricsDatasourceType.AWS]: AwsDatasourceCreationSchema,
} as const satisfies Record<MetricsDatasourceType, ZodSchema>
