import {
  ComboboxData,
  Group,
  Select,
  Stack,
  Tooltip,
  Text,
  Popover,
  ActionIcon,
  Indicator,
} from "@mantine/core"
import { DatePickerInput, DatesRangeValue } from "@mantine/dates"
import { UseFormReturnType } from "@mantine/form"
import { useClickOutside, useDisclosure } from "@mantine/hooks"
import { IconCalendar } from "@tabler/icons-react"
import { useCallback, useState } from "react"

import { EventsBody } from "@costory/types/endpoints/events"
import {
  AGG_BY_OPTIONS,
  CurrencyOptions,
  DatePreset,
  Filters,
} from "@costory/types/filters"

import dayjs from "@costory/shared/dayjs"
import {
  computeComparisonPeriodDates,
  computePresetDates,
  getDatePresetOptions,
  LIMIT_OPTIONS,
} from "@costory/shared/utils/filters"

import { QueryBuilder } from "@costory/front/components/QueryBuilder"
import { TagsSearch } from "@costory/front/pages/Events"
import { toUTCDate } from "@costory/front/utils/filters"

type Props = {
  form: UseFormReturnType<Filters>
  metricsOptions: ComboboxData
  groupByOptions: ComboboxData
  queryError: boolean
  drawerMode?: boolean
}

export const FilterBar = ({
  form,
  metricsOptions,
  groupByOptions,
  queryError,
  drawerMode = false,
}: Props) => {
  const [dateRange, setDateRange] = useState<DatesRangeValue>([
    form.getValues().from,
    form.getValues().to,
  ])
  const [previousDateRange, setPreviousDateRange] = useState<DatesRangeValue>([
    form.getValues().previousFrom,
    form.getValues().previousTo,
  ])

  const handleSelectDateRange = useCallback(
    (dateRange: DatesRangeValue) => {
      const [fromDate, toDate] = dateRange
      setDateRange([fromDate, toDate])

      // So date values match the ones from z.coerce.date()
      const from = fromDate ? toUTCDate(dayjs(fromDate).toDate()) : null
      const to = toDate ? toUTCDate(dayjs(toDate).toDate()) : null

      if (from && to) {
        const previous = computeComparisonPeriodDates(from, to, null)
        form.setValues({
          from,
          to,
          datePreset: null,
          ...previous,
        })
        setPreviousDateRange([previous.previousFrom, previous.previousTo])
      }
    },
    [form],
  )

  const handleSelectDatePreset = useCallback(
    (selectedPreset: string | null) => {
      if (!selectedPreset) return
      const presetDates = computePresetDates(selectedPreset as DatePreset)
      setDateRange([presetDates.from, presetDates.to])
      setPreviousDateRange([presetDates.previousFrom, presetDates.previousTo])
      form.setValues(presetDates)
    },
    [form],
  )
  return (
    <>
      <Stack gap="xs">
        <Group align="flex-end">
          <Select
            w={180}
            label="Measurement"
            placeholder="Select measurement"
            allowDeselect={false}
            data={metricsOptions}
            {...form.getInputProps("metricId")}
            key={form.key("metricId")}
          />{" "}
          <Select
            w={250}
            label="Group By"
            placeholder="Select group by"
            allowDeselect={false}
            data={groupByOptions}
            searchable
            {...form.getInputProps("groupBy")}
            key={form.key("groupBy")}
          />
          <Group align="flex-end">
            <Select
              w={150}
              data={getDatePresetOptions()}
              label="Period Type"
              placeholder="Select date preset"
              {...form.getInputProps("datePreset")}
              key={form.key("datePreset")}
              onChange={handleSelectDatePreset}
            />
            <Group align="flex-end" gap={0}>
              <DatePickerInput
                valueFormat="YYYY MMM DD"
                label="Selected Period"
                allowSingleDateInRange={false}
                placeholder="Select time period"
                type="range"
                w={220}
                value={dateRange}
                onChange={handleSelectDateRange}
                styles={{
                  input: {
                    borderRight: 0,
                    borderTopRightRadius: 0,
                    borderBottomRightRadius: 0,
                  },
                }}
              />
              <DatePickerInput
                valueFormat="YYYY MMM DD"
                label="Reference Period"
                labelProps={{ style: { textAlign: "right", width: "100%" } }}
                type="range"
                w={220}
                value={previousDateRange}
                disabled
                styles={{
                  input: {
                    borderTopLeftRadius: 0,
                    borderBottomLeftRadius: 0,
                  },
                }}
              />
            </Group>
          </Group>
        </Group>
        {!drawerMode && (
          <QueryBuilder
            key={form.key("whereClause")}
            defaultValue={form.getValues().whereClause}
            queryError={queryError}
            {...form.getInputProps("whereClause")}
          />
        )}
      </Stack>
    </>
  )
}

export const Show = ({ form }: { form: UseFormReturnType<Filters> }) => (
  <Select
    w={70}
    label="Show"
    placeholder="Select limit"
    allowDeselect={false}
    data={LIMIT_OPTIONS}
    {...form.getInputProps("limit")}
    key={form.key("limit")}
    value={form.getValues().limit.toString()}
    onChange={(value) =>
      value ? form.setFieldValue("limit", parseInt(value)) : null
    }
  />
)

export const CurrencyPicker = ({
  form,
  openModal,
}: {
  form: UseFormReturnType<Filters>
  openModal: () => void
}) => (
  <Group align="center" gap="xs">
    <Tooltip label="Show currency exchange rates used">
      <Text
        role="button"
        size="sm"
        style={{ cursor: "pointer", textDecoration: "dotted underline" }}
        onClick={() => openModal()}
      >
        Currency
      </Text>
    </Tooltip>
    <Select
      w={90}
      label=""
      placeholder="Currency"
      allowDeselect={false}
      data={CurrencyOptions}
      {...form.getInputProps("currency")}
      key={form.key("currency")}
      comboboxProps={{}}
    />
  </Group>
)

export const AggregateBy = ({ form }: { form: UseFormReturnType<Filters> }) => (
  <Select
    w={100}
    label="Aggregate By"
    placeholder="Select aggregation"
    allowDeselect={false}
    data={AGG_BY_OPTIONS}
    {...form.getInputProps("aggBy")}
    key={form.key("aggBy")}
  />
)

export const EventsTags = ({ form }: { form: UseFormReturnType<Filters> }) => {
  const [opened, { open, close }] = useDisclosure(false)
  // Close the popover when clicking outside this ref's element
  const ref = useClickOutside(() => close())

  const [eventsTags, setEventsTags] = useState<EventsBody["tags"]>([])
  const onSetEventsTags = (tags: EventsBody["tags"]) => {
    setEventsTags(tags)
    form.getInputProps("eventsTagsSearch").onChange(tags)
  }

  return (
    <>
      <Popover
        opened={opened}
        trapFocus
        position="left"
        withArrow
        arrowSize={10}
        shadow="md"
        styles={{
          dropdown: {
            minWidth: "350px",
          },
        }}
      >
        <Popover.Target>
          <Tooltip label="Display Events">
            <Indicator
              inline
              label={eventsTags.length.toString()}
              size={16}
              offset={10}
              disabled={!eventsTags.length}
            >
              <ActionIcon onClick={open} size="xl">
                <IconCalendar
                  style={{ width: "80%", height: "80%" }}
                  stroke={1.5}
                />
              </ActionIcon>
            </Indicator>
          </Tooltip>
        </Popover.Target>
        <Popover.Dropdown ref={ref}>
          <TagsSearch
            withinPortal={false}
            label="Display Events"
            w="400px"
            tags={form.getValues().eventsTagsSearch || []}
            setTags={(e) => onSetEventsTags(e)}
            key={form.key("eventsTagsSearch")}
          />
        </Popover.Dropdown>
      </Popover>
    </>
  )
}
